import {
  getAccessToken,
  getEmulateId,
  getIdToken,
  getLastAuthUser,
  getRefreshToken,
} from "Services/Auth/auth.service";
import { clientId, userPoolId } from "Services/userPool";

import { MainAppDomain } from "../Constants";

const getParams = () => {
  const refreshToken = getRefreshToken();
  const username = getLastAuthUser();
  const accessToken = getAccessToken();
  const idToken = getIdToken();
  const emulateInfluencerId = getEmulateId();

  return {
    refreshToken,
    username,
    accessToken,
    idToken,
    emulateInfluencerId,
  };
};

export const goToMainAppProfile = ({ pathname, isReturn = false }) => {
  const { refreshToken, username, accessToken, idToken, emulateInfluencerId } =
    getParams();
  const path = pathname ? `${MainAppDomain}/${pathname}` : "";
  let url = `${path}?&username=${username}&refreshToken=${refreshToken}&accessToken=${accessToken}&idToken=${idToken}&clientId=${clientId}&userPoolId${userPoolId}`;

  if (emulateInfluencerId) {
    url = `${url}&emulateInfluencerId=${emulateInfluencerId}`;
  }

  if (isReturn) {
    return url;
  } else {
    window.open(url);
  }
};
