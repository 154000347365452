import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  IconButton,
  Typography,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { ReactComponent as IconCamera } from "assets/svg/icon-camera.svg";
import { ReactComponent as IconClose } from "assets/svg/icon-close.svg";
import { ReactComponent as IconMedia } from "assets/svg/media-icon.svg";
import { ReactComponent as IconMusic } from "assets/svg/music-symbol.svg";
import { ReactComponent as IconPlay } from "assets/svg/play-icon.svg";
import { ReactComponent as IconQuestionMark } from "assets/svg/question-mark-icon.svg";
import { CustomTooltip } from "Components/CustomTooltip/CustomTooltip";
import { MEDIA_TYPES, MODAL_TYPE, PATHS, YOUTUBE_REGEX } from "Constants/index";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { setModal, setSnackbar } from "Redux/appSlice";
import { fetchPostById, getStripeAccount } from "Redux/communitySlice";
import {
  createScheduledPost,
  updatePostScheduled,
} from "Redux/scheduledPostsSlice";
import {
  getUserMeSubscriptionPrice,
  getUsersMeStripeData,
  getUsersMeStripeDataStatus,
} from "Redux/selectors/userSelector";
import { LocalizationContext } from "Services/Localization/LocalizationContext";
import { useIsMobile } from "Utils/Hooks";
import * as yup from "yup";

import { getPostsLoader } from "../../Redux/selectors/communitySelectors";
import SetupStripeWarningModal from "../Modal/SetupStripeWarningModal";
import Editor from "../QuillEditor/Editor";
import { parseLinks } from "../QuillEditor/utils";
import { SwitchIOS } from "../SwitchIOS/SwitchIOS";
import {
  AddMediaButton,
  PollButton,
  PollSection,
  TooltipTypes,
  TypesErrorBox,
  YoutubeButton,
  YoutubeSection,
} from "./components";
import styles from "./PostCreator.module.scss";
import { EDITOR_TOOLBAR_ITEMS, getMediaType, toggleActiveVideo } from "./utils";

export const PostCreator = ({
  isUpdate,
  sendButtonTitle,
  formClassname,
  selectedPostId,
  isSchedule,
  closePostCreatorModal,
  onSubmitHandler,
  post,
}) => {
  const { t } = useContext(LocalizationContext);
  const videoContainerRef = useRef(null);
  const reactQuillRef = useRef(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { postId = selectedPostId } = useParams();
  const isMobile = useIsMobile();

  const postByIdLoading = useSelector(getPostsLoader);
  const stripeAccountStatus = useSelector(getUsersMeStripeDataStatus);
  const subscriptionPrice = useSelector(getUserMeSubscriptionPrice);
  const stripeData = useSelector(getUsersMeStripeData);

  const [stripeUrl, setStripeUrl] = useState(null);
  const [stripeLoader, setStripeLoader] = useState(false);
  const [isYoutubeSection, setYoutubeSection] = useState(false);
  const [isPollSection, setPollSection] = useState(false);
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [message, setMessage] = useState({ value: "", isValid: false });
  const [isErrorType, setErrorType] = useState("");
  const [shouldHideTooltips, setShouldHideTooltips] = useState(false);
  const [freeAccess, setFreeAccess] = useState(true);
  const [allowComments, setAllowComments] = useState(true);
  const [isRequireSubscriptionTooltip, setRequireSubscriptionTooltip] =
    useState(false);

  const schema = yup.object({
    allowComments: yup.boolean().notRequired(),
    freeAccess: yup.boolean().notRequired(),
    ...(isYoutubeSection && {
      youtubeLink: yup
        .string()
        .trim()
        .notRequired()
        .matches(YOUTUBE_REGEX, t("community.invalidLink")),
    }),
    ...(isPollSection && {
      pollQuestion: yup
        .string()
        .max(100, t("community.pollValidationQuestionError"))
        .required(t("community.pollErrorQuestion")),
      pollOptions: yup
        .array()
        .of(
          yup.object().shape({
            option: yup
              .string()
              .max(45, t("community.pollValidationOptionError"))
              .required(t("community.pollErrorOption")),
          })
        )
        .test(
          "unique-options",
          t("community.pollValidationOptionErrorDuplicate"),
          function (options) {
            const optionValues = options.map((o) => o.option);
            const uniqueOptions = new Set(optionValues);
            return uniqueOptions.size === optionValues.length;
          }
        ),
      duration: yup
        .mixed()
        .nullable(true)
        .transform((originalValue) => {
          return originalValue === "" || originalValue === undefined
            ? null
            : originalValue;
        }),
    }),
  });

  const {
    handleSubmit,
    getValues,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      duration: null,
      pollOptions: [{ option: "" }, { option: "" }],
      pollQuestion: "",
    },
  });

  const onCancelPaid = () => setStripeUrl(null);

  const getStripeAccountLinkUrl = (callback) => {
    setStripeLoader(true);
    dispatch(getStripeAccount())
      .unwrap()
      .then((res) => {
        if (res?.url) callback && callback(res?.url);
      })
      .catch((e) => {
        dispatch(
          setSnackbar({
            open: true,
            message: e?.message || t("errors.defaultError"),
            severity: "error",
            autoHideDuration: 3000,
          })
        );
      })
      .finally(() => setStripeLoader(false));
  };

  const donationUnlockHandler = () => {
    getStripeAccountLinkUrl((url) => setStripeUrl(url));
  };

  const isSubscriptionDisable =
    stripeAccountStatus !== "active" || !subscriptionPrice;

  const subscriptionOptionTooltip =
    stripeAccountStatus !== "active" ? (
      <Box className={styles.tooltipOptions}>
        {stripeLoader ? (
          <CircularProgress size={18} className={styles.tooltipOptionsLoader} />
        ) : null}
        {t("default.subscriptionOptionTooltipSetupPayment1")}{" "}
        <Box
          component="span"
          className={styles.tooltipButton}
          onClick={donationUnlockHandler}
        >
          {t("default.subscriptionOptionTooltipSetupPayment2")}
        </Box>{" "}
        {t("default.subscriptionOptionTooltipSetupPayment3")}
      </Box>
    ) : !subscriptionPrice ? (
      t("default.subscriptionOptionTooltipSubscription")
    ) : (
      t("default.subscriptionOptionTooltipDefault")
    );

  const updateMediaFiles = () => {
    if (post?.images?.length) {
      const images = post.images.map((imageUrl) => ({
        imageUrl,
        type: MEDIA_TYPES.IMAGE,
      }));
      setFiles(images);
    } else if (post?.videos?.length) {
      setFiles([{ videoUrl: post.videos[0], type: MEDIA_TYPES.VIDEO }]);
    } else if (post?.audios?.length) {
      const audios = post.audios.map((audioUrl) => ({
        audioUrl,
        type: MEDIA_TYPES.AUDIO,
      }));
      setFiles(audios);
    }
  };

  const messageValidation = (value, _, __, editor) => {
    const messageValue = editor ? editor.getText() : value;
    if (messageValue?.trim()?.length) {
      setMessage({ value, isValid: true });
    } else {
      setMessage({ value, isValid: false });
    }
  };

  const openSchedulePost = ({ payload }) => {
    const data = payload || getValues();
    const mediaType = files.length ? getMediaType(files[0]) : null;
    const handlerFunc = postId ? updatePostScheduled : createScheduledPost;

    dispatch(
      setModal({
        type: MODAL_TYPE.SCHEDULE_MODAL,
        open: true,
        payload: {
          ...payload,
          message: parseLinks(message?.value),
          ...(postId && { postId }),
          allowComments: allowComments,
          freeAccess: !freeAccess,
          ...(files.length && { mediaType }),
          files: files,
          youtubeUrl: isUpdate ? data.youtubeUrl : data.youtubeLink,
        },
        handler: (data, cb) =>
          dispatch(handlerFunc(data))
            .then(() => {
              dispatch(setModal({ open: false, payload: null }));
              if (!payload?.postId) {
                navigate(PATHS.SCHEDULED_POSTS);
              } else {
                dispatch(
                  setSnackbar({
                    message: t("community.updateSuccess"),
                    open: true,
                    right: "20px",
                    left: "unset",
                  })
                );
              }
            })
            .finally(() => {
              cb(false);
            }),
      })
    );
  };

  const onSubmit = (data) => {
    setLoading(true);
    const mediaType = files.length ? getMediaType(files[0]) : null;
    const payload = {
      scheduledAt: post?.scheduledAt,
      message: parseLinks(message?.value),
      ...(postId && { postId }),
      allowComments: allowComments,
      freeAccess: !freeAccess,
      ...(files.length && { mediaType }),
      files: files,
      youtubeUrl: data.youtubeLink,
      pollQuestion: data.pollQuestion,
      question: data.pollQuestion,
      options: data.pollOptions,
      duration: data.duration,
    };
    if (isSchedule && isUpdate) {
      openSchedulePost({
        payload: { ...payload, mainTitle: t("community.editPost") },
      });
      closePostCreatorModal();
      return;
    }
    dispatch(onSubmitHandler(payload))
      .then((res) => {
        dispatch(
          setSnackbar({
            message: isUpdate
              ? t("community.updateSuccess")
              : t("community.newPostSuccess"),
            open: true,
            right: "20px",
            left: "unset",
          })
        );
        navigate(PATHS.COMMUNITY);
      })
      .catch((e) => {
        dispatch(
          setSnackbar({
            open: true,
            message: e?.message || t("community.newPostNotSuccess"),
            right: "20px",
            left: "unset",
          })
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleRemoveItem = (index) => {
    const newFiles = [...files];
    newFiles.splice(index, 1);
    setFiles(newFiles);
  };

  useEffect(() => {
    if (post?.data?.youtubeUrl) {
      setYoutubeSection(true);
      setValue("youtubeLink", post?.data?.youtubeUrl);
    }
  }, [post?.data?.youtubeUrl]);

  useEffect(() => {
    if (post?.poll) {
      const { question, options, duration } = post?.poll;
      setPollSection(true);
      setValue("pollQuestion", question);
      setValue("pollOptions", options);
      setValue("duration", duration);
    }
  }, [post?.poll]);

  useEffect(() => {
    if (postId) {
      if ((+postId && isUpdate && !post?.id) || +postId !== +post?.id) {
        dispatch(fetchPostById({ postId }));
      }
    }
  }, [postId, post?.id]);

  // useEffect(() => {
  //   return () => {
  //     dispatch(setPost(null));
  //   };
  // }, []);

  useEffect(() => {
    if (post) {
      setAllowComments(post?.allowComments);
      if (stripeData?.capabilities?.transfers === "active") {
        setFreeAccess(!post?.freeAccess);
      } else {
        setFreeAccess(false);
      }
      messageValidation(post?.message);
      updateMediaFiles();
    } else {
      setAllowComments(true);
      if (stripeData?.capabilities?.transfers === "active") {
        setFreeAccess(true);
      } else {
        setFreeAccess(false);
      }
    }
  }, [post]);

  const PostIdLoader = () =>
    postByIdLoading ? (
      <Box className={styles.newPostFormLoader}>
        <CircularProgress />
      </Box>
    ) : null;

  return (
    <Box className={`${styles.newPostForm} ${formClassname}`}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl>
          <PostIdLoader />
          <Editor
            data={message?.value}
            reactQuillRef={reactQuillRef}
            editorToolbarId={"toolbar"}
            customFormats={EDITOR_TOOLBAR_ITEMS}
            onChange={messageValidation}
          />
        </FormControl>
        <FormControl>
          <Box className={styles.newPostFormEmptyActions}>
            {isMobile ? (
              <Box className={styles.attachBox}>
                <Typography variant="body2" className={styles.attach}>
                  {t("community.attach")}
                </Typography>
                <Box className={styles.typesBox}>
                  <TooltipTypes>
                    <IconQuestionMark />
                  </TooltipTypes>
                  <Typography component="p" variant="body1">
                    {t("community.fileTypesAccept")}
                  </Typography>
                </Box>
              </Box>
            ) : null}
            <Box className={styles.buttonsContainer}>
              <CustomTooltip
                tooltipClassName={styles.mediaChangeTooltip}
                isArrow={true}
                shouldHideTooltip={shouldHideTooltips}
                title={
                  <Box>
                    <Typography
                      variant="body3_medium"
                      className={styles.mediaChangeTooltipContent}
                    >
                      {t("community.replaceYtbVideoTooltipTop")}
                      <br />
                      {t("community.replaceYtbVideoTooltip")}
                    </Typography>
                  </Box>
                }
                isDisabled={
                  files.length > 0 || (files.length === 0 && !isYoutubeSection)
                }
              >
                <Box>
                  <AddMediaButton
                    files={files}
                    setFiles={setFiles}
                    setErrorType={setErrorType}
                    isActive={files.length > 0}
                    icon={<IconCamera />}
                    isAnotherMediaType={isYoutubeSection}
                    handleMediaChange={() =>
                      isYoutubeSection &&
                      setYoutubeSection(false) &&
                      setFiles([])
                    }
                    setShouldHideTooltips={setShouldHideTooltips}
                  />
                </Box>
              </CustomTooltip>
              <PollButton
                isPollSection={isPollSection}
                setPollSection={setPollSection}
              />
              <CustomTooltip
                tooltipClassName={styles.mediaChangeTooltip}
                isArrow={true}
                shouldHideTooltip={shouldHideTooltips}
                title={
                  <Box>
                    <Typography
                      variant="body3_medium"
                      className={styles.mediaChangeTooltipContent}
                    >
                      {t("community.replaceMediaTooltipTop")}
                      <br />
                      {t("community.replaceMediaTooltip")}
                    </Typography>
                  </Box>
                }
                isDisabled={
                  isYoutubeSection || (files.length === 0 && !isYoutubeSection)
                }
              >
                <Box>
                  <YoutubeButton
                    isAnotherMediaType={files.length > 0}
                    isYoutubeSection={isYoutubeSection}
                    setYoutubeSection={setYoutubeSection}
                    handleMediaChange={() =>
                      files.length > 0 &&
                      setFiles([]) &&
                      setYoutubeSection(true)
                    }
                    setShouldHideTooltips={setShouldHideTooltips}
                  />
                </Box>
              </CustomTooltip>
            </Box>
            {!isMobile ? (
              <Box className={styles.typesBox}>
                <TooltipTypes>
                  <IconQuestionMark />
                </TooltipTypes>
                <Typography component="p" variant="body1">
                  {t("community.fileTypesAccept")}
                </Typography>
              </Box>
            ) : null}
          </Box>
          {!!files && files.length ? (
            <Box className={styles.galleryWrapper}>
              <Box className={styles.galleryWrapperContent}>
                {files.map((file, index) => {
                  return (
                    <Box className={styles.galleryItem} key={`item-${index}`}>
                      <IconButton
                        className={styles.galleryRemoveItemBtn}
                        onClick={() => handleRemoveItem(index)}
                      >
                        <IconClose />
                      </IconButton>
                      {file?.imageUrl ? (
                        <img src={file?.imageUrl} alt="" />
                      ) : file?.videoUrl ? (
                        <Box
                          className={styles.newPostFormVideoWrapper}
                          data-active
                          ref={videoContainerRef}
                          onClick={toggleActiveVideo(videoContainerRef)}
                        >
                          <IconPlay />
                          <video
                            src={file.videoUrl}
                            width={175}
                            height={100}
                            playsInline
                          />
                        </Box>
                      ) : file?.audioUrl ? (
                        <Box className={styles.audioBox}>
                          <IconMusic />
                        </Box>
                      ) : null}
                    </Box>
                  );
                })}
                <Box className={styles.galleryItem}>
                  <AddMediaButton
                    files={files}
                    setFiles={setFiles}
                    setErrorType={setErrorType}
                    className={styles.addPhotoBtn}
                    icon={<IconMedia />}
                  />
                </Box>
              </Box>
            </Box>
          ) : null}
        </FormControl>
        <YoutubeSection
          isActive={isYoutubeSection}
          control={control}
          errors={errors}
          setValue={setValue}
        />
        <PollSection
          isActive={isPollSection}
          control={control}
          errors={errors}
          setValue={setValue}
          getValues={getValues}
          hasVotes={post?.poll?.totalVotes && post?.poll?.totalVotes > 0}
        />
        <TypesErrorBox
          message={t("community.errorMessageDifferentFiles")}
          isError={isErrorType}
          setErrorType={setErrorType}
        />
        <Box className={styles.optionsBox}>
          <Typography variant="body4">
            {t("default.postOptionsTitle")}
          </Typography>
          <Box>
            <FormControlLabel
              label={
                <Typography variant="body18">
                  {t("default.allowComments")}
                </Typography>
              }
              control={
                <SwitchIOS
                  checked={allowComments}
                  onChange={(e) => setAllowComments(e.target.checked)}
                />
              }
              disabled={postByIdLoading}
            />
          </Box>
          <Box>
            <FormControlLabel
              label={
                <Typography variant="body18" className={styles.optionsBoxLabel}>
                  {t("default.requireSubscription")}
                  <CustomTooltip
                    isArrow={true}
                    title={subscriptionOptionTooltip}
                    isClick={isMobile}
                    open={isRequireSubscriptionTooltip}
                    setOpen={setRequireSubscriptionTooltip}
                  >
                    <IconQuestionMark />
                  </CustomTooltip>
                </Typography>
              }
              control={
                <SwitchIOS
                  checked={freeAccess}
                  name="freeAccess"
                  onChange={(e) => setFreeAccess(e.target.checked)}
                />
              }
              className={styles.formControlLabel}
              disabled={isSubscriptionDisable || postByIdLoading}
            />
          </Box>
        </Box>
        <Box className={styles.actions}>
          {!isUpdate ? (
            <Button
              variant="outlined"
              onClick={openSchedulePost}
              disabled={loading || !message?.isValid}
            >
              {t("default.scheduledPost")}
            </Button>
          ) : null}
          <LoadingButton
            variant="contained"
            type="submit"
            loading={loading}
            disabled={loading || !message?.isValid}
          >
            {sendButtonTitle}
          </LoadingButton>
        </Box>
      </form>
      <SetupStripeWarningModal stripeUrl={stripeUrl} onCancel={onCancelPaid} />
    </Box>
  );
};
