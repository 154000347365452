import { Box, Typography } from "@mui/material";
import React from "react";

import styles from "./ResultItem.module.scss";

export const ResultItem = ({ setChatHandler, ...data }) => {
  const { profileImageUrl, username, isInfluencer } = data;

  return (
    <Box className={styles.box} onClick={() => setChatHandler(data)}>
      <img
        src={profileImageUrl}
        alt="avatar"
        className={isInfluencer ? styles.square : ""}
      />
      <Box>
        <Typography
          variant="body1_medium"
          className={`opacity_07 ${styles.title}`}
        >
          {username}
        </Typography>
      </Box>
    </Box>
  );
};
