import { roundToHundredth } from "Utils/math";

export const getChartTitle = (data) =>
  data.find((d) => d.amount === Math.max(...data.map((d) => d.amount)))?.name;

export const dataAmountToPct = ({ d, totalAmount }) =>
  d.map((item) => {
    return {
      name: item.name,
      amount: roundToHundredth((item.amount / totalAmount) * 100),
    };
  });

export const prepareData = ({ data, catName, totalAmount }) => {
  const filteredData = data.filter((item) => item.amount > 0);

  if (filteredData?.length <= 5) {
    return dataAmountToPct({ d: filteredData, totalAmount: totalAmount });
  }

  const catLimit = 4;
  const descSortedData = dataAmountToPct({
    d: filteredData,
    totalAmount: totalAmount,
  }).sort((a, b) => b.amount - a.amount);
  const firstFourCats = descSortedData.slice(0, catLimit);
  const otherCatsValue = descSortedData
    .slice(catLimit, descSortedData.length)
    .map((cat) => cat.amount)
    .reduce((a, b) => a + b, 0);
  const otherCat = { name: catName, amount: otherCatsValue };

  return [...firstFourCats, otherCat];
};
