import { Box, CircularProgress, Typography } from "@mui/material";
import { CHAT_CATEGORIES, CHATS_TYPE, SCROLL_THRESHOLD } from "Constants/index";
import DOMPurify from "dompurify";
import React, { useEffect, useRef } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import { patchMessagesSeenInChat } from "Redux/messengerSlice";
import { getMainAppUserId } from "Redux/selectors/mainAppUserSelector";
import {
  getActiveMessagesData,
  getSingleChatsLoader,
} from "Redux/selectors/messengerSelectors";
import { getConvertedTimeZoneDate } from "Utils/index";

import { DeliveredTickIcon, SeenIcon, SendTickIcon } from "../../../../icons";
import { DateTitle } from "./DateTitle";
import styles from "./Messages.module.scss";
import { RequestInfoBlock } from "./RequestInfoBlock";

export const Messages = ({ loadMore, activeChat, chatsType, loader }) => {
  const dispatch = useDispatch();
  const scrollRefWrapper = useRef(null);

  const { messages, total } = useSelector(
    getActiveMessagesData(activeChat?.id)
  );

  const mainAppUserId = useSelector(getMainAppUserId);
  const isLoading = useSelector(getSingleChatsLoader);

  useEffect(() => {
    if (scrollRefWrapper.current) {
      const scrollElement = scrollRefWrapper.current;
      const isAtBottom =
        scrollElement.scrollTop + scrollElement.scrollHeight >=
        scrollElement.scrollHeight - 100;

      if (isAtBottom) {
        scrollElement.scrollTop = scrollElement.scrollHeight;
      }
    }
    const lastMessage = messages.at(-1);
    if (lastMessage?.id && activeChat?.id) {
      dispatch(
        patchMessagesSeenInChat({
          otherChatId: activeChat.otherChatId,
          chatId: activeChat.id,
          messageId: lastMessage.id,
          type: activeChat?.isPremium
            ? CHAT_CATEGORIES.PREMIUM
            : CHAT_CATEGORIES.GENERAL,
        })
      );
    }
  }, [messages.length]);

  return (
    <Box className={styles.listWrapper}>
      <Box
        className={styles.list}
        ref={scrollRefWrapper}
        id="scrollableEl-chat"
        style={{
          display: "flex",
          flexDirection: "column-reverse",
        }}
      >
        {(isLoading && !messages.length) || loader ? (
          <Box className={styles.loader}>
            <CircularProgress />
          </Box>
        ) : null}
        {messages.length ? (
          <InfiniteScroll
            dataLength={messages.length}
            next={() => loadMore(messages[0].id)}
            inverse={true}
            hasMore={total}
            scrollThreshold={SCROLL_THRESHOLD}
            scrollableTarget="scrollableEl-chat"
          >
            <Box className={styles.listContent}>
              {messages.map((item, index) => (
                <React.Fragment key={item.timestamp || item.id}>
                  <DateTitle
                    dateItem={item.createdAt}
                    datePrevItem={
                      messages[index - 1] ? messages[index - 1].createdAt : null
                    }
                    index={index}
                  />
                  <Box
                    className={`${styles.messageWrapper} ${
                      mainAppUserId === item.senderId
                        ? styles.messageWrapperOwner
                        : styles.messageWrapperNotOwner
                    }`}
                  >
                    <Box
                      key={item.id}
                      className={`${styles.message} ${
                        mainAppUserId === item.senderId
                          ? styles.messageOwner
                          : ""
                      }`}
                    >
                      <Typography
                        variant="body1"
                        className={styles.messageText}
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(item.messageContent, {
                            USE_PROFILES: { html: true },
                          }),
                        }}
                      />
                      <Typography
                        className={`${styles.messageTime} opacity_07`}
                        variant="body3"
                      >
                        {getConvertedTimeZoneDate(item.createdAt, "HH:mm")}
                        {mainAppUserId === item.senderId ? (
                          <Box>
                            {item.seen ? (
                              <SeenIcon />
                            ) : item.id ? (
                              <DeliveredTickIcon />
                            ) : (
                              <SendTickIcon />
                            )}
                          </Box>
                        ) : null}
                      </Typography>
                    </Box>
                  </Box>
                </React.Fragment>
              ))}
            </Box>
          </InfiniteScroll>
        ) : null}
      </Box>
      {chatsType === CHATS_TYPE.REQUESTS &&
      !activeChat?.isNewMessageTo &&
      activeChat ? (
        <RequestInfoBlock activeChat={activeChat} />
      ) : null}
    </Box>
  );
};
