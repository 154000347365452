import { Box, CircularProgress, Skeleton, Typography } from "@mui/material";
import Page from "Components/Page/Page";
import SidebarTablet from "Components/SidebarTablet/SidebarTablet";
import { SCROLL_THRESHOLD } from "Constants/index";
import React, { useContext, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch } from "react-redux";
import { setError } from "Redux/appSlice";
import { errorResponseMessages } from "Services/Api/apiErrorHelper.tsx";
import { getEarnings } from "Services/Api/donationsApi";
import { LocalizationContext } from "Services/Localization/LocalizationContext";

import styles from "./Earnings.module.scss";
import { CollapsibleBox } from "./omponents/CollapsibleBox/CollapsibleBox";
import { SumBoxList } from "./omponents/SumBoxList/SumBoxList";

const initialState = {
  monthlyAverage: 0,
  allTimeEarnings: 0,
  monthlyEarnings: null,
};

const LIMIT = 40;

export const Earnings = () => {
  const dispatch = useDispatch();
  const { t } = useContext(LocalizationContext);

  const [loader, setLoader] = useState(true);
  const [earningsData, setEarningsData] = useState(initialState);
  const [page, setPage] = useState(1);

  const loadMore = () => setPage((prevState) => prevState + 1);

  const dataLength = earningsData?.monthlyEarnings
    ? Object.keys(earningsData?.monthlyEarnings)?.length
    : 0;

  const hasMore = dataLength === LIMIT * page;

  useEffect(() => {
    getEarnings({ page, limit: LIMIT })
      .then((data) => {
        if (data) {
          setEarningsData((prevState) => ({
            ...data,
            monthlyEarnings: {
              ...prevState.monthlyEarnings,
              ...data?.monthlyEarnings,
            },
          }));
        }
      })
      .catch((e) => {
        dispatch(
          setError({
            open: true,
            title: t("errors.error"),
            subtitle: errorResponseMessages(e, t),
          })
        );
      })
      .finally(() => setLoader(false));
  }, [page]);

  return (
    <Page>
      <Box className={styles.titleWrap}>
        <SidebarTablet />
        <Typography variant="h2" className={styles.title}>
          {t("default.earnings")}
        </Typography>
      </Box>
      <SumBoxList
        monthlyAverage={earningsData.monthlyAverage}
        allTimeEarnings={earningsData.allTimeEarnings}
      />
      {loader && !earningsData?.monthlyEarnings ? (
        <Skeleton
          variant="rectangular"
          style={{ height: "300px", borderRadius: "10px" }}
        />
      ) : null}
      <InfiniteScroll
        next={loadMore}
        dataLength={dataLength}
        hasMore={hasMore}
        scrollableTarget="scrollableDiv"
        scrollThreshold={SCROLL_THRESHOLD}
        loader={
          <Box style={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </Box>
        }
      >
        <CollapsibleBox data={earningsData.monthlyEarnings} loader={loader} />
      </InfiniteScroll>
    </Page>
  );
};
