import { Box } from "@mui/material";
import { ReactComponent as IconClose } from "assets/icons/close.svg";

import styles from "./ModalLayout.module.scss";

export const ModalLayout = ({ children, onClose }) => {
  return (
    <Box className={styles.wrapper}>
      <Box className={styles.overlay} onClick={onClose} />
      <Box className={styles.modal}>
        <Box className={styles.modalClose} onClick={onClose}>
          <IconClose />
        </Box>
        {children}
      </Box>
    </Box>
  );
};
