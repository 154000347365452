import { Box, Button, Typography } from "@mui/material";
import React, { useContext } from "react";
import { LocalizationContext } from "Services/Localization/LocalizationContext";

import { EmptyStateIcon } from "../../icons";
import styles from "./EmptyState.module.scss";

export const EmptyState = ({ action, isShow }) => {
  const { t } = useContext(LocalizationContext);

  if (!isShow) {
    return null;
  }

  return (
    <Box className={styles.empty}>
      <Box className={styles.emptyIcon}>
        <EmptyStateIcon />
      </Box>
      <Typography variant="h5" className={styles.emptyTitle}>
        {t("limitedEdition.emptyListTitle")}
      </Typography>
      <Typography variant="body2" className={styles.emptyDescription}>
        {t("limitedEdition.emptyListDescription")}
      </Typography>
      <Button
        variant="contain"
        size="small"
        className={styles.emptyBtn}
        onClick={action}
      >
        <Typography variant="body4_14" className={styles.emptyBtnTxt}>
          {t("limitedEdition.emptyListCreateButton")}
        </Typography>
      </Button>
    </Box>
  );
};
