import { Box } from "@mui/material";
import { FileThumb } from "Components/FileThumb/FileThumb";
import { MediaModal } from "Components/Modal/components/MediaModal/MediaModal";
import { MEDIA_TYPES } from "Constants";
import { useContext, useState } from "react";
import { LocalizationContext } from "Services/Localization/LocalizationContext";

import { AudioIcon, FileIcon, ImageIcon, VideoIcon } from "../icons";
import { MediaAmountBox } from "../MediaAmountBox/MediaAmountBox";
import { RewardHeader } from "../RewardHeader/RewardHeader";
import styles from "./HiddenContent.module.scss";

export const HiddenContent = ({ media }) => {
  const { t } = useContext(LocalizationContext);

  const [isOpen, setOpen] = useState(false);
  const [mediaIndex, setMediaIndex] = useState(0);

  const setMediaHandler = (index) => {
    setMediaIndex(index);
    setOpen(true);
  };

  const mediaAmount = media.reduce(
    (acc, current) => {
      if (current.type === MEDIA_TYPES.VIDEO) {
        acc.video = acc.video + 1;
      } else if (current.type === MEDIA_TYPES.AUDIO) {
        acc.audio = acc.audio + 1;
      } else if (current.type === MEDIA_TYPES.IMAGE) {
        acc.images = acc.images + 1;
      } else {
        acc.files = acc.files + 1;
      }
      return acc;
    },
    {
      audio: 0,
      video: 0,
      images: 0,
      files: 0,
    }
  );

  const parsedMedia = media.map((item, index) => ({
    ...item,
    nativeIndex: index,
  }));

  const filteredMedia = parsedMedia.filter(
    (item) => item.type !== MEDIA_TYPES.FILE
  );

  const index = filteredMedia.findIndex(
    (item) => +item.nativeIndex === +mediaIndex
  );

  return (
    <Box>
      <Box className={styles.header}>
        <RewardHeader title={t("limitedEdition.hiddenContent")}>
          <MediaAmountBox count={mediaAmount.images} icon={<ImageIcon />} />
          <MediaAmountBox count={mediaAmount.video} icon={<VideoIcon />} />
          <MediaAmountBox count={mediaAmount.audio} icon={<AudioIcon />} />
          <MediaAmountBox count={mediaAmount.files} icon={<FileIcon />} />
        </RewardHeader>
      </Box>
      <Box className={`${styles.list} global-list-thumb-${media.length}`}>
        {media.map((item, index) => (
          <FileThumb
            file={{ type: item.type, preview: item.url }}
            className={`${styles.thumb} ${
              item.type === MEDIA_TYPES.AUDIO ? styles.thumbAudio : ""
            }`}
            clickHandler={(e) => {
              e.stopPropagation();
              setMediaHandler(`${index}`);
            }}
            thumbClassName={`${styles.thumbPreview} ${
              item.type === MEDIA_TYPES.VIDEO ? styles.thumbPreviewVideo : ""
            } ${
              item.type === MEDIA_TYPES.IMAGE ? styles.thumbPreviewImage : ""
            }`}
          />
        ))}
      </Box>
      <MediaModal
        isOpen={isOpen}
        setOpen={setOpen}
        media={filteredMedia}
        index={+index}
      />
    </Box>
  );
};
