import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { Avatar, Box, Skeleton, TextField, Typography } from "@mui/material";
import { ReactComponent as IconSend } from "assets/svg/icon-send.svg";
import classnames from "classnames/bind";
import { ImagesSlider } from "Components/ImagesSlider/ImagesSlider";
import Poll from "Components/Poll/Poll";
import { VideoBlock } from "Components/VideoBlock/VideoBlock";
import { FULL_COMMENT_TEXT_LIMIT, LEVEL_GROUP, PATHS } from "Constants";
import { format, isToday } from "date-fns";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { setError, setLoader, setSnackbar } from "Redux/appSlice";
import {
  createMessage,
  fetchCommentReplies,
  fetchPostById,
  fetchPostComments,
  fetchReactions,
  patchPost,
  resetComments,
  setPost,
  updateRepliesCount,
} from "Redux/communitySlice";
import {
  getPostById,
  getPostCommentsData,
  getPostsLoader,
} from "Redux/selectors/communitySelectors";
import { getMainAppUserId } from "Redux/selectors/mainAppUserSelector";
import { errorResponseMessages } from "Services/Api/apiErrorHelper.tsx";
import { LocalizationContext } from "Services/Localization/LocalizationContext";
import { formatPostMessage } from "Utils";
import * as Yup from "yup";

import { PostRejectModal } from "../../Components/Modal/components/PostRejectModal/PostRejectModal";
import { YouTubeVideoBlock } from "../../Components/VideoBlock/YouTubeVideoBlock";
import CommentsCount from "../Community/components/CommentsCount/CommentsCount";
import { AudioBlock } from "../Community/components/CommunityPost/components/AudioBlock/AudioBlock";
import Reactions from "../Community/components/Reactions/Reactions";
import Comments from "./Comments/Comments";
import SelectedReply from "./Comments/SelectedReply/SelectedReply";
import { ActionsControls } from "./components/ActionsControls/ActionsControls";
import { MediaControls } from "./components/MediaControls/MediaControls";
import { StatusSection } from "./components/StatusSection/StatusSection";
import NavigationPost from "./NavigationPost/NavigationPost";
import styles from "./Post.module.scss";

const scrollToElement = (id) => {
  if (!id) return;

  const element = document.getElementById(id);
  if (element) {
    const bounding = element.getBoundingClientRect();
    if (
      bounding.top < 0 ||
      bounding.left < 0 ||
      bounding.right > window.innerWidth ||
      bounding.bottom > window.innerHeight
    ) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }
};

const Post = () => {
  const [loadingMessage, setLoading] = useState(false);
  const [isReply, setIsReply] = useState(false);
  const [selectedReply, setSelectedReply] = useState(null);
  const [loadingReplyIds, setLoadingReplyIds] = useState([]);
  const [activeReplyId, setActiveReplyId] = useState(null);
  const [fullScreen, setFullScreen] = useState(false);
  const [isActiveRejectModal, setActiveRejectModal] = useState(false);
  const [commentPage, setCommentPage] = useState(1);

  const cx = classnames.bind(styles);

  const { postId } = useParams();
  const { t } = useContext(LocalizationContext);
  const validationSchema = Yup.object().shape({
    message: Yup.string().required(t("errors.required")),
  });
  const { register, handleSubmit, reset, formState } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const backRoute = location.state?.from || PATHS.COMMUNITY;

  const goBack = () => {
    navigate(backRoute, { replace: true });
  };

  const {
    total,
    entities: comments,
    hasMore,
    loading: loadingComments,
  } = useSelector(getPostCommentsData);
  const post = useSelector(getPostById);
  const loading = useSelector(getPostsLoader);
  const me = useSelector((state) => state.users.me.entities);
  const mainAppUserId = useSelector(getMainAppUserId);

  const { images, videos, audios, data, poll } = post || {};
  const hasImages = images?.length > 0;
  const hasVideo = videos?.length > 0 || data?.youtubeUrl;
  const hasMedia = hasImages || hasVideo;
  const lang = localStorage.getItem("language") || "en-US";

  const switchFullScreen = () => {
    setFullScreen(!fullScreen);
  };
  const { imgIdx } = location.state || {};
  const setLoadingReply = (activeReplyId) => {
    setLoadingReplyIds((prevState) => [...prevState, activeReplyId]);
  };

  const deleteLoadingReply = (activeReplyId) => {
    setLoadingReplyIds((prevState) =>
      prevState.filter((id) => id !== activeReplyId)
    );
  };

  useEffect(() => {
    const promises = [];
    if (activeReplyId && postId) {
      setLoadingReply(activeReplyId);
      const promise = dispatch(
        fetchCommentReplies({
          id: postId,
          params: { commentId: activeReplyId, limit: 30, page: 1 },
        })
      );
      promises.push(promise);
      promise.finally(() => {
        deleteLoadingReply(activeReplyId);
      });
    }
    return () => {
      promises.forEach((p) => p.abort());
    };
  }, [activeReplyId, postId]);

  useEffect(() => {
    const promise = dispatch(fetchReactions());
    return () => {
      dispatch(resetComments());
      promise.abort();
    };
  }, []);

  useEffect(() => {
    if (postId && !post?.id) {
      dispatch(setLoader(true));
      dispatch(fetchPostById({ postId })).finally(() =>
        dispatch(setLoader(false))
      );
    }
  }, [post?.id, postId]);

  useEffect(() => {
    if (postId) {
      dispatch(
        fetchPostComments({
          id: postId,
          params: { page: commentPage, limit: 10 },
        })
      )
        .unwrap()
        .catch((err) => {
          console.error("Cannot get comments", err);
        });
    }
  }, [commentPage, postId]);

  if (!post) return null;

  const onSendMessage = ({ message }) => {
    setLoading(true);
    dispatch(
      createMessage({
        postId,
        message,
        ...(isReply && { replyToId: selectedReply.id }),
      })
    )
      .then((data) => {
        if (isReply) {
          setLoadingReply(selectedReply.id);
          dispatch(
            updateRepliesCount({ replyToId: selectedReply.id, message })
          );
          dispatch(
            fetchCommentReplies({
              id: postId,
              params: { limit: 30, page: 1, commentId: selectedReply.id },
            })
          ).finally(() => {
            deleteLoadingReply(selectedReply.id);
            scrollToElement(data?.payload?.id);
          });
        } else {
          scrollToElement(data?.payload?.id);
        }
      })
      .finally(() => {
        reset();
        setIsReply(false);
        setLoading(false);
        setSelectedReply(null);
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const LoadMore = () => setCommentPage((prevState) => prevState + 1);

  const rejectHandler = () => {
    setActiveRejectModal(true);
  };

  const approveHandler = () => {
    setLoading(true);
    dispatch(patchPost({ postId, status: "published" }))
      .then(() => {
        dispatch(
          setSnackbar({
            message: t("community.approvePostSuccess"),
            open: true,
            right: "20px",
            left: "unset",
          })
        );
        navigate(PATHS.COMMUNITY);
      })
      .catch((err) => {
        if (err?.status !== 401) {
          dispatch(
            setError({
              open: true,
              title: t("errors.error"),
              subtitle: errorResponseMessages(err, t),
            })
          );
        }
      })
      .finally(() => setLoading(false));
  };

  const handleEditPost = () => {
    dispatch(setPost(post));
    navigate(`/edit-post/${postId}`);
  };

  return (
    <Box className={cx(styles.page, fullScreen && styles.postImageFullScreen)}>
      <Box
        className={cx(
          styles.container,
          styles.containerMain,
          post?.allowComments && styles.hasBottom
        )}
      >
        {!hasMedia && !loading && (
          <NavigationPost
            className={styles.top}
            pathBack={backRoute}
            pathBackText={t("community.post")}
          />
        )}
        <Box className={styles.content} id="scrollableDiv">
          {loading ? (
            <Skeleton className={styles.postSkeleton} />
          ) : (
            <>
              {hasMedia && (
                <MediaControls
                  onClose={goBack}
                  showFullScreenBtn={hasImages}
                  isFullScreen={fullScreen}
                  onSwicthFullScreen={switchFullScreen}
                  closeClass={data?.youtubeUrl ? styles.closeYoutube : ""}
                  controlsClass={data?.youtubeUrl ? styles.controlsYoutube : ""}
                />
              )}
              <Box>
                <Box className={styles.galleryWrapper}>
                  {hasImages && (
                    <ImagesSlider
                      images={post?.images}
                      isFullScreen={fullScreen}
                      overrideStyles={{ sliderItem: styles.sliderItem }}
                      alt={`By ${post?.influencer?.name}`}
                      index={imgIdx}
                    />
                  )}
                  {hasVideo && (
                    <VideoBlock
                      videos={post?.videos}
                      overrideStyles={{ videoWrapper: styles.videoWrapper }}
                      postIndex={"-1"}
                      mediaIndex={0}
                    />
                  )}
                  <YouTubeVideoBlock
                    className={`${styles.youtubeVideo} ${styles.videoWrapper}`}
                    youtubeUrl={post?.data?.youtubeUrl}
                    parentIndex="-2"
                  />
                </Box>
                <InfiniteScroll
                  next={LoadMore}
                  dataLength={comments?.length}
                  hasMore={hasMore}
                  scrollableTarget="scrollableDiv"
                  className={styles.postContent}
                >
                  <Box className={styles.postWrapper}>
                    <Box className={styles.post}>
                      <Box className={styles.postInfo}>
                        {me?.level !== LEVEL_GROUP ||
                        mainAppUserId === post?.appUser?.id ||
                        !post?.appUser?.id ? (
                          <img
                            src={me?.profileImageUrl}
                            alt="avatar"
                            className={styles.postAvatar}
                          />
                        ) : post?.appUser?.profileImageUrl ? (
                          <img
                            alt="avatar"
                            src={post?.appUser?.profileImageUrl}
                            className={styles.postAvatar}
                          />
                        ) : null}
                        <Box className={styles.postNameWrapper}>
                          <Typography variant="h6" className={styles.postName}>
                            {me?.level !== LEVEL_GROUP ||
                            mainAppUserId === post?.appUser?.id ||
                            !post?.appUser?.id
                              ? me?.name
                              : post?.appUser?.username}
                          </Typography>
                          <Typography
                            variant="body3"
                            className={styles.postDate}
                          >
                            {isToday(new Date(post?.createdAt))
                              ? format(new Date(post?.createdAt), "HH:mm")
                              : new Date(post?.createdAt).toLocaleDateString(
                                  lang,
                                  {
                                    year: "numeric",
                                    month: "short",
                                    day: "numeric",
                                    hour: "2-digit",
                                    minute: "2-digit",
                                    hour12: false,
                                  }
                                )}
                          </Typography>
                        </Box>
                        <ActionsControls
                          postId={postId}
                          status={post?.status}
                          appUser={post?.appUser}
                          approve={approveHandler}
                          reject={rejectHandler}
                          handleEditPost={handleEditPost}
                          loading={loadingMessage}
                        />
                      </Box>
                      <AudioBlock audios={audios} postIndex={0} />
                      <Box className={`${styles.messageWrapper} ql-editor`}>
                        <Typography variant="h6" className={styles.postMessage}>
                          {formatPostMessage(post?.message)}
                        </Typography>
                      </Box>
                      <Poll data={poll} />
                      {post?.status === "published" ? (
                        <Box className={styles.itemFooter}>
                          <Reactions
                            postId={postId}
                            influencerReaction={post?.influencerReactions}
                            postSummary={post?.postSummary}
                          />
                          <CommentsCount
                            count={total}
                            allowComments={post?.allowComments}
                            postSummary={post?.postSummary}
                            loading={loadingComments && commentPage === 1}
                            wrapperClass={styles.postCommentsCount}
                          />
                        </Box>
                      ) : null}
                    </Box>
                    <StatusSection
                      status={post?.status}
                      approveHandler={approveHandler}
                      rejectHandler={rejectHandler}
                      loading={loadingMessage}
                    />
                    <Comments
                      items={comments}
                      loadingReplyIds={loadingReplyIds}
                      setSelectedReply={setSelectedReply}
                      setActiveReplyId={setActiveReplyId}
                      setIsReply={setIsReply}
                      loading={loadingComments}
                      commentsCount={post?.commentsCount}
                    />
                  </Box>
                </InfiniteScroll>
              </Box>
            </>
          )}
        </Box>
        {post?.allowComments && post?.status === "published" ? (
          <form
            className={styles.sendMsg}
            autoComplete="off"
            onSubmit={handleSubmit(onSendMessage)}
          >
            <SelectedReply
              selectedReply={selectedReply}
              isReply={isReply}
              onClick={() => setIsReply(false)}
            />
            <Box className={styles.sendMsgContainer}>
              <Avatar
                className={styles.sendMsgAvatar}
                src={me?.profileImageUrl}
              />
              <TextField
                {...register("message")}
                name="message"
                variant="outlined"
                multiline
                minRows={1}
                maxRows={2}
                inputProps={{ maxLength: FULL_COMMENT_TEXT_LIMIT }}
                className={styles.sendMsgInput}
                placeholder={t("community.writeMessagePrompt")}
                autoComplete="off"
                disabled={loadingMessage}
                onKeyPress={(ev) => {
                  if (ev.key === "Enter") {
                    ev.preventDefault();
                    handleSubmit(onSendMessage)();
                  }
                }}
              />
              <LoadingButton
                variant="icon"
                className={styles.sendMsgBtn}
                type="submit"
                loading={loadingMessage}
                disabled={!formState.isValid}
              >
                <IconSend />
              </LoadingButton>
            </Box>
          </form>
        ) : null}
      </Box>
      <PostRejectModal
        isOpen={isActiveRejectModal}
        onClose={() => setActiveRejectModal(false)}
        postId={postId}
      />
    </Box>
  );
};

export default Post;
