import { Typography } from "@mui/material";
import { Container } from "@mui/system";
import React, { useContext } from "react";
import { LocalizationContext } from "Services/Localization/LocalizationContext";

import styles from "./SuccessBlock.module.scss";

export const SuccessBlock = () => {
  const { t } = useContext(LocalizationContext);

  return (
    <Container className={styles.successWrapper}>
      <Typography variant="h3" className={styles.title}>
        {t("celebform.celebrityFormTitle")} {t("celebform.celebrityForm")}
      </Typography>
      <Typography
        variant="h6"
        className={`${styles.headline} ${styles.headlineLineHeight}`}
      >
        {t("celebform.successTitle")}
      </Typography>
      <Typography variant="h6" className={styles.headline}>
        {t("celebform.successSubtitle")}{" "}
        <u>{t("celebform.successSubtitleUnderlined")}</u>{" "}
        {t("celebform.successSubtitleLast")}
      </Typography>
      <Typography variant="h6" className={styles.headline}>
        <p>
          {t("celebform.phrazeTitle")}: "
          <span className={styles.highlight}>{t("celebform.phraze")}</span>"
        </p>
      </Typography>
      <Typography variant="h6" className={styles.headline}>
        {t("celebform.sendmessage")}
        <ul>
          <li>
            {t("celebform.instagram")}:{" "}
            <a
              target="_blank"
              href="https://www.instagram.com/thisiszoop/"
              rel="noreferrer"
            >
              <u>{t("celebform.atthisiszoop")}</u>
            </a>{" "}
            {t("default.or")}{" "}
            <a
              target="_blank"
              href="https://www.instagram.com/zoopsupport/"
              rel="noreferrer"
            >
              <u>{t("celebform.atzoopSupport")}</u>
            </a>
          </li>
          <li>
            {t("celebform.twitter")}:{" "}
            <a
              target="_blank"
              href="https://twitter.com/ThisIsZoop"
              rel="noreferrer"
            >
              <u>{t("celebform.atthisiszoop")}</u>
            </a>{" "}
            {t("default.or")}{" "}
            <a
              target="_blank"
              href="https://twitter.com/zoopsupport"
              rel="noreferrer"
            >
              <u>{t("celebform.atzoopSupport")}</u>
            </a>
          </li>
          <li>
            {t("celebform.ticktock")}:{" "}
            <a
              target="_blank"
              href="https://www.tiktok.com/@zoop.cards"
              rel="noreferrer"
            >
              <u>{t("celebform.atzoopcards")}</u>
            </a>
          </li>
        </ul>
      </Typography>
      <Typography variant="h6" className={styles.headline}>
        {t("celebform.afterVerifyInfo")}
      </Typography>
      <Typography variant="h6" className={styles.headline}>
        <a href="https://www.zoopcards.com/" className={styles.buttonLink}>
          {t("celebform.returnToHomepage")}
        </a>
      </Typography>
    </Container>
  );
};
