import { Box, Button, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, { useContext } from "react";
import DatePicker from "react-datepicker";
import { LocalizationContext } from "Services/Localization/LocalizationContext";

import { filterTypes } from "../../utils";
import { SelectFilter } from "./components/SelectFilter/SelectFilter";
import styles from "./Filter.module.scss";
import { PickerIcon } from "./icons";

export const Filter = ({ filter, setFilter }) => {
  const { t } = useContext(LocalizationContext);
  const theme = useTheme();

  const isMd = useMediaQuery(theme.breakpoints.down(1025));

  const setFilterItemClass = (type) => {
    return `${styles.filterItem} ${
      filter.type === type ? styles.filterItemActive : ""
    }`;
  };

  const setActiveFilter =
    ({ type }) =>
    () => {
      setFilter((prevState) => ({ ...prevState, type, page: 1 }));
    };

  return (
    <Box className={styles.filter}>
      <Box className={styles.filterList}>
        {!isMd ? (
          <>
            <Button
              className={`${setFilterItemClass(filterTypes.all)} ${
                styles.filterBtn
              }`}
              onClick={setActiveFilter({ type: filterTypes.all })}
            >
              <Typography variant="body4_16_regular">
                {t("default.filterAll")}
              </Typography>
            </Button>
            <Button
              className={`${setFilterItemClass(filterTypes.subscriptions)} ${
                styles.filterBtn
              }`}
              onClick={setActiveFilter({ type: filterTypes.subscriptions })}
            >
              <Typography variant="body4_16_regular">
                {t("default.subscriptions")}
              </Typography>
            </Button>
            <Button
              className={`${setFilterItemClass(filterTypes.donations)} ${
                styles.filterBtn
              }`}
              onClick={setActiveFilter({ type: filterTypes.donations })}
            >
              <Typography variant="body4_16_regular">
                {t("default.donations")}
              </Typography>
            </Button>
            <Button
              className={`${setFilterItemClass(filterTypes.sell)} ${
                styles.filterBtn
              }`}
              onClick={setActiveFilter({ type: filterTypes.sell })}
            >
              <Typography variant="body4_16_regular">
                {t("default.sales")}
              </Typography>
            </Button>
          </>
        ) : (
          <SelectFilter setActiveFilter={setActiveFilter} value={filter.type} />
        )}
        <Box className={styles.rangeWrapper}>
          <PickerIcon />
          <DatePicker
            selectsRange={true}
            startDate={filter.startDate}
            endDate={filter.endDate}
            onChange={(update) => {
              setFilter((prevState) => ({
                ...prevState,
                page: 1,
                startDate: update[0],
                endDate: update[1],
              }));
            }}
            dateFormat="MMM d, yyyy"
            withPortal
            className={styles.rangePicker}
            calendarClassName="modal-calendar-range-picker"
          />
        </Box>
      </Box>
    </Box>
  );
};
