import { useTheme } from "@emotion/react";
import { Backdrop, Box, LinearProgress, Typography } from "@mui/material";
import { useSelector } from "react-redux";

const BackdropLoader = () => {
  const loader = useSelector((state) => state.app.loader);
  const title = loader?.title;
  const subtitle = loader?.subtitle;
  const theme = useTheme();
  return (
    <Backdrop
      sx={{
        color: "#fff",
        background: "rgba(0, 0, 0, 0.8)",
        zIndex: 99999,
      }}
      open={!!loader}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "start",
          flexFlow: "column",
          padding: "20px",
          borderRadius: "10px",
          background: theme.palette.dark.main,
          minWidth: "150px",
        }}
      >
        <LinearProgress color="accent" sx={{ width: "100%" }} />
        <Typography mt="15px" variant="body1">
          {title}
        </Typography>
        <Typography variant="body3">{subtitle}</Typography>
      </Box>
    </Backdrop>
  );
};
export default BackdropLoader;
