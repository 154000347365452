import { Box, Typography } from "@mui/material";
import React, { useContext } from "react";
import { LocalizationContext } from "Services/Localization/LocalizationContext";
import { getMonthWithIndexes } from "Utils/translations";

import styles from "./CollapsibleBox.module.scss";
import { Row } from "./components/Row/Row";

export const CollapsibleBox = ({ data, loader }) => {
  const { t } = useContext(LocalizationContext);

  const months = getMonthWithIndexes({ t });

  if (!data && !loader) {
    return null;
  }

  const RowBox = ({ index, row, month, year }) => {
    const isShowYear = new Date().getFullYear() !== +year;

    return (
      <Box key={`${year}-${month}`}>
        {isShowYear ? (
          <Typography variant="body4_16" component="p" className={styles.title}>
            {year}
          </Typography>
        ) : null}
        <Box className={styles.wrapper}>
          <Row row={row} index={index} month={month} />
        </Box>
      </Box>
    );
  };

  const renderRows = (data) => {
    if (data) {
      const rows = [];
      let index = 0;
      for (const rowsKey in data) {
        const date = rowsKey.split("-");
        rows.push(
          RowBox({
            row: data[rowsKey],
            index,
            year: date[0],
            month: months[+date[1]],
          })
        );
        index++;
      }
      return rows;
    } else {
      return null;
    }
  };

  return <>{renderRows(data)}</>;
};
