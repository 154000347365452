import { LoadingButton } from "@mui/lab";
import { Box, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { BackHeader } from "Components/BackHeader/BackHeader";
import Page from "Components/Page/Page";
import { LEVEL_GROUP } from "Constants/index";
import React, { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchFans } from "Redux/communitySlice";
import { getFansTotalSelector } from "Redux/selectors/communitySelectors";
import { getUserLevel } from "Redux/selectors/userSelector";
import { LocalizationContext } from "Services/Localization/LocalizationContext";

import styles from "./Followers.module.scss";
import { FollowersList } from "./FollowersList/FollowersList";
import { SearchWrapper } from "./SearchWrapper/SearchWrapper";

const Followers = () => {
  const { t } = useContext(LocalizationContext);
  const dispatch = useDispatch();
  const isMobile = useMediaQuery("(max-width:768px)");

  const totalFans = useSelector(getFansTotalSelector);

  const handleSearch = (searchTerm) => {
    dispatch(
      fetchFans({
        page: 1,
        limit: 50,
        ...(searchTerm && { searchTerm }),
      })
    );
  };

  return (
    <Page>
      <Box className={styles.header}>
        <BackHeader
          title={
            <Box className={styles.headerTitle}>
              {t("community.members")}
              {isMobile ? (
                <Typography variant="h6" style={{ fontSize: "24px" }}>
                  ({totalFans})
                </Typography>
              ) : null}
            </Box>
          }
          isBackArrow={false}
        />
      </Box>
      {!isMobile ? (
        <Typography variant="body1" className={styles.followerFans}>
          {totalFans} {t("community.fans")}
        </Typography>
      ) : null}
      <SearchWrapper handleSearch={handleSearch} />
      <FollowersList />
    </Page>
  );
};

export default Followers;
