import { format } from "date-fns";

export const filterTypes = {
  all: "all",
  sell: "sale",
  subscriptions: "subscription",
  donations: "donation",
};

const formatDate = (date) => {
  return format(
    new Date(
      date?.toLocaleDateString("en-US", {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      })
    ),
    "MM/dd/yyyy"
  );
};

export const getCurrentWeekRange = () => {
  const currentDate = new Date();

  const lastDayOfWeek = new Date(currentDate);

  const firstDayOfWeek = new Date(lastDayOfWeek);
  firstDayOfWeek.setDate(firstDayOfWeek.getDate() - 6);

  return {
    start: new Date(formatDate(firstDayOfWeek)),
    end: new Date(formatDate(lastDayOfWeek)),
  };
};
