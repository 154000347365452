import { Button } from "@mui/material";
import { ReactComponent as IconPoll } from "assets/svg/poll-icon.svg";
import React, { useContext } from "react";
import { LocalizationContext } from "Services/Localization/LocalizationContext";

import { useIsMobile } from "../../../../Utils/Hooks";
import styles from "./AddMediaButton.module.scss";

export const PollButton = ({ isPollSection, setPollSection }) => {
  const isMobile = useIsMobile();
  const { t } = useContext(LocalizationContext);

  const handleConfirmation = () => {
    setPollSection((prevState) => !prevState);
  };

  return (
    <>
      <Button
        variant="contained"
        size="small"
        className={`${styles.addPhotoBtn} ${
          isPollSection ? styles.addPhotoBtnActive : ""
        }`}
        startIcon={<IconPoll />}
        onClick={handleConfirmation}
      >
        {isMobile ? t("community.pollCreateMobile") : t("community.pollCreate")}
      </Button>
    </>
  );
};
