import { InputBase, OutlinedInput } from "@mui/material";
import { IconButton } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import { styled } from "@mui/material/styles";
import { ReactComponent as Visibility } from "assets/svg/visibility.svg";
import { ReactComponent as VisibilityOff } from "assets/svg/visibility-off.svg";
import classnames from "classnames/bind";
import * as React from "react";
import { useState } from "react";

import styles from "./Input.module.scss";

const cx = classnames.bind(styles);
const Input = styled(OutlinedInput)(({ theme }) => ({
  background: "none",
  color: theme.palette.dark.contrastText,
  fontFamily: `"ClashGrotesk-Regular", "Noto Sans JP", "Noto Sans KR", "Noto Sans SC", sans-serif`,
  fontWeight: "normal",
  fontSize: "16px",
  lineHeight: "20px",
  letterSpacing: "normal",
  padding: `0 16px`,
  width: "100%",
  border: `1px solid rgba(80, 90, 169, 0.5)`,
  borderRadius: 10,
  "& .MuiOutlinedInput-input": {
    "&::placeholder": {
      color: theme.palette.dark.placeholder,
      opacity: 1,
    },
    color: theme.palette.dark.contrastText,
    padding: 0,
    border: "none",
    borderRadius: "0 !important",
    height: "100%",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  "&.Mui-focused": {
    borderBottom: `1px solid ${theme.palette.accent.main}`,
    // ".MuiOutlinedInput-notchedOutline": {
    //   borderBottom: `1px solid ${theme.palette.accent.main}`,
    // },
  },
  "&.Mui-error": {
    borderBottom: `1px solid${theme.palette.error.main}`,
    fontSize: "16px",
    lineHeight: "20px",
    ".MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
}));

const StyledCustomization = React.forwardRef(
  ({ children, className, inputPattern, ...rest }, ref) => {
    const classNames = cx(styles.styled, className);

    return (
      <Input
        ref={ref}
        {...rest}
        inputProps={{ pattern: inputPattern ? inputPattern : null }}
        className={classNames}
      >
        {children}
      </Input>
    );
  }
);

export const InputPassword = React.forwardRef(
  ({ children, className, ...rest }, ref) => {
    const classNames = cx(styles.styled, className);
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => {
      setShowPassword(!showPassword);
    };
    const handleMouseDownPassword = (e) => {
      e.preventDefault();
    };
    return (
      <Input
        ref={ref}
        {...rest}
        type={showPassword ? "text" : "password"}
        className={classNames}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
      >
        {children}
      </Input>
    );
  }
);

export default StyledCustomization;
