import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getCountries } from "Services/Api/mainAppApi";

export const fetchCountries = createAsyncThunk("app/countries", async () => {
  const response = await getCountries();
  return response.data;
});

const initialState = {
  snackbar: {
    open: false,
    autoHideDuration: 2000,
    message: "",
    severity: "success",
    width: "320px",
    left: "8px",
  },
  modal: {
    open: false,
    type: null,
    payload: null,
  },
  isMobileMenuVisible: false,
  isSidebarVisible: false,
  countries: null,
  error: {
    open: false,
  },
  loader: false,
  onfidoToken: null,
  isAuthProcess: true,
  accessToken: null,
  idToken: null,
};
const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setSnackbar: (state, action) => {
      state.snackbar = { ...initialState.snackbar, ...action.payload };
    },
    setModal: (state, action) => {
      state.modal = { ...initialState.modal, ...action.payload };
    },
    setError: (state, action) => {
      state.error = { ...initialState.error, ...action.payload };
    },
    toggleMobileMenu: (state) => {
      state.isMobileMenuVisible = !state.isMobileMenuVisible;
    },
    toggleSidebar: (state) => {
      state.isSidebarVisible = !state.isSidebarVisible;
    },
    hideSidebar: (state) => {
      state.isSidebarVisible = false;
    },
    setLoader: (state, action) => {
      state.loader = action.payload;
    },
    setAuthProcess: (state, action) => {
      state.isAuthProcess = action.payload;
    },
    setOnfidoToken: (state, action) => {
      state.onfidoToken = action.payload?.sdkToken || null;
      state.onfidoRedirectComplete =
        action.payload?.onfidoRedirectComplete || null;
    },
    setTokens: (state, action) => {
      const { accessToken, idToken } = action.payload || {};
      if (accessToken) {
        state.accessToken = accessToken;
      }
      if (idToken) {
        state.idToken = idToken;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCountries.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchCountries.fulfilled, (state, action) => {
        state.loading = false;
        state.countries = action.payload;
      });
  },
});

export const {
  setSnackbar,
  setError,
  toggleMobileMenu,
  setLoader,
  toggleSidebar,
  setModal,
  setOnfidoToken,
  setAuthProcess,
  hideSidebar,
  setTokens,
} = appSlice.actions;
export default appSlice.reducer;
