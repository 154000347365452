import { Box, Typography } from "@mui/material";
import { ShareBtn } from "Components/ShareBtn/ShareBtn";
import DOMPurify from "dompurify";
import React, { useContext } from "react";
import { LocalizationContext } from "Services/Localization/LocalizationContext";

import { SeasonSlider } from "../SeasonSlider/SeasonSlider";
import { Tier } from "../Tier/Tier";
import styles from "./MainSeason.module.scss";

export const MainSeason = ({ seasonCards, season }) => {
  const shareLink = window.location.href;
  const { t } = useContext(LocalizationContext);

  const SeasonDescription = () =>
    season.description ? (
      <Typography
        variant="body2"
        className={styles.seasonDescription}
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(season.description, {
            USE_PROFILES: { html: true },
          }),
        }}
      />
    ) : null;

  const tier1Perks = season?.perks[0]?.tier1Perks || null;
  const tier2Perks = season?.perks[0]?.tier2Perks || null;
  const tier3Perks = season?.perks[0]?.tier3Perks || null;
  const status = season?.perks[0]?.status || "archive";

  const getStatus = (status) => {
    let currentStatus = "";
    switch (status) {
      case "approved":
        currentStatus = t("seasons.statusApproved");
        break;
      case "archived":
        currentStatus = t("seasons.statusArchived");
        break;
      default:
        currentStatus = t("seasons.statusInReview");
        break;
    }
    return currentStatus;
  };

  return (
    <Box className={styles.season}>
      <Box className={styles.seasonTiers}>
        <Tier
          tierData={tier1Perks || []}
          title={t("seasons.tier", { count: 1 })}
          // fansCount={5023}
          status={tier1Perks?.length ? getStatus(status) : null}
        />
        <Tier
          tierData={tier2Perks || []}
          title={t("seasons.tier", { count: 2 })}
          // fansCount={253}
          status={tier2Perks?.length ? getStatus(status) : null}
        />
        <Tier
          tierData={tier3Perks || []}
          title={t("seasons.tier", { count: 3 })}
          // fansCount={23}
          status={tier3Perks?.length ? getStatus(status) : null}
        />
      </Box>
      <Box className={styles.seasonDescriptionWrapper}>
        <SeasonDescription />
        <ShareBtn shareLink={shareLink} />
      </Box>
      <SeasonSlider seasonCards={seasonCards} />
    </Box>
  );
};
