import { Box, Button, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { useEffect, useRef, useState } from "react";

import styles from "./Audio.module.scss";
import { ReactComponent as PauseIcon } from "./icons/pause-icon.svg";
import { ReactComponent as PlayIcon } from "./icons/play-icon.svg";

export const Audio = ({
  src,
  callbackFunc,
  globalActiveParentIndex,
  globalActiveMediaIndex,
  parentIndex,
  mediaIndex,
  classNameWrapper,
}) => {
  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const theme = useTheme();

  const pause = () => {
    audioRef.current?.pause();
    setIsPlaying(false);
  };
  const play = () => {
    audioRef.current?.play();
    setIsPlaying(true);
  };

  const handlePlayPause = () => {
    if (isPlaying) {
      pause();
    } else {
      play();
    }

    if (callbackFunc) {
      callbackFunc(audioRef.current)();
    }
  };

  const handleTimeUpdate = () => {
    setCurrentTime(audioRef.current.currentTime);
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds.toString().padStart(2, "0")}`;
  };

  const getBackgroundRange = () => {
    if (!currentTime) {
      return theme.palette.accent.third;
    }

    return `linear-gradient(to right, ${theme.palette.accent.main} 0%, ${
      theme.palette.accent.main
    } ${(currentTime * 100) / duration}%, ${theme.palette.accent.third} ${
      (currentTime * 100) / duration
    }%, ${theme.palette.accent.third}`;
  };

  useEffect(() => {
    audioRef.current.onloadedmetadata = () => {
      setDuration(audioRef.current?.duration);
    };
  }, [audioRef.current]);

  useEffect(() => {
    if (audioRef.current?.duration <= audioRef.current?.currentTime) {
      audioRef.current.currentTime = 0;
      setIsPlaying(false);
    }
  }, [audioRef.current?.duration, audioRef.current?.currentTime]);

  useEffect(() => {
    if (
      parentIndex !== globalActiveParentIndex ||
      mediaIndex !== globalActiveMediaIndex
    ) {
      pause();
    }
  }, [
    globalActiveMediaIndex,
    globalActiveParentIndex,
    mediaIndex,
    parentIndex,
  ]);

  return (
    <Box
      className={`${styles.audioWrapper} ${
        classNameWrapper ? classNameWrapper : ""
      } global-audio-class`}
      onClick={(e) => e.stopPropagation()}
    >
      <audio
        src={src}
        ref={audioRef}
        onTimeUpdate={handleTimeUpdate}
        preload="metadata"
      />
      <Box className={styles.audio}>
        <Button onClick={handlePlayPause} className={styles.play}>
          {isPlaying ? <PauseIcon /> : <PlayIcon />}
        </Button>
        <Box className={styles.timeline}>
          <input
            type="range"
            min="0"
            max={duration || 0}
            step="0.01"
            value={currentTime}
            onChange={(e) => (audioRef.current.currentTime = e.target.value)}
            style={{
              background: getBackgroundRange(),
            }}
          />
          <Typography
            variant="body1"
            component="span"
            className={styles.timelineValue}
          >{`${currentTime > 0 ? "- " : ""} ${formatTime(
            duration - currentTime
          )}`}</Typography>
        </Box>
      </Box>
    </Box>
  );
};
