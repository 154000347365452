import { Container } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useIsMobile } from "Utils/Hooks";
import { useWindowSize } from "Utils/Hooks/";

import styles from "./Page.module.scss";
const PADDING_HORIZONTAL = 50 * 2;

const Page = ({ children, className }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const isSidebarVisible = useSelector((state) => state.app.isSidebarVisible);
  const ws = useWindowSize();

  const sideBarWidth = isMobile ? 231 : 340;

  const bdrs = isMobile ? "20px 0 0 20px" : "40px 0 0 40px";

  const containerWidth = (() => {
    if (isMobile) {
      return isSidebarVisible
        ? ws.width - sideBarWidth
        : ws.width - sideBarWidth;
    }
    return ws.width - sideBarWidth;
  })();

  return (
    <Container
      className={`${styles.container} ${className} ${
        isSidebarVisible ? "global-class-container-sidebar-visible" : ""
      }`}
      sx={{
        width: isSidebarVisible ? containerWidth : "100%",
        borderRadius: isSidebarVisible ? bdrs : 0,
      }}
    >
      {children}
    </Container>
  );
};

export default Page;
