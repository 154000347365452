import { Box, Button, Tooltip, Typography } from "@mui/material";
import React from "react";

import styles from "./TooltipBox.module.scss";

export const TooltipBox = ({
  cb,
  children,
  title,
  actionText,
  tooltipClassName,
  popperClassName,
  rootClassName,
}) => {
  return (
    <Tooltip
      placement="top"
      classes={{
        popper: `${styles.popper} ${popperClassName}`,
        tooltip: `${styles.tooltip} ${tooltipClassName}`,
      }}
      className={rootClassName}
      enterTouchDelay={0}
      title={
        <Box>
          <Typography variant="body3">{title}</Typography>
          {actionText ? (
            <Box>
              <Button type="text" onClick={cb}>
                <Typography variant="body3">{actionText}</Typography>
              </Button>
            </Box>
          ) : null}
        </Box>
      }
    >
      <Box>{children}</Box>
    </Tooltip>
  );
};
