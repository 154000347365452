import { enGB, es, fr, ja, ko, pt, tr, zhCN } from "date-fns/locale";

export const COMMENT_TEXT_LIMIT = 200;

export const PLAYING_FEED_VIDEO = "playing-feed-video";

export const SCROLL_THRESHOLD = "150px";

export const BLOCK_REASON_KYC = "KYC_REQUIRED";
export const USER_BLOCKED = "UserIsBlocked";

export const AUTH = {
  REFERRAL_CODE: "auth-referral-code",
  ACCESS_TOKEN: "dapple-access-token",
  REFRESH_TOKEN: "dapple-refresh-token",
  ID_TOKEN: "dapple-id-token",
  UUID: "uuid",
  INVITE_CODE: "invite-code",
  ZENDESK_TOKEN: "zendesk-token",
  LAST_AUTH_USER: "last-auth-user",
};

export const MainAppDomain =
  process.env.REACT_APP_LOCAL === "local"
    ? "http://localhost:3000"
    : process.env.REACT_APP_ENV === "staging"
    ? "https://staging.zoop.club"
    : "https://app.zoop.club";

export const zoopPolicyHost = "https://policy.zoop.club";

export const LANGUAGES_LIST = {
  english: "en-GB",
  spanish: "es-ES",
  portugues: "pt-BR",
  french: "fr-FR",
  turkish: "tr-TR",
  korean: "ko-KR",
  japanese: "ja-JP",
  chinese: "zh-CN",
  chineseTraditional: "zh-TW",
};
export const ERROR_RESPONSE_TYPES = {
  LimitExceededException: "LimitExceededException",
  UsernameExistsException: "UsernameExistsException",
  AliasExistsException: "AliasExistsException",
  CodeMismatchException: "CodeMismatchException",
  UserNotFoundException: "UserNotFoundException",
  InvalidPasswordException: "InvalidPasswordException",
  NotAuthorizedException: "NotAuthorizedException",
  SessionExpiredException: "SessionExpiredException",
  SessionUnavailableOfflineException: "SessionUnavailableOfflineException",
  SignedOutException: "SignedOutException",
  UserNotConfirmedException: "UserNotConfirmedException",
  ProfileNotCompleted: "Profile_not_completed",
  Exception: "Exception",
};
export const ERROR_RESPONSE_MSG = {
  PasswordAttemptsExceeded: "Password attempts exceeded",
  InvalidVerificationCode:
    "Invalid verification code provided, please try again.",
  LimitExceededTryLater: "Attempt limit exceeded, please try after some time.",
  IncorrectUsernameOrPassword: "Incorrect username or password.",
};
export const SOCIAL_LINKS = [
  {
    socialPlatformId: 2,
    code: "twitter",
    prependLink: "https://twitter.com/",
    adornment: "@",
  },
  {
    socialPlatformId: 3,
    code: "facebook",
    prependLink: "https://facebook.com/",
    adornment: "https://facebook.com/",
  },
  {
    socialPlatformId: 4,
    code: "instagram",
    prependLink: "https://www.instagram.com/",
    adornment: "@",
  },
  {
    socialPlatformId: 5,
    code: "tiktok",
    prependLink: "https://www.tiktok.com/@",
    adornment: "@",
  },
  {
    socialPlatformId: 6,
    code: "website",
    prependLink: "https://",
    adornment: "https://",
  },
  {
    socialPlatformId: 7,
    code: "youtube",
    prependLink: "https://youtube.com/",
    adornment: "https://youtube.com/",
  },
];

export const getCategories = ({ t }) => ({
  Sport: t("default.sport"),
  Beauty: t("default.beauty"),
  Fashion: t("default.fashion"),
  Business: t("default.business"),
  "Tech &Gaming": t("default.techGaming"),
  "Health & Fitness": t("default.healthFitness"),
  Travel: t("default.travel"),
  Music: t("default.music"),
  "Social Figure": t("default.socialFigure"),
  Entertainment: t("default.entertainment"),
});

export const EDITION_STATUSES = {
  phase0: "droppingStage0",
  phase2: "droppingStage2",
  complete: "complete",
};

export const ANIMATION_DIRECTION = {
  forward: 1,
  backward: -1,
};

export const DATE_LOCALES = {
  "en-GB": enGB,
  "zh-CN": zhCN,
  "fr-FR": fr,
  "ja-JP": ja,
  "ko-KR": ko,
  "es-ES": es,
  "tr-TR": tr,
  "pt-BR": pt,
};

export const AUTO_HIDE_TIMEOUT = 2000;

export const MODAL_TYPE = {
  VERIFY: "VERIFY",
  DELETE_POST: "DELETE_POST",
  COMMUNITY_GUIDELINES: "COMMUNITY_GUIDELINES",
  SCHEDULE_MODAL: "SCHEDULE_MODAL",
  MODAL: "MODAL",
  REPORT_MODAL: "REPORT_MODAL",
};

export const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10mb
export const MAX_FILE_HEIGHT = 5000;
export const MAX_ALL_FILES_SIZE = 500 * 1024 * 1024; // 500mb
export const REPLY_COMMENT_TEXT_LIMIT = 145;

export const errorResponseTypes = {
  RestrictedTerritory: "RestrictedTerritory",
  UnsupportedTerritory: "UnsupportedTerritory",
  LimitExceededException: "LimitExceededException",
  UsernameExistsException: "UsernameExistsException",
  AliasExistsException: "AliasExistsException",
  CodeMismatchException: "CodeMismatchException",
  UserNotFoundException: "UserNotFoundException",
  InvalidPasswordException: "InvalidPasswordException",
  NotAuthorizedException: "NotAuthorizedException",
  SessionExpiredException: "SessionExpiredException",
  SessionUnavailableOfflineException: "SessionUnavailableOfflineException",
  SignedOutException: "SignedOutException",
  UserNotConfirmedException: "UserNotConfirmedException",
  IncorrectPaymentMethodForZoopBalanceFund:
    "IncorrectPaymentMethodForZoopBalanceFund",
  ZoopBalanceInsufficientFunds: "ZoopBalanceInsufficientFunds",
  ProfileNotCompleted: "Profile_not_completed",
  Exception: "Exception",
  RequestIsOpen: "RequestIsOpen",
  BadRequest: "BadRequest",
  HaveOpenSellOrder: "HaveOpenSellOrder",
  InDroppingProcess: "InDroppingProcess",
  HaveOpenBuyOrder: "HaveOpenBuyOrder",
  KYCRequired: "KYCRequired",
  NoEditionsLeft: "NoEditionsLeft",
  InsufficientCards: "InsufficientCards",
  Error: "Error",
  UnsupportedJurisdictionIP: "UnsupportedJurisdictionIP",
  ProhibitedJurisdictionIP: "ProhibitedJurisdictionIP",
};

export const PATHS = {
  HOME: "/",
  NEW_POST: "/new-post",
  EDIT_POST: "/edit-post/:postId",
  POST_ID: "/post/:postId",
  FOLLOWERS: "/followers",
  PROFILE: "/profile",
  COMPLETE_PROFILE: "/complete-profile",
  COMMUNITY: "/community",
  EDIT_COMMUNITY: "/edit-community",
  SIGNIN: "/signin",
  SIGN_UP: "/signup",
  SIGN_UP_CODE: "/signup/:code",
  EMAIL_VERIFICATION: "/email-verification",
  SEASONS: "/seasons",
  LIMITED_EDITION: "/club-pass",
  LIMITED_EDITION_CARD: "/club-pass/:id",
  REWARD_ORDERS: "/reward-orders",
  CARD_CONSTRUCTOR: "/pass-constructor",
  CARD_CONSTRUCTOR_EDIT: "/pass-constructor/:id",
  SEASONS_REWARDS_EDIT: "/seasons-rewards-edit",
  INSIGHTS: "/insights",
  CHAT: "/messages",
  CHAT_DIRECT: "/messages/:userId",
  JOIN: "/join",
  JOIN_CODE: "/join/:code",
  SETUP_PASSWORD: "/setup-password",
  FORGOT_PASSWORD: "/forgot-password",
  PAYMENTS_INFLUENCER_ID_RETURN: "/payments/:influencerId/return",
  PAYMENTS_INFLUENCER_ID_REFRESH: "/payments/:influencerId/refresh",
  ACCOUNT_SUSPENDED: "/account-suspended",
  SCHEDULED_POSTS: "/scheduled-posts",
  EARNINGS: "/earnings",
  TRANSACTIONS: "/transactions",
  SETTINGS: "/settings",
  SETTINGS_EMAIL_NOTIFICATIONS: "/settings/email-notifications",
  LAST_SEGMENT_EMAIL_NOTIFICATIONS: "email-notifications",
  CLUB_MEMBERS: "/insights/club-members",
  404: "/404",
  MEETING: "/meeting",
  MEETING_ID: "/meeting/:meetingId",
};

export const SUBSCRIPTIONS_PRICE = {
  FREE: "free",
  PAID: "paid",
};

export const MEDIA_TYPES = {
  IMAGE: "image",
  VIDEO: "video",
  AUDIO: "audio",
  TEXT: "text",
  FILE: "file",
};

export const MEDIA_URLS_PROP = {
  imageUrl: "imageUrl",
  audioUrl: "audioUrl",
  videoUrl: "videoUrl",
};

export const VIDEO_TYPES = {
  MP4: "video/mp4",
  MOV: "video/quicktime",
  AVI: "video/avi",
};

export const IMAGE_TYPES = {
  JPEG: "image/jpeg",
  JPG: "image/jpg",
  PNG: "image/png",
};

export const AUDIO_TYPES = {
  MPEG: "audio/mpeg",
  MP3: "audio/mp3",
};

export const IMAGE_FORMAT = {
  PNG: "png",
  JPG: "jpg",
  JPEG: "jpeg",
};

export const VIDEO_FORMAT = {
  MP4: "mp4",
  AVI: "avi",
  MOV: "mov",
  QUICKTIME: "quicktime",
};

export const AUDIO_FORMAT = {
  MPEG: "mpeg",
  MP3: "mp3",
};

export const WEBSITE_LANG_STRINGS = {
  "en-GB": "en",
  "zh-CN": "ch",
  "ja-JP": "ja",
  "ko-KR": "ko",
  "fr-FR": "fr",
  "es-ES": "es",
  "tr-TR": "tr",
  "pt-BR": "pt",
};
export const ZOOP_POLICY_HOST = "https://policy.zoopcards.com";

export const LEVEL_BIGGER = "bigger";
export const LEVEL_SMALLER = "smaller";
export const LEVEL_GROUP = "group";

export const POST_IN_GROUPS = {
  ONLY_ME: "only_me",
  EVERYONE: "all",
  SELECTED_MEMBERS: "selected_members",
};

export const COOKIE = "cookie";
export const COOKIE_ACCEPTED = "accepted";
export const SNACKBAR_TYPE = {
  COOKIE: "COOKIE",
};
export const FULL_COMMENT_TEXT_LIMIT = 4000;
export const FETCH_POSTS_LIMIT = 30;
export const EMULATE = {
  LOCAL_STORAGE_ID: "emulated-id",
  HEADER: "emulate-influencer-id",
};

export const ADMIN_APP_DOMAIN =
  process.env.REACT_APP_ENV === "staging"
    ? "https://staging.admin.influencerindex.com"
    : "https://admin.influencerindex.com/";

export const POST_STATUS = {
  PUBLISHED: "published",
  PENDING_REVIEW: "pending_review",
  REJECTED: "rejected",
};

export const YOUTUBE_REGEX =
  /(?:youtube(?:-nocookie)?\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|shorts\/|live\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;

export const CARD_STATUS = {
  NEW: "new",
  APPROVED: "approved",
  REJECTED: "rejected",
};

export const USER_ME_STATUS = {
  NEW: "new",
  BASICS_FILLED: "basicsFilled",
  PROFILE_COMPLETED: "profileCompleted",
  VERIFIED: "verified",
  BLOCKED: "blocked",
  SUSPENDED: "suspended",
};
export const dateLocales = {
  "en-GB": enGB,
  "zh-CN": zhCN,
  "fr-FR": fr,
  "ja-JP": ja,
  "ko-KR": ko,
  "es-ES": es,
  "tr-TR": tr,
  "pt-BR": pt,
};

export const EVENTS = {
  CHATS: "chats",
  CHATS_UNREAD_COUNT: "chats-unread-count",
  CHAT_TYPING: "chat-typing",
  CHATS_MESSAGE_SEEN: "chats-message-seen",
  CHATS_DELETED: "chats-deleted",
  CHATS_DELETED_RESTORE: "chats-deleted-restore",
  REWARD_MESSAGING: "reward-messaging",
  KYC: "kyc",
};

export const TMP_USER_CHAT_ID = "-1";
export const GLOBAL_HEADER_CLASSNAME = "main-header";
export const CHATS_TYPE = {
  MY: "my",
  REQUESTS: "requests",
};

export const REASONS_FOR_REPORTS = [
  { label: "Spam", name: "spam", id: 1 },
  { label: "Bullying or harassment", name: "bullying_or_harassment", id: 2 },
  { label: "Scams and fraud", name: "scams_and_fraud", id: 3 },
  { label: "Hate speech", name: "hate_speech", id: 4 },
];

export const TYPES_OF_PROOF = [
  { label: "Link", name: "LINK", id: 1 },
  { label: "Screenshot", name: "SCREENSHOT", id: 2 },
];

export const REWARDS_ACTION_TYPE = {
  HIDDEN_CONTENT: "content",
  PREMIUM_CHAT: "chat",
  FOLLOW_BACK: "followBack",
  VIDEO_CALL: "call",
  LIVESTREAM: "livestream",
  CUSTOM: "custom",
};

export const VIDEO_DATA_ACTIVE = "active";
export const VIDEO_DATA_NOT_ACTIVE = "";

export const CHAT_CATEGORIES = {
  PREMIUM: "premium",
  GENERAL: "general",
};

export const REWARD_ORDER_STATUS = {
  OPEN: "open",
  PENDING_REPLY: "pendingReply",
  AWAITING: "awaiting",
  DELIVERED: "delivered",
  FAILED: "failed",
};

export const TUTORIALS_PROPS = {
  PROFILE_COMPLETE: "profileComplete",
  EDIT_COMMUNITY: "editCommunity",
  FIRST_CARD: "firstCard",
  CREATE_CARD_PREMIUM_CHAT: "createCardPremiumChat",
  CREATE_CARD_FOLLOW_BACK: "createCardFollowBack",
  CREATE_CARD_VIDEO_CALL: "createCardVideoCall",
  CREATE_CARD_IMG: "createCardImg",
  CREATE_CARD_SUPPLY: "createCardSupply",
  CREATE_CARD_PRICE: "createCardPrice",
  CREATE_CARD_SCHEDULE: "createCardSchedule",
  FIRST_ORDER: "firstOrder",
};
