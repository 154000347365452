export const getMonthWithIndexes = ({ t }) => ({
  1: t("default.january"),
  2: t("default.february"),
  3: t("default.march"),
  4: t("default.april"),
  5: t("default.may"),
  6: t("default.june"),
  7: t("default.july"),
  8: t("default.august"),
  9: t("default.september"),
  10: t("default.october"),
  11: t("default.november"),
  12: t("default.december"),
});
