import { formatDistanceToNow } from "date-fns";

import { dateLocales } from "../Constants";

const TimeAgo = ({ value, children, className, ...props }) => {
  const lang = localStorage.getItem("language") || "en-US";
  return formatDistanceToNow(new Date(value), {
    addSuffix: true,
    locale: dateLocales[lang],
  });
};

export default TimeAgo;
