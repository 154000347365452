import { Box, IconButton, Typography } from "@mui/material";
import { ReactComponent as CloseReply } from "assets/svg/reply-close-icon.svg";
import React from "react";

import { REPLY_COMMENT_TEXT_LIMIT } from "../../../../Constants";
import { truncate } from "../../../../Utils";
import styles from "./SelectedReply.module.scss";

const SelectedReply = ({ isReply, selectedReply, onClick }) => {
  if (!isReply || !selectedReply) return null;

  const {
    message,
    appUser: { username },
  } = selectedReply;

  return (
    <Box className={styles.replyWrapper}>
      <Box className={styles.replyBlock} />
      <Box className={styles.replyMsg}>
        <Typography variant="body3" className={styles.replyName}>
          {username}
        </Typography>
        <Typography variant="body2">
          {truncate(message, REPLY_COMMENT_TEXT_LIMIT)}
        </Typography>
        <IconButton onClick={onClick} className={styles.replyClose}>
          <CloseReply />
        </IconButton>
      </Box>
      <Box className={styles.replyBlock} />
    </Box>
  );
};

export default SelectedReply;
