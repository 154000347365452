import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";

export const LinearProgressStyled = ({ color, value }) => {
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 6,
    borderRadius: 2,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: color?.progressBg || theme.palette.borderColor.progress,
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 2,
      backgroundColor: color?.main || theme.palette.borderColor.main,
    },
  }));
  return <BorderLinearProgress variant="determinate" value={value} />;
};
