import { Box } from "@mui/material";
import React from "react";
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  YAxis,
} from "recharts";
import { useIsMobile, useIsTablet } from "Utils/Hooks";

import Title from "../Title/Title";

const LineGraph = ({ data, totalSub }) => {
  const isMb = useIsMobile();
  const isTb = useIsTablet("1025px");
  const shouldChangeLayout = isMb && !isTb;
  const { capitalization } = data;
  const processedData = capitalization.entries.map((entry, index) => ({
    index,
    value: parseFloat(entry.value),
  }));

  return (
    <Box>
      <Title data={data} totalSub={totalSub} />
      <ResponsiveContainer width="100%" height={shouldChangeLayout ? 170 : 350}>
        <LineChart
          data={processedData}
          margin={
            shouldChangeLayout
              ? { top: 30, right: 0, left: 0, bottom: 10 }
              : { top: 20, right: 40, left: 50, bottom: 30 }
          }
        >
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="1" y2="0">
              <stop offset="47.68%" stopColor="#70E8FC" />
              <stop offset="97.49%" stopColor="#27E7C4" />
            </linearGradient>
          </defs>
          <CartesianGrid
            vertical={false}
            horizontal={true}
            stroke="#ABB3F3"
            strokeOpacity={0.1}
            strokeWidth={shouldChangeLayout ? 2 : 3}
          />
          <YAxis
            ticks={capitalization.ticks}
            domain={["dataMin", "dataMax"]}
            axisLine={false}
            tickLine={false}
            tickFormatter={(value) => `$${value}`}
            tick={{
              fill: "rgba(255, 255, 255, 0.76)",
              fontSize: shouldChangeLayout ? 10 : 16,
              fontFamily: "ClashGrotesk-Regular",
              alignmentBaseline: "middle",
            }}
            tickMargin={isMb ? 15 : 30}
          />
          <Line
            type="monotone"
            dataKey="value"
            stroke="url(#colorUv)"
            strokeWidth={shouldChangeLayout ? 2 : 3}
            dot={false}
          />
        </LineChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default LineGraph;
