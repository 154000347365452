import { Button, Snackbar } from "@mui/material";
import { AUTO_HIDE_TIMEOUT } from "Constants/index";
import React, { useContext } from "react";
import { LocalizationContext } from "Services/Localization/LocalizationContext";

import styles from "./UndoSnackbar.module.scss";

export const UndoSnackbar = ({
  message,
  isOpen,
  onClose,
  undoHandler,
  autoHideDuration,
}) => {
  const { t } = useContext(LocalizationContext);

  return (
    <>
      <Snackbar
        open={isOpen}
        message={message}
        autoHideDuration={autoHideDuration || AUTO_HIDE_TIMEOUT}
        severity={"success"}
        onClose={onClose}
        classes={{ root: styles.snackbarRoot }}
        action={
          <Button className={styles.snackbarButton} onClick={undoHandler}>
            {t("community.undo")}
            <svg
              width="28"
              height="12"
              viewBox="0 0 28 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M27 10.75V7.25C27 3.93629 24.3137 1.25 21 1.25H1.5"
                stroke="#111322"
                strokeWidth="1.5"
                strokeLinecap="round"
              />
            </svg>
          </Button>
        }
      />
    </>
  );
};
