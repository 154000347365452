export const KYC_ERROR_STATUS = {
  REJECTED: "rejected",
  DENY: "DENY",
  SUSPECTED: "suspected",
  CAUTION: "caution",
};

export const KYC_STATUS = {
  ACCEPT: "ACCEPT",
  IN_PROGRESS: "IN_PROGRESS",
  ...KYC_ERROR_STATUS,
};
