import { LoadingButton } from "@mui/lab";
import { Box, Skeleton, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { BackHeader } from "Components/BackHeader/BackHeader";
import Page from "Components/Page/Page";
import { FETCH_POSTS_LIMIT, PATHS, SCROLL_THRESHOLD } from "Constants/index";
import { format } from "date-fns";
import { sortBy } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  fetchScheduledPosts,
  resetScheduledPosts,
} from "Redux/scheduledPostsSlice";
import { LocalizationContext } from "Services/Localization/LocalizationContext";

import CommunityPost from "../Community/components/CommunityPost/CommunityPost";
import styles from "./ScheduledPosts.module.scss";

export const ScheduledPosts = () => {
  const { t } = useContext(LocalizationContext);
  const theme = useTheme();
  const dispatch = useDispatch();
  const location = useLocation();

  const isMd = useMediaQuery(theme.breakpoints.down(1025));
  const posts = useSelector((state) => state.scheduledPosts.posts);
  const total = useSelector((state) => state.scheduledPosts.total);
  const [loader, setLoader] = useState(true);
  const [page, setPage] = useState(1);

  const postsArr = sortBy(Object.values(posts), "scheduledAt");
  const hasMore = postsArr.length < total;
  const backRoute = location.state?.from || PATHS.COMMUNITY;

  const isSameDay = (date1, date2) => {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  };

  const convertDate = (value) => {
    if (!value) {
      return null;
    }
    const date = new Date(value);

    if (isSameDay(date, new Date())) {
      return t("default.today");
    }
    return format(
      new Date(
        date?.toLocaleDateString("en-US", {
          month: "2-digit",
          day: "2-digit",
          year: "numeric",
        })
      ),
      "EEEE, MMM dd"
    );
  };

  const loadMore = () => setPage((prevState) => prevState + 1);

  useEffect(() => {
    dispatch(
      fetchScheduledPosts({ limit: FETCH_POSTS_LIMIT, page, type: "scheduled" })
    ).finally(() => setLoader(false));

    return () => {
      dispatch(resetScheduledPosts());
    };
  }, []);

  useEffect(() => {
    if (page !== 1) {
      setLoader(true);
      dispatch(
        fetchScheduledPosts({
          limit: FETCH_POSTS_LIMIT,
          page,
          type: "scheduled",
          isAdd: true,
        })
      ).finally(() => setLoader(false));
    }
  }, [page]);

  return (
    <Page className={styles.wrapper}>
      <BackHeader
        title={t("default.scheduledPosts")}
        className={styles.header}
        pathBack={backRoute}
      />
      <Box className={styles.main}>
        <Box className={styles.posts}>
          <InfiniteScroll
            next={loadMore}
            dataLength={postsArr?.length}
            hasMore={hasMore}
            scrollableTarget="scrollableDiv"
            scrollThreshold={SCROLL_THRESHOLD}
          >
            {!postsArr.length && loader ? (
              <>
                <Skeleton
                  variant="rectangular"
                  className={styles.skeletonPostTitle}
                />
                <Skeleton
                  variant="rectangular"
                  className={styles.skeletonPost}
                />
              </>
            ) : (
              postsArr.map((post, index) => (
                <CommunityPost
                  key={post.id}
                  {...post}
                  createdAt={post.scheduledAt}
                  postIndex={index}
                  isScheduled={true}
                  title={
                    index === 0 ? (
                      <Typography
                        variant="body2_display"
                        component="p"
                        className={styles.title}
                      >
                        {convertDate(post?.scheduledAt)}
                      </Typography>
                    ) : isSameDay(
                        new Date(postsArr[index].scheduledAt),
                        new Date(postsArr[index - 1].scheduledAt)
                      ) ? null : (
                      <Typography
                        variant="body2_display"
                        component="p"
                        className={styles.title}
                      >
                        {convertDate(post?.scheduledAt)}
                      </Typography>
                    )
                  }
                />
              ))
            )}
            {!postsArr.length && !loader ? (
              <Typography
                variant="body4"
                component="p"
                className={styles.nodata}
              >
                {t("default.noData")}
              </Typography>
            ) : postsArr?.length < total ? (
              <LoadingButton
                variant="outlined"
                className={styles.btnLoadMore}
                onClick={loadMore}
                loading={loader}
              >
                {t("community.loadMore")}
              </LoadingButton>
            ) : null}
          </InfiniteScroll>
        </Box>
        {!isMd ? (
          <Box className={styles.scheduledBlock}>
            {!postsArr.length && loader ? (
              <Skeleton
                variant="rectangular"
                className={styles.skeletonPicker}
              />
            ) : (
              <DatePicker
                selected={new Date()}
                highlightDates={postsArr.map(
                  (item) => new Date(item.scheduledAt)
                )}
                inline
                dateFormat="MMMM d, yyyy"
                calendarClassName="scheduled-calendar"
              />
            )}
          </Box>
        ) : null}
      </Box>
    </Page>
  );
};
