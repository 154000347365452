import { useTheme } from "@emotion/react";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  Typography,
} from "@mui/material";
import { AuthenticationDetails, CognitoUser } from "amazon-cognito-identity-js";
import { ReactComponent as ArrowBack } from "assets/svg/arrow-left.svg";
import AuthLeftSide from "Components/AuthLeftSide";
import Alert from "Components/ErrorAlert";
import { InputPassword } from "Components/Inputs/Input";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setLoader } from "Redux/appSlice";
import { fetchUserMe } from "Redux/usersSlice";
import { errorResponseMessages } from "Services/Api/apiErrorHelper.tsx";
import { setAuth } from "Services/Auth/auth.service";
import { LocalizationContext } from "Services/Localization/LocalizationContext";
import UserPool from "Services/userPool";
import { useIsMobile } from "Utils/Hooks";
import { useRedirectAfterAuth } from "Utils/Hooks";
import styles from "Views/Login/Auth.module.scss";
import * as Yup from "yup";

const NewPassword = () => {
  const { t } = useContext(LocalizationContext);
  const ref = useRef(null);
  const refConfirm = useRef(null);
  const [serverError, setServerError] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const email = location.state?.email;
  const oldPassword = location.state?.password;
  const redirect = useRedirectAfterAuth();

  useEffect(() => {
    if (!location.state?.email || !location.state?.password) navigate("/");
  });

  const setAuthUser = (data) => {
    setAuth({ data, dispatch });
    dispatch(fetchUserMe());
    redirect();
  };

  const onSubmit = (newPassword) => {
    const user = new CognitoUser({
      Username: location.state?.email,
      Pool: UserPool,
    });

    const authDetails = new AuthenticationDetails({
      Username: email,
      Password: oldPassword,
    });
    setServerError(false);

    dispatch(
      setLoader({
        title: t("auth.setupAccountLoaderTitle"),
        subtitle: t("auth.setupAccountLoaderDesc"),
      })
    );
    user.authenticateUser(authDetails, {
      onFailure: (error) => {
        dispatch(setLoader(false));
        setServerError(errorResponseMessages(error, t));
      },
      newPasswordRequired() {
        user.completeNewPasswordChallenge(
          newPassword,
          {},
          {
            onSuccess: (result) => {
              dispatch(setLoader(false)); //login
              setAuthUser(result);
            },
            onFailure(err) {
              dispatch(setLoader(false));
              setServerError(errorResponseMessages(err, t));
            },
          }
        );
      },
    });
  };

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required(t("errors.required"))
      .min(8, t("errors.shortText"))
      .matches(/\d/, t("errors.shouldContainNumbers"))
      .matches(/^(?=(.*[a-z]){1})/, t("errors.shouldContainLowercaseLetter"))
      .matches(/^(?=(.*[A-Z]){1})/, t("errors.shouldContainUppercaseLetter"))
      .matches(
        /^(?=(.*[!@#$%^&*()_+\-=\[\]{};~`':"\\|,.<>\/?]){1})/,
        t("errors.shouldContainSpecialCharacter")
      ),
    confirm_password: Yup.string().oneOf(
      [Yup.ref("password"), null],
      t("auth.passwordsDontMatch")
    ),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onPasswordSubmit = (data) => {
    onSubmit(data.password);
  };
  const goBack = () => {
    navigate("/signin");
  };
  const theme = useTheme();

  return (
    <Box className={styles.authContainer}>
      {!isMobile && <AuthLeftSide step={0} />}
      <Box className={styles.authRightSide}>
        <Box className={styles.authRightSideContent}>
          {!isMobile && (
            <IconButton
              onClick={goBack}
              className={styles.backBtn}
              sx={{ bgcolor: theme.palette.dark.surface }}
            >
              <ArrowBack width={40} height={40} />
            </IconButton>
          )}
          <form
            onSubmit={handleSubmit(onPasswordSubmit)}
            autoComplete="off"
            className={styles.form}
          >
            <Box>
              <Typography
                variant="h3"
                component="h1"
                className={styles.authTitle}
              >
                {t("auth.setNewPassword")}
              </Typography>
              <FormControl className={styles.formControl}>
                <InputLabel className={styles.label}>
                  {t("auth.nwPassword")}
                </InputLabel>
                <InputPassword
                  id="filled-adornment-password"
                  autoFocus
                  ref={ref}
                  placeholder={t("auth.nwPassword")}
                  {...register("password")}
                  error={!!errors.password}
                  autoComplete="off"
                />
                {errors.password && (
                  <Typography
                    variant="error"
                    className={styles.inputErrorMessage}
                  >
                    {errors.password?.message}
                  </Typography>
                )}
              </FormControl>

              <FormControl className={styles.formControl}>
                <InputLabel className={styles.label}>
                  {t("auth.confirmNewPassword")}
                </InputLabel>
                <InputPassword
                  type="password"
                  ref={refConfirm}
                  placeholder={t("auth.confirmNewPassword")}
                  {...register("confirm_password")}
                  error={!!errors.confirm_password}
                  autoComplete="off"
                />
                {errors.confirm_password && (
                  <Typography
                    variant="error"
                    className={styles.inputErrorMessage}
                  >
                    {errors.confirm_password?.message}
                  </Typography>
                )}
              </FormControl>
              {serverError && (
                <Alert className={styles.errorAlert}>{serverError}</Alert>
              )}
            </Box>
            <FormControl>
              <Button
                type="submit"
                variant="contained"
                className={styles.authSubmitBtn}
                disabled={!!errors.password || !!errors.confirm_password}
              >
                {t("auth.submitNewPassword")}
              </Button>
            </FormControl>
          </form>
        </Box>
      </Box>
    </Box>
  );
};
export default NewPassword;
