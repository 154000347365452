import { LoadingButton } from "@mui/lab";
import { Box, IconButton, Typography } from "@mui/material";
import { ReactComponent as CloseIcon } from "assets/svg/reply-close-icon.svg";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSnackbar } from "Redux/appSlice";
import { getStripeAccount } from "Redux/communitySlice";
import {
  getUsersMeId,
  getUsersMeIsPaymentEnable,
  getUsersMeStripeAccount,
  getUsersMeStripeDataStatus,
} from "Redux/selectors/userSelector";
import { LocalizationContext } from "Services/Localization/LocalizationContext";

import SetupStripeWarningModal from "../Modal/SetupStripeWarningModal";
import styles from "./SetupPaymentsSection.module.scss";

export const SetupPaymentsSection = ({
  classNameContent,
  classNameHeader,
  classNameHeaderWrapper,
  classNameDescription,
  isCustomShow = false,
  isDisabled,
}) => {
  const { t } = useContext(LocalizationContext);
  const dispatch = useDispatch();

  const isPaymentEnable = useSelector(getUsersMeIsPaymentEnable);
  const stripeAccountStatus = useSelector(getUsersMeStripeDataStatus);
  const userMeId = useSelector(getUsersMeId);

  const [loader, setLocalLoader] = useState();
  const [stripeUrl, setStripeUrl] = useState(null);
  const [isCloseSetup, setCloseSetup] = useState(false);

  const getStripeAccountLinkUrl = (callback) => {
    setLocalLoader(true);
    dispatch(getStripeAccount())
      .unwrap()
      .then((res) => {
        if (res?.url) callback && callback(res?.url);
      })
      .catch((e) => {
        dispatch(
          setSnackbar({
            open: true,
            message: e?.message || t("errors.defaultError"),
            severity: "error",
            autoHideDuration: 3000,
          })
        );
      })
      .finally(() => setLocalLoader(false));
  };

  const donationUnlockHandler = () => {
    getStripeAccountLinkUrl((url) => setStripeUrl(url));
  };

  const onCancelPaid = () => setStripeUrl(null);

  const onClose = () => {
    localStorage.setItem(
      `${userMeId}-setup-payment`,
      `${userMeId}-setup-payment`
    );
    setCloseSetup(true);
  };

  useEffect(() => {
    if (userMeId) {
      setCloseSetup(!!localStorage.getItem(`${userMeId}-setup-payment`));
    }
  }, [userMeId]);

  if (
    (!isPaymentEnable || stripeAccountStatus === "active" || isCloseSetup) &&
    !isCustomShow
  ) {
    return <></>;
  }

  return (
    <>
      <Box
        className={`${styles.content} ${
          classNameContent ? classNameContent : ""
        }`}
      >
        <Box
          className={`${styles.contentHeaderWrapper} ${
            classNameHeaderWrapper ? classNameHeaderWrapper : ""
          }`}
        >
          <Box
            className={`${styles.contentHeader} ${
              classNameHeader ? classNameHeader : ""
            }`}
          >
            <Typography variant="body4" className={styles.contentTitle}>
              {t("default.paymentsSectionTitle")}
            </Typography>
            <IconButton className={styles.close} onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Typography
            className={`${styles.contentDescription} ${
              classNameDescription ? classNameDescription : ""
            }`}
            variant="body2"
          >
            {t("default.paymentsSectionDescription")}
          </Typography>
        </Box>
        <LoadingButton
          className={styles.contentButton}
          variant="contained"
          onClick={donationUnlockHandler}
          loading={loader}
          disabled={loader || isDisabled}
        >
          {t("default.paymentsSectionButton")}
        </LoadingButton>
      </Box>
      <SetupStripeWarningModal stripeUrl={stripeUrl} onCancel={onCancelPaid} />
    </>
  );
};
