import { CardsSlider } from "Components/CardsSlider/CardsSlider";
import { Card } from "Components/NewCard";
import { CARD_VARIANTS } from "Constants/card";
import { EDITION_STATUSES } from "Constants/index";
import React from "react";

export const SeasonSlider = ({ seasonCards }) => {
  return (
    <CardsSlider>
      {seasonCards
        .filter((item) => Boolean(item))
        .map((data, index) => {
          const card = {
            ...data,
            ...data?.cardMetadata,
          };
          const isReleased = [
            EDITION_STATUSES.complete,
            EDITION_STATUSES.phase2,
          ].includes(data?.status);
          return (
            <Card
              variant={CARD_VARIANTS.SMALL}
              data={data && card}
              key={index}
              isGrayedOut={isReleased && !data?.holdings}
            />
          );
        })}
    </CardsSlider>
  );
};
