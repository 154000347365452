import { Box, Typography } from "@mui/material";
import React, { useContext } from "react";
import { LocalizationContext } from "Services/Localization/LocalizationContext";

import styles from "./Row.module.scss";

export const RowChildren = ({ data }) => {
  const { t } = useContext(LocalizationContext);

  const RowBox = ({ item, title }) => (
    <Box className={`${styles.tr} ${styles.childTr}`}>
      <Box className={styles.colTop}>
        <Box className={styles.col}>
          <Typography variant="body1" className={styles.colTitle}>
            {title}
          </Typography>
        </Box>
      </Box>
      <Box className={styles.colMainContent}>
        <Box className={styles.col}>
          <Typography variant="body1" className={styles.colValue}>
            $ {item.gross}
          </Typography>
        </Box>
        <Box className={styles.col}>
          <Typography variant="body1" className={styles.colValue}>
            $ {item.fee}
          </Typography>
        </Box>
        <Box className={styles.col}>
          <Typography variant="body1" className={styles.colValue}>
            $ {item.net}
          </Typography>
        </Box>
      </Box>
    </Box>
  );

  return (
    <>
      <RowBox item={data.subscriptions} title={t("default.subscriptions")} />
      <RowBox item={data.donations} title={t("default.donations")} />
      <RowBox
        item={data.limitedEditionSales}
        title={t("settings.emailNotificationsLabelCardSale")}
      />
      {/*<RowBox item={data.seasonSales} title={t("default.seasonSales")} />*/}
    </>
  );
};
