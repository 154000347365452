import { Navigate } from "react-router-dom";
import { SUBSCRIPTIONS_PRICE } from "Constants";
import { useDispatch } from "react-redux";
import { setLoader } from "Redux/appSlice";
import { getStripeAccount } from "Redux/communitySlice";
import { useEffect } from "react";
// TODO::don't forget to change return_url and refresh_url to prod/stage on BE
export const StripeOnboardingReturnUrl = () => {
  return (
    <Navigate
      to="/edit-community"
      state={{ subscriptionType: SUBSCRIPTIONS_PRICE.PAID }}
      replace
    />
  );
};
/**
 * Redirect back to stripe account url in cases when:
 * The link is expired (a few minutes went by since the link was created).
 * The user already visited the URL (the user refreshed the page or clicked back or forward in the browser).\
 * Your platform is no longer able to access the account.
 * The account has been rejected.
 */
//TODO::test account rejected case.

export const StripeOnboardingRefreshUrl = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setLoader(true));
    dispatch(getStripeAccount())
      .unwrap()
      .then((res) => {
        if (res?.url) window.location.href = res.url;
      })
      .catch((e) => {
        console.error(e);
        return <Navigate to="/edit-community" replace />;
      })
      .finally(() => dispatch(setLoader(false)));
  }, []);
};
