import { Box, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import BackBtn from "Components/BackBtn/BackBtn";
import Page from "Components/Page/Page";
import SidebarTablet from "Components/SidebarTablet/SidebarTablet";
import React, { useContext } from "react";
import { LocalizationContext } from "Services/Localization/LocalizationContext";
import { useIsMobile } from "Utils/Hooks";

import { RewardsTable } from "./components/RewardsTable/RewardsTable";
import styles from "./RewardOrders.module.scss";

export const RewardOrders = () => {
  const { t } = useContext(LocalizationContext);
  const isMobile = useIsMobile();
  const isS = useMediaQuery("(max-width:767px)");

  return (
    <Page className={styles.container}>
      <Box className={styles.titleWrap}>
        <SidebarTablet />
        {!isMobile || isS ? <BackBtn isPlain /> : null}
        <Typography variant="h2" className={styles.title}>
          {t("limitedEdition.rewardOrdersTitle")}
        </Typography>
      </Box>
      <RewardsTable />
    </Page>
  );
};
