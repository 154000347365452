import { Box } from "@mui/material";
import Countdown from "Components/Countdown";
import { differenceInSeconds } from "date-fns";
import * as React from "react";
import { useState } from "react";

import { LinearProgressStyled } from "./LinearProgress";

const CountdownProgress = (
  { endDate, startDate, color, children, className },
  ref
) => {
  const range = differenceInSeconds(new Date(endDate), new Date(startDate));

  const getValue = () => {
    const diff = Math.max(
      0,
      differenceInSeconds(new Date(endDate), new Date())
    );
    return 100 - (100 * diff) / range;
  };
  const [value, setValue] = useState(getValue);

  const onSetTimer = (val) => {
    setValue(getValue);
    setSecondsLeft(differenceInSeconds(new Date(endDate), new Date()));
  };
  const [secondsLeft, setSecondsLeft] = useState(
    differenceInSeconds(new Date(endDate), new Date())
  );

  return (
    <Box className={className}>
      <LinearProgressStyled color={color} value={value} />
      <Countdown value={secondsLeft} onSetValue={onSetTimer} />
      {children}
    </Box>
  );
};

export default CountdownProgress;
