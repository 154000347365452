import { Box, Button, Snackbar } from "@mui/material";
import classNames from "classnames/bind";
import { AUTO_HIDE_TIMEOUT } from "Constants/index";
import React, { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  changePostCommentsCount,
  updateRepliesCount,
} from "Redux/communitySlice";
import { LocalizationContext } from "Services/Localization/LocalizationContext";
import useDelayedExecution from "Utils/Hooks/useDelayedExecution";

import { STATE_REPLIES_COUNT } from "../../utils";
import CommentItem from "../CommentItem/CommentItem";
import styles from "./CommentBox.module.scss";

export const CommentBox = ({
  renderComments,
  isNested,
  comment,
  handleReply,
  loadingReplyIds,
  parentId,
  activeCommentReplyIds,
  setActiveCommentReplyIds,
  setActiveReplyId,
  parentRepliesCount,
}) => {
  const dispatch = useDispatch();

  const { t } = useContext(LocalizationContext);
  const cx = classNames.bind(styles);
  const post = useSelector((state) => state.community.posts.postById);
  const total = useSelector((state) => state.community.posts.comments.total);

  const isActiveReplies = activeCommentReplyIds.includes(comment.id);
  const { id: postId } = post;

  const [isDeleteParentMode, setDeleteParentMode] = useState(false);
  const [isSnackBarDelete, setSnackBarDelete] = useState(false);
  const [isDeleteMode, setDeleteMode] = useState(false);
  const [isActionLoading, setActionLoading] = useState(false);

  const { stopExecute, startExecute } = useDelayedExecution(AUTO_HIDE_TIMEOUT);

  const updateRepliesCountHandler = (state) => {
    const count = total ? total : 0;
    const parentCount = parentRepliesCount || 0;
    const repliesToCommentCount =
      (!comment?.replyToId && comment?.countReplies) || 0;
    const postCount =
      state === STATE_REPLIES_COUNT.INCREASE
        ? count + repliesToCommentCount + 1
        : count - repliesToCommentCount - 1;

    if (parentId) {
      const repliesCount =
        state === STATE_REPLIES_COUNT.INCREASE
          ? parentCount + 1
          : parentCount - 1;
      dispatch(
        updateRepliesCount({
          replyToId: parentId,
          value: repliesCount,
        })
      );
      dispatch(changePostCommentsCount({ postId, commentsCount: postCount }));
    } else {
      dispatch(changePostCommentsCount({ postId, commentsCount: postCount }));
    }
  };

  const undoDelete = () => {
    stopExecute();
    setDeleteMode(false);
    setDeleteParentMode(false);
    setSnackBarDelete(false);
    setActionLoading(false);
    updateRepliesCountHandler(STATE_REPLIES_COUNT.INCREASE);
  };

  return (
    <>
      {!isDeleteParentMode ? (
        <Box
          className={cx(isNested && styles.commentReplyWrapper)}
          key={comment.id}
        >
          <CommentItem
            comment={comment}
            handleReply={handleReply}
            isNested={isNested}
            activeCommentReplyIds={activeCommentReplyIds}
            setActiveCommentReplyIds={setActiveCommentReplyIds}
            setActiveReplyId={setActiveReplyId}
            isReplyLoading={loadingReplyIds.includes(comment.id)}
            setDeleteParentMode={setDeleteParentMode}
            setSnackBarDelete={setSnackBarDelete}
            startExecute={startExecute}
            setDeleteMode={setDeleteMode}
            setActionLoading={setActionLoading}
            updateRepliesCountHandler={updateRepliesCountHandler}
            isActionLoading={isActionLoading}
            isDeleteMode={isDeleteMode}
          />
          {comment?.replies && isActiveReplies
            ? renderComments(
                comment.replies,
                true,
                comment.id,
                comment.countReplies
              )
            : null}
        </Box>
      ) : null}
      <Snackbar
        open={isSnackBarDelete}
        message={t("community.deleteCommentSuccess")}
        autoHideDuration={AUTO_HIDE_TIMEOUT}
        severity={"success"}
        onClose={() => setSnackBarDelete(false)}
        action={
          <Button onClick={undoDelete} className={styles.snackbarButton}>
            {t("community.undo")}
          </Button>
        }
      />
    </>
  );
};
