import { LoadingButton } from "@mui/lab";
import { Box, Typography } from "@mui/material";
import { LEVEL_GROUP, POST_IN_GROUPS } from "Constants/index";
import { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setError } from "Redux/appSlice";
import { allowPostingFan } from "Redux/communitySlice";
import {
  getUserLevel,
  getUserMePostingRights,
} from "Redux/selectors/userSelector";
import { LocalizationContext } from "Services/Localization/LocalizationContext";

import { editCommunity } from "../../../Redux/usersSlice";
import styles from "./FollowerItem.module.scss";

const FollowerItem = ({
  profileImageUrl,
  username,
  place,
  allowPosting,
  id,
  index,
}) => {
  const { t } = useContext(LocalizationContext);
  const dispatch = useDispatch();

  const level = useSelector(getUserLevel);
  const postingRights = useSelector(getUserMePostingRights);

  const [loader, setLoader] = useState(false);

  const allowPostingFanHandler = ({ appUserId, allowPosting }) => {
    setLoader(true);
    dispatch(allowPostingFan({ appUserId, allowPosting, index })).finally(() =>
      setLoader(false)
    );
  };

  const submitHandler =
    ({ appUserId, allowPosting }) =>
    () => {
      if (
        postingRights === POST_IN_GROUPS.ONLY_ME ||
        postingRights === POST_IN_GROUPS.EVERYONE
      ) {
        dispatch(
          setError({
            open: true,
            title: t("errors.alertMembersPostTitle"),
            subtitle: t("errors.alertMembersPostSubTitle"),
            actions: "AllowPostingHandler",
            handler: () => {
              allowPostingFanHandler({ appUserId, allowPosting });
              dispatch(
                editCommunity({
                  postingRights: POST_IN_GROUPS.SELECTED_MEMBERS,
                })
              );
            },
          })
        );
      } else {
        allowPostingFanHandler({ appUserId, allowPosting });
      }
    };

  return (
    <Box className={styles.followerItem}>
      <Box className={styles.followerInfo}>
        <img
          src={profileImageUrl}
          alt="folower avatar"
          className={styles.followerAvatar}
        />
        <Box className={styles.followerItemMain}>
          <Box style={{ maxWidth: level === LEVEL_GROUP ? "50%" : "100%" }}>
            <Typography variant="body18" className={styles.followerItemTitle}>
              {username}
            </Typography>
            {place ? (
              <Typography variant="body2">
                {t("default.placeNumber", { number: place })}
              </Typography>
            ) : null}{" "}
          </Box>
          {level === LEVEL_GROUP ? (
            <>
              {allowPosting ? (
                <LoadingButton
                  variant="outlined"
                  size="small"
                  className={`${styles.followerItemBtn} ${styles.followerItemBtnError}`}
                  color="error"
                  loading={loader}
                  onClick={submitHandler({
                    appUserId: id,
                    allowPosting: false,
                  })}
                >
                  {t("default.revokePosting")}
                </LoadingButton>
              ) : (
                <LoadingButton
                  variant="contained"
                  size="small"
                  className={styles.followerItemBtn}
                  loading={loader}
                  onClick={submitHandler({
                    appUserId: id,
                    allowPosting: true,
                  })}
                >
                  {t("default.allowPosting")}
                </LoadingButton>
              )}
            </>
          ) : null}
        </Box>
      </Box>
    </Box>
  );
};

export default FollowerItem;
