import { Box, Typography } from "@mui/material";
import CountdownDate from "Components/CountdownDate/CountdownDate";
import CountdownProgress from "Components/CountdownProgress";
import React, { useContext } from "react";
import { LocalizationContext } from "Services/Localization/LocalizationContext";

import styles from "./DropIn.module.scss";

export const DropIn = ({ startDate, endDate, colorTime, colorProgress }) => {
  const { t } = useContext(LocalizationContext);

  const getFormat = (value) => {
    const date = new Date(value) - new Date();
    const hours = date / 1000 / 60 / 60;
    return hours >= 24
      ? ["days", "hours", "minutes"]
      : ["days", "hours", "minutes", "seconds"];
  };

  if (!endDate || !startDate) {
    return null;
  }

  return (
    <Box>
      <Box className={styles.droppingInBlock}>
        <Typography variant="body4">{t("seasons.droppingIn")}</Typography>
        <Typography variant="body4" style={{ color: colorTime || "#fff" }}>
          <CountdownDate
            endDate={endDate}
            divider=":"
            timerFormat={{
              format: getFormat(endDate),
            }}
          />
        </Typography>
      </Box>
      <CountdownProgress
        className={styles.droppingInTimer}
        startDate={startDate}
        endDate={endDate}
        color={colorProgress || "#27E7C4"}
      />
    </Box>
  );
};
