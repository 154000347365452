import { Skeleton } from "@mui/material";
import React from "react";

export const TransactionsSkeleton = () => {
  return (
    <>
      <Skeleton
        variant="rectangular"
        style={{
          height: "43px",
          borderRadius: "10px",
          marginTop: "40px",
          marginBottom: "8px",
        }}
      />
      <Skeleton
        variant="rectangular"
        style={{ height: "43px", borderRadius: "10px", marginBottom: "8px" }}
      />
      <Skeleton
        variant="rectangular"
        style={{ height: "43px", borderRadius: "10px", marginBottom: "8px" }}
      />
    </>
  );
};
