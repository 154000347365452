import { Box, Typography } from "@mui/material";

import styles from "./MediaAmountBox.module.scss";

export const MediaAmountBox = ({ count, icon }) => {
  if (!count) {
    return null;
  }

  return (
    <Box className={styles.wrapper}>
      {icon}
      <Typography variant="body3_medium" className="opacity_07">
        {count}
      </Typography>
    </Box>
  );
};
