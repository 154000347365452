import {
  Box,
  FormControl,
  InputAdornment,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { SOCIAL_LINKS } from "Constants";
import { useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { LocalizationContext } from "Services/Localization/LocalizationContext";

import { getUsersMe } from "../../Redux/selectors/userSelector";
import styles from "./Profile.module.scss";
const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    padding: "5px 10px",
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 14,
  },
}));
const SocialMediaLinks = ({ inputFields, setInputFields }) => {
  const { t } = useContext(LocalizationContext);

  const user = useSelector(getUsersMe);
  const socialMediaArr = user?.socialMediaLinks;

  useEffect(() => {
    setInputFields(
      SOCIAL_LINKS.map((item) => {
        const found =
          socialMediaArr &&
          socialMediaArr.length &&
          socialMediaArr.find(
            (val) => val.socialPlatformId === item.socialPlatformId
          );
        return { ...item, link: found?.link || "" };
      })
    );
  }, [socialMediaArr]);

  const handleFormChange = (event, index) => {
    let data = [...inputFields];
    data[index].link = event.target.value;
    setInputFields(data);
  };
  const getLink = ({ socialPlatformId, link }) => {
    let prependLink = "";
    const found = SOCIAL_LINKS.find(
      (item) => item.socialPlatformId === socialPlatformId
    );
    if (found) prependLink = found.prependLink;
    return prependLink + link;
  };
  const getSicualLinkValue = ({ id, propName }) => {
    const found = SOCIAL_LINKS.find((item) => item.socialPlatformId === id);
    return found ? found[propName] : "";
  };
  return (
    <Box>
      <Typography variant="subtitle" className={styles.subtitle}>
        {t("account.socialTitle")}
        <Typography className={styles.subtitleDescription} variant="body2">
          {t("account.socialTitleDescription")}
        </Typography>
      </Typography>
      <Box className={`${styles.infoBlock} ${styles.social}`}>
        <Box className={styles.socialList}>
          {inputFields.map((social, index) => {
            return (
              <FormControl className={styles.socialListItem} key={index}>
                <Box className={styles.label}>
                  {t(`default.${social.code}`)}
                </Box>
                <LightTooltip title={getLink(social)}>
                  <TextField
                    value={social.link}
                    label={null}
                    className={styles.input}
                    placeholder=""
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Link
                            component={social?.link ? "a" : "div"}
                            target="_blank"
                            href={getLink(social)}
                            rel="noreferrer"
                          >
                            <img
                              src={`social/${social.code}-outlined.svg`}
                              alt={t(`default.${social.code}`)}
                            />
                          </Link>
                          <Typography
                            variant="body1"
                            className={`${styles.socialListItemHandle} opacity_05`}
                          >
                            {getSicualLinkValue({
                              id: social.socialPlatformId,
                              propName: "adornment",
                            })}
                          </Typography>
                        </InputAdornment>
                      ),
                      onChange: (event) => handleFormChange(event, index),
                    }}
                    type="text"
                    autoComplete="off"
                  />
                </LightTooltip>
              </FormControl>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};
export default SocialMediaLinks;
