import { Box, Button, Typography } from "@mui/material";
import React from "react";

import { CustomTooltip } from "../CustomTooltip/CustomTooltip";
import styles from "./MultipleTutorialTooltip.module.scss";

export const MultipleTutorialTooltip = ({
  children,
  isOpen,
  setOpen,
  next,
  prev,
  skip,
  skipButtonText,
  prevButtonText,
  nextButtonText,
  title,
  subTitle,
  tutorialBoxClass,
  isArrow,
  tooltipClassName,
  ...props
}) => {
  if (!isOpen) {
    return children;
  }

  return (
    <CustomTooltip
      isArrow={isArrow}
      open={isOpen}
      setOpen={setOpen}
      isClick={true}
      title={
        <Box
          className={`${styles.tutorialBox} ${
            tutorialBoxClass ? tutorialBoxClass : ""
          }`}
          onClick={(e) => e.stopPropagation()}
        >
          <Typography variant="body4">{title}</Typography>
          <Typography variant="body2">{subTitle}</Typography>
          <Box className={styles.footer}>
            <Button className={styles.footerSkip} onClick={skip}>
              {skipButtonText}
            </Button>
            <Box style={{ gap: "12px", display: "flex" }}>
              {prev ? (
                <Button
                  className={`${styles.footerBack} ${styles.footerSkip}`}
                  onClick={prev}
                >
                  {prevButtonText}
                </Button>
              ) : null}
              <Button
                className={styles.footerNext}
                variant="contain"
                onClick={next}
              >
                {nextButtonText}
              </Button>
            </Box>
          </Box>
        </Box>
      }
      tooltipClassName={`${styles.tooltip} ${
        tooltipClassName ? tooltipClassName : ""
      }`}
      customTooltipArrow={styles.tooltipArrow}
      isCloseOutside={false}
      {...props}
    >
      {children}
    </CustomTooltip>
  );
};
