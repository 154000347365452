import { Box, Tooltip, Typography } from "@mui/material";
import React from "react";

import styles from "./TooltipBox.module.scss";

export const TooltipBox = ({ children, title }) => {
  return (
    <Tooltip
      placement="top"
      classes={{
        arrow: styles.arrow,
        tooltip: styles.tooltip,
      }}
      enterTouchDelay={0}
      arrow
      title={
        <Box>
          <Typography variant="body3">{title}</Typography>
        </Box>
      }
    >
      <Box>{children}</Box>
    </Tooltip>
  );
};
