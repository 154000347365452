import {
  Box,
  Button,
  CircularProgress,
  Skeleton,
  Typography,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { PATHS, REWARD_ORDER_STATUS } from "Constants/index";
import { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchRewardOrdersClubPass } from "Redux/limitedEditionSlice";
import {
  getRewardsOrdersClubPass,
  getRewardsOrdersClubPassLoading,
} from "Redux/selectors/limitedEditionSelectors";
import { LocalizationContext } from "Services/Localization/LocalizationContext";

import { CheckIcon } from "../../icons";
import { Order } from "./components/Order/Order";
import styles from "./RewardOrders.module.scss";

export const RewardOrders = () => {
  const { t } = useContext(LocalizationContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMd = useMediaQuery("(max-width:920px)");

  const loading = useSelector(getRewardsOrdersClubPassLoading);
  const orders = useSelector(getRewardsOrdersClubPass);

  useEffect(() => {
    dispatch(
      fetchRewardOrdersClubPass({
        page: 1,
        limit: 35,
        status: `${REWARD_ORDER_STATUS.OPEN},${REWARD_ORDER_STATUS.AWAITING},${REWARD_ORDER_STATUS.PENDING_REPLY}`,
      })
    );
  }, []);

  const skeletonArr = [];
  skeletonArr.length = 8;
  skeletonArr.fill(null, 0);

  const goToRewards = () => navigate(PATHS.REWARD_ORDERS);

  return (
    <Box style={{ flex: 1 }}>
      <Box className={styles.wrapper}>
        <Box className={styles.header}>
          <Typography variant="subtitle" className={styles.headerTitle}>
            {t("limitedEdition.rewardOrdersTitle")}
          </Typography>
          <Button className={styles.headerButton} onClick={goToRewards}>
            {t("default.viewList")}
          </Button>
        </Box>
        <Box className={styles.list}>
          {loading && orders.length ? (
            <Box className={styles.listLoader}>
              <CircularProgress />
            </Box>
          ) : null}
          {loading && !orders.length
            ? skeletonArr.map((_, index) => (
                <Skeleton
                  variant="rectangular"
                  className={styles.listItemSkeleton}
                  key={`skeleton-${index}`}
                />
              ))
            : null}
          {!loading && !orders.length ? (
            <Typography variant="body2" className={styles.ordersEmpty}>
              <CheckIcon />
              {t("limitedEdition.emptyOrdersText")}
            </Typography>
          ) : null}
          {orders.length && isMd
            ? orders.slice(0, 5).map((item, index) => (
                <Box className={styles.listItem} key={item.id}>
                  <Order {...item} index={index} />
                </Box>
              ))
            : orders.length
            ? orders.map((item, index) => (
                <Box className={styles.listItem} key={item.id}>
                  <Order {...item} index={index} />
                </Box>
              ))
            : null}
        </Box>
      </Box>
    </Box>
  );
};
