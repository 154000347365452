import { Box, TextField } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { LocalizationContext } from "Services/Localization/LocalizationContext";
import roundedInputStyles from "styles/input/rounded.module.scss";

import styles from "./SeparateDateInput.module.scss";

const SeparateDateInput = ({
  defaultValue,
  onChange,
  validation,
  disabled,
}) => {
  const { t } = useContext(LocalizationContext);

  const [value, setValue] = useState({
    month: "",
    day: "",
    year: "",
  });

  useEffect(() => {
    if (!defaultValue) return;

    const [defaultMonth, defaultDay, defaultYear] = defaultValue?.split("/");

    setValue({
      month: defaultMonth,
      day: defaultDay,
      year: defaultYear,
    });
  }, [defaultValue]);

  const handleChange = (e) => {
    const { maxLength, value, name } = e.target;
    const [_fieldName, fieldIndex] = name.split("-");

    if (value.length >= maxLength) {
      if (parseInt(fieldIndex, 10) < 3) {
        const nextSibling = document.querySelector(
          `input[name=input-${parseInt(fieldIndex, 10) + 1}]`
        );

        if (nextSibling !== null) {
          nextSibling.focus();
        }
      }
    }
  };

  const handleChangeValue = (e, key) => {
    const tmpValue = e?.target?.value;
    let changedValue = 0;

    const pattern = /^[0-9]*$/;
    const maxMonth = 12;
    const maxDay = 31;
    const maxYear = new Date().getUTCFullYear();

    if (!pattern.test(tmpValue)) {
      return tmpValue;
    }

    switch (key) {
      case "month": {
        changedValue =
          tmpValue > maxMonth || tmpValue.toString().length > 2
            ? `${maxMonth}`
            : tmpValue;

        break;
      }

      case "day": {
        changedValue =
          tmpValue > maxDay || tmpValue.toString().length > 2
            ? `${maxDay}`
            : tmpValue;
        break;
      }
      case "year": {
        changedValue =
          tmpValue > maxYear || tmpValue.toString().length > 4
            ? `${maxYear}`
            : tmpValue;
        break;
      }
      default:
        return tmpValue;
    }

    const newValue = { ...value, [key]: changedValue };
    setValue(newValue);
    handleChange(e);

    onChange(Object.values(newValue).join("/"));
  };

  return (
    <Box className={styles.container}>
      <TextField
        label={t("default.month")}
        className={`${roundedInputStyles.input} ${styles.input} ${
          !!value.month.length ? roundedInputStyles.filled : null
        }`}
        inputProps={{
          onChange: (e) => handleChangeValue(e, "month"),
          maxLength: 2,
        }}
        autoComplete="off"
        name="input-1"
        type="number"
        pattern="[0-9]*"
        inputMode="numeric"
        value={value.month}
        disabled={disabled}
      />
      <TextField
        label={t("default.day")}
        className={`${roundedInputStyles.input} ${styles.input} ${
          !!value.day.length ? roundedInputStyles.filled : null
        }`}
        inputProps={{
          onChange: (e) => handleChangeValue(e, "day"),
          maxLength: 2,
        }}
        autoComplete="off"
        name="input-2"
        type="number"
        pattern="[0-9]*"
        inputMode="numeric"
        value={value.day}
        disabled={disabled}
      />
      <TextField
        label={t("default.year")}
        className={`${roundedInputStyles.input} ${styles.input} ${
          !!value.year.length ? roundedInputStyles.filled : null
        }`}
        inputProps={{
          onChange: (e) => handleChangeValue(e, "year"),
          maxLength: 4,
        }}
        autoComplete="off"
        name="input-3"
        type="number"
        pattern="[0-9]*"
        inputMode="numeric"
        value={value.year}
        disabled={disabled}
      />
      <input
        type="hidden"
        autoComplete="off"
        value={Object.values(value).join("/")}
        {...validation}
      />
    </Box>
  );
};

export default SeparateDateInput;
