import { Box, Button, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { SearchBar } from "Components/SearchBar/SearchBar";
import {
  CHAT_CATEGORIES,
  CHATS_TYPE,
  GLOBAL_HEADER_CLASSNAME,
  PATHS,
  TMP_USER_CHAT_ID,
} from "Constants/index";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createSearchParams,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { fetchChats, setActiveChat, setNewChat } from "Redux/messengerSlice";
import {
  getActiveChat,
  getChats,
  getChatsLoading,
  getHasMoreChats,
  getHasMoreRequestsChats,
  getRequestsChats,
  getRequestsChatsLoading,
  getSelectedUser,
  getTotalChats,
  getTotalRequests,
} from "Redux/selectors/messengerSelectors";
import {
  getChats as getSearchedChats,
  getSearchChat,
} from "Services/Api/messangerApi";
import { getPublicUser } from "Services/Api/userApi";
import { LocalizationContext } from "Services/Localization/LocalizationContext";
import { useIsFirstRender } from "Utils/Hooks/useIsFirstRender";
import { isMobileDevice } from "Utils/index";

import { Chats } from "../../../Chats/Chats";
import styles from "./Sidebar.module.scss";

export const Sidebar = ({
  setUsersLoading,
  chatsType,
  setChatsType,
  chatCategory,
  setChatCategory,
}) => {
  const { t } = useContext(LocalizationContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width:840px)");
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const isPhone = isMobileDevice();
  const isFirstRender = useIsFirstRender();

  const chatsLoading = useSelector(getChatsLoading);
  const requestsChatsLoading = useSelector(getRequestsChatsLoading);
  const hasMoreChats = useSelector(getHasMoreChats(CHAT_CATEGORIES.GENERAL));
  const hasMoreChatsPremium = useSelector(
    getHasMoreChats(CHAT_CATEGORIES.PREMIUM)
  );
  const hasMoreRequestsChats = useSelector(getHasMoreRequestsChats);
  const totalRequests = useSelector(getTotalRequests);
  const totalChats = useSelector(getTotalChats(CHAT_CATEGORIES.GENERAL));
  const totalChatsPremium = useSelector(getTotalChats(CHAT_CATEGORIES.PREMIUM));
  const selectedUser = useSelector(getSelectedUser);
  const chats = useSelector(getChats(CHAT_CATEGORIES.GENERAL));
  const chatsPremium = useSelector(getChats(CHAT_CATEGORIES.PREMIUM));
  const chatsRequests = useSelector(getRequestsChats);
  const activeChat = useSelector(getActiveChat);

  const [searchedChats, setSearchedChats] = useState(null);
  const [searchLoader, setSearchLoader] = useState(false);
  const [chatPage, setChatPage] = useState(1);
  const [chatPageGeneral, setChatPageGeneral] = useState(1);
  const [chatRequestsPage, setRequestsChatPage] = useState(1);

  const loadMoreChats = () => {
    if (chatCategory === CHAT_CATEGORIES.PREMIUM) {
      setChatPage((prevState) => prevState + 1);
    } else {
      setChatPageGeneral((prevState) => prevState + 1);
    }
  };

  const loadMoreRequestsChats = () => {
    setRequestsChatPage((prevState) => prevState + 1);
  };

  const handleSearch = (type) => (value) => {
    const search = value.trim();
    if (search) {
      setSearchLoader(true);
      getSearchedChats({
        search,
        type,
      })
        .then((data) => {
          setSearchedChats(data.chats);
        })
        .finally(() => setSearchLoader(false));
    } else {
      setSearchedChats(null);
      setSearchLoader(false);
    }
  };

  const convertNewUserDataToChat = (data) => {
    return {
      id: TMP_USER_CHAT_ID,
      lastMessage: {
        timestamp: new Date().getTime(),
      },
      otherUser: {
        id: data.id,
        profileImageUrl: data.profileImageUrl,
        username: data.username,
        isInfluencer: data.isInfluencer,
      },
      updatedAt: new Date().getTime(),
    };
  };

  const setChatCategoryHandler = (type) => () => {
    setChatCategory(type);
    setSearchedChats(null);
    navigate({
      pathname: PATHS.CHAT,
      search: createSearchParams({
        category: type,
      }).toString(),
    });
    dispatch(setActiveChat(null));
    // setSearchParams({ category: type });
  };

  const getSearchChatByUserId = (userData) => {
    const appUserId = userData?.id || params.userId;
    if (!appUserId) {
      return false;
    }
    setUsersLoading(true);
    getSearchChat({ appUserId })
      .then((data) => {
        if (!data?.chat?.id) {
          if (userData) {
            const convertedUserData = convertNewUserDataToChat(userData);
            dispatch(setNewChat(convertedUserData));
            dispatch(setActiveChat(convertedUserData));
            setChatCategoryHandler(CHAT_CATEGORIES.GENERAL);
          } else {
            setUsersLoading(true);
            getPublicUser(params.userId)
              .then((res) => {
                if (res?.data) {
                  const data = convertNewUserDataToChat(res.data);
                  const type = res.data?.isPremium
                    ? CHAT_CATEGORIES.PREMIUM
                    : CHAT_CATEGORIES.GENERAL;
                  dispatch(
                    setNewChat({
                      ...data,
                      type,
                    })
                  );
                  dispatch(setActiveChat(data));
                  setChatCategoryHandler(type);
                }
              })
              .finally(() => setUsersLoading(false));
          }
        } else {
          if (data?.chat) {
            const type = data.chat?.isPremium
              ? CHAT_CATEGORIES.PREMIUM
              : CHAT_CATEGORIES.GENERAL;
            dispatch(
              setNewChat({
                ...data.chat,
                type,
              })
            );
            dispatch(setActiveChat(data.chat));
            setChatCategoryHandler(type);
          }
        }
      })
      .finally(() => setUsersLoading(false));
  };

  const handleActiveChatType = (type) => () => {
    setChatsType(type);
    dispatch(setActiveChat(null));
    navigate({
      pathname: PATHS.CHAT,
      search: createSearchParams({
        type,
      }).toString(),
      replace: true,
      state: window.history.state,
    });
  };

  const chatsData = searchedChats
    ? searchedChats
    : chatsType === CHATS_TYPE.MY && chatCategory === CHAT_CATEGORIES.GENERAL
    ? chats
    : chatsType === CHATS_TYPE.MY && chatCategory === CHAT_CATEGORIES.PREMIUM
    ? chatsPremium
    : chatsRequests;

  useEffect(() => {
    const value = searchParams.get("category");
    if (value) {
      setChatCategory(value);
    }
  }, [searchParams]);

  useEffect(() => {
    function unlockPhoneHandler() {
      if (document.visibilityState === "visible") {
        dispatch(fetchChats({ page: 1, type: CHAT_CATEGORIES.PREMIUM }));
        dispatch(fetchChats({ page: 1, type: CHAT_CATEGORIES.GENERAL }));
      }
    }

    if (isPhone) {
      document.addEventListener("visibilitychange", unlockPhoneHandler);
    }
    return () => {
      if (isPhone) {
        document.removeEventListener("visibilitychange", unlockPhoneHandler);
      }
    };
  }, []);

  useEffect(() => {
    if (
      !totalChatsPremium ||
      (chatPage !== 1 && chatCategory === CHAT_CATEGORIES.PREMIUM)
    ) {
      dispatch(
        fetchChats({ page: chatPage, limit: 35, type: CHAT_CATEGORIES.PREMIUM })
      );
    }
  }, [chatPage, chatCategory, totalChatsPremium]);

  useEffect(() => {
    if (
      !totalChats ||
      (chatPageGeneral !== 1 && chatCategory === CHAT_CATEGORIES.GENERAL)
    ) {
      dispatch(
        fetchChats({
          page: chatPageGeneral,
          limit: 35,
          type: CHAT_CATEGORIES.GENERAL,
        })
      );
    }
  }, [chatPageGeneral, chatCategory, totalChats]);

  useEffect(() => {
    if (!totalRequests || chatRequestsPage !== 1) {
      dispatch(
        fetchChats({
          page: chatRequestsPage,
          limit: 35,
          retrieveRequestsList: true,
        })
      );
    }
    if (!totalRequests && chatsType === CHATS_TYPE.REQUESTS && !isFirstRender) {
      setChatsType(CHATS_TYPE.MY);
      setChatCategory(CHAT_CATEGORIES.GENERAL);
    }
  }, [chatRequestsPage, totalRequests]);

  useEffect(() => {
    if (chatsType === CHATS_TYPE.REQUESTS) {
      dispatch(
        fetchChats({
          page: 1,
          limit: 35,
          retrieveRequestsList: true,
        })
      );
    }
    setSearchedChats(null);
  }, [chatsType]);

  useEffect(() => {
    if (params?.userId && !selectedUser && !activeChat) {
      const chat = chats.find((item) => item.otherUser?.id === params.userId);
      if (chat) {
        dispatch(setActiveChat(chat));
      } else {
        getSearchChatByUserId();
      }
    }
  }, [params?.userId, selectedUser, activeChat]);

  useEffect(() => {
    if (selectedUser) {
      const chat = chats.find((item) => item.otherUser?.id === selectedUser.id);
      if (chat) {
        dispatch(setActiveChat(chat));
      } else {
        getSearchChatByUserId(selectedUser);
      }
    }
  }, [selectedUser]);

  useEffect(() => {
    const mainHeader = document.querySelector(`.${GLOBAL_HEADER_CLASSNAME}`);
    if (isMobile) {
      if (activeChat) {
        // document.body.classList.add("overflow-hidden");
        if (mainHeader) {
          mainHeader.style.cssText =
            "position: fixed; z-index: 3; top: 0; left: 0; width: 100%; box-sizing: border-box";
        }
      } else {
        // document.body.classList.remove("overflow-hidden");
        if (mainHeader) {
          mainHeader.style.cssText = "";
        }
      }
    } else {
      if (mainHeader) {
        mainHeader.style.cssText = "";
      }
      // document.body.classList.remove("overflow-hidden");
    }
    return () => {
      if (mainHeader) {
        mainHeader.style.cssText = "";
      }
    };
  }, [activeChat, isMobile]);

  return (
    <Box
      className={`${styles.sidebar} ${activeChat ? styles.sidebarActive : ""}`}
    >
      <Box className={styles.sidebarInfo}>
        <Button variant="text" onClick={handleActiveChatType(CHATS_TYPE.MY)}>
          <Typography
            variant={
              chatsType === CHATS_TYPE.MY ? "body4_16" : "body4_16_regular"
            }
            className={chatsType === CHATS_TYPE.REQUESTS ? "opacity_07" : ""}
          >
            {t("messages.inbox")}
          </Typography>
        </Button>
        <Button
          variant="text"
          onClick={handleActiveChatType(CHATS_TYPE.REQUESTS)}
        >
          <Typography
            variant={
              chatsType === CHATS_TYPE.REQUESTS
                ? "body4_16"
                : "body4_16_regular"
            }
            className={chatsType === CHATS_TYPE.MY ? "opacity_07" : ""}
          >
            {t("messages.requests")}{" "}
            {totalRequests ? `(${totalRequests})` : null}
          </Typography>
        </Button>
      </Box>
      {chatsType === CHATS_TYPE.MY ? (
        <Box className={styles.sidebarActions}>
          <Button
            variant="outlined"
            onClick={setChatCategoryHandler(CHAT_CATEGORIES.PREMIUM)}
            className={
              chatCategory === CHAT_CATEGORIES.PREMIUM
                ? styles.sidebarActionsButtonActive
                : ""
            }
          >
            {t("default.premium")}
          </Button>
          <Button
            variant="outlined"
            onClick={setChatCategoryHandler(CHAT_CATEGORIES.GENERAL)}
            className={
              chatCategory === CHAT_CATEGORIES.GENERAL
                ? styles.sidebarActionsButtonActive
                : ""
            }
          >
            {t("default.general")}
          </Button>
        </Box>
      ) : null}
      <SearchBar
        onSeacrhChanged={handleSearch(chatCategory)}
        classNameWrapper={styles.search}
        classNameResult={styles.searchResult}
        isClearDataByOutClick={false}
        isShow={
          chatsType === CHATS_TYPE.MY ? chats.length : chatsRequests.length
        }
        key={chatCategory + chatsType}
      />
      <Chats
        chats={chatsData}
        isLoading={
          chatsType === CHATS_TYPE.MY ? chatsLoading : requestsChatsLoading
        }
        loadMore={
          chatsType === CHATS_TYPE.MY ? loadMoreChats : loadMoreRequestsChats
        }
        isSearchLoader={searchLoader}
        hasMore={
          chatsType === CHATS_TYPE.MY &&
          chatCategory === CHAT_CATEGORIES.GENERAL
            ? hasMoreChats
            : chatsType === CHATS_TYPE.MY &&
              chatCategory === CHAT_CATEGORIES.PREMIUM
            ? hasMoreChatsPremium
            : hasMoreRequestsChats
        }
        className={
          chatsType === CHATS_TYPE.REQUESTS ? styles.chatsRequests : ""
        }
        chatCategory={chatCategory}
      />
    </Box>
  );
};
