import { Box, CircularProgress, Skeleton, Typography } from "@mui/material";
import { ReactComponent as ChartPlaceholderIcon } from "assets/svg/chart-placeholder-icon.svg";
import { BackHeader } from "Components/BackHeader/BackHeader";
import Growth from "Components/Growth";
import LineGraphComponent from "Components/LineGraphComponent";
import PieChartComponent from "Components/PieChart";
import { PATHS } from "Constants";
import { format } from "date-fns";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import { setLoader } from "Redux/appSlice";
import { fetchUserStats } from "Redux/usersSlice";
import { LocalizationContext } from "Services/Localization/LocalizationContext";
import { useIsMobile } from "Utils/Hooks";
import { useWindowSize } from "Utils/Hooks/";
import { roundToHundredth } from "Utils/math";

import SidebarTablet from "../../Components/SidebarTablet/SidebarTablet";
import { getChartTitle, prepareData } from "./helpers";
import styles from "./Insights.module.scss";

const PADDING_HORIZONTAL = 50 * 2;
const THOUSAND = 1000;

const Insights = () => {
  const { t } = useContext(LocalizationContext);
  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLoading = useSelector((state) => state.app.loader);
  const isSidebarVisible = useSelector((state) => state.app.isSidebarVisible);
  const userStats = useSelector((state) => state.users.stats.entities);
  const numFollowers = useSelector(
    (state) => state.users.me.entities?.community?.numFollowers
  );

  const [ageChartData, setAgeChartData] = useState([]);
  const [clubMembersGraphData, setClubMembersGraphData] = useState([]);
  const [countryChartData, setCountryChartData] = useState([]);
  const [languageChartData, setLanguageChartData] = useState([]);
  const [engagementData, setEngagementData] = useState(null);
  const ws = useWindowSize();
  const sideBarWidth = isMobile ? 231 : 340;

  const bdrs = isMobile ? "20px 0 0 20px" : "40px 0 0 40px";

  const loader = <CircularProgress className={styles.loader} />;

  const containerWidth = (() => {
    if (isMobile) {
      return isSidebarVisible
        ? ws.width - sideBarWidth - PADDING_HORIZONTAL
        : ws.width - PADDING_HORIZONTAL;
    }
    return ws.width - sideBarWidth - PADDING_HORIZONTAL;
  })();

  const calcEngagementRate = ({ numComments, numReactions, numFollowers }) => {
    let res = null;
    if (numComments && numReactions && numFollowers) {
      const totalEngagement = numComments + numReactions;
      const rate = (totalEngagement / numFollowers) * 100;
      res = `${roundToHundredth(rate)}%`;
    }
    return res;
  };

  useEffect(() => {
    dispatch(setLoader(true));
    dispatch(fetchUserStats())
      .unwrap()
      .then(
        ({
          stats: d = {},
          details = {
            all: [],
          },
        }) => {
          const { all } = details;
          setClubMembersGraphData(
            all?.map((detail) => {
              return { value: detail?.numFollowers };
            })
          );
          setEngagementData(calcEngagementRate(all[all.length - 1] || {}));
          setAgeChartData(
            prepareData({
              data: Object.entries(d.fanInsights).map(([k, v]) => {
                return { name: k, amount: v };
              }),
              catName: t("insights.other"),
              totalAmount: Object.values(d.fanInsights).reduce(
                (a, b) => a + b,
                0
              ),
            })
          );

          setCountryChartData(
            prepareData({
              data: d.countries.map((item) => {
                return {
                  name: item.country || t("insights.other"),
                  amount: item.amount,
                };
              }),
              catName: t("insights.other"),
              totalAmount: d.countries
                .map((item) => item.amount)
                .reduce((a, b) => a + b, 0),
            })
          );

          setLanguageChartData(
            prepareData({
              data: d.languageStats.map((item) => {
                return {
                  name: t(`default.${item.language}`),
                  amount: item.amount,
                };
              }),
              catName: t("insights.other"),
              totalAmount: d.languageStats
                .map((item) => item.amount)
                .reduce((a, b) => a + b, 0),
            })
          );
        }
      )
      .finally(() => dispatch(setLoader(false)));
  }, []);

  const getFormattedDate = (date, str) => format(new Date(date || null), str);
  const formatNumericValue = (value) => {
    if (!value) {
      return t("insights.na");
    }
    if (value > THOUSAND) {
      return `${roundToHundredth(value / THOUSAND)}k`;
    }
    return value;
  };

  const StatsItem = ({ label, value, description }) => {
    const preparedValue = formatNumericValue(value);

    return (
      <Box className={`${styles.flexColumnStart} ${styles.statsItem}`}>
        <Typography
          variant="body3"
          component="span"
          className={styles.statsItemLabel}
        >
          {label}
        </Typography>
        {isLoading ? (
          <Skeleton variant="rectangular" className={styles.skeletonRect} />
        ) : (
          <Box className={styles.statsItemValueWrap}>
            <Typography
              variant="h2"
              component="span"
              className={styles.statsItemValue}
            >
              {preparedValue}
            </Typography>
            {description && !!value && (
              <Typography
                variant="body3"
                component="span"
                className={styles.statsItemDescription}
              >
                {description}
              </Typography>
            )}
          </Box>
        )}
      </Box>
    );
  };

  const ValueIndicator = ({ value }) => {
    if (value > 0) {
      return (
        <Typography
          variant="body3"
          component="span"
          className={`${styles.graphDiff} ${styles.positive}`}
        >
          +{value} <span className={`${styles.arrow} ${styles.up}`}></span>
        </Typography>
      );
    } else if (value < 0) {
      return (
        <Typography
          variant="body3"
          component="span"
          className={`${styles.graphDiff} ${styles.negative}`}
        >
          {value} <span className={`${styles.arrow} ${styles.down}`}></span>
        </Typography>
      );
    } else {
      return (
        <Typography
          variant="body3"
          component="span"
          className={`${styles.graphDiff} ${styles.positive}`}
        >
          {value}
        </Typography>
      );
    }
  };

  const ChartItem = ({ label, chart, isGraph, onClick }) => {
    return (
      <Box
        onClick={onClick}
        className={`${styles.flexColumnStart} ${styles.chartItem} ${
          isGraph && styles.graph
        } ${onClick && styles.clickable}`}
      >
        <Typography
          variant="body3"
          component="span"
          className={styles.statsItemLabel}
        >
          {label}
        </Typography>
        {isLoading ? loader : chart}
      </Box>
    );
  };

  return (
    <Box
      className={`${styles.flexColumnStart} ${styles.container}`}
      sx={{
        width: containerWidth,
        borderRadius: isSidebarVisible || !isMobile ? bdrs : 0,
      }}
    >
      <Box className={styles.titleWrap}>
        <BackHeader title={t("insights.title")} isBackArrow={false} />
      </Box>
      {/*<Box className={`${styles.flexColumnStart} ${styles.statsWrap}`}>*/}
      {/*  <Box className={`${styles.flexColumnStart} ${styles.statsBlock}`}>*/}
      {/*    <StatsItem*/}
      {/*      label={t("insights.droppedEditionsNum")}*/}
      {/*      value={userStats?.summary?.cardsDropped}*/}
      {/*    />*/}
      {/*    <StatsItem*/}
      {/*      label={t("insights.cardsSoldTotal")}*/}
      {/*      value={userStats?.summary?.totalAmount}*/}
      {/*    />*/}
      {/*    <StatsItem*/}
      {/*      label={t("insights.revenue")}*/}
      {/*      value={userStats?.summary?.totalRevenue}*/}
      {/*    />*/}
      {/*    <StatsItem label={t("insights.fansNum")} value={numFollowers} />*/}
      {/*    <StatsItem*/}
      {/*      label={t("insights.highestTradingPrice")}*/}
      {/*      value={userStats?.summary?.maxPrice}*/}
      {/*    />*/}
      {/*    <StatsItem*/}
      {/*      label={t("insights.averageSalePrice")}*/}
      {/*      value={userStats?.summary?.averageSellPrice}*/}
      {/*    />*/}
      {/*  </Box>*/}
      {/*</Box>*/}
      {/* <Box className={`${styles.flexColumnStart} ${styles.statsWrap}`}>
        <Typography variant="h5" component="h3" className={styles.statsTitle}>
          {t("insights.currentDrop")}
        </Typography>
        <Box className={`${styles.flexColumnStart} ${styles.statsBlock}`}>
          <StatsItem
            label={t("insights.dropDate")}
            value={getFormattedDate(
              userStats?.currentDrop?.dropStartDate,
              "MM/dd/yy"
            )}
            description={getFormattedDate(
              userStats?.currentDrop?.dropStartDate,
              "HH:mm"
            )}
          />
          <StatsItem
            label={t("insights.phase1Intrested")}
            value={userStats?.currentDrop?.phase1interest}
            description={t("insights.phase1IntrestedDesc")}
          />
          <StatsItem
            label={t("insights.phase2CardsSold")}
            value={userStats?.currentDrop?.tokensSold}
          />
          <StatsItem
            label={t("insights.currentPrice")}
            value={userStats?.currentDrop?.latestPrice}
          />
        </Box>
      </Box> */}
      <Box className={`${styles.flexColumnStart} ${styles.statsWrap}`}>
        <Typography variant="h5" component="h3" className={styles.statsTitle}>
          {t("insights.fanStatistics")}
        </Typography>
        <Box
          className={`${styles.flexColumnStart} ${
            isMobile ? styles.statsBlock : styles.statsBlock2
          }`}
        >
          <Box className={`${styles.flexColumnDouble}`}>
            <ChartItem
              label={t("insights.clubMembers")}
              chart={
                <LineGraphComponent
                  data={clubMembersGraphData}
                  info={
                    <Box className={styles.graphInfoWrap}>
                      <Typography
                        variant="h2"
                        component="span"
                        className={styles.grahpItemValue}
                      >
                        {formatNumericValue(userStats?.numFollowers)}
                      </Typography>
                      <Growth
                        value={userStats?.numFollowersChange}
                        className={styles.lineGraphCrowth}
                        arrowClassName={styles.lineGraphRowGrowthArrow}
                      />
                      <Typography
                        variant="body3"
                        component="span"
                        className={styles.statsItemDescription}
                      >
                        {t("insights.clubThisWeek")}
                      </Typography>
                    </Box>
                  }
                />
              }
              onClick={() => navigate(PATHS.CLUB_MEMBERS)}
              isGraph={true}
            />
            <StatsItem label={t("insights.engRate")} value={engagementData} />
          </Box>
          <ChartItem
            label={t("insights.age")}
            chart={
              <PieChartComponent
                id="ageChart"
                data={ageChartData}
                label={t("insights.ageChartLabel")}
                title={getChartTitle(ageChartData) || ""}
              />
            }
          />
          <ChartItem
            label={t("insights.country")}
            chart={
              <PieChartComponent
                id="countriesChart"
                data={countryChartData}
                label={t("insights.countryChartLabel")}
                title={getChartTitle(countryChartData) || ""}
              />
            }
          />
          <ChartItem
            label={t("insights.language")}
            chart={
              <PieChartComponent
                id="languageChart"
                data={languageChartData}
                label={t("insights.languageChartLabel")}
                title={getChartTitle(languageChartData) || ""}
              />
            }
          />
        </Box>
      </Box>
      <Outlet />
    </Box>
  );
};
export default Insights;
