import { LoadingButton } from "@mui/lab";
import { Box, Button, Skeleton, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { HeaderSection } from "Components/HeaderSection/HeaderSection";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setError } from "Redux/appSlice";
import { fetchPosts } from "Redux/communitySlice";
import { errorResponseMessages } from "Services/Api/apiErrorHelper.tsx";
import { LocalizationContext } from "Services/Localization/LocalizationContext";

import stylesCommunity from "../../Community.module.scss";
import CommunityPost from "../CommunityPost/CommunityPost";
import styles from "./ReviewPosts.module.scss";

export const ReviewPosts = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { t } = useContext(LocalizationContext);
  const isXS = useMediaQuery(theme.breakpoints.down(441));
  const { posts, hasMore, total } = useSelector(
    (state) => state.community.postsInReview
  );

  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [isHide, setHide] = useState(true);

  const fakePosts = total >= 4 ? 3 : total - 1;

  const postsArr = Object.values(posts);

  const toggleShow = () => setHide((prevState) => !prevState);

  const fetchPostsHandler = (page) => {
    setLoading(true);
    dispatch(fetchPosts({ limit: 20, page, type: "pending_review" }))
      .unwrap()
      .catch((err) => {
        if (err?.status !== 401) {
          dispatch(
            setError({
              open: true,
              subtitle: errorResponseMessages(err.data || err, t),
            })
          );
        }
        console.log(err?.data || err);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchPostsHandler(1);
  }, []);

  useEffect(() => {
    if (page > 1) {
      fetchPostsHandler(page);
    }
  }, [page]);

  if (!postsArr?.length && !loading) {
    return null;
  }

  if (loading && page === 1) {
    return (
      <Skeleton
        variant="rectangular"
        style={{
          marginBottom: "16px",
          height: "200px",
        }}
      />
    );
  }

  return (
    <Box className={styles.wrapper}>
      <HeaderSection
        wrapperClass={styles.headerWrapper}
        customTitle={
          <Box className={stylesCommunity.currentTierHeader}>
            <Typography
              variant="subtitle"
              className={stylesCommunity.sectionTitle}
              component="span"
            >
              {t("default.headerPendingPostsTitle")}
              <Typography
                variant="body18"
                component="span"
                className={stylesCommunity.sectionTitleValue}
              >
                ({total})
              </Typography>
            </Typography>
          </Box>
        }
        customButton={
          total > 1 ? (
            <Button className={styles.headerButton} onClick={toggleShow}>
              <Typography
                variant="textMediumBold"
                className={styles.headerButtonTitle}
              >
                {!isHide ? t("default.viewLess") : t("default.viewAll")}
              </Typography>
            </Button>
          ) : null
        }
      />
      <Box className={styles.postsListWrapper}>
        <Box
          className={styles.postsList}
          style={{
            marginBottom: isHide
              ? `${8 * fakePosts + 16}px`
              : isXS
              ? `8px`
              : `16px`,
          }}
        >
          {postsArr.map((item, index) =>
            isHide ? (
              <CommunityPost
                {...item}
                key={item?.id}
                inReview={true}
                className={styles.postCollapsed}
                stylesWrapper={{
                  zIndex: postsArr.length - index,
                  background: theme.palette.dark.surface,
                }}
                userName={item?.influencer?.name}
                postIndex={index}
                originalMessage={item.message}
              />
            ) : (
              <CommunityPost
                {...item}
                key={item?.id}
                inReview={true}
                postIndex={index}
              />
            )
          )}
        </Box>
      </Box>
      {hasMore && !isHide ? (
        <LoadingButton
          loading={loading}
          variant="outlined"
          size="small"
          onClick={() => setPage((prevState) => prevState + 1)}
          className={styles.loadMoreBtn}
        >
          {t("community.loadMore")}
        </LoadingButton>
      ) : null}
    </Box>
  );
};
