import React, { useState } from "react";

import { CommentEditBlock } from "../CommentEditBlock/CommentEditBlock";
import { CommentTextBlock } from "../CommentTextBlock/CommentTextBlock";

export const CommentMainSection = ({
  isEditMode,
  message,
  setEditMode,
  postId,
  commentId,
  setActionLoading,
  isCommentEdited,
  setCommentEdited,
  replyToUser,
  replyToId,
}) => {
  const [messageValue, setMessageValue] = useState(message);

  return (
    <>
      <CommentTextBlock
        message={messageValue}
        isEditMode={isEditMode}
        isCommentEdited={isCommentEdited}
        replyToUser={replyToUser}
        replyToId={replyToId}
      />
      <CommentEditBlock
        setEditMode={setEditMode}
        message={messageValue}
        setMessageValue={setMessageValue}
        postId={postId}
        setActionLoading={setActionLoading}
        commentId={commentId}
        isEditMode={isEditMode}
        oldMessageVersion={message}
        setCommentEdited={setCommentEdited}
      />
    </>
  );
};
