export const ClockIcon = () => (
  <svg
    width="23"
    height="23"
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.5 1C9.4233 1 7.39323 1.61581 5.66652 2.76957C3.9398 3.92332 2.59399 5.5632 1.79927 7.48182C1.00455 9.40045 0.796615 11.5116 1.20176 13.5484C1.6069 15.5852 2.60693 17.4562 4.07538 18.9246C5.54383 20.3931 7.41475 21.3931 9.45155 21.7982C11.4884 22.2034 13.5996 21.9954 15.5182 21.2007C17.4368 20.406 19.0767 19.0602 20.2304 17.3335C21.3842 15.6068 22 13.5767 22 11.5C21.9971 8.71613 20.8899 6.04712 18.9214 4.07862C16.9529 2.11013 14.2839 1.00294 11.5 1ZM11.5 20.3846C9.74279 20.3846 8.02504 19.8635 6.56398 18.8873C5.10291 17.911 3.96414 16.5234 3.29169 14.9C2.61923 13.2765 2.44329 11.4901 2.7861 9.7667C3.12892 8.04325 3.9751 6.46016 5.21763 5.21763C6.46017 3.97509 8.04325 3.12891 9.7667 2.7861C11.4901 2.44328 13.2765 2.61923 14.9 3.29169C16.5234 3.96414 17.911 5.1029 18.8873 6.56397C19.8635 8.02504 20.3846 9.74279 20.3846 11.5C20.3819 13.8555 19.445 16.1138 17.7794 17.7794C16.1138 19.445 13.8555 20.3819 11.5 20.3846ZM17.9615 11.5C17.9615 11.7142 17.8764 11.9197 17.725 12.0711C17.5735 12.2226 17.3681 12.3077 17.1538 12.3077H11.5C11.2858 12.3077 11.0803 12.2226 10.9289 12.0711C10.7774 11.9197 10.6923 11.7142 10.6923 11.5V5.84615C10.6923 5.63194 10.7774 5.4265 10.9289 5.27503C11.0803 5.12356 11.2858 5.03846 11.5 5.03846C11.7142 5.03846 11.9197 5.12356 12.0711 5.27503C12.2226 5.4265 12.3077 5.63194 12.3077 5.84615V10.6923H17.1538C17.3681 10.6923 17.5735 10.7774 17.725 10.9289C17.8764 11.0803 17.9615 11.2858 17.9615 11.5Z"
      fill="#F0F1F5"
    />
  </svg>
);
