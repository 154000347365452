import { useEffect, useContext } from "react";
import { LocalizationContext } from "Services/Localization/LocalizationContext";
import { LANGUAGES_LIST } from "Constants";
import { getMatchString } from "Utils/StringHelper";

const LangService = () => {
  const { setLocalization } = useContext(LocalizationContext);

  useEffect(() => {
    const selectedLang = localStorage.getItem("language");
    if (selectedLang) return setLocalization(selectedLang);

    const userLocale =
      navigator.languages && navigator.languages.length
        ? navigator.languages[0]
        : navigator.language;

    const localeStr = getMatchString(Object.values(LANGUAGES_LIST), userLocale);
    setLocalization(localeStr);
  }, []);

  return null;
};

export default LangService;
