export const getPostById = (state) => state.community.posts.postById;
export const getPostsLoader = (state) => state.community.posts.loading;
export const getPostsCommunity = (state) =>
  state.community.posts.entities?.posts;
export const getPinnedPostCommunity = (state) =>
  state.community.posts.entities?.pinnedPost;
export const getPostsCommunityTotal = (state) =>
  state.community.posts.entities?.total;
export const getPostCommentsData = (state) => state.community.posts.comments;
export const getFansTotalSelector = (state) =>
  state.community.fans.entities.total;
export const getFansSelector = (state) => state.community.fans.entities.fans;
export const getFansPageSelector = (state) =>
  state.community.fans.entities.page;
export const getFansLoadingSelector = (state) => state.community.fans.loading;
