import { Box, Skeleton, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { SCROLL_THRESHOLD } from "Constants/index";
import { useContext, useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchRewardOrders,
  setRewardOrdersPage,
} from "Redux/limitedEditionSlice";
import {
  getRewardsOrders,
  getRewardsOrdersLoading,
  getRewardsOrdersPage,
  getRewardsOrdersTotal,
} from "Redux/selectors/limitedEditionSelectors";
import { LocalizationContext } from "Services/Localization/LocalizationContext";

import { Filter } from "./components/Filter/Filter";
import styles from "./RewardsTable.module.scss";
import { Row } from "./Row";

export const RewardsTable = () => {
  const { t } = useContext(LocalizationContext);
  const dispatch = useDispatch();
  const isMobile = useMediaQuery("(max-width:1100px)");

  const orders = useSelector(getRewardsOrders);
  const total = useSelector(getRewardsOrdersTotal);
  const loading = useSelector(getRewardsOrdersLoading);
  const page = useSelector(getRewardsOrdersPage);

  useEffect(() => {
    if (!total) {
      dispatch(fetchRewardOrders({ page: 1, limit: 25 }));
    }
  }, []);

  useEffect(() => {
    if (page > 1) {
      dispatch(fetchRewardOrders({ page, limit: 25 }));
    }
  }, [page]);

  return (
    <Box>
      <Filter />
      {!isMobile ? (
        <Box className={`${styles.row} ${styles.rowHead}`}>
          <Box className={styles.col}>
            <Typography variant="body4">{t("default.member")}</Typography>
          </Box>
          <Box className={styles.col}>
            <Typography variant="body4">{t("default.reward")}</Typography>
          </Box>
          <Box className={styles.col}>
            <Typography variant="body4">{t("default.message")}</Typography>
          </Box>
          <Box className={styles.col}>
            <Typography variant="body4">{t("default.status")}</Typography>
          </Box>
        </Box>
      ) : null}
      {loading && !total ? (
        <>
          <Skeleton
            className={`${styles.rowSkeleton} ${styles.row}`}
            variant="rectangular"
          />
          <Skeleton
            className={`${styles.rowSkeleton} ${styles.row}`}
            variant="rectangular"
          />
          <Skeleton
            className={`${styles.rowSkeleton} ${styles.row}`}
            variant="rectangular"
          />
          <Skeleton
            className={`${styles.rowSkeleton} ${styles.row}`}
            variant="rectangular"
          />
          <Skeleton
            className={`${styles.rowSkeleton} ${styles.row}`}
            variant="rectangular"
          />
          <Skeleton
            className={`${styles.rowSkeleton} ${styles.row}`}
            variant="rectangular"
          />
        </>
      ) : null}
      <InfiniteScroll
        next={() => dispatch(setRewardOrdersPage(page + 1))}
        hasMore={total > orders.length}
        loader={loading}
        dataLength={orders.length}
        scrollableTarget="scrollableDiv"
        scrollThreshold={SCROLL_THRESHOLD}
      >
        <Box className={styles.list}>
          {orders.map((item, index) => (
            <Row key={item.id} {...item} index={index} />
          ))}
        </Box>
      </InfiniteScroll>
    </Box>
  );
};
