import "./index.css";

import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { LocalizationProvider } from "Services/Localization/LocalizationContext.js";
import { ViewportProvider } from "Utils/ViewportContext";

import App from "./App";
const root = ReactDOM.createRoot(document.getElementById("root"));

Bugsnag.start({
  apiKey: process.env.REACT_APP_BUGSNAG_API_KEY,
  plugins: [new BugsnagPluginReact()],
  releaseStage: process.env.REACT_APP_ENV,
});

const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);

root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <LocalizationProvider>
        <BrowserRouter>
          <ViewportProvider>
            <App />
          </ViewportProvider>
        </BrowserRouter>
      </LocalizationProvider>
    </ErrorBoundary>
  </React.StrictMode>
);
