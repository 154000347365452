import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import appSlice from "Redux/appSlice";
import communitySlice from "Redux/communitySlice";
import limitedEditionSlice from "Redux/limitedEditionSlice";
import mainAppUserSlice from "Redux/mainAppUserSlice";
import mediaSlice from "Redux/mediaSlice";
import meetingsSlice from "Redux/meetingsSlice";
import messengerSlice from "Redux/messengerSlice";
import scheduledPostsSlice from "Redux/scheduledPostsSlice";
import usersSlice from "Redux/usersSlice";

const reducers = combineReducers({
  users: usersSlice,
  app: appSlice,
  community: communitySlice,
  mainAppUser: mainAppUserSlice,
  media: mediaSlice,
  scheduledPosts: scheduledPostsSlice,
  limitedEdition: limitedEditionSlice,
  messenger: messengerSlice,
  meetings: meetingsSlice,
});

const rootReducer = (state, action) => {
  if (action.type === "users/logout") {
    state = undefined;
  }
  return reducers(state, action);
};

const store = configureStore({
  reducer: rootReducer,
  devTools: true,
});

export default store;
