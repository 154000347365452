import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Skeleton,
  Typography,
} from "@mui/material";
import { ReactComponent as IconChecked } from "assets/svg/icon-checked.svg";
import { ReactComponent as Send } from "assets/svg/icon-send.svg";
import { ReactComponent as IconUnchecked } from "assets/svg/icon-unchecked.svg";
import Editor from "Components/QuillEditor/Editor";
import {
  FULL_COMMENT_TEXT_LIMIT,
  REWARD_ORDER_STATUS,
  REWARDS_ACTION_TYPE,
} from "Constants/index";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSnackbar } from "Redux/appSlice";
import {
  addRewardMessage,
  fetchLimitedEditionRewardMessages,
  patchLimitedEditionStatus,
  postLimitedEditionRewardMessage,
} from "Redux/limitedEditionSlice";
import {
  getRewardMessages,
  getRewardMessagesLoader,
} from "Redux/selectors/limitedEditionSelectors";
import { LocalizationContext } from "Services/Localization/LocalizationContext";
import { isMobileDevice } from "Utils/index";

import styles from "./ChatWidget.module.scss";
import { MessageItem } from "./components/MessageItem/MessageItem";
import { ScheduledButton } from "./components/ScheduledButton/ScheduledButton";

export const ChatWidget = ({
  cardId,
  orderId,
  title,
  index,
  status,
  rewardType,
}) => {
  const { t } = useContext(LocalizationContext);
  const dispatch = useDispatch();
  const isPhone = isMobileDevice();
  const reactQuillRef = useRef(null);
  const scrollRefWrapper = useRef(null);
  const deliveredRef = useRef(false);
  const isChecked = useRef(null);
  const titleRef = useRef(null);

  const loader = useSelector(getRewardMessagesLoader);
  const messages = useSelector(getRewardMessages);

  const [isCompleted, setCompleted] = useState({
    value: false,
    loading: false,
  });

  const undeliveredHandler = () => {
    const lastMessage = messages.at("-1");
    if (!lastMessage) {
      return;
    }
    setCompleted((prevState) => ({ ...prevState, loading: true }));
    isChecked.current = false;
    dispatch(
      patchLimitedEditionStatus({
        cardId,
        orderId,
        status: lastMessage.isIncoming
          ? REWARD_ORDER_STATUS.AWAITING
          : REWARD_ORDER_STATUS.PENDING_REPLY,
        index,
      })
    )
      .then(() => setCompleted({ value: false, loading: false }))
      .finally(() => (prevState) => ({ ...prevState, loading: false }));
  };

  const submit = () => {
    const txtLength = reactQuillRef.current?.value?.length;
    if (txtLength > FULL_COMMENT_TEXT_LIMIT) {
      dispatch(
        setSnackbar({
          open: true,
          message: t("error.maxCharacters"),
          severity: "error",
        })
      );
      return;
    }

    if (reactQuillRef.current.editor?.getText().trim()?.length) {
      if (isChecked.current) {
        setCompleted({ value: true, loading: true });
      }
      dispatch(
        postLimitedEditionRewardMessage({
          cardId,
          orderId,
          messageContent: reactQuillRef.current.value,
          index,
        })
      ).then(() => {
        if (isChecked.current) {
          dispatch(
            patchLimitedEditionStatus({
              cardId,
              orderId,
              status: REWARD_ORDER_STATUS.DELIVERED,
              index,
            })
          ).then(() => setCompleted({ value: true, loading: false }));
        }
      });
      const value = reactQuillRef.current.value;
      dispatch(
        addRewardMessage({
          id: Date.now(),
          messageContent: value,
          date: Date.now(),
          isIncoming: false,
          rewardOrderId: orderId,
        })
      );
      reactQuillRef.current.editor.setText("");
    }
  };

  const keyDownHandler = (e) => {
    if (e.key === "Enter" && !e.shiftKey && !isPhone) {
      e.preventDefault();
      submit();
    }
  };

  const getHeightRef = (ref, space = 0) => {
    if (ref.current?.clientHeight) {
      return ref.current?.clientHeight + space;
    }
    return 24 + space;
  };

  useEffect(() => {
    if (
      (status && isChecked.current) ||
      status === REWARD_ORDER_STATUS.DELIVERED
    ) {
      setCompleted((prevState) => ({
        ...prevState,
        value: REWARD_ORDER_STATUS.DELIVERED,
      }));
      isChecked.current = true;
    } else if (status) {
      setCompleted((prevState) => ({
        ...prevState,
        value: false,
      }));
      isChecked.current = false;
    }
  }, [status]);

  useEffect(() => {
    if (cardId && orderId) {
      dispatch(
        fetchLimitedEditionRewardMessages({ cardId, orderId, limit: 100 })
      );
    }
  }, []);

  useEffect(() => {
    if (scrollRefWrapper.current && messages.length) {
      const scrollElement = scrollRefWrapper.current;
      const isAtBottom =
        scrollElement.scrollTop + scrollElement.scrollHeight >=
        scrollElement.scrollHeight - 80;

      if (isAtBottom) {
        scrollElement.scrollTop = scrollElement.scrollHeight;
      }
    }
  }, [messages.length]);

  return (
    <Box className={styles.wrapper}>
      {title ? (
        <Typography
          variant="h4_24"
          className={styles.title}
          component="p"
          ref={titleRef}
        >
          {title}
        </Typography>
      ) : null}
      <Box
        className={styles.content}
        style={{ height: `calc(100% - ${getHeightRef(titleRef, 24)}px` }}
      >
        <Box className={styles.list} ref={scrollRefWrapper}>
          {!loader && messages.length ? (
            <>
              {messages.map((item) => (
                <MessageItem
                  text={item.messageContent}
                  incoming={item.isIncoming}
                  className={styles.message}
                  key={item.id}
                />
              ))}
            </>
          ) : null}
        </Box>
        {loader ? (
          <Skeleton variant="rectangular" className={styles.messageSkeleton} />
        ) : null}
        <ScheduledButton
          rewardOrderId={orderId}
          isShow={
            !loader &&
            messages.length &&
            rewardType === REWARDS_ACTION_TYPE.VIDEO_CALL
          }
        />
        <Box className={styles.footer}>
          {isCompleted.value ? (
            <Box className={styles.completed}>
              <Typography variant="body1">
                {t("limitedEdition.markedAsDelivered")}{" "}
                <Box
                  component="span"
                  className={styles.completedButton}
                  onClick={undeliveredHandler}
                >
                  {isCompleted.loading ? (
                    <Box className={styles.completedLoader}>
                      <CircularProgress size={24} />
                    </Box>
                  ) : null}
                  {t("default.openAgain")}
                </Box>
              </Typography>
            </Box>
          ) : (
            <>
              <Box className={`${styles.footerMain}`}>
                <Editor
                  reactQuillRef={reactQuillRef}
                  customFormats={[]}
                  editorToolbarId={"toolbar-chat"}
                  className={`${styles.editor}`}
                  placeholder={t("messages.writeMessage")}
                  onKeyDown={keyDownHandler}
                />
              </Box>
              <Button
                variant="contained"
                className={styles.sendBtn}
                onClick={submit}
              >
                <Send />
              </Button>
            </>
          )}
        </Box>
        {!isCompleted.value ? (
          <FormControlLabel
            className={styles.labelWrapper}
            control={
              <Checkbox
                icon={<IconUnchecked />}
                checkedIcon={<IconChecked />}
                ref={deliveredRef}
                onChange={() => (isChecked.current = !isChecked.current)}
                defaultValue={isChecked.current}
                sx={{
                  ".MuiSvgIcon-root": {
                    path: {
                      fill: "#ABB3F3",
                    },
                  },
                }}
              />
            }
            label={
              <Typography variant="body2" className={styles.label}>
                {t("limitedEdition.chatWidgetCheckboxTitle")}
              </Typography>
            }
          />
        ) : null}
      </Box>
    </Box>
  );
};
