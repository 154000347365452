import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import React, { Suspense, useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setError } from "Redux/appSlice";
import { patchPost } from "Redux/communitySlice";
import { errorResponseMessages } from "Services/Api/apiErrorHelper.tsx";
import { LocalizationContext } from "Services/Localization/LocalizationContext";

import { PATHS } from "../../../../Constants";
import { IconClose } from "./icons";
import styles from "./PostRejectModal.module.scss";

export const PostRejectModal = ({ isOpen, onClose, postId }) => {
  const { t } = useContext(LocalizationContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [value, setValue] = useState({ type: null, reason: null });
  const [loading, setLoading] = useState(false);

  const handleType = (v) => () => {
    if (v === value.type) {
      setValue((prevState) => ({ ...prevState, type: null }));
    } else {
      setValue((prevState) => ({ ...prevState, type: v, reason: null }));
    }
  };

  const handleSubmit = () => {
    setLoading(true);
    dispatch(
      patchPost({
        postId,
        status: "rejected",
        rejectReason: value.type || value.reason,
      })
    )
      .then(() => {
        navigate(PATHS.COMMUNITY);
        onClose();
      })
      .catch((err) => {
        if (err?.status !== 401) {
          dispatch(
            setError({
              open: true,
              title: t("errors.error"),
              subtitle: errorResponseMessages(err, t),
            })
          );
        }
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    return () => {
      setValue({ type: null, reason: null });
    };
  }, []);
  return (
    <Modal open={isOpen} onClose={onClose} classes={{ root: styles.root }}>
      <Suspense
        fallback={
          <Box>
            <CircularProgress />
          </Box>
        }
      >
        <Box className={styles.wrapper}>
          <IconButton className={styles.close} onClick={onClose}>
            <IconClose />
          </IconButton>
          <Typography className={styles.title} variant="h4_24" component="p">
            {t("default.postRejectModalTitle")}
          </Typography>
          <Typography className={styles.subTitle} variant="body2" component="p">
            {t("default.postRejectModalSubTitle")}
          </Typography>
          <Box className={styles.rowBtn}>
            <Button
              variant="outlined"
              onClick={handleType("spam")}
              className={value.type === "spam" ? styles.btnActive : ""}
            >
              <Typography variant="body18">{t("default.spam")}</Typography>
            </Button>
            <Button
              variant="outlined"
              onClick={handleType("bullying_or_harassment")}
              className={
                value.type === "bullying_or_harassment" ? styles.btnActive : ""
              }
            >
              <Typography variant="body18">
                {t("default.bullyingOrHarassment")}
              </Typography>
            </Button>
          </Box>
          <Box className={styles.rowBtn}>
            <Button
              variant="outlined"
              onClick={handleType("scams_and_fraud")}
              className={
                value.type === "scams_and_fraud" ? styles.btnActive : ""
              }
            >
              <Typography variant="body18">
                {t("default.scamsAndFraud")}
              </Typography>
            </Button>
            <Button
              variant="outlined"
              onClick={handleType("hate_speech")}
              className={value.type === "hate_speech" ? styles.btnActive : ""}
            >
              <Typography variant="body18">
                {t("default.hateSpeech")}
              </Typography>
            </Button>
          </Box>
          <Typography className={styles.formTitle}>
            {t("default.otherReason")}
          </Typography>
          <TextField
            placeholder={t("default.writeHere")}
            variant="outlined"
            className={styles.input}
            value={value.reason}
            onChange={(e) =>
              setValue((prevState) => ({
                ...prevState,
                reason: e.target.value,
              }))
            }
            multiline={true}
            disabled={value.type}
            maxRows={3}
          />
          <LoadingButton
            variant="contained"
            className={styles.submitBtn}
            onClick={handleSubmit}
            loading={loading}
          >
            {t("default.continueBtn")}
          </LoadingButton>
        </Box>
      </Suspense>
    </Modal>
  );
};
