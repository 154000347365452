import { Box, IconButton } from "@mui/material";
import { ReactComponent as Logo } from "assets/svg/logo.svg";
import { GLOBAL_HEADER_CLASSNAME } from "Constants/index";
import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { toggleMobileMenu } from "Redux/appSlice";

import styles from "./Header.module.scss";

const Header = () => {
  const dispatch = useDispatch();

  const handleOpenMenu = () => {
    dispatch(toggleMobileMenu());
  };

  return (
    <Box className={`${styles.header} ${GLOBAL_HEADER_CLASSNAME}`}>
      <Link to="/" className={styles.logo}>
        <Logo />
      </Link>
      <IconButton onClick={handleOpenMenu} className={styles.hamburgerMenu}>
        <Box className={styles.line} />
        <Box className={styles.line} />
        <Box className={styles.line} />
      </IconButton>
    </Box>
  );
};

export default Header;
