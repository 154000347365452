import { LoadingButton } from "@mui/lab";
import { Box } from "@mui/material";
import { REWARDS_ACTION_TYPE } from "Constants/index";
import { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchRewardOrders } from "Redux/limitedEditionSlice";
import { getRewardsOrdersLoading } from "Redux/selectors/limitedEditionSelectors";
import { LocalizationContext } from "Services/Localization/LocalizationContext";

import styles from "./Filter.module.scss";

export const Filter = () => {
  const dispatch = useDispatch();
  const { t } = useContext(LocalizationContext);

  const loading = useSelector(getRewardsOrdersLoading);

  const [activeItem, setActiveItem] = useState(null);

  const setActiveItemHandler = (value) => () => {
    setActiveItem(value);
    dispatch(fetchRewardOrders({ page: 1, limit: 25, type: value }));
  };

  return (
    <Box className={styles.row}>
      <LoadingButton
        loading={loading && !activeItem}
        variant="outlined"
        onClick={setActiveItemHandler(null)}
        className={`${!activeItem ? styles.actionActive : ""}`}
      >
        {t("default.filterAll")}
      </LoadingButton>
      <LoadingButton
        loading={loading && activeItem === REWARDS_ACTION_TYPE.PREMIUM_CHAT}
        variant="outlined"
        onClick={setActiveItemHandler(REWARDS_ACTION_TYPE.PREMIUM_CHAT)}
        className={`${
          activeItem === REWARDS_ACTION_TYPE.PREMIUM_CHAT
            ? styles.actionActive
            : ""
        }`}
      >
        {t("limitedEdition.premiumChat")}
      </LoadingButton>
      <LoadingButton
        loading={loading && activeItem === REWARDS_ACTION_TYPE.FOLLOW_BACK}
        variant="outlined"
        onClick={setActiveItemHandler(REWARDS_ACTION_TYPE.FOLLOW_BACK)}
        className={`${
          activeItem === REWARDS_ACTION_TYPE.FOLLOW_BACK
            ? styles.actionActive
            : ""
        }`}
      >
        {t("limitedEdition.followBack")}
      </LoadingButton>
      <LoadingButton
        loading={loading && activeItem === REWARDS_ACTION_TYPE.VIDEO_CALL}
        variant="outlined"
        onClick={setActiveItemHandler(REWARDS_ACTION_TYPE.VIDEO_CALL)}
        className={`${
          activeItem === REWARDS_ACTION_TYPE.VIDEO_CALL
            ? styles.actionActive
            : ""
        }`}
      >
        {t("limitedEdition.videoCall")}
      </LoadingButton>
    </Box>
  );
};
