import { Typography } from "@mui/material";
import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { getSingleRequestsChat } from "Redux/selectors/messengerSelectors";
import { LocalizationContext } from "Services/Localization/LocalizationContext";

import { InfoIcon } from "../../../../icons";
import styles from "./Messages.module.scss";

export const RequestInfoBlock = ({ activeChat }) => {
  const { t } = useContext(LocalizationContext);
  const requestChat = useSelector(getSingleRequestsChat(activeChat.id));

  if (activeChat.id !== requestChat?.id) {
    return null;
  }

  return (
    <Typography className={styles.info} variant="body1">
      <InfoIcon />
      {t("messages.requestMessageAlert")}
    </Typography>
  );
};
