import { Typography } from "@mui/material";
import React from "react";

import styles from "./Profile.module.scss";

export const FormErrorMessage = ({ isError, message }) => {
  if (!isError) {
    return null;
  }

  return (
    <Typography variant="error" className={styles.error}>
      {message}
    </Typography>
  );
};
