import { Box, Skeleton } from "@mui/material";
import { SCROLL_THRESHOLD } from "Constants/index";
import { useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import { fetchFans, setFansPage } from "Redux/communitySlice";
import {
  getFansLoadingSelector,
  getFansPageSelector,
  getFansSelector,
  getFansTotalSelector,
} from "Redux/selectors/communitySelectors";
import { useIsFirstRender } from "Utils/Hooks/useIsFirstRender";

import FollowerItem from "../FollowerItem/FollowerItem";
import styles from "./FollowersList.module.scss";

export const FollowersList = () => {
  const dispatch = useDispatch();
  const isFirstRender = useIsFirstRender();

  const fans = useSelector(getFansSelector);
  const fansPage = useSelector(getFansPageSelector);
  const fansLoader = useSelector(getFansLoadingSelector);
  const totalFans = useSelector(getFansTotalSelector);

  const loadMore = () => dispatch(setFansPage(fansPage + 1));

  const hasMore = fans.length && fans.length < totalFans;

  useEffect(() => {
    if (!totalFans && isFirstRender) {
      dispatch(
        fetchFans({
          page: 1,
          limit: 50,
        })
      );
    }
  }, [totalFans, isFirstRender]);

  useEffect(() => {
    if (fansPage > 1) {
      dispatch(
        fetchFans({
          page: fansPage,
          limit: 50,
        })
      );
    }
  }, [fansPage]);

  return (
    <InfiniteScroll
      next={loadMore}
      dataLength={fans?.length}
      hasMore={hasMore}
      scrollableTarget="scrollableDiv"
      scrollThreshold={SCROLL_THRESHOLD}
      className={styles.list}
    >
      {!fans.length && fansLoader ? (
        <>
          <Skeleton variant="rectangular" className={styles.itemSkeleton} />
          <Skeleton variant="rectangular" className={styles.itemSkeleton} />
          <Skeleton variant="rectangular" className={styles.itemSkeleton} />
          <Skeleton variant="rectangular" className={styles.itemSkeleton} />
        </>
      ) : null}
      {fans.map((item, index) => (
        <FollowerItem {...item} key={item.id} index={index} />
      ))}
    </InfiniteScroll>
  );
};
