import React, { useContext } from "react";
import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";

import { useNavigate } from "react-router-dom";
import { LocalizationContext } from "Services/Localization/LocalizationContext";
import styles from "./LeftSide.module.scss";
import { ReactComponent as LogoIcon } from "assets/svg/logo.svg";
import { ReactComponent as CheckedIcon } from "assets/svg/checked-icon.svg";
import { ReactComponent as BulletIcon } from "assets/svg/bullet-icon.svg";
import { ReactComponent as HederaIcon } from "assets/svg/hedera.svg";
//TODO::remove step functionality if it is not being used
const AuthLeftSide = ({ step, steps = [] }) => {
  const { t } = useContext(LocalizationContext);
  const navigate = useNavigate();
  const isHide = (steps || []).find((item) => item.step === step)?.hidden;

  return (
    <Box className={styles.authLeftSide}>
      <Box className={styles.authLeftSideContent}>
        <IconButton onClick={() => navigate("/")} sx={{ p: 0 }}>
          <LogoIcon className={styles.authLogo} />
        </IconButton>
        {steps.length > 0 ? (
          <>
            {!isHide && (
              <List className={styles.authProgressList}>
                {steps.map((s) => {
                  if (s.hidden) return null;

                  return (
                    <ListItem key={`step-${s.step}`}>
                      <ListItemIcon>
                        {s.step < step ? (
                          <CheckedIcon />
                        ) : (
                          <BulletIcon fill="#111322" />
                        )}
                      </ListItemIcon>
                      <ListItemText primary={s.title} />
                    </ListItem>
                  );
                })}
              </List>
            )}
          </>
        ) : (
          step > 1 && (
            <List className={styles.authProgressList}>
              <ListItem>
                <ListItemIcon>
                  <CheckedIcon />
                </ListItemIcon>
                <ListItemText primary={t("auth.signUpWithPhone")} />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  {step > 2 ? <CheckedIcon /> : <BulletIcon fill="#111322" />}
                </ListItemIcon>
                <ListItemText primary={t("auth.enterConfirmPassword")} />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  {step > 3 ? <CheckedIcon /> : <BulletIcon fill="#111322" />}
                </ListItemIcon>
                <ListItemText primary={t("auth.verifyPhone")} />
              </ListItem>
            </List>
          )
        )}
        <Box>
          <Typography variant="h1" component="p" className={styles.authSlogan}>
            {t("auth.slogan")}
          </Typography>
          <HederaIcon className={styles.hederaIcon} />
        </Box>
      </Box>
    </Box>
  );
};

export default AuthLeftSide;
