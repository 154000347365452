import { Box, CircularProgress, Skeleton, Typography } from "@mui/material";
import { ReactComponent as CommentsOff } from "assets/svg/feed/no-comments.svg";
import { ReactComponent as CommentIco } from "assets/svg/icon-comment.svg";
import React, { useContext } from "react";
import { LocalizationContext } from "Services/Localization/LocalizationContext";
import { useIsLessThan1536, useIsMobile } from "Utils/Hooks";

import styles from "./CommentsCount.module.scss";

const CommentsCount = ({
  count,
  allowComments,
  postSummary,
  loading,
  wrapperClass,
}) => {
  const { t } = useContext(LocalizationContext);
  const isMobile = useIsMobile();
  const isSmallPc = useIsLessThan1536();
  const isShortened = postSummary.length > 1 && (isMobile || isSmallPc);

  return (
    <Box
      className={`${styles.postsBottomTitle} ${styles.postsBottomTitleCount} ${
        wrapperClass || ""
      }`}
    >
      {loading ? (
        <Skeleton
          variant="rectangular"
          style={{ height: "24px", width: "100px" }}
        />
      ) : (
        <>
          {allowComments ? (
            <>
              <CommentIco />
              <Typography
                variant="body1"
                className={styles.postsBottomTitleText}
              >
                {isShortened
                  ? count
                  : t("community.comments", { count, useSingular: true })}
              </Typography>
            </>
          ) : (
            <>
              <CommentsOff />
              <Typography
                variant="body1"
                className={styles.postsBottomTitleText}
              >
                {t("community.commentsOff")}
              </Typography>
            </>
          )}
        </>
      )}
    </Box>
  );
};
export default CommentsCount;
