import { MenuItem, Select, Typography } from "@mui/material";
import { ReactComponent as ArrowDown } from "assets/svg/arrow-down.svg";
import React, { useContext } from "react";
import { LocalizationContext } from "Services/Localization/LocalizationContext";

import { filterTypes } from "../../../../utils";
import styles from "./SelectFilter.module.scss";

export const SelectFilter = ({ setActiveFilter, value }) => {
  const { t } = useContext(LocalizationContext);

  return (
    <Select
      value={value}
      label={"Category"}
      IconComponent={ArrowDown}
      className={styles.select}
      MenuProps={{
        classes: {
          paper: styles.selectPaper,
          list: styles.selectList,
        },
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "left",
        },
        getContentAnchorEl: null,
      }}
      notched={false}
      onChange={(_, data) => {
        setActiveFilter({ type: data.props.value })();
      }}
      size="small"
    >
      <MenuItem key={filterTypes.all} value={filterTypes.all}>
        <Typography variant="h6">{t("default.filterAll")}</Typography>
      </MenuItem>
      <MenuItem
        key={filterTypes.subscriptions}
        value={filterTypes.subscriptions}
      >
        <Typography variant="h6">{t("default.subscriptions")}</Typography>
      </MenuItem>
      <MenuItem key={filterTypes.donations} value={filterTypes.donations}>
        <Typography variant="h6">{t("default.donations")}</Typography>
      </MenuItem>
      <MenuItem key={filterTypes.sell} value={filterTypes.sell}>
        <Typography variant="h6">{t("default.sales")}</Typography>
      </MenuItem>
    </Select>
  );
};
