import { Box, Typography } from "@mui/material";
import React from "react";

import styles from "./RewardHeader.module.scss";

export const RewardHeader = ({ children, title, statusTitle }) => {
  return (
    <Box className={styles.header}>
      <Typography variant="body4_16">{title}</Typography>
      {children ? <Box className={styles.headerInfo}>{children}</Box> : null}
      {statusTitle ? (
        <Typography variant="body3_medium" className={styles.status}>
          {statusTitle}
        </Typography>
      ) : null}
    </Box>
  );
};
