import { Box, Typography } from "@mui/material";
import React from "react";

import styles from "../../SmallCard.module.scss";

export const CardContentItem = ({ color, title, value, isVisible = false }) => {
  if (!isVisible) {
    return null;
  }

  return (
    <Box className={styles.cardContentBlock}>
      <Typography variant="body5">{title}</Typography>
      <Box>
        <Typography variant="body5" color={color}>
          {value}
        </Typography>
      </Box>
    </Box>
  );
};
