export const getUserName = (state) => state.users.me.entities?.name;
export const getUserLevel = (state) => state.users.me.entities?.level;
export const getUserProfileImgUrl = (state) =>
  state.users.me.entities?.profileImageUrl;
export const getUsersMeId = (state) => state.users.me.entities?.id;
export const getUsersMeStatus = (state) => state.users.me.entities?.status;
export const getUsersMe = (state) => state.users.me.entities;
export const getSeenTutorials = (state) =>
  state.users.me.entities?.seenTutorials;
export const getUsersMeIsPaymentEnable = (state) =>
  state.users.me.entities?.paymentsEnabled;
export const getUsersMeStripeAccount = (state) =>
  state.users.me.entities?.stripeAccount;

export const getUsersMeStripeData = (state) =>
  state.users.me.entities?.stripeData;
export const getUsersMeStripeDataStatus = (state) =>
  state.users.me.entities?.stripeData?.capabilities?.transfers;
export const getUserMeCommunityMembers = (state) =>
  state.users.me.entities?.community?.numFollowers;
export const getUserMeCommunityComments = (state) =>
  state.users.me.entities?.community?.numComments;
export const getUserMePostingRights = (state) =>
  state.users.me.entities?.community?.postingRights;
export const getUserMeLoading = (state) => state.users.me.loading;
export const getUserMeSubscriptionPrice = (state) =>
  state.users.me.entities?.community?.subscriptionPrice;
