import { Box, Button, CircularProgress, Tooltip } from "@mui/material";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { ReactComponent as DotsEditIcon } from "assets/svg/dots-edit-icon.svg";
import React, { useState } from "react";

import styles from "./ActionTooltip.module.scss";
import { TooltipContent } from "./components/TooltipContent/TooltipContent";

export const ActionTooltip = ({
  deleteHandler,
  editHandler,
  isLoading,
  isMyComment,
}) => {
  const [open, setOpen] = useState(false);

  const Loader = () =>
    isLoading ? (
      <Box className={styles.loader}>
        <CircularProgress color="inherit" size={16} />
      </Box>
    ) : null;

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <Box>
        <Tooltip
          open={open}
          classes={{
            tooltip: styles.wrapperTooltip,
          }}
          title={
            <Box className={styles.tooltip}>
              <Loader />
              <TooltipContent
                isMyComment={isMyComment}
                deleteHandler={deleteHandler}
                editHandler={editHandler}
              />
            </Box>
          }
        >
          <Button
            variant="text"
            className={styles.button}
            onClick={() => setOpen(true)}
          >
            <DotsEditIcon />
          </Button>
        </Tooltip>
      </Box>
    </ClickAwayListener>
  );
};
