import { Box } from "@mui/material";
import { Audio } from "Components/Audio/Audio";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setActiveMedia } from "Redux/mediaSlice";

import styles from "./AudioBlock.module.scss";

export const AudioWrapper = ({ src, postIndex, mediaIndex }) => {
  const dispatch = useDispatch();
  const mediaParams = useSelector((state) => state.media.mediaParams);

  const audioHandler = (audioRef) => () => {
    if (!audioRef.paused) {
      dispatch(setActiveMedia({ postIndex, mediaIndex }));
    }
  };

  return (
    <Box className={styles.audioBox}>
      <Audio
        src={src}
        callbackFunc={audioHandler}
        globalActiveMediaIndex={mediaParams.mediaIndex}
        globalActiveParentIndex={mediaParams.postIndex}
        mediaIndex={mediaIndex}
        parentIndex={postIndex}
      />
    </Box>
  );
};
