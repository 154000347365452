import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { Box, Button, TextField, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import BackBtn from "Components/BackBtn/BackBtn";
import { CropModal } from "Components/Modal/components/CropModal/CropModal";
import { MultipleTutorialTooltip } from "Components/MultipleTutorialTooltip/MultipleTutorialTooltip";
import Page from "Components/Page/Page";
import SidebarTablet from "Components/SidebarTablet/SidebarTablet";
import { MODAL_TYPE, PATHS, TUTORIALS_PROPS } from "Constants/index";
import { REWARDS_ACTION_TYPE } from "Constants/index";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { setError, setLoader, setModal } from "Redux/appSlice";
import {
  createCard,
  setActiveLeCardId,
  setDataToConstructor,
  setEditedCard,
} from "Redux/limitedEditionSlice";
import { getLeCurrentEditCard } from "Redux/selectors/limitedEditionSelectors";
import { getSeenTutorials } from "Redux/selectors/userSelector";
import { errorResponseMessages } from "Services/Api/apiErrorHelper.tsx";
import { LocalizationContext } from "Services/Localization/LocalizationContext";
import { useIsMobile } from "Utils/Hooks";

import { changeUser, updateUser } from "../../Redux/usersSlice";
import { getCard } from "../../Services/Api/limitedEditionApi";
import { getConvertedFiles, getFileTypeByUrl, getTimeRange } from "../../Utils";
import styles from "./CardConstructor.module.scss";
import { RewardsSection } from "./components/RewardsSection/RewardsSection";
import {
  CardConstructorFields,
  MIN_IMG_HEIGHT,
  MIN_IMG_WIDTH,
} from "./constant";
import { UploadIcon } from "./icons";
import { getValidationSchema } from "./utils";

export const CardConstructor = () => {
  const { t } = useContext(LocalizationContext);
  const isMobile = useIsMobile();
  const isS = useMediaQuery("(max-width:767px)");
  const isMd = useMediaQuery("(max-width:1024px)");
  const inputFileRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const card = useSelector(getLeCurrentEditCard);
  const seenTutorials = useSelector(getSeenTutorials);

  const [isOpenCropModal, setOpenCropModal] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const [imageFileToCrop, setImageFileToCrop] = useState(null);
  const [imageURL, setImageURL] = useState(null);
  const [loader, setLocalLoader] = useState(false);
  const [oldCardData, setOldCardData] = useState(false);
  const [files, setFiles] = useState([]);
  const [skeletonLoader, setSkeletonLoader] = useState(false);
  const [rewardType, setRewardType] = useState(null);
  const [premiumMessage, setPremiumMessage] = useState("");
  const [rewardMessage, setRewardMessage] = useState({
    [REWARDS_ACTION_TYPE.FOLLOW_BACK]: "",
    [REWARDS_ACTION_TYPE.VIDEO_CALL]: "",
  });
  const validationSchema = getValidationSchema({
    requiredErrorMsg: t("errors.isRequired"),
    t,
  });

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });

  const getDefaultDropDate = () => {
    const dropDate = new Date();
    if (oldCardData?.dropStartDate) {
      const startDateDelay = new Date(oldCardData?.dropStartDate) - new Date();
      const hours = startDateDelay / 1000 / 60 / 60;
      if (hours > 48) {
        return new Date(oldCardData?.dropStartDate).toString();
      }
    }
    dropDate.setHours(dropDate.getHours() + 48);
    const timeSlots = getTimeRange({ intervalMinutes: 30, date: dropDate });
    const defaultDate = timeSlots.find((item) => !item.disabled);
    if (defaultDate?.value) {
      return new Date(defaultDate.value).toString();
    }
    dropDate.setMinutes(dropDate.getMinutes() + 7); // if request is too long
    return dropDate.toString();
  };

  const createHandler = async (data, cbFuncLoader) => {
    let media = [];
    if (
      data.media?.length &&
      rewardType === REWARDS_ACTION_TYPE.HIDDEN_CONTENT
    ) {
      const result = await getConvertedFiles({ blobFiles: data.media });
      media = result.map((item, index) => ({
        index,
        url: item,
        type: getFileTypeByUrl(item),
      }));
    }
    const rewardTypeValue =
      (data.media?.length ||
        premiumMessage?.trim().length ||
        rewardMessage[rewardType]?.trim().length) &&
      rewardType
        ? rewardType
        : null;
    const rewardMessageValue =
      premiumMessage?.trim().length &&
      rewardType === REWARDS_ACTION_TYPE.PREMIUM_CHAT
        ? premiumMessage
        : (rewardMessage[rewardType]?.trim().length &&
            rewardType === REWARDS_ACTION_TYPE.FOLLOW_BACK) ||
          rewardType === REWARDS_ACTION_TYPE.VIDEO_CALL
        ? rewardMessage[rewardType]
        : "";
    dispatch(
      createCard({
        ...data,
        ...(params?.id && { cardId: params.id }),
        media: rewardType === REWARDS_ACTION_TYPE.HIDDEN_CONTENT ? media : [],
        rewardType: rewardTypeValue,
        rewardMessage: rewardMessageValue,
      })
    )
      .then((data) => {
        if (data?.error) {
          dispatch(
            setError({
              open: true,
              title: t("errors.error"),
              subtitle: data?.error?.message,
            })
          );
        } else {
          dispatch(setModal({ open: false, payload: null }));
          dispatch(setActiveLeCardId(data?.payload?.data?.id));
          navigate(PATHS.LIMITED_EDITION);
        }
      })
      .catch((err) => {
        if (err?.status !== 401) {
          dispatch(
            setError({
              open: true,
              title: t("errors.error"),
              subtitle: errorResponseMessages(err, t),
            })
          );
        }
      })
      .finally(() => {
        setLocalLoader(false);
        if (cbFuncLoader) {
          cbFuncLoader(false);
        }
      });
  };

  const updateHandler = (data) => {
    dispatch(setModal({ open: false, payload: null }));
    dispatch(setEditedCard({ id: params.id, ...data }));
    navigate(PATHS.LIMITED_EDITION);
  };

  const submit = (data, _, cbFuncLoader) => {
    setLocalLoader(true);
    const dropDate = data.dropDate || getDefaultDropDate();
    const newData = {
      ...data,
      salePrice: data.price,
      dropDate,
      imageFile,
      rewardType,
      rewardMessage:
        rewardType === REWARDS_ACTION_TYPE.PREMIUM_CHAT
          ? premiumMessage
          : rewardType === REWARDS_ACTION_TYPE.FOLLOW_BACK ||
            rewardType === REWARDS_ACTION_TYPE.VIDEO_CALL
          ? rewardMessage[rewardType]
          : "",
    };
    delete newData.price;
    newData.media = files;
    if (params.id) {
      updateHandler(newData);
    } else {
      createHandler(newData, cbFuncLoader);
    }
  };

  const openScheduleModal = () => {
    const scheduledAt = getDefaultDropDate();
    const calendarStartDay = new Date();
    calendarStartDay.setHours(calendarStartDay.getHours() + 48);
    calendarStartDay.setMinutes(calendarStartDay.getMinutes() + 7);

    dispatch(
      setModal({
        type: MODAL_TYPE.SCHEDULE_MODAL,
        open: true,
        payload: {
          ...getValues(),
          imageFile,
          calendarStartDay,
          customStartDate: scheduledAt,
          mainTitle: t("limitedEdition.scheduleTitle"),
          subTitle: t("limitedEdition.scheduleSubTitle"),
          sheduleModalPublichedTitle:
            "limitedEdition.sheduleModalPublichedTitle",
          delay: 1000 * 60 * 60 * 24 * 2, // 48h
        },
        handler: (data, cbLoader) => {
          submit(
            {
              price: +data.price,
              name: data.name,
              imageFile: data.imageFile,
              allocation: +data.allocation,
              description: data.description,
              dropDate: new Date(data.scheduledAt),
            },
            null,
            cbLoader
          );
        },
      })
    );
  };

  const resetImageFile = () => {
    setImageFileToCrop(null);
    if (inputFileRef.current?.value) {
      inputFileRef.current.value = null;
    }
  };

  const setDefaultValue = (data) => {
    if (data) {
      setValue(CardConstructorFields.name, data.name);
      setValue(CardConstructorFields.description, data.description);
      setValue(CardConstructorFields.salePrice, data.currentSalePrice);
      setValue(CardConstructorFields.allocation, data.quantityAllocated);
      setValue(CardConstructorFields.cardFile, data.imageUrl);
      setImageFile(card.imageUrl);
      if (
        data.rewardType === REWARDS_ACTION_TYPE.FOLLOW_BACK ||
        data.rewardType === REWARDS_ACTION_TYPE.VIDEO_CALL
      ) {
        setRewardMessage((prevState) => ({
          ...prevState,
          [data.rewardType]: data.rewardMessage,
        }));
      } else if (data.rewardType === REWARDS_ACTION_TYPE.PREMIUM_CHAT) {
        setPremiumMessage(data.rewardMessage);
      }
      if (!oldCardData) {
        setOldCardData(data);
      }
    }
  };

  const isDisabled =
    !isValid ||
    !imageFile ||
    (rewardType &&
      rewardType === REWARDS_ACTION_TYPE.PREMIUM_CHAT &&
      !premiumMessage?.trim().length) ||
    ((rewardType === REWARDS_ACTION_TYPE.FOLLOW_BACK ||
      rewardType === REWARDS_ACTION_TYPE.VIDEO_CALL) &&
      !rewardMessage[rewardType]?.trim().length);

  const tutorialUpdate = (params) => () => {
    dispatch(changeUser(params));
    dispatch(updateUser(params));
  };

  const tutorialSkip = () => {
    const params = {
      seenTutorials: {
        ...(seenTutorials && seenTutorials),
        [TUTORIALS_PROPS.CREATE_CARD_IMG]: true,
        [TUTORIALS_PROPS.CREATE_CARD_SUPPLY]: true,
        [TUTORIALS_PROPS.CREATE_CARD_PRICE]: true,
        [TUTORIALS_PROPS.CREATE_CARD_SCHEDULE]: true,
      },
    };
    dispatch(changeUser(params));
    dispatch(updateUser(params));
  };

  useEffect(() => {
    if (imageFile) {
      if (typeof imageFile === "string") {
        setImageURL(imageFile);
      } else {
        const data = URL.createObjectURL(imageFile);
        setImageURL(data);
      }
    }
  }, [imageFile]);

  useEffect(() => {
    setDefaultValue(card);
    return () => {
      dispatch(setDataToConstructor(null));
    };
  }, [card]);

  useEffect(() => {
    if (params?.id) {
      if (!card) {
        dispatch(setLoader(true));
      }
      setSkeletonLoader(true);
      getCard({ cardId: params.id })
        .then((res) => {
          if (res?.data) {
            dispatch(setDataToConstructor(res.data));
          }
        })
        .catch((err) => {
          if (err?.status !== 401) {
            dispatch(
              setError({
                open: true,
                title: t("errors.error"),
                subtitle: errorResponseMessages(err, t),
              })
            );
          }
        })
        .finally(() => {
          setSkeletonLoader(false);
          dispatch(setLoader(false));
        });
    }
  }, []);

  useEffect(() => {
    if (card?.media?.length) {
      const data = card?.media?.map((item) => ({
        ...item,
        type: item.type,
        path: item.url,
        preview: item.url,
      }));
      setFiles(data);
    }
  }, [card?.media]);

  useEffect(() => {
    if (card?.rewardType) {
      setRewardType(card?.rewardType);
    }
  }, [card?.rewardType]);

  return (
    <Page>
      {!seenTutorials?.createCardImg ? (
        <Box className={styles.overlay} />
      ) : null}
      <Box className={styles.titleWrap}>
        <SidebarTablet />
        {!isMobile || isS ? <BackBtn isPlain /> : null}
        <Typography variant="h2">
          {params?.id ? t("limitedEdition.editCard") : t("default.createCard")}
        </Typography>
      </Box>
      <Box className={styles.main}>
        <form onSubmit={handleSubmit(submit)}>
          <Box className={styles.formFields}>
            <Box className={styles.formFieldsImage}>
              <MultipleTutorialTooltip
                isOpen={!seenTutorials?.createCardImg}
                title={t("default.tutorialCreateCardImgTitle")}
                subTitle={t("default.tutorialCreateCardImgSubTitle")}
                skipButtonText={t("default.skip")}
                nextButtonText={`${t("default.start")} (1/4)`}
                isArrow={false}
                placement={isMd ? "bottom" : "right"}
                next={tutorialUpdate({
                  seenTutorials: {
                    ...(seenTutorials && seenTutorials),
                    [TUTORIALS_PROPS.CREATE_CARD_IMG]: true,
                  },
                })}
                skip={tutorialSkip}
                tooltipClassName={styles.imgTooltip}
              >
                <label htmlFor="le-file">
                  {imageURL ? (
                    <img src={imageURL} alt="card" loading="lazy" />
                  ) : null}
                  <Box
                    className={`${styles.formFieldsImageInfo} ${
                      imageURL ? styles.formFieldsImageInfoHover : ""
                    }`}
                  >
                    <UploadIcon />
                    <Typography
                      variant="body1_medium"
                      className={styles.formFieldsImageTitle}
                      component="p"
                    >
                      {t("limitedEdition.chooseCardImage")}
                    </Typography>
                    <Box className={styles.formFieldsImageSizeBox}>
                      <Typography variant="body2">
                        {t("default.minimumSize")}:
                      </Typography>
                      <Typography variant="body2">{`${MIN_IMG_WIDTH}px x ${MIN_IMG_HEIGHT}px`}</Typography>
                    </Box>
                  </Box>
                </label>
              </MultipleTutorialTooltip>
              <input
                ref={inputFileRef}
                type="file"
                id="le-file"
                accept={["image/png", "image/jpeg", "image/jpg"]}
                onChange={(e) => {
                  setOpenCropModal(true);
                  const file = e.target.files[0];
                  if (file) {
                    setImageFileToCrop(file);
                    setOpenCropModal(true);
                  }
                }}
              />
              {errors[CardConstructorFields.cardFile]?.message ? (
                <Typography variant="error">
                  {errors[CardConstructorFields.cardFile].message}
                </Typography>
              ) : null}
            </Box>
            <Box className={styles.formFieldsRows}>
              <Box className={styles.row}>
                <Box className={styles.col}>
                  <TextField
                    variant="outlined"
                    inputProps={{ maxLength: 101 }}
                    label={t("limitedEdition.cardName")}
                    {...register(CardConstructorFields.name)}
                    defaultValue={card?.name || ""}
                  />
                  <Typography variant="error">
                    {errors[CardConstructorFields.name]?.message}
                  </Typography>
                </Box>
              </Box>
              <Box className={styles.row}>
                <Box className={styles.col}>
                  <TextField
                    variant="outlined"
                    label={t("default.descriptionKey")}
                    rows={4}
                    multiline={true}
                    className={styles.textarea}
                    inputProps={{ maxLength: 1001 }}
                    {...register(CardConstructorFields.description)}
                    defaultValue={card?.description || ""}
                  />
                  <Typography variant="error">
                    {errors[CardConstructorFields.description]?.message}
                  </Typography>
                </Box>
              </Box>
              <Box className={styles.row}>
                <Box className={styles.col}>
                  <TextField
                    label={t("default.price")}
                    variant="outlined"
                    {...register(CardConstructorFields.salePrice)}
                    defaultValue={card?.currentSalePrice || ""}
                  />
                  <Typography variant="error">
                    {errors[CardConstructorFields.salePrice]?.message}
                  </Typography>
                </Box>
                <Box className={styles.col}>
                  <MultipleTutorialTooltip
                    isOpen={
                      seenTutorials?.createCardImg &&
                      !seenTutorials?.createCardSupply
                    }
                    title={t("default.tutorialCreateCardSupplyTitle")}
                    subTitle={t("default.tutorialCreateCardSupplySubTitle")}
                    skipButtonText={t("default.skip")}
                    nextButtonText={`${t("default.next")} (2/4)`}
                    prevButtonText={t("default.back")}
                    isArrow={true}
                    placement={"top"}
                    next={tutorialUpdate({
                      seenTutorials: {
                        ...(seenTutorials && seenTutorials),
                        [TUTORIALS_PROPS.CREATE_CARD_SUPPLY]: true,
                      },
                    })}
                    prev={tutorialUpdate({
                      seenTutorials: {
                        ...(seenTutorials && seenTutorials),
                        [TUTORIALS_PROPS.CREATE_CARD_IMG]: null,
                      },
                    })}
                    skip={tutorialSkip}
                  >
                    <TextField
                      label={t("limitedEdition.totalCardsForSale")}
                      variant="outlined"
                      {...register(CardConstructorFields.allocation)}
                      defaultValue={card?.quantityAllocated || ""}
                    />
                  </MultipleTutorialTooltip>
                  <Typography variant="error">
                    {errors[CardConstructorFields.allocation]?.message}
                  </Typography>
                </Box>
              </Box>
              <MultipleTutorialTooltip
                isOpen={
                  seenTutorials?.createCardImg &&
                  seenTutorials?.createCardSupply &&
                  !seenTutorials?.createCardPrice
                }
                title={t("default.tutorialCreateCardPriceTitle")}
                subTitle={t("default.tutorialCreateCardPriceSubTitle")}
                skipButtonText={t("default.skip")}
                nextButtonText={`${t("default.next")} (3/4)`}
                prevButtonText={t("default.back")}
                isArrow={true}
                placement={isMd ? "top" : "left"}
                next={tutorialUpdate({
                  seenTutorials: {
                    ...(seenTutorials && seenTutorials),
                    [TUTORIALS_PROPS.CREATE_CARD_PRICE]: true,
                  },
                })}
                prev={tutorialUpdate({
                  seenTutorials: {
                    ...(seenTutorials && seenTutorials),
                    [TUTORIALS_PROPS.CREATE_CARD_SUPPLY]: null,
                  },
                })}
                skip={tutorialSkip}
              >
                <RewardsSection
                  files={files}
                  setFiles={setFiles}
                  rewardType={rewardType}
                  setRewardType={setRewardType}
                  isLoading={skeletonLoader}
                  message={premiumMessage}
                  setMessage={setPremiumMessage}
                  rewardMessage={rewardMessage}
                  setRewardMessage={setRewardMessage}
                />
              </MultipleTutorialTooltip>
            </Box>
          </Box>
          <Box className={styles.formActions}>
            <Box className={`${styles.row} ${styles.rowActions}`}>
              <MultipleTutorialTooltip
                isOpen={
                  seenTutorials?.createCardImg &&
                  seenTutorials?.createCardSupply &&
                  seenTutorials?.createCardPrice &&
                  !seenTutorials?.createCardSchedule
                }
                title={t("default.tutorialCreateCardScheduleTitle")}
                subTitle={t("default.tutorialCreateCardScheduleSubTitle")}
                skipButtonText={t("default.skip")}
                nextButtonText={t("default.gotIt")}
                prevButtonText={t("default.back")}
                isArrow={true}
                placement={"top"}
                next={tutorialUpdate({
                  seenTutorials: {
                    ...(seenTutorials && seenTutorials),
                    [TUTORIALS_PROPS.CREATE_CARD_SCHEDULE]: true,
                  },
                })}
                prev={tutorialUpdate({
                  seenTutorials: {
                    ...(seenTutorials && seenTutorials),
                    [TUTORIALS_PROPS.CREATE_CARD_PRICE]: null,
                  },
                })}
                skip={tutorialSkip}
              >
                <Button
                  variant="outlined"
                  disabled={isDisabled}
                  onClick={openScheduleModal}
                  className={styles.full}
                >
                  {t("limitedEdition.scheduleCardLaunch")}
                </Button>
              </MultipleTutorialTooltip>
              <LoadingButton
                variant="contained"
                type="submit"
                disabled={isDisabled}
                loading={loader}
              >
                {t("limitedEdition.submitCard")}
              </LoadingButton>
            </Box>
          </Box>
        </form>
      </Box>
      {imageFileToCrop && isOpenCropModal ? (
        <CropModal
          isOpen={isOpenCropModal && !!imageFileToCrop}
          setOpen={setOpenCropModal}
          imgFile={imageFileToCrop}
          resetImageFile={resetImageFile}
          aspect={MIN_IMG_WIDTH / MIN_IMG_HEIGHT}
          keepSelection={true}
          locked={true}
          minImgHeight={MIN_IMG_HEIGHT}
          minImgWidth={MIN_IMG_WIDTH}
          submitHandler={(file) => setImageFile(file)}
        />
      ) : null}
    </Page>
  );
};
