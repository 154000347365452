import React, { useEffect, useState, useRef, useContext } from "react";
import { LocalizationContext } from "Services/Localization/LocalizationContext";
import {
  Box,
  Button,
  Typography,
  Modal,
  IconButton,
  Avatar,
} from "@mui/material";
import styles from "./ModalUploadPhoto.module.scss";
import { ReactComponent as IconClose } from "assets/svg/icon-close.svg";
import { ReactComponent as UploadIcon } from "assets/svg/account/upload-btn.svg";
import { ReactComponent as BinIcon } from "assets/svg/account/remove-bin.svg";

import LoadingButton from "@mui/lab/LoadingButton";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 600,
        minWidth: 600,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

const ModalUploadPhoto = ({
  open,
  handleClick,
  handleClose,
  isCanDeletePhoto,
  profileImageUrl,
}) => {
  const { t } = useContext(LocalizationContext);
  const [step, setStep] = useState(0);
  const [crop, setCrop] = useState(undefined);
  const [imgSrc, setImgSrc] = useState("");
  const [loading, setLoading] = useState(false);
  const imgRef = useRef(null);

  const [completedCrop, setCompletedCrop] = useState();

  const scale = 1;
  const rotate = 0;
  const aspect = 1;

  const handleRemovePhoto = async (e) => {
    e.stopPropagation();
    setLoading(true);
    const img = await fetch(require("assets/img/default-avatar.png"));
    const blob = await img.blob();

    handleClick({ image: blob });
  };

  useEffect(() => {
    setStep(0);
    setLoading(false);
  }, [open]);

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined); // Makes crop update between images.
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImgSrc(reader.result.toString() || "");
        setStep(1);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const onImageLoad = (e) => {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  };

  const makeClientCrop = async (crop) => {
    if ((imgSrc, crop.width && crop.height)) {
      const croppedImg = await getCroppedImg(imgSrc, crop, "croppedImage.jpeg");
      setCompletedCrop(croppedImg);
    }
  };

  const getCroppedImg = (sourceImage, crop, fileName) => {
    const canvas = document.createElement("canvas");

    // image sizes of returned image
    const width = 1200;
    const height = 1200;

    const scaleX = imgRef.current.naturalWidth / imgRef.current.width;
    const scaleY = imgRef.current.naturalHeight / imgRef.current.height;
    canvas.width = width;
    canvas.height = height;

    const ctx = canvas.getContext("2d");

    const image = new Image();
    image.src = sourceImage;

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      width,
      height
    );

    try {
      return new Promise((resolve) => {
        canvas.toBlob((file) => {
          resolve(file);
        }, "image/jpeg");
      });
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const handleUpload = () => {
    setLoading(true);

    handleClick({ image: completedCrop });
  };

  const renderSteps = () => {
    switch (step) {
      case 0: {
        return (
          <Box>
            <input
              type="file"
              accept="image/*"
              onChange={onSelectFile}
              style={{ display: "none" }}
              id="contained-button-file"
            />
            <label htmlFor="contained-button-file" className={styles.uploadBtn}>
              {profileImageUrl && (
                <Avatar
                  variant="square"
                  src={profileImageUrl}
                  className={styles.profileImage}
                />
              )}

              <Box className={styles.buttonContainer}>
                {isCanDeletePhoto && (
                  <LoadingButton
                    className={`${styles.btn} ${styles.removeBtn}`}
                    variant="outlined"
                    onClick={handleRemovePhoto}
                    loading={loading}
                  >
                    <BinIcon width="15.71" height="17.14" />

                    {t("account.removeCurrentPhoto")}
                  </LoadingButton>
                )}
                <Button
                  className={`${styles.btn} ${styles.uploadBtnIcon}`}
                  variant="contained"
                  component="span"
                >
                  <UploadIcon width="20" height="20" />
                  {t("account.uploadFromDevice")}
                </Button>
              </Box>
            </label>
          </Box>
        );
      }
      case 1: {
        return (
          <Box className={styles.cropContainer}>
            {Boolean(imgSrc) && (
              <ReactCrop
                crop={crop}
                onChange={(_, percentCrop) => setCrop(percentCrop)}
                onComplete={(c) => makeClientCrop(c)}
                aspect={aspect}
              >
                <img
                  ref={imgRef}
                  alt="Crop me"
                  src={imgSrc}
                  style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
                  onLoad={onImageLoad}
                />
              </ReactCrop>
            )}
            <Box className={styles.buttonContainer}>
              <Box className={styles.uploadBtn}>
                <LoadingButton
                  className={`${styles.btn} ${styles.saveBtn}`}
                  variant="contained"
                  onClick={handleUpload}
                  loading={loading}
                >
                  {t("default.save")}
                </LoadingButton>
              </Box>
            </Box>
          </Box>
        );
      }
      default: {
        return null;
      }
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          height: "auto",
          transform: "translate(-50%, -50%)",
          background: "#111322",
          outline: "none",
          borderRadius: "12px",
        }}
        className={styles.modalBody}
      >
        <Typography className={styles.modalTitle} id="modal-modal-title">
          {t("account.changeProfilePhoto")}
        </Typography>
        <IconButton className={styles.iconClose} onClick={handleClose}>
          <IconClose />
        </IconButton>
        <Typography className={styles.modalDesc}>
          {t("account.changeProfilePhotoDesc")}
        </Typography>
        {renderSteps()}
      </Box>
    </Modal>
  );
};

export default ModalUploadPhoto;
