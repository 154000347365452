import { CognitoUserPool } from "amazon-cognito-identity-js";

export const userPoolId = process.env.REACT_APP_AWS_ADMIN_COGNITO_USERPOOL_ID;
export const clientId = process.env.REACT_APP_AWS_ADMIN_COGNITO_APP_CLIENT_ID;

const poolData = {
  UserPoolId: userPoolId,
  ClientId: clientId,
};

const getAdminUserPool = () => {
  return new CognitoUserPool(poolData);
};

export { getAdminUserPool };
