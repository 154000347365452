import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setActiveMedia } from "Redux/mediaSlice";

import { YouTubeVideo } from "./YouTubeVideo";

export const YouTubeVideoBlock = ({ youtubeUrl, className, parentIndex }) => {
  const dispatch = useDispatch();
  const mediaParams = useSelector((state) => state.media.mediaParams);

  const onPlayerStateChange = (e) => {
    if (e.data === window.YT.PlayerState.PLAYING) {
      dispatch(
        setActiveMedia({
          postIndex: parentIndex,
          mediaIndex: 0,
        })
      );
    }
  };

  return (
    <YouTubeVideo
      youtubeUrl={youtubeUrl}
      className={className}
      onPlayerStateChange={onPlayerStateChange}
      globalActiveMediaIndex={mediaParams.mediaIndex}
      globalActiveParentIndex={mediaParams.postIndex}
      mediaIndex={0}
      parentIndex={parentIndex}
    />
  );
};
