import { Box } from "@mui/material";
import AuthLeftSide from "Components/AuthLeftSide";
import React, { useState } from "react";
import { useIsMobile } from "Utils/Hooks";

import styles from "./KYC.module.scss";
import Step0 from "./Step0";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import StepAddress from "./StepAddress";

const steps = [
  {
    step: 0,
    title: "Verify your identity",
    hidden: true,
  },
  {
    step: 1,
    title: "General Information",
  },
  // {
  //   step: 2,
  //   title: "Email Verification",
  //   hidden: true,
  // },
  {
    step: 2,
    title: "Personal Information",
  },
  {
    step: 3,
    title: "Address",
  },
];

const Kyc = () => {
  const isMobile = useIsMobile();
  const [activeStep, setActiveStep] = useState(0);
  const [selectedCountry, setCountry] = useState("");
  const [mainError, setMainError] = useState(null);

  const onMainError = (e) => {
    if (e?.ssn) {
      setMainError(e.ssn);
      setActiveStep(1);
    }
  };
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    if (activeStep !== 0) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const renderStep = (step) => {
    switch (step) {
      case 0:
        return <Step0 handleNext={handleNext} handleBack={handleBack} />;
      case 1:
        return (
          <Step1
            mainError={mainError}
            handleNext={handleNext}
            handleBack={handleBack}
            setSelectedCountry={setCountry}
          />
        );
      case 2:
        return (
          <Step3
            handleNext={handleNext}
            handleBack={handleBack}
            selectedCountry={selectedCountry}
          />
        );
      case 3:
        return (
          <StepAddress
            setMainError={onMainError}
            handleNext={handleNext}
            handleBack={handleBack}
            selectedCountry={selectedCountry}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Box className={styles.container}>
      {!isMobile && <AuthLeftSide step={activeStep} steps={steps} />}

      <Box className={styles.rightSide}>{renderStep(activeStep)}</Box>
    </Box>
  );
};
export default Kyc;
