import { Box, CircularProgress, IconButton, Modal } from "@mui/material";
import { ReactComponent as CloseIcon } from "assets/svg/reply-close-icon.svg";
import { REWARDS_ACTION_TYPE } from "Constants/index";
import React, { Suspense, useContext } from "react";
import { LocalizationContext } from "Services/Localization/LocalizationContext";

import { ChatWidget } from "../../../ChatWidget/ChatWidget";
import styles from "./ChatWidgetModal.module.scss";

export const ChatWidgetModal = ({
  isOpen,
  setOpen,
  cardId,
  orderId,
  appUser,
  reward,
  status,
  index,
}) => {
  const { t } = useContext(LocalizationContext);

  const closeHandler = () => setOpen(false);

  const title =
    reward === REWARDS_ACTION_TYPE.FOLLOW_BACK
      ? t("limitedEdition.titleChatFollowBack", {
          name: appUser.username,
        })
      : reward === REWARDS_ACTION_TYPE.VIDEO_CALL
      ? t("limitedEdition.titleChatVideoCall", {
          name: appUser.username,
        })
      : null;

  if (!isOpen) {
    return null;
  }

  return (
    <Modal open={isOpen} onClose={closeHandler} classes={{ root: styles.root }}>
      <Suspense
        fallback={
          <Box>
            <CircularProgress />
          </Box>
        }
      >
        <Box className={styles.wrapper}>
          <IconButton className={styles.close} onClick={closeHandler}>
            <CloseIcon className={styles.btnIcon} />
          </IconButton>
          <ChatWidget
            cardId={cardId}
            orderId={orderId}
            appUser={appUser}
            index={index}
            status={status}
            title={title}
            rewardType={reward}
          />
        </Box>
      </Suspense>
    </Modal>
  );
};
