import { Button, Snackbar } from "@mui/material";
import React, { useContext, useState } from "react";
import { updateComment } from "Services/Api/communityApi";
import { LocalizationContext } from "Services/Localization/LocalizationContext";
import useDelayedExecution from "Utils/Hooks/useDelayedExecution";

import styles from "./CommentEditBlock.module.scss";
import { EditBlock } from "./components/EditBlock";

const AUTO_HIDE_TIMEOUT = 2000;

export const CommentEditBlock = ({
  message,
  oldMessageVersion,
  setEditMode,
  setActionLoading,
  postId,
  commentId,
  setMessageValue,
  isEditMode,
  setCommentEdited,
}) => {
  const [isSnackBarEdit, setSnackBarEdit] = useState(false);

  const { stopExecute, startExecute } = useDelayedExecution(AUTO_HIDE_TIMEOUT);

  const { t } = useContext(LocalizationContext);

  const editHandler = ({ message }) => {
    setActionLoading(true);
    setSnackBarEdit(true);
    setMessageValue(message);
    setEditMode(false);
    setCommentEdited(true);
    startExecute(() => {
      updateComment({
        postId,
        commentId,
        message: message,
      }).finally(() => {
        setActionLoading(false);
      });
    });
  };

  const undoEdit = () => {
    stopExecute();
    setActionLoading(false);
    setSnackBarEdit(false);
    setMessageValue(oldMessageVersion);
    setCommentEdited(false);
  };

  return (
    <>
      <EditBlock
        editHandler={editHandler}
        isEditMode={isEditMode}
        message={message}
        setEditMode={setEditMode}
      />
      <Snackbar
        open={isSnackBarEdit}
        message={t("community.editCommentSuccess")}
        autoHideDuration={AUTO_HIDE_TIMEOUT}
        severity={"success"}
        onClose={() => setSnackBarEdit(false)}
        action={
          <Button className={styles.snackbarButton} onClick={undoEdit}>
            {t("community.undo")}
          </Button>
        }
      />
    </>
  );
};
