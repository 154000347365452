import { Box } from "@mui/material";
import { SetupPaymentsSection } from "Components/SetupPaymentsSection/SetupPaymentsSection";
import { MODAL_TYPE, PATHS, USER_ME_STATUS } from "Constants/index";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setModal, setOnfidoToken } from "Redux/appSlice";
import { fetchUser } from "Redux/mainAppUserSlice";
import {
  getUserMeLoading,
  getUsersMeId,
  getUsersMeIsPaymentEnable,
  getUsersMeStripeAccount,
  getUsersMeStripeDataStatus,
} from "Redux/selectors/userSelector";
import { fetchUserMe } from "Redux/usersSlice";
import { getKycStatus } from "Services/Api/mainAppApi";
import { LocalizationContext } from "Services/Localization/LocalizationContext";
import { CheckKycStatus } from "Utils/Hooks/kycCheck";

import { KYC_ERROR_STATUS, KYC_STATUS } from "../../constants";
import { PostIcon } from "../../icons";
import { OnboardingItem } from "../OnboardingItem/OnboardingItem";
import OnboardingItemStyles from "../OnboardingItem/OnboardingItem.module.scss";
import styles from "./OnboardingList.module.scss";

export const OnboardingList = ({
  status,
  communityGuidelinesAccepted,
  postsCount,
}) => {
  const dispatch = useDispatch();
  const { t } = useContext(LocalizationContext);
  const navigate = useNavigate();

  const [processing, setProcessing] = useState(false);
  const [kycStatus, setKycStatus] = useState(null);
  const [kycStatusLoading, setKycStatusLoading] = useState(false);
  const onfido = useSelector((state) => state.mainAppUser?.onfido);
  const userMeId = useSelector(getUsersMeId);
  const userMeLoading = useSelector(getUserMeLoading);
  const isPaymentEnable = useSelector(getUsersMeIsPaymentEnable);
  const stripeAccountStatus = useSelector(getUsersMeStripeDataStatus);

  const checkStatus = CheckKycStatus();

  const handleOnfido = async () => {
    setProcessing(true);
    const status = await checkStatus();
    if (status?.sdkToken) {
      setProcessing(false);
      return dispatch(
        setOnfidoToken({
          sdkToken: status?.sdkToken,
        })
      );
    }
    if (status !== 1) {
      setProcessing(false);
    }
  };

  const goToSupport = () => {
    window.zE("messenger:set", "zIndex", 99999);
    window.zE("messenger", "open");
  };

  const fidoHandle = (kycStatus) => () => {
    if (status === USER_ME_STATUS.PROFILE_COMPLETED) {
      if (KYC_ERROR_STATUS[kycStatus?.toUpperCase()]) {
        goToSupport();
      } else {
        handleOnfido();
      }
    } else {
      navigate(PATHS.COMPLETE_PROFILE);
    }
  };

  const openGuidelinesModal = () => {
    dispatch(
      setModal({
        open: true,
        type: MODAL_TYPE.COMMUNITY_GUIDELINES,
        payload: {},
      })
    );
  };

  useEffect(() => {
    dispatch(fetchUser());
    dispatch(fetchUserMe()).then(() => {
      setKycStatusLoading(true);
      getKycStatus()
        .then((data) => {
          if (
            data.data.state === KYC_STATUS.ACCEPT &&
            kycStatus !== KYC_STATUS.ACCEPT
          ) {
            // dispatch(
            //   setSnackbar({
            //     open: true,
            //     message: t("community.identityVerificationSuccessful"),
            //     right: "20px",
            //     left: "unset",
            //   })
            // );
          }
          setKycStatus(data.data.state);
        })
        .finally(() => setKycStatusLoading(false));
    });
  }, [onfido.status]);

  useEffect(() => {
    if (
      status === USER_ME_STATUS.PROFILE_COMPLETED &&
      !sessionStorage.getItem(`onfido-run-${userMeId}`)
    ) {
      handleOnfido().finally(() => {
        sessionStorage.setItem(`onfido-run-${userMeId}`, "true");
      });
    }
  }, [status]);

  const getKycText = (currentStatus) => {
    switch (currentStatus) {
      case KYC_STATUS.IN_PROGRESS:
        return {
          title: t("community.oboarding1TitleInProgress"),
          description: t("community.oboarding1DescriptionInProgress"),
          buttonText: t("community.oboarding1ActionTextInProgress"),
        };
      case KYC_STATUS.REJECTED:
      case KYC_STATUS.DENY:
      case KYC_STATUS.SUSPECTED:
      case KYC_STATUS.CAUTION:
        return {
          title: t("community.oboarding1TitleError"),
          description: t("community.oboarding1DescriptionError"),
          buttonText: t("community.oboarding1ActionTextError"),
        };
      default:
        return {
          title: t("community.oboarding1Title"),
          description: t("community.oboarding1Description"),
          buttonText: t("community.oboarding1ActionText"),
        };
    }
  };

  const isDisabledPayment =
    !communityGuidelinesAccepted || status !== USER_ME_STATUS.VERIFIED;

  return (
    <Box>
      <OnboardingItem
        title={getKycText(kycStatus).title}
        description={getKycText(kycStatus).description}
        isShowing={status !== "verified"}
        buttonText={getKycText(kycStatus).buttonText}
        action={fidoHandle(kycStatus)}
        isLoading={processing || userMeLoading || kycStatusLoading}
        status={kycStatus}
      />
      <OnboardingItem
        title={t("community.oboarding2Title")}
        description={t("community.oboarding2Description")}
        buttonText={t("community.oboarding2ActionText")}
        isShowing={!communityGuidelinesAccepted}
        action={openGuidelinesModal}
      />
      {!postsCount ? (
        <SetupPaymentsSection
          isDisabled={isDisabledPayment}
          title={t("default.paymentsSectionTitle2")}
          classNameContent={`${OnboardingItemStyles.wrapper} ${
            styles.paymentSection
          } ${isDisabledPayment ? styles.paymentSectionDisabled : ""}`}
          classNameHeader={styles.paymentSectionHeader}
          classNameHeaderWrapper={OnboardingItemStyles.main}
          classNameDescription={styles.paymentSectionDescription}
          isCustomShow={!isPaymentEnable || stripeAccountStatus !== "active"}
        />
      ) : null}
      <OnboardingItem
        title={t("community.oboarding3Title")}
        description={t("community.oboarding3Description")}
        buttonText={t("community.oboarding3ActionText")}
        isShowing={!postsCount}
        isDisabled={
          !communityGuidelinesAccepted || status !== USER_ME_STATUS.VERIFIED
        }
        btnIcon={<PostIcon />}
        action={() => navigate(PATHS.NEW_POST)}
      />
    </Box>
  );
};
