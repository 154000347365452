import { Box, LinearProgress, Typography } from "@mui/material";
import { ReactComponent as IconPoll } from "assets/svg/icon-poll-indicator.svg";
import React from "react";

import styles from "./Poll.module.scss";

const Poll = ({ data }) => {
  if (!data) return null;
  const { options, totalVotes } = data;

  const calculatePercentage = (votes) => {
    return votes && totalVotes > 0 ? Math.round((votes / totalVotes) * 100) : 0;
  };

  return (
    <Box className={styles.pollContainer}>
      {options.map((option, index) => (
        <Box
          key={index}
          className={`${styles.pollOption} ${
            totalVotes === 0 ? styles.noVote : ""
          }`}
        >
          <IconPoll className={styles.iconPoll} width={24} height={24} />
          <Box className={styles.optionVotes}>
            <Box className={styles.optionData}>
              <Typography variant="body2" className={styles.optionText}>
                {option.option}
              </Typography>
              {totalVotes > 0 ? (
                <Typography variant="body2" className={styles.percentageText}>
                  {`${calculatePercentage(option.votes)}%`}
                </Typography>
              ) : null}
            </Box>
            {totalVotes > 0 ? (
              <LinearProgress
                className={styles.progress}
                variant="determinate"
                value={calculatePercentage(option.votes)}
              />
            ) : null}
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default Poll;
