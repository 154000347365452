import { yupResolver } from "@hookform/resolvers/yup";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ReactComponent as ArrowBack } from "assets/svg/arrow-left.svg";
import { ReactComponent as LogoIcon } from "assets/svg/logo.svg";
import AuthLeftSide from "Components/AuthLeftSide";
import { MainAppDomain, PATHS } from "Constants/index";
import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setError } from "Redux/appSlice";
import { fetchUserMe, logout } from "Redux/usersSlice";
import { errorResponseMessages } from "Services/Api/apiErrorHelper.tsx";
import { sendVerifyCode, sendVerifyEmail } from "Services/Api/userApi";
import { LocalizationContext } from "Services/Localization/LocalizationContext";
import { useIsMobile } from "Utils/Hooks";
import * as Yup from "yup";

import stylesAuth from "../Login/Auth.module.scss";
import styles from "./EmailVerification.module.scss";

export const EmailVerification = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useContext(LocalizationContext);
  const isMobile = useIsMobile();
  const user = useSelector((state) => state.users.me.entities);

  const [loading, setLoading] = useState(false);

  const validationSchemaCode = Yup.object().shape({
    verificationCode: Yup.string()
      .trim()
      .required(t("errors.required"))
      .matches(/^[0-9]+$/, t("errors.invalidCode")),
  });

  const {
    register: registerCode,
    handleSubmit: handleSubmitCode,
    formState: { errors: errorsCode, isValid: isCodeValid },
    setError: setCodeError,
  } = useForm({
    resolver: yupResolver(validationSchemaCode),
    mode: "onChange",
    defaultValues: {
      verificationCode: "",
    },
  });

  const handleFromSubmit = (data) => {
    setLoading(true);
    sendVerifyCode({
      ...data,
      verificationCode: Number(data.verificationCode),
    }).then((res) => {
      setLoading(false);
      if (res?.data?.error) {
        setCodeError("verificationCode", {
          type: "custom",
          message: t("auth.errorCode"),
        });
      } else {
        dispatch(fetchUserMe()).then(() => navigate(PATHS.PROFILE));
      }
    });
  };

  const onVerify = () => {
    setLoading(true);
    sendVerifyEmail({ email: user.email })
      .catch((e) => {
        dispatch(
          setError({
            open: true,
            title: t("errors.error"),
            subtitle: errorResponseMessages(e, t),
          })
        );
      })
      .finally(() => setLoading(false));
  };

  const resendCode = () => {
    onVerify();
  };

  const goBack = () => {
    dispatch(logout());
    window.location.href = `${MainAppDomain}/login#creator`;
  };

  return (
    <Box className={stylesAuth.authContainer}>
      {!isMobile && <AuthLeftSide step={0} />}
      <Box className={stylesAuth.authRightSide}>
        <Box className={stylesAuth.authRightSideContent}>
          {!isMobile && (
            <IconButton
              onClick={goBack}
              className={styles.backBtn}
              sx={{ bgcolor: theme.palette.dark.surface }}
            >
              <ArrowBack width={40} height={40} />
            </IconButton>
          )}
          {isMobile ? (
            <Box className={styles.authLogoWrapper}>
              <IconButton onClick={goBack} sx={{ p: 0 }}>
                <LogoIcon className={styles.authLogo} />
              </IconButton>
            </Box>
          ) : null}
          <Typography
            variant="h3"
            component="h1"
            className={`${stylesAuth.authTitle} ${styles.title}`}
          >
            {t("auth.verifyingYourEmailAddress")}
          </Typography>
          <Typography variant="body1" className={styles.description}>
            {t("auth.verifyEmailDescription", { email: user?.email })}
          </Typography>
          <Box className={styles.form}>
            <form
              onSubmit={handleSubmitCode(handleFromSubmit)}
              autoComplete="off"
            >
              <FormControl className={styles.formControlInput}>
                <TextField
                  autoFocus
                  placeholder={"XXXXXX"}
                  label={t("auth.verificationCode")}
                  {...registerCode("verificationCode")}
                  error={!!errorsCode.verificationCode}
                  autoComplete="off"
                  className={styles.input}
                />
                <Typography variant="error">
                  {errorsCode.verificationCode?.message}
                </Typography>
                <Typography variant="body1" className={styles.sendCode} mt={2}>
                  {t("auth.didntReceiveCode")}&nbsp;&nbsp;
                  <Button
                    disabled={loading}
                    onClick={resendCode}
                    variant="text"
                    className={styles.sendCodeBtn}
                  >
                    {t("auth.resend")}
                  </Button>
                </Typography>
              </FormControl>
              <FormControl>
                <LoadingButton
                  type="submit"
                  variant="outlined"
                  className={styles.btnContinue}
                  loading={loading}
                  disabled={!isCodeValid}
                >
                  {t("auth.continueBtn")}
                </LoadingButton>
              </FormControl>
            </form>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
