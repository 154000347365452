import { centerCrop, makeAspectCrop } from "react-image-crop";

export const centerAspectCrop = ({
  mediaWidth,
  mediaHeight,
  aspect,
  width,
  minWidth,
}) => {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width,
        minWidth,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
};
