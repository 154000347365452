import { Box, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import { styled } from "@mui/material/styles";
import { ReactComponent as Icon } from "assets/svg/error-icon.svg";
import { lazy, Suspense, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setError } from "Redux/appSlice";
import { LocalizationContext } from "Services/Localization/LocalizationContext";

import styles from "./ErrorModal.module.scss";

const BootstrapDialog = styled(Dialog)(({ theme }) => {
  return {
    "& .MuiDialog-paper": {
      background: theme.palette.dark?.main,
      borderRadius: "10px",
      maxWidth: "420px",
    },
  };
});
const ErrorModal = ({ ...rest }) => {
  const { t } = useContext(LocalizationContext);
  const error = useSelector((state) => state.app.error);
  const dispatch = useDispatch();
  const onClose = () => {
    dispatch(setError({ open: false }));
  };
  const Actions = lazy(() => {
    return import(`./Actions/${error.actions}.jsx`).catch(() =>
      import("./Actions/DefaultDlgAction")
    );
  });
  if (!error?.open) return null;

  return (
    <BootstrapDialog
      onClose={onClose}
      open={error?.open}
      {...rest}
      classes={{
        paper: "errorDlgPaper",
        container: "errorDlgContainer",
        root: "errorDlg",
      }}
    >
      <Box className={styles.container}>
        <Box className={styles.iconWrapper}>
          <Icon />
        </Box>
        <Box>
          <Typography className={styles.title} variant="body4" component="h5">
            {error.title || t("errors.errorTitle")}
          </Typography>
          <Typography className={styles.subtitle} variant="body1" component="p">
            {error.subtitle || t("errors.unknownError")}
          </Typography>
        </Box>
        <Box className={styles.actions}>
          <Suspense fallback={<></>}>
            <Actions payload={error} />
          </Suspense>
        </Box>
      </Box>
    </BootstrapDialog>
  );
};

export default ErrorModal;
