import { Box, Button, Typography } from "@mui/material";
import { ReactComponent as CalendarEditIcon } from "assets/svg/calendar-edit-icon.svg";
import { PATHS } from "Constants/index";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getScheduledCalls } from "Services/Api/limitedEditionApi";
import { LocalizationContext } from "Services/Localization/LocalizationContext";
import { getConvertedTimeZoneDate } from "Utils/index";

import { useShowWithDelay } from "../../../../Utils/Hooks/useShowWithDelay";
import styles from "./ScheduledButton.module.scss";
export const ScheduledButton = ({ rewardOrderId, isShow }) => {
  const { t } = useContext(LocalizationContext);
  const navigate = useNavigate();

  const [calls, setCalls] = useState([]);
  // const [isConfirmOpen, setConfirmOpen] = useState(false);

  const join = () => {
    if (currentCall?.extMeetingId) {
      navigate(
        PATHS.MEETING_ID.replace(":meetingId", currentCall.extMeetingId)
      );
    }
  };

  // const confirm = () => {
  //   if (isAbleToEdit) {
  //     setConfirmOpen(true);
  //   }
  // };

  const getCurrentCall = ({ calls }) => {
    if (calls.length) {
      return calls.reduce((acc, current) => {
        acc =
          new Date(acc.date).getTime() < new Date(current.date).getTime()
            ? acc
            : current;
        return acc;
      }, calls[0]);
    }
  };

  const currentCall = getCurrentCall({ calls });
  const startTimeCall = currentCall?.date;

  const isShowJoinButton = useShowWithDelay({
    checkDelay: 2500,
    targetDate: startTimeCall,
    minutes: new Date().getTime() < new Date(startTimeCall) ? 1 : 15,
  });

  // const isAbleToEdit = startTimeCall
  //   ? new Date(startTimeCall) - new Date() > 24 * 60 * 60 * 1000
  //   : false; // 24 hours
  const isAbleToEdit = false;

  useEffect(() => {
    if (rewardOrderId) {
      getScheduledCalls({ orderId: rewardOrderId }).then((res) => {
        if (res?.calls) {
          setCalls(res.calls);
        }
      });
    }
  }, []);

  if (!startTimeCall || !isShow) {
    return <></>;
  }

  return (
    <Box className={styles.scheduled}>
      <Button
        variant="contained"
        className={`${styles.scheduledButton} ${styles.scheduledButtonState2} ${
          isAbleToEdit ? styles.scheduledButtonState2Edit : ""
        }`}
        // onClick={confirm}
        style={{ cursor: isAbleToEdit ? "pointer" : "initial" }}
      >
        {t("messages.scheduledState2")}
        <Typography variant="body1">
          {getConvertedTimeZoneDate(startTimeCall, "MMM dd, yyyy HH:mm")}
        </Typography>
        {isAbleToEdit ? <CalendarEditIcon /> : null}
      </Button>
      {isShowJoinButton ? (
        <Button
          variant="contained"
          className={styles.scheduledButton}
          onClick={join}
        >
          {t("messages.scheduledState3")}
        </Button>
      ) : null}
    </Box>
  );
};
