import { Box, Button, Typography } from "@mui/material";
import { useContext } from "react";
import { useDispatch } from "react-redux";
import { setActiveChat } from "Redux/messengerSlice";
import { LocalizationContext } from "Services/Localization/LocalizationContext";

import styles from "./EmptyMessengerContent.module.scss";

export const EmptyMessengerContent = () => {
  const { t } = useContext(LocalizationContext);
  const dispatch = useDispatch();

  return (
    <Box className={styles.wrapper}>
      <Typography variant="h5" className={styles.emptyTitle}>
        {t("messages.emptyTitle")}
      </Typography>
      <Button
        variant="contained"
        className={styles.emptyButton}
        onClick={() => dispatch(setActiveChat({ isNewMessageTo: true }))}
      >
        {t("messages.newMessage")}
      </Button>
    </Box>
  );
};
