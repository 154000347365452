import { Box } from "@mui/material";
import Countdown from "Components/Countdown";
import {
  differenceInSeconds,
  formatDuration,
  intervalToDuration,
  isValid,
} from "date-fns";
import React, { useContext, useEffect, useRef, useState } from "react";
import { LocalizationContext } from "Services/Localization/LocalizationContext";
import { v4 as uuidv4 } from "uuid";

import styles from "./CountdownDate.module.scss";
import TimeItem from "./TimeItem";

const DISTANCE = {
  xSeconds: "xSeconds",
  xMinutes: "xMinutes",
  xHours: "xHours",
  xDays: "xDays",
};

const CountdownDate = ({
  children,
  endDate,
  timerFormat,
  className,
  onEnd,
  isFull,
  divider,
}) => {
  const { timerLocalizeFull, timerLocalize: localize } =
    useContext(LocalizationContext);
  const formatDistanceLocale = {
    [DISTANCE.xSeconds]: `{{count}}${localize.seconds}`,
    [DISTANCE.xMinutes]: `{{count}}${localize.minutes}`,
    [DISTANCE.xHours]: `{{count}}${localize.hours}`,
    [DISTANCE.xDays]: `{{count}}${localize.days}`,
  };

  const locale = {
    formatDistance: (token, count) => {
      if (isFull) return count;
      if (token === DISTANCE.xDays && count === 0) return "";

      return formatDistanceLocale[token].replace("{{count}}", count);
    },
  };

  const getFormattedValue = () => {
    const start =
      differenceInSeconds(new Date(endDate), new Date()) > 0
        ? new Date(endDate)
        : new Date();

    return formatDuration(
      intervalToDuration({
        start: new Date(),
        end: start,
      }),
      {
        zero: true,
        format: ["days", "hours", "minutes", "seconds"],
        locale,
        ...(timerFormat && timerFormat),
      }
    );
  };

  const onSetTimer = () => {
    setSecondsLeft(differenceInSeconds(new Date(endDate), new Date()));
    setTimeFormatted(getFormattedValue);

    if (onEnd && differenceInSeconds(new Date(endDate), new Date()) <= 0) {
      onEnd();
      setIsEnd(true);
    }
  };
  const [secondsLeft, setSecondsLeft] = useState(
    differenceInSeconds(new Date(endDate), new Date())
  );
  const [timeFormatted, setTimeFormatted] = useState(getFormattedValue);
  const [isEnd, setIsEnd] = useState(false);
  const firstUpdate = useRef(true);

  useEffect(() => {
    let isSubscribed = true;
    isSubscribed &&
      setIsEnd(differenceInSeconds(new Date(endDate), new Date()) <= 0);

    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    if (isSubscribed) {
      onSetTimer();
    }
    return () => (isSubscribed = false);
  }, [endDate]);

  if (isFull) {
    const timeItemsArray = timeFormatted.split(" ");
    const checkDivider = (index) => index !== timeItemsArray.length - 1;

    return (
      <Countdown
        value={secondsLeft}
        onSetValue={onSetTimer}
        className={className}
      >
        <Box className={styles.timeItemsBlock}>
          {timeItemsArray.map((t, i) => (
            <TimeItem
              key={uuidv4()}
              value={t}
              label={Object.values(timerLocalizeFull).reverse().at(i)}
              hasDivider={checkDivider(i)}
            />
          ))}
        </Box>
      </Countdown>
    );
  }

  if (isEnd) return <>- : -</>;

  const getTimeValue = () => {
    if (divider) {
      const formatArr = timeFormatted.split(" ");
      return formatArr.map((item, index) => (
        <React.Fragment key={item}>
          <Box component="span" className="countdown-date-item">
            {item}
            {index < formatArr.length - 1 && item ? divider : null}
          </Box>
        </React.Fragment>
      ));
    } else {
      return timeFormatted;
    }
  };

  return (
    <Countdown
      value={secondsLeft}
      onSetValue={onSetTimer}
      className={className}
    >
      {getTimeValue()}
      {children}
    </Countdown>
  );
};
const validDate = (props, propName, componentName) => {
  if (!isValid(new Date(props[propName])))
    return new Error(
      `Invalid prop ${propName} passed to ${componentName}. Expected a valid Date.`
    );
};
CountdownDate.propTypes = {
  endDate: validDate,
};

export default CountdownDate;
