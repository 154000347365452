import { Typography } from "@mui/material";
import React from "react";

import styles from "./Growth.module.scss";

const Growth = ({
  value,
  percentage = "",
  desc = "",
  className = "",
  arrowClassName = "",
}) => {
  const arrowClass = value > 0 ? styles.up : value < 0 ? styles.down : "";
  const valueClass =
    value > 0 ? styles.positive : value < 0 ? styles.negative : "";
  const combinedClassName = `${styles.diff} ${valueClass} ${className}`.trim();
  const combinedArrowClassName =
    `${styles.arrow} ${arrowClass} ${arrowClassName}`.trim();

  const displayValue = value > 0 ? `+${value}` : value?.toString();
  const percentageDisplay = percentage ? ` (${percentage}%)` : "";

  return (
    <Typography variant="body2" component="span" className={combinedClassName}>
      {`${displayValue}${percentageDisplay} `}
      {arrowClass && <span className={combinedArrowClassName}></span>}
      {desc && ` ${desc}`}
    </Typography>
  );
};

export default Growth;
