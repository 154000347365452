import { useTheme } from "@emotion/react";
import { Box, Typography } from "@mui/material";
import { ReactComponent as EditIcon } from "assets/svg/edit-icon.svg";
import { ReactComponent as RemoveIcon } from "assets/svg/icon-delete.svg";
import React, { useContext } from "react";
import { LocalizationContext } from "Services/Localization/LocalizationContext";

import styles from "../../ActionTooltip.module.scss";

export const TooltipContent = ({ editHandler, deleteHandler, isMyComment }) => {
  const theme = useTheme();
  const { t } = useContext(LocalizationContext);

  return (
    <>
      {isMyComment ? (
        <Box
          className={`${styles.tooltipRow} ${styles.tooltipRowEdit}`}
          onClick={editHandler}
        >
          <EditIcon />
          <Typography variant="body2">{t("community.editComment")}</Typography>
        </Box>
      ) : null}
      <Box className={styles.tooltipRow} onClick={deleteHandler}>
        <RemoveIcon />
        <Typography
          variant="body2"
          style={{
            color: theme.palette.error.main,
          }}
        >
          {t("community.deleteComment")}
        </Typography>
      </Box>
    </>
  );
};
