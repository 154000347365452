import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  IconButton,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { ReactComponent as DatePickerIcon } from "assets/svg/datapiker-icon.svg";
import { ReactComponent as CloseReply } from "assets/svg/reply-close-icon.svg";
import { format } from "date-fns";
import React, { forwardRef, useContext, useState } from "react";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import { setModal } from "Redux/appSlice";
import { LocalizationContext } from "Services/Localization/LocalizationContext";
import { getConvertedTimeZoneDate, getTimeRange } from "Utils/index";

import { ClockIcon } from "../../icons";
import styles from "./ScheduleModal.module.scss";
import { getClosestTimeSlot, getPostTimer } from "./utils";

export const ScheduleModal = () => {
  const { t } = useContext(LocalizationContext);
  const dispatch = useDispatch();
  const payload = useSelector((state) => state.app.modal?.payload) || {};
  const handlerFunc = useSelector((state) => state.app.modal?.handler);

  const timeSlots = getTimeRange({ intervalMinutes: 30 });
  const closestTimeSlot = getClosestTimeSlot({ timeSlots });

  const defaultStartDate = payload?.scheduledAt
    ? new Date(payload.scheduledAt).getTime()
    : payload.customStartDate
    ? new Date(payload.customStartDate).getTime()
    : new Date().getTime();

  const defaultTimeValue = {
    label: payload?.scheduledAt
      ? format(new Date(payload.scheduledAt), "HH:mm")
      : payload.customStartDate
      ? format(new Date(payload.customStartDate), "HH:mm")
      : closestTimeSlot?.label,
    value: payload?.scheduledAt
      ? new Date(payload.scheduledAt).getTime()
      : payload.customStartDate
      ? new Date(payload.customStartDate).getTime()
      : closestTimeSlot?.value,
  };

  const [startDate, setStartDate] = useState(defaultStartDate);
  const [time, setTime] = useState(defaultTimeValue);
  const [loader, setLoader] = useState(false);

  const postTimeEndValue = getPostTimer({
    date: new Date(startDate).setHours(
      new Date(time.value).getHours(),
      new Date(time.value).getMinutes()
    ),
  });

  const closeHandler = () => {
    dispatch(setModal({ open: false, type: null }));
  };

  const changeTimeHandler = (_, { props }) => {
    const { label, value } = props || {};
    setTime({ label, value });
  };

  const currentDate = payload.delay
    ? new Date(new Date().getTime() + payload.delay)
    : new Date();

  const futureDate = new Date(startDate);
  const isCurrentDay =
    currentDate.getDate() === futureDate.getDate() &&
    currentDate.getMonth() === futureDate.getMonth() &&
    currentDate.getFullYear() === futureDate.getFullYear();

  const setSchedulePostTime = ({ startDate, time }) => {
    const date = new Date(startDate);
    date.setHours(
      new Date(time.value).getHours(),
      new Date(time.value).getMinutes()
    );
    if (!handlerFunc) {
      return;
    }
    setLoader(true);
    handlerFunc({ ...payload, scheduledAt: date.getTime() }, setLoader);
  };

  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <Box className={styles.inputBox} onClick={onClick} ref={ref}>
      <Box className={`${styles.icon} ${styles.iconPicker}`}>
        <DatePickerIcon />
      </Box>
      <Typography
        variant="body2"
        component="p"
        className={styles.inputBoxLabel}
      >
        {t("default.date")}
      </Typography>
      <Typography variant="body1_medium">
        {getConvertedTimeZoneDate(new Date(value))}
      </Typography>
    </Box>
  ));

  return (
    <Box className={styles.wrapper} open={true}>
      <IconButton onClick={closeHandler} className={styles.close}>
        <CloseReply />
      </IconButton>
      <Typography variant="h4_24" className={styles.title} component="p">
        {payload?.mainTitle ? payload?.mainTitle : t("default.schedulePost")}
      </Typography>
      <Typography variant="body2" className={styles.subTitle}>
        {payload?.subTitle
          ? payload?.subTitle
          : t("default.scheduledPostModalDescription")}
      </Typography>
      <DatePicker
        selected={startDate}
        onChange={(date) => setStartDate(date)}
        customInput={<CustomInput />}
        calendarClassName={`scheduled-calendar ${styles.calendar}`}
        minDate={
          payload?.calendarStartDay
            ? new Date(payload.calendarStartDay)
            : new Date()
        }
      />
      <Box className={styles.inputBox}>
        <Box className={styles.icon}>
          <ClockIcon />
        </Box>
        <Typography
          variant="body2"
          component="p"
          className={styles.inputBoxLabel}
        >
          {t("default.time")}
        </Typography>
        <Typography variant="body1_medium" className={styles.inputBoxValue}>
          {time.label}
        </Typography>
        <Select
          className={styles.select}
          IconComponent={() => null}
          MenuProps={{
            classes: {
              paper: styles.selectMenu,
            },
          }}
          value={time.value}
          onChange={changeTimeHandler}
        >
          {timeSlots.map(({ value, label, disabled }) => (
            <MenuItem
              value={value}
              label={label}
              classes={{
                root: styles.menuItemRoot,
                selected: styles.menuItemRootSelected,
              }}
              disabled={disabled && isCurrentDay}
              key={value}
            >
              <Typography variant="body1" className={styles.menuItemLabel}>
                {label}
              </Typography>
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Typography
        variant="body2"
        component="p"
        className={styles.publishedInfo}
      >
        {t(
          payload?.sheduleModalPublichedTitle
            ? payload.sheduleModalPublichedTitle
            : "default.sheduleModalPublichedTitle",
          {
            day: `${postTimeEndValue.days}${t("default.daysAbbr")}`,
            hours: `${postTimeEndValue.hours}${t("default.hourAbbr")}`,
            min: `${postTimeEndValue.minutes}${t("default.minAbbr")}`,
          }
        )}
      </Typography>
      <Box className={styles.actions}>
        <Button onClick={closeHandler} variant="outlined" size="small">
          <Typography>{t("default.cancel")}</Typography>
        </Button>
        <LoadingButton
          variant="contained"
          onClick={() => setSchedulePostTime({ startDate, time })}
          loading={loader}
          disabled={loader}
        >
          <Typography>{t("default.schedule")}</Typography>
        </LoadingButton>
      </Box>
    </Box>
  );
};
