import { Box, CircularProgress, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { ChatWidgetModal } from "Components/Modal/components/ChatWidgetModal/ChatWidgetModal";
import {
  MODAL_TYPE,
  PATHS,
  REWARD_ORDER_STATUS,
  REWARDS_ACTION_TYPE,
} from "Constants/index";
import DOMPurify from "dompurify";
import React, { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setModal } from "Redux/appSlice";
import { patchLimitedEditionStatus } from "Redux/limitedEditionSlice";
import { setSelectedUser } from "Redux/messengerSlice";
import { LocalizationContext } from "Services/Localization/LocalizationContext";
import { getRewardStatus, getRewardType, rewardFormatDate } from "Utils/index";
import { goToMainAppProfile } from "Utils/mainAppUtils";

import { RowMobile } from "./components/RowMobile/RowMobile";
import { RowTooltip } from "./components/RowTooltip/RowTooltip";
import styles from "./RewardsTable.module.scss";

export const Row = ({
  appUser,
  reward,
  lastMessage,
  status,
  index,
  id,
  cardId,
  ...rest
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const { t } = useContext(LocalizationContext);
  const isMobile = useMediaQuery("(max-width:1100px)");

  const [isOpenCustomTooltip, setOpenCustomTooltip] = useState(false);
  const [isOpenChatWidget, setOpenChatWidget] = useState(false);
  const [loader, setLoader] = useState(false);

  const goToMessenger = () => {
    dispatch(setSelectedUser(appUser));
    navigate({
      pathname: PATHS.CHAT_DIRECT.replace(":userId", appUser.id),
    });
  };

  const goToProfile = (e) => {
    e.stopPropagation();
    if (appUser?.isInfluencer) {
      const link = appUser.username
        ? `${appUser.username}`
        : `discover/influencer/${appUser.id}`;
      goToMainAppProfile({
        pathname: link,
      });
    } else {
      goToMainAppProfile({
        pathname: `public/user/${appUser.username}`,
      });
    }
  };

  const reportHandler = () => {
    setOpenCustomTooltip(false);
    dispatch(
      setModal({
        open: true,
        type: MODAL_TYPE.REPORT_MODAL,
        payload: {
          recordId: appUser.id,
          contentType: "user",
        },
      })
    );
  };

  const statusHandler = (status) => () => {
    setLoader(true);
    setOpenCustomTooltip(false);
    dispatch(
      patchLimitedEditionStatus({ cardId, orderId: id, status, index })
    ).finally(() => {
      setLoader(false);
      setOpenCustomTooltip(false);
    });
  };

  const openMessageHandler = (e) => {
    if (e.target.closest(".global-row-tooltip-class")) {
      return;
    }
    if (reward === REWARDS_ACTION_TYPE.PREMIUM_CHAT) {
      goToMessenger();
    }
    if (
      reward === REWARDS_ACTION_TYPE.FOLLOW_BACK ||
      reward === REWARDS_ACTION_TYPE.VIDEO_CALL
    ) {
      setOpenChatWidget(true);
    }
  };

  const statusColor =
    status === REWARD_ORDER_STATUS.DELIVERED
      ? theme.palette.secondary.opacity
      : status === REWARD_ORDER_STATUS.FAILED ||
        status === REWARD_ORDER_STATUS.OPEN ||
        status === REWARD_ORDER_STATUS.AWAITING ||
        (reward === REWARDS_ACTION_TYPE.VIDEO_CALL &&
          rest?.call?.date &&
          (status === REWARD_ORDER_STATUS.AWAITING ||
            status === REWARD_ORDER_STATUS.OPEN ||
            status === REWARD_ORDER_STATUS.PENDING_REPLY))
      ? theme.palette.error.main
      : theme.palette.secondary.main;

  const isShowStatusDate =
    reward === REWARDS_ACTION_TYPE.VIDEO_CALL &&
    (status === REWARD_ORDER_STATUS.AWAITING ||
      status === REWARD_ORDER_STATUS.OPEN ||
      status === REWARD_ORDER_STATUS.PENDING_REPLY) &&
    rest?.call?.date;

  return (
    <>
      {isMobile ? (
        <RowMobile
          appUser={appUser}
          reward={reward}
          lastMessage={lastMessage}
          status={status}
          goToProfile={goToProfile}
          reportHandler={reportHandler}
          setOpenCustomTooltip={setOpenCustomTooltip}
          isOpenCustomTooltip={isOpenCustomTooltip}
          statusColor={statusColor}
          clickHandler={openMessageHandler}
          statusHandler={() =>
            status === REWARD_ORDER_STATUS.DELIVERED
              ? lastMessage?.isIncoming
                ? statusHandler(REWARD_ORDER_STATUS.AWAITING)
                : statusHandler(REWARD_ORDER_STATUS.PENDING_REPLY)
              : statusHandler(REWARD_ORDER_STATUS.DELIVERED)
          }
          loading={loader}
          isShowStatusDate={isShowStatusDate}
          {...rest}
        />
      ) : (
        <Box className={styles.rowBodyWrapper}>
          {loader ? (
            <Box className={styles.loader}>
              <CircularProgress size={24} />
            </Box>
          ) : null}
          <Box className={`${styles.row} ${styles.rowBody}`}>
            <Box className={styles.col}>
              <Typography variant="body18" className={styles.colTitle}>
                {appUser?.username}
              </Typography>
            </Box>
            <Box className={styles.col}>
              <Typography variant="body18" className={styles.colTitle}>
                {getRewardType({ t, value: reward })}
              </Typography>
            </Box>
            <Box className={styles.col}>
              <Typography
                variant="body18"
                className={styles.colTitle}
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(lastMessage?.messageContent, {
                    USE_PROFILES: { html: true },
                  }),
                }}
              />
            </Box>
            <Box className={`${styles.col} ${styles.colStatus}`}>
              <Typography
                variant="body18"
                className={styles.colTitle}
                style={{ color: statusColor }}
              >
                {isShowStatusDate
                  ? rewardFormatDate(rest.call.date, t)
                  : getRewardStatus({ t, value: status })}
              </Typography>
              <Box className={styles.colHiddenContent}>
                <Typography
                  onClick={openMessageHandler}
                  component="p"
                  className={styles.button}
                >
                  {t("default.openMessage")}
                </Typography>
              </Box>
              <RowTooltip
                reward={reward}
                status={status}
                setOpenCustomTooltip={setOpenCustomTooltip}
                isOpenCustomTooltip={isOpenCustomTooltip}
                reportHandler={reportHandler}
                goToProfile={goToProfile}
                deliveredHandler={
                  status === REWARD_ORDER_STATUS.DELIVERED
                    ? lastMessage?.isIncoming
                      ? statusHandler(REWARD_ORDER_STATUS.AWAITING)
                      : statusHandler(REWARD_ORDER_STATUS.PENDING_REPLY)
                    : statusHandler(REWARD_ORDER_STATUS.DELIVERED)
                }
                classNameIconTooltip={styles.iconTooltip}
              />
            </Box>
          </Box>
        </Box>
      )}
      <ChatWidgetModal
        isOpen={isOpenChatWidget}
        setOpen={setOpenChatWidget}
        appUser={appUser}
        orderId={id}
        cardId={cardId}
        reward={reward}
        index={index}
        status={status}
      />
    </>
  );
};
