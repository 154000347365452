export const getDays = () => {
  const arr = [];
  for (let i = 1; i <= 31; i++) {
    arr.push(i);
  }
  return arr;
};

export const getYears = () => {
  const arr = [];
  for (let i = 1900; i <= 2050; i++) {
    arr.push(i);
  }
  return arr;
};

export const getMonth = ({ t }) => [
  t("celebform.january"),
  t("celebform.february"),
  t("celebform.march"),
  t("celebform.april"),
  t("celebform.may"),
  t("celebform.june"),
  t("celebform.july"),
  t("celebform.august"),
  t("celebform.september"),
  t("celebform.october"),
  t("celebform.november"),
  t("celebform.december"),
];

export const getSocMediaList = ({ t }) => [
  t("celebform.instagram"),
  t("celebform.twitter"),
  t("celebform.twitch"),
  t("celebform.youTube"),
  t("celebform.tikTok"),
  t("celebform.facebook"),
  t("celebform.other"),
];

export const parseDateString = ({ month, day, year }) => {
  const date = new Date(`${month}/${day}/${year}`);

  if (isNaN(date.getTime())) {
    // Check if date is invalid
    return false;
  }
  const now = new Date();
  const maxAge = 120; // Set maximum age to 120 years
  const minAge = 18; // Set minimum age to 18 years
  const minDate = new Date(
    now.getFullYear() - maxAge,
    now.getMonth(),
    now.getDate()
  );
  const maxDate = new Date(
    now.getFullYear() - minAge,
    now.getMonth(),
    now.getDate()
  );
  return !(date < minDate || date > maxDate);
};
