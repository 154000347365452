import { Box } from "@mui/material";
import classNames from "classnames/bind";
import { PLAYING_FEED_VIDEO } from "Constants/index";
import { throttle } from "lodash";
import React, { useEffect, useLayoutEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setActiveMedia } from "Redux/mediaSlice";
import { BigPlayButton, Player } from "video-react";

import styles from "./VideoBlock.module.scss";

export const VideoBlock = ({
  videos,
  overrideStyles,
  fluid = false,
  postIndex,
  mediaIndex,
}) => {
  const dispatch = useDispatch();
  const refVideoWrapper = useRef();
  const refVideo = useRef();

  const mediaParams = useSelector((state) => state.media.mediaParams);

  const cx = classNames.bind(styles);

  const videoHandler = () => {
    dispatch(setActiveMedia({ postIndex, mediaIndex: 0 }));
    sessionStorage.setItem(PLAYING_FEED_VIDEO, postIndex);
  };

  const setVideoContainerStyles = () => {
    // these styles need to prevent jumping
    if (refVideoWrapper.current?.style) {
      refVideoWrapper.current.style.position = "relative";
      refVideoWrapper.current.style.opacity = 1;
      refVideoWrapper.current.style.zIndex = 0;
    }
  };

  const setVideoParams = (video) => {
    if (video) {
      const space = 150;
      const visibleHeight = window.innerHeight;
      function setVideoSize() {
        if (video.readyState >= 3) {
          const videoHeight = video.offsetHeight - space; // need some space below the video for the content
          if (videoHeight > visibleHeight) {
            video.style.height = `${visibleHeight - space}px`;
            video.style.width = "auto";
          }
          setVideoContainerStyles();
        }
      }
      video.removeEventListener("canplay", setVideoSize);
      video.addEventListener("canplay", setVideoSize);
    }
  };

  const pause = () => {
    refVideo.current.pause();
  };

  useLayoutEffect(() => {
    if (refVideoWrapper.current && refVideo.current) {
      const video = refVideoWrapper.current.getElementsByTagName("video")[0];
      setVideoParams(video);
    }
  }, [refVideo]);

  useEffect(() => {
    if (
      (postIndex !== mediaParams.postIndex ||
        mediaIndex !== mediaParams.mediaIndex) &&
      refVideo.current
    ) {
      pause();
    }
  }, [mediaIndex, mediaParams.mediaIndex, mediaParams.postIndex, postIndex]);

  const handleScroll = throttle(() => {
    const videoElement = refVideo.current?.video?.props?.video;
    if (!videoElement) return;

    const { top, bottom } = videoElement.getBoundingClientRect();
    const isInViewport =
      top + (bottom - top) * 0.4 >= 0 &&
      top + (bottom - top) * 0.6 <= window.innerHeight;

    const postVideoIndex = sessionStorage.getItem(PLAYING_FEED_VIDEO);

    if (!isInViewport && !videoElement.paused && postVideoIndex) {
      videoElement.pause();
      sessionStorage.removeItem(PLAYING_FEED_VIDEO);
    } else if (isInViewport && videoElement.paused && !postVideoIndex) {
      videoHandler();
      videoElement.play();
    }

    if (+postVideoIndex === +postIndex && !isInViewport) {
      sessionStorage.removeItem(PLAYING_FEED_VIDEO);
    }
  }, 500);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { capture: true });
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  if (!videos?.length) {
    return null;
  }

  return (
    <Box
      className={cx(
        styles.videoWrapper,
        overrideStyles?.videoWrapper,
        "meta-video"
      )}
      ref={refVideoWrapper}
      onClick={videoHandler}
    >
      <Player
        fluid={fluid}
        height="100%"
        width="100%"
        playsInline
        ref={refVideo}
        loop
        muted
        key={videos[0]}
      >
        <source src={`${videos[0]}#t=0.001`} />
        <BigPlayButton position="center" className={styles.playButton}>
          <BigPlayButton />
        </BigPlayButton>
      </Player>
    </Box>
  );
};
