import { LoadingButton } from "@mui/lab";
import { Box, Skeleton, Typography } from "@mui/material";
import { DropDown } from "Components/DropDown/DropDown";
import TimeAgo from "Components/TimeAgo";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { LocalizationContext } from "Services/Localization/LocalizationContext";

import { MainAppDomain } from "../../../../Constants";
import { EmptyOrders } from "./components/EmptyOrders/EmptyOrders";
import styles from "./OrderHistory.module.scss";

const OrderHistory = ({ data, loading, page, isNext, handler }) => {
  const { t } = useContext(LocalizationContext);
  const navigate = useNavigate();

  if (!data?.length && !loading) {
    return <></>;
  }

  if (loading && page === 1) {
    return (
      <Skeleton
        variant="rectangular"
        style={{ borderRadius: "12px", height: "80px" }}
      />
    );
  }

  return (
    <DropDown
      title={t("default.orderHistory")}
      className={`${data?.length ? "" : styles.empty}`}
    >
      <Box className={styles.list}>
        {data.length ? (
          data.map((item) => (
            <Box key={item?.id} className={styles.listItem}>
              <Typography
                variant="h6"
                component="p"
                className={`fontDisplay ${styles.username}`}
                onClick={() =>
                  window.open(
                    `${MainAppDomain}/public/user/${item?.appUser?.username}`,
                    "_blank"
                  )
                }
              >
                {item?.appUser?.username}
              </Typography>
              <Typography
                variant="h6"
                component="span"
                className="fontDisplay"
                textAlign="right"
              >
                <TimeAgo value={item?.date} />
              </Typography>
            </Box>
          ))
        ) : (
          <EmptyOrders />
        )}
        {isNext ? (
          <LoadingButton
            variant="outlined"
            size="small"
            loading={loading}
            className={styles.loadMoreBtn}
            onClick={handler}
          >
            {t("community.loadMore")}
          </LoadingButton>
        ) : null}
      </Box>
    </DropDown>
  );
};
export default OrderHistory;
