import { Box } from "@mui/material";
import { MainAppDomain, PATHS } from "Constants/index";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setAuthProcess, setLoader } from "Redux/appSlice";
import { getAuthProcess } from "Redux/selectors/appSelector";
import { getEmulateId, isAuthenticated } from "Services/Auth/auth.service";

import styles from "./Auth.module.scss";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isAuthProcess = useSelector(getAuthProcess);

  useEffect(() => {
    if (!isAuthProcess) {
      if (!isAuthenticated()) {
        window.location.href = `${MainAppDomain}/login#creator`;
      } else {
        dispatch(setLoader(false));
        dispatch(setAuthProcess(false));
        const path = getEmulateId() ? PATHS.PROFILE : PATHS.HOME;
        navigate(path);
      }
    }
  }, [isAuthProcess]);

  return <Box className={styles.authContainer} />;
};
export default Login;
