import { Box, Typography } from "@mui/material";
import { ReactComponent as ArrowUpIcon } from "assets/svg/arrow-up.svg";
import DOMPurify from "dompurify";
import React, { useContext, useState } from "react";
import { LocalizationContext } from "Services/Localization/LocalizationContext";

import { SeasonSlider } from "../SeasonSlider/SeasonSlider";
import styles from "./SeasonItem.module.scss";

const LocalArrowDownIcon = () => (
  <svg
    width="17"
    height="9"
    viewBox="0 0 17 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 0.66927L8.49213 7.33594L1 0.66927"
      stroke="white"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
  </svg>
);

export const SeasonItem = ({
  season,
  seasonCards,
  isDefaultActive = false,
}) => {
  const { t } = useContext(LocalizationContext);

  const [isActive, setActive] = useState(isDefaultActive);

  const toggleActive = () => setActive((prevState) => !prevState);

  const Slider = () =>
    seasonCards?.length ? <SeasonSlider seasonCards={seasonCards} /> : null;

  const SeasonDescription = () =>
    season.description ? (
      <Typography
        variant="body2"
        className={styles.seasonDescription}
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(season.description, {
            USE_PROFILES: { html: true },
          }),
        }}
      />
    ) : null;

  return (
    <Box className={styles.season}>
      <Box className={styles.seasonHeader} onClick={toggleActive}>
        <Typography variant="body1" component="p">
          {`${t("seasons.season")} ${season.seasonNum}${
            season.title ? " - " + season.title : ""
          }`}
        </Typography>
        {isActive ? <ArrowUpIcon /> : <LocalArrowDownIcon />}
      </Box>
      {isActive ? (
        <Box className={styles.seasonContent}>
          <SeasonDescription />
          <Slider />
        </Box>
      ) : null}
    </Box>
  );
};
