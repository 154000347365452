export const UploadIcon = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.63623 5.41227L8.99987 1.04553L13.3635 5.41227"
      stroke="white"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M9 1L9 13.3724"
      stroke="white"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M13.9231 12.6333H17V15.6805C17 16.4074 15.6954 17 14.0954 17H3.90462C2.30462 17 1 16.4074 1 15.6805V12.6333H4.07692"
      stroke="white"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
  </svg>
);
