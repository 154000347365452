import { Button } from "@mui/material";
import MediaResetPostModal from "Components/PostCreator/components/ResetModal/MediaResetPostModal";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { LocalizationContext } from "Services/Localization/LocalizationContext";

import { useIsMobile } from "../../../../Utils/Hooks";
import { FILE_TYPES, handleChangeFile } from "../../utils";
import styles from "./AddMediaButton.module.scss";

export const AddMediaButton = ({
  files,
  setFiles,
  setErrorType,
  className = "",
  isActive,
  icon,
  isAnotherMediaType,
  handleMediaChange,
  setShouldHideTooltips,
}) => {
  const isMobile = useIsMobile();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalConfirmed, setIsModalConfirmed] = useState(false);
  const dispatch = useDispatch();
  const { t } = useContext(LocalizationContext);
  const fileInputRef = useRef(null);

  useEffect(() => {
    setShouldHideTooltips && setShouldHideTooltips(isModalOpen);
  }, [isModalOpen]);

  const triggerFileInput = () => {
    fileInputRef.current.click();
  };

  const handleModalConfirmation = () => {
    setIsModalConfirmed(true);
    triggerFileInput();
  };

  const handleConfirmationModal = () => {
    if (isAnotherMediaType) {
      setIsModalOpen(true);
    } else {
      setIsModalConfirmed(true);
      triggerFileInput();
    }
  };

  const handleChangeFileWrapper = (event) => {
    if (isModalConfirmed) {
      handleChangeFile({
        dispatch,
        t,
        setFiles,
        filesInState: files,
        setErrorType,
      })(event);
      handleMediaChange && handleMediaChange();
    }
  };

  return (
    <>
      <MediaResetPostModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        title={t("community.alertReplaceYtbVideoTitle")}
        description={t("community.alertReplaceYtbVideoDesc")}
        handleModalConfirmation={handleModalConfirmation}
      />
      <Button
        variant="contained"
        size="small"
        startIcon={icon}
        className={`${className} ${styles.addPhotoBtn} ${
          isActive ? styles.addPhotoBtnActive : ""
        }`}
        onClick={handleConfirmationModal}
      >
        {isMobile ? t("community.media") : t("community.addMedia")}
      </Button>
      <input
        ref={fileInputRef}
        type="file"
        multiple
        accept={FILE_TYPES}
        hidden
        onChange={handleChangeFileWrapper}
      />
    </>
  );
};
