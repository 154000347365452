import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { POST_STATUS } from "Constants/index";
import { useContext } from "react";
import { LocalizationContext } from "Services/Localization/LocalizationContext";

import { InfoIcon } from "./icons";
import styles from "./StatusSection.module.scss";

export const StatusSection = ({
  status,
  approveHandler,
  rejectHandler,
  loading,
}) => {
  const { t } = useContext(LocalizationContext);
  const theme = useTheme();

  if (status !== POST_STATUS.PENDING_REVIEW) {
    return null;
  }

  return (
    <Box className={styles.statusSection}>
      {loading ? (
        <Box className={styles.loaderBlock}>
          <CircularProgress />
        </Box>
      ) : null}
      <Box className={styles.iconWrapper}>
        <InfoIcon />
      </Box>
      <Box>
        <Box className={styles.row}>
          <Typography variant="body1" className={styles.label}>
            {t("default.status")}
          </Typography>
          <Button
            style={{
              background: theme?.palette?.lime?.main,
              color: theme?.palette?.dark?.main,
            }}
          >
            {t("default.statusPendingReview")}
          </Button>
        </Box>
        <Typography variant="body1" className={styles.statusDescription}>
          {t("default.statusPendingReviewDescription")}
        </Typography>
        <Box className={styles.actionWrapper}>
          <Button
            variant="outlined"
            size="small"
            style={{ borderColor: theme?.palette?.error?.main }}
            onClick={rejectHandler}
          >
            <Typography
              variant="body4_16"
              style={{ color: theme?.palette?.error?.main }}
            >
              {t("default.reject")}
            </Typography>
          </Button>
          <Button variant="outlined" size="small" onClick={approveHandler}>
            <Typography variant="body4_16">{t("default.approve")}</Typography>
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
