import { Box, Button, CircularProgress } from "@mui/material";
import { ReactComponent as Send } from "assets/svg/icon-send.svg";
import Editor from "Components/QuillEditor/Editor";
import { CHATS_TYPE, FULL_COMMENT_TEXT_LIMIT } from "Constants/index";
import React, { lazy, useContext, useRef } from "react";
import { useDispatch } from "react-redux";
import { setSnackbar } from "Redux/appSlice";
import { LocalizationContext } from "Services/Localization/LocalizationContext";
import { isMobileDevice } from "Utils/index";

import styles from "./Footer.module.scss";

const EmojiPicker = lazy(() =>
  import("Components/EmojiPicker/EmojiPicker.jsx")
);

export const Footer = ({ submitHandler, activeChatId, chatsType }) => {
  const dispatch = useDispatch();
  const isPhone = isMobileDevice();
  const reactQuillRef = useRef(null);
  const { t } = useContext(LocalizationContext);

  const submit = () => {
    const txtLength = reactQuillRef.current?.value?.length;
    if (txtLength > FULL_COMMENT_TEXT_LIMIT) {
      dispatch(
        setSnackbar({
          open: true,
          message: t("errors.maxCharacters", { count: 4000 }),
          severity: "error",
        })
      );
      return;
    }
    if (activeChatId) {
      if (reactQuillRef.current.editor?.getText().trim()?.length) {
        submitHandler({ messageContent: reactQuillRef.current.value });
        reactQuillRef.current.editor.setText("");
      }
    }
  };

  const keyDownHandler = (e) => {
    if (e.key === "Enter" && !e.shiftKey && !isPhone) {
      e.preventDefault();
      submit();
    }
  };

  const onEmojiClick = ({ emoji }) => {
    const quill = reactQuillRef.current;
    const editor = quill.getEditor();
    const range = editor.getSelection();
    const position = range ? range.index : editor.getLength() - 1;
    editor.insertText(position, emoji);
  };

  return (
    <Box
      className={`${styles.footer} ${
        chatsType === CHATS_TYPE.REQUESTS ? styles.footerRequests : ""
      }`}
    >
      <Box
        className={`${styles.footerMain} ${
          isPhone ? styles.footerMainNonSmile : null
        }`}
      >
        {!isPhone ? (
          <React.Suspense
            fallback={
              <Box>
                <CircularProgress size={18} />
              </Box>
            }
          >
            <EmojiPicker
              onEmojiClick={onEmojiClick}
              className={!activeChatId ? styles.nonActive : ""}
            />
          </React.Suspense>
        ) : null}
        <Editor
          reactQuillRef={reactQuillRef}
          customFormats={[]}
          editorToolbarId={"toolbar-chat"}
          className={`${styles.editor} ${isPhone ? styles.editorMobile : ""}`}
          placeholder={t("messages.writeMessage")}
          onKeyDown={keyDownHandler}
          readOnly={!activeChatId}
        />
        {/*<Button variant="text">*/}
        {/*  <AssetsIcon />*/}
        {/*</Button>*/}
      </Box>
      <Button
        variant="contained"
        className={styles.sendBtn}
        onClick={submit}
        disabled={!activeChatId}
      >
        <Send />
      </Button>
    </Box>
  );
};
