export const IconClose = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 1L7 7M13 13L7 7M7 7L13 1M7 7L1 13"
      stroke="#ABB3F3"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const ErrorInfo = () => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 19.5C14.9706 19.5 19 15.4706 19 10.5C19 5.52947 14.9706 1.50003 10 1.50003C5.02944 1.50003 1 5.52947 1 10.5C1 15.4706 5.02944 19.5 10 19.5Z"
      stroke="#EE5261"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M10 5.50003V13.09"
      stroke="#EE5261"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M10 14.93V14.72"
      stroke="#EE5261"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const UploadIcon = () => (
  <svg
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.13623 5.91225L9.49987 1.54552L13.8635 5.91225"
      stroke="white"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M9.5 1.5L9.5 13.8724"
      stroke="white"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M14.4231 13.1332H17.5V16.1805C17.5 16.9073 16.1954 17.5 14.5954 17.5H4.40462C2.80462 17.5 1.5 16.9073 1.5 16.1805V13.1332H4.57692"
      stroke="white"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
  </svg>
);
