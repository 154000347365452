import { LoadingButton } from "@mui/lab";
import { Checkbox, FormControlLabel } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React, { useContext, useState } from "react";
import { signTerms } from "Services/Api/api.service";
import { LocalizationContext } from "Services/Localization/LocalizationContext";

import styles from "./LicenseBlock.module.scss";

export const LicenseBlock = ({ success, data }) => {
  const [termsRead, setTermsRead] = useState(0);
  const [termsAgree, setTermsAgree] = useState(0);
  const [policyAgree, setPolicyAgree] = useState(0);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const { t } = useContext(LocalizationContext);

  const onTermsRead = () => {
    setTermsRead(termsRead ? 0 : 1);
  };

  const onTermsAgree = () => {
    setTermsAgree(termsAgree ? 0 : 1);
  };

  const onPolicyAgree = () => {
    setPolicyAgree(policyAgree ? 0 : 1);
  };

  const onSubmit = async () => {
    try {
      setLoading(true);
      const params = {
        ...data,
        ...{ termsRead, termsAgree, type: "INFLUENCER" },
      };
      await signTerms(params);
      setLoading(false);
      success();
    } catch (e) {
      setError(e?.response?.data?.message || t("errors.defaultError"));
      setLoading(false);
    }
  };

  return (
    <Box className={styles.license}>
      <Typography variant="h3" component="h3" className={styles.title}>
        {t("agreement.title")}
      </Typography>
      <Box>
        <Typography variant="body-2">
          <div
            dangerouslySetInnerHTML={{ __html: t("agreement.agreementText") }}
          />
        </Typography>
        <Box className={styles.checkLabel}>
          <FormControlLabel
            control={<Checkbox onChange={onTermsRead} checked={!!termsRead} />}
            label={
              <Typography variant="body3">{t("agreement.isRead")}</Typography>
            }
          />
        </Box>
        <Box className={styles.checkLabel}>
          <FormControlLabel
            control={
              <Checkbox onChange={onPolicyAgree} checked={!!policyAgree} />
            }
            label={
              <Typography variant="body3">
                {t("agreement.agreeTo")}
                <a
                  className={styles.link}
                  href="https://policy.zoopcards.com/en/privacy-policy"
                  target="_blank"
                  rel="noreferrer"
                >
                  {t("agreement.privacyPolicy")}
                </a>{" "}
                {t("agreement.and")}{" "}
                <a
                  className={styles.link}
                  href="https://policy.zoopcards.com/en/terms-of-service"
                  target="_blank"
                  rel="noreferrer"
                >
                  {t("agreement.terms")}
                </a>
              </Typography>
            }
          />
        </Box>
        <Box className={[styles.checkLabel, styles.last]}>
          <FormControlLabel
            control={
              <Checkbox onChange={onTermsAgree} checked={!!termsAgree} />
            }
            label={
              <Typography variant="body3">{t("agreement.isAgree")}</Typography>
            }
          />
        </Box>
        <Box mt="20px">
          <Typography variant="error">{error}</Typography>
        </Box>
        <LoadingButton
          loading={loading}
          className={styles.btn}
          onClick={onSubmit}
          disabled={!termsAgree || !termsRead || !policyAgree}
        >
          {t("agreement.send")}
        </LoadingButton>
      </Box>
    </Box>
  );
};
