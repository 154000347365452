import { useTheme } from "@emotion/react";
import { Button, Typography } from "@mui/material";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { LocalizationContext } from "Services/Localization/LocalizationContext";

import styles from "./ViewAll.module.scss";

export const ViewAll = ({ path, handler, text }) => {
  const { t } = useContext(LocalizationContext);

  const navigate = useNavigate();
  const theme = useTheme();

  const clickHandler = () => {
    if (handler) {
      return handler;
    }
    if (path) {
      return () => navigate(path);
    }
  };

  return (
    <Button onClick={clickHandler()} size="small" className={styles.btn}>
      <Typography
        variant="body3_medium_14_display_2"
        style={{ color: theme.palette.accent.mistyLilak }}
      >
        {text ? text : t("default.viewAll")}
      </Typography>
    </Button>
  );
};
