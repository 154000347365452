import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Button, TextareaAutosize, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ReactComponent as CheckedIcon } from "assets/svg/checked-icon.svg";
import { FULL_COMMENT_TEXT_LIMIT } from "Constants/index";
import React, { useContext, useRef } from "react";
import { LocalizationContext } from "Services/Localization/LocalizationContext";

import styles from "../CommentEditBlock.module.scss";

export const EditBlock = ({
  isEditMode,
  editHandler,
  message,
  setEditMode,
}) => {
  const { t } = useContext(LocalizationContext);
  const textareaRef = useRef(null);

  const theme = useTheme();

  if (!isEditMode) {
    return null;
  }

  return (
    <Box className={styles.editBlock}>
      <TextareaAutosize
        minRows={1}
        maxRows={9}
        defaultValue={message}
        ref={textareaRef}
        maxLength={FULL_COMMENT_TEXT_LIMIT}
      />
      <Box className={styles.editBlockActions}>
        <Button onClick={() => setEditMode(false)} variant="outlined">
          <Typography component="span" variant="subtitle1">
            {t("default.cancel")}
          </Typography>
        </Button>
        <LoadingButton
          variant="contained"
          style={{ background: theme.palette.chart.fifth }}
          startIcon={<CheckedIcon />}
          onClick={() => editHandler({ message: textareaRef.current.value })}
        >
          <Typography
            component="span"
            variant="subtitle1"
            color={(theme) => theme.palette.common.black}
          >
            {t("default.save")}
          </Typography>
        </LoadingButton>
      </Box>
    </Box>
  );
};
