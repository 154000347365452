import { Box, IconButton, Typography } from "@mui/material";
import { ReactComponent as DeliveredIcon } from "assets/svg/delivered-icon.svg";
import { ReactComponent as DotsEditIcon } from "assets/svg/dots-edit-icon.svg";
import { ReactComponent as ProfileIcon } from "assets/svg/pofile-icon.svg";
import { ReactComponent as ReportIcon } from "assets/svg/report-icon.svg";
import { CustomTooltip } from "Components/CustomTooltip/CustomTooltip";
import { REWARD_ORDER_STATUS, REWARDS_ACTION_TYPE } from "Constants/index";
import React, { useContext } from "react";
import { LocalizationContext } from "Services/Localization/LocalizationContext";

import styles from "./RowTooltip.module.scss";

export const RowTooltip = ({
  reward,
  status,
  goToProfile,
  reportHandler,
  setOpenCustomTooltip,
  isOpenCustomTooltip,
  classNameIconTooltip,
  deliveredHandler,
}) => {
  const { t } = useContext(LocalizationContext);

  return (
    <CustomTooltip
      title={
        <Box className={"global-row-tooltip-class"}>
          {reward !== REWARDS_ACTION_TYPE.PREMIUM_CHAT ? (
            <Box className={styles.tooltipRow} onClick={deliveredHandler}>
              <DeliveredIcon />
              <Typography variant="body2">
                {status === REWARD_ORDER_STATUS.DELIVERED
                  ? t("default.markAsUnDelivered")
                  : t("default.markAsDelivered")}
              </Typography>
            </Box>
          ) : null}
          <Box className={styles.tooltipRow} onClick={goToProfile}>
            <ProfileIcon />
            <Typography variant="body2">{t("default.viewProfile")}</Typography>
          </Box>
          <Box className={styles.tooltipRow} onClick={reportHandler}>
            <ReportIcon />
            <Typography variant="body2">{t("default.report")}</Typography>
          </Box>
        </Box>
      }
      isClick={true}
      setOpen={setOpenCustomTooltip}
      open={isOpenCustomTooltip}
      placement="bottom"
    >
      <IconButton
        className={`${styles.iconTooltip} ${classNameIconTooltip} global-row-tooltip-class`}
      >
        <DotsEditIcon />
      </IconButton>
    </CustomTooltip>
  );
};
