import { Box, Button, Typography } from "@mui/material";
import { MODAL_TYPE, PATHS } from "Constants/index";
import React, { useContext } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setError, setModal, setSnackbar } from "Redux/appSlice";
import { errorResponseMessages } from "Services/Api/apiErrorHelper.tsx";
import { getKycStatus, getOnfidoToken } from "Services/Api/mainAppApi";
import { LocalizationContext } from "Services/Localization/LocalizationContext";
import styles from "Views/KYC/KYC.module.scss";

export const CheckKycStatus = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useContext(LocalizationContext);

  const getTokenOnfidoKyc = async () => {
    try {
      const sdkToken = await getOnfidoToken();
      return sdkToken;
    } catch (e) {
      dispatch(
        setSnackbar({
          open: true,
          message: errorResponseMessages(e, t),
          severity: "error",
        })
      );
    }
  };

  const onGetKycStatus = async () => {
    try {
      return await getKycStatus();
    } catch (e) {
      return null;
    }
  };

  const denyStatusTexts = {
    title: t("kyc.verifaicationFailed"),
    body: t("kyc.applicationFailedBody"),
  };
  const inProgressStatusTexts = {
    title: t("kyc.inReview"),
    body: t("kyc.inReviewBody"),
  };

  const checkStatus = async ({ hideModals = false } = {}) => {
    const res = await onGetKycStatus();
    if (
      res?.data?.state === "DENY" ||
      res?.data?.state === "IN_PROGRESS" ||
      res?.data?.state === "MANUAL_REVIEW"
    ) {
      const statusText =
        res?.data?.state === "DENY" ? denyStatusTexts : inProgressStatusTexts;
      return hideModals
        ? statusText
        : dispatch(
            setModal({
              type: MODAL_TYPE.MODAL,
              open: true,
              payload: {
                children: (
                  <Box className={[styles.modal, styles.error]}>
                    <Typography
                      variant="textMediumBold"
                      className={styles.modalTitle}
                    >
                      {statusText.title}
                    </Typography>
                    <Typography
                      variant="body1"
                      className={styles.modalSubtitle}
                    >
                      {statusText.body}
                    </Typography>
                    <Box className={styles.buttonContainer}>
                      <Button
                        variant="outlined"
                        onClick={() => {
                          dispatch(
                            setModal({
                              open: false,
                              payload: null,
                            })
                          );
                          navigate(PATHS.HOME);
                        }}
                      >
                        {t("kyc.goToHome")}
                      </Button>
                    </Box>
                  </Box>
                ),
              },
            })
          );
    }
    if (
      res?.data?.state === "NODATA" ||
      res?.data?.state === "REVERIFY" ||
      res?.data?.state === "REQUIRED" ||
      !res
    ) {
      const sdkToken = await getTokenOnfidoKyc();
      return { sdkToken };
    }
    return 1;
  };
  return checkStatus;
};
