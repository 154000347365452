import { api } from "Services/Api/api.service";
const API_URL = process.env.REACT_APP_API_HOST;

export const getSeasons = async (params) => {
  try {
    return api
      .get(`${API_URL}/me/season`, { ...params })
      .then((res) => res.data);
  } catch (error) {
    console.error(error);
  }
};

export const postSeasonPerks = async ({ seasonId, params }) => {
  try {
    return api
      .post(`${API_URL}/me/${seasonId}/perks`, { ...params })
      .then((res) => res.data);
  } catch (error) {
    console.error(error);
  }
};
