import { Avatar, Box, IconButton, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { ReactComponent as CommunityIcon } from "assets/svg/club-icon.svg";
import { ReactComponent as DotsEditIcon } from "assets/svg/dots-edit-icon.svg";
import { ReactComponent as EarningsIcon } from "assets/svg/earnings-icon.svg";
import { ReactComponent as InsightsIcon } from "assets/svg/insights-icon.svg";
import { ReactComponent as LimitedEditionIcon } from "assets/svg/limited-edition-icon.svg";
import { ReactComponent as Logo } from "assets/svg/logo.svg";
import { ReactComponent as MembersIcon } from "assets/svg/members-icon.svg";
import { ReactComponent as MessagesIcon } from "assets/svg/messages-icon.svg";
import { ReactComponent as SeasonsIcon } from "assets/svg/seasons-icon.svg";
import { ReactComponent as TransactionsIcon } from "assets/svg/transactions-icon.svg";
import ActiveLink from "Components/ActiveLink";
import SettingsMenu from "Components/SettingsMenu";
import {
  LEVEL_BIGGER,
  LEVEL_GROUP,
  LEVEL_SMALLER,
  MainAppDomain,
  PATHS,
  USER_ME_STATUS,
} from "Constants";
import React, { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getGlobalCountUnreadMessages } from "Redux/selectors/messengerSelectors";
import { logout } from "Redux/usersSlice";
import { LocalizationContext } from "Services/Localization/LocalizationContext";

import { getUsersMe } from "../../Redux/selectors/userSelector";
import { CustomTooltip } from "../CustomTooltip/CustomTooltip";
import styles from "./Sidebar.module.scss";

const Sidebar = () => {
  const { t } = useContext(LocalizationContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const userMe = useSelector(getUsersMe);
  const isSidebarVisible = useSelector((state) => state.app.isSidebarVisible);
  const mobileBP = 768;
  const mobileWidth = useMediaQuery(theme.breakpoints.down(mobileBP));
  const messagesCount = useSelector(getGlobalCountUnreadMessages);
  const userLevel = useSelector((state) => state.users.me.entities.level);

  const [isOpenCustomTooltip, setOpenCustomTooltip] = useState(false);

  // useEffect(() => {
  //   dispatch(setLoader(true));
  //   dispatch(fetchUserMe()).finally(() => dispatch(setLoader(false)));
  // }, []);

  const isProfilePage = location?.pathname === PATHS.PROFILE;
  const handleLogout = () => {
    dispatch(logout());
    window.location.href = `${MainAppDomain}/login#creator`;
  };

  const accountType =
    userMe?.level === LEVEL_GROUP
      ? t("account.groupAccount")
      : userMe?.level === LEVEL_BIGGER || userMe?.level === LEVEL_SMALLER
      ? t("account.individualAccount")
      : t("account.profileTmpType");

  if (mobileWidth) {
    return null;
  }

  return (
    <Box
      className={`${styles.sidebar} ${isSidebarVisible ? styles.opened : null}`}
    >
      <Box className={styles.sidebarContainer}>
        <Link to={PATHS.HOME} className={styles.logo}>
          <Logo />
        </Link>
        <Box
          className={`${styles.menu} ${
            userMe?.status === USER_ME_STATUS.NEW || !userLevel
              ? `${styles.menuDisable} prevent-action`
              : ""
          }`}
        >
          <ActiveLink
            to={PATHS.COMMUNITY}
            className={styles.menuItem}
            activeClass={styles.menuItemActive}
          >
            <CommunityIcon className={`${styles.clubIcon}`} />
            <span>{t("default.myClub")}</span>
          </ActiveLink>
          {/*{userMe?.level === LEVEL_BIGGER ? (*/}
          {/*  <ActiveLink*/}
          {/*    to={PATHS.SEASONS}*/}
          {/*    className={styles.menuItem}*/}
          {/*    activeClass={styles.menuItemActive}*/}
          {/*  >*/}
          {/*    <SeasonsIcon className={`${styles.seasonsIcon}`} />*/}
          {/*    <span>{t("default.seasons")}</span>*/}
          {/*  </ActiveLink>*/}
          {/*) : null}*/}
          {userMe?.level === LEVEL_SMALLER || userMe?.level === LEVEL_BIGGER ? (
            <ActiveLink
              to={PATHS.LIMITED_EDITION}
              className={styles.menuItem}
              activeClass={styles.menuItemActive}
            >
              <SeasonsIcon className={`${styles.seasonsIcon}`} />
              <span>{t("default.sales")}</span>
            </ActiveLink>
          ) : null}
          <ActiveLink
            to={PATHS.FOLLOWERS}
            className={styles.menuItem}
            activeClass={styles.menuItemActive}
          >
            <MembersIcon className={styles.icon} />
            <span>{t("community.members")}</span>
          </ActiveLink>
          <ActiveLink
            to={PATHS.CHAT}
            className={`${styles.menuItem} ${
              userMe?.status !== USER_ME_STATUS.VERIFIED
                ? `${styles.menuDisable} prevent-action`
                : ""
            }`}
            activeClass={styles.menuItemActive}
          >
            <Box
              className={`${styles.icon} ${
                messagesCount ? styles.iconMessages : ""
              }`}
            >
              <Box className={styles.iconData}>
                {messagesCount ? (
                  <Typography
                    variant="text_12_display_bold"
                    className={styles.messagesCount}
                  >
                    {messagesCount}
                  </Typography>
                ) : null}
                <MessagesIcon />
              </Box>
            </Box>
            <span>{t("default.messages")}</span>
          </ActiveLink>
          <ActiveLink
            to={PATHS.INSIGHTS}
            className={styles.menuItem}
            activeClass={styles.menuItemActive}
            matchEnd={false}
          >
            <InsightsIcon className={styles.insightsIcon} />
            <span>{t("default.insights")}</span>
          </ActiveLink>
          <ActiveLink
            to={PATHS.EARNINGS}
            className={styles.menuItem}
            activeClass={styles.menuItemActive}
          >
            <EarningsIcon className={`${styles.icon} ${styles.iconEarnings}`} />
            <span>{t("default.earnings")}</span>
          </ActiveLink>
          <ActiveLink
            to={PATHS.TRANSACTIONS}
            className={styles.menuItem}
            activeClass={styles.menuItemActive}
          >
            <TransactionsIcon className={styles.icon} />
            <span>{t("default.transactions")}</span>
          </ActiveLink>
          <SettingsMenu />
        </Box>
        <Box
          className={`${styles.profile} ${
            isProfilePage ? styles.profileActive : ""
          }`}
        >
          <Box
            onClick={() => navigate("/profile")}
            className={styles.profileActionBox}
          />
          <Avatar
            src={userMe?.profileImageUrl}
            className={styles.profilePicture}
          />
          <Box className={styles.profileTextBlock}>
            <Typography variant="h5" className={styles.profileName}>
              {userMe?.name}
            </Typography>
            <Typography variant="body2" className={styles.profileCat}>
              {accountType}
            </Typography>
          </Box>
          <CustomTooltip
            title={<Box onClick={handleLogout}>{t("default.logOut")}</Box>}
            tooltipClassName={styles.logoutTooltip}
            isClick={true}
            setOpen={setOpenCustomTooltip}
            open={isOpenCustomTooltip}
          >
            <IconButton className={styles.iconTooltip}>
              <DotsEditIcon />
            </IconButton>
          </CustomTooltip>
        </Box>
      </Box>
    </Box>
  );
};

export default Sidebar;
