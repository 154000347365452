import { Box, Button, Typography } from "@mui/material";
import { ReactComponent as AddPerkIcon } from "assets/svg/icon-add-perk-mobile.svg";
import { PATHS } from "Constants/index";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { LocalizationContext } from "Services/Localization/LocalizationContext";
import { v4 as uuidv4 } from "uuid";

import styles from "./Tier.module.scss";

export const Tier = ({ title, tierData, fansCount, status }) => {
  const navigate = useNavigate();
  const { t } = useContext(LocalizationContext);

  return (
    <Box className={styles.tier}>
      <Box className={styles.tierHeader}>
        <Box className={styles.tierHeaderBlock}>
          <Typography
            variant="body2"
            component="span"
            className={styles.tierHeaderTitle}
          >
            {title}
          </Typography>
          {status ? (
            <Typography
              variant="body3"
              component="span"
              className={styles.tierHeaderLabel}
            >
              {status}
            </Typography>
          ) : null}
        </Box>
        {/*{fansCount ? (*/}
        {/*  <Box className={styles.tierFans}>*/}
        {/*    <Typography*/}
        {/*      component="span"*/}
        {/*      variant="body4"*/}
        {/*      className={styles.tierFansAmount}*/}
        {/*    >*/}
        {/*      {fansCount}*/}
        {/*    </Typography>*/}
        {/*    <Typography*/}
        {/*      component="span"*/}
        {/*      variant="body1"*/}
        {/*      className={styles.tierFansTitle}*/}
        {/*    >*/}
        {/*      fans*/}
        {/*    </Typography>*/}
        {/*  </Box>*/}
        {/*) : null}*/}
      </Box>
      <Box className={styles.tierBody}>
        {tierData?.length ? (
          <ul>
            {tierData.map((item) => (
              <li key={uuidv4()}>
                <Typography variant="body1" component="p">
                  {item}
                </Typography>
              </li>
            ))}
          </ul>
        ) : (
          <Button
            className={styles.btn}
            variant="outlined"
            onClick={() => navigate(PATHS.SEASONS_REWARDS_EDIT)}
          >
            <AddPerkIcon />
            <Typography component="span" variant="body2">
              {t("seasons.addNewRewards")}
            </Typography>
          </Button>
        )}
      </Box>
    </Box>
  );
};
