import { Box, IconButton, Typography } from "@mui/material";
import { ReactComponent as IconReaction } from "assets/svg/icon-reaction.svg";
import { ReactComponent as IconLiked } from "assets/svg/liked.svg";
import { useContext, useState } from "react";

import { LocalizationContext } from "../../../../Services/Localization/LocalizationContext";
import styles from "./Likes.module.scss";

const Likes = ({ count, isLiked, onChange }) => {
  const { t } = useContext(LocalizationContext);
  const [isAnimating, setIsAnimating] = useState(false);

  const handleClick = () => {
    setIsAnimating(!isLiked);
    onChange(!isLiked);
  };
  return (
    <Box className={styles.like} onClick={handleClick}>
      <IconButton
        className={`${styles.btn} ${isAnimating ? styles.liked : ""}`}
      >
        {isLiked ? <IconLiked /> : <IconReaction />}
      </IconButton>

      <Typography variant="body2" className={styles.likeText}>
        {count > 0 && count}{" "}
        {t("community.likes", {
          count: count > 0 ? count : 1,
          useSingular: true,
        })}
      </Typography>
    </Box>
  );
};
export default Likes;
