export const FileIcon = () => (
  <svg
    width="31"
    height="30"
    viewBox="0 0 31 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="15.49" cy="15" r="14.5" fill="white" fillOpacity="0.2" />
    <circle cx="15.49" cy="15" r="14.5" fill="#191C38" fillOpacity="0.5" />
    <circle cx="15.49" cy="15" r="14.5" stroke="white" />
    <path
      d="M11.0017 11.5781V17.845C11.0017 19.0776 11.4681 20.2598 12.2982 21.1315C13.1283 22.0031 14.2542 22.4928 15.4282 22.4928C16.6022 22.4928 17.7281 22.0031 18.5582 21.1315C19.3884 20.2598 19.8547 19.0776 19.8547 17.845V10.0986C19.8547 9.27677 19.5438 8.48864 18.9904 7.90755C18.437 7.32645 17.6864 7 16.9037 7C16.1211 7 15.3705 7.32645 14.817 7.90755C14.2636 8.48864 13.9527 9.27677 13.9527 10.0986V17.2113C13.9527 17.4148 13.9909 17.6162 14.065 17.8042C14.1392 17.9922 14.2479 18.1629 14.3849 18.3068C14.5219 18.4507 14.6846 18.5648 14.8636 18.6427C15.0426 18.7205 15.2345 18.7606 15.4282 18.7606C15.8196 18.7606 16.1949 18.5974 16.4716 18.3068C16.7483 18.0163 16.9037 17.6222 16.9037 17.2113V11.6478"
      stroke="white"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const CrossIcon = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 1L6 6M11 11L6 6M6 6L11 1M6 6L1 11"
      stroke="white"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const AddMoreIcon = () => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.075 24.7451C19.7024 24.7451 25.075 19.3725 25.075 12.7451C25.075 6.1177 19.7024 0.745117 13.075 0.745117C6.44753 0.745117 1.07495 6.1177 1.07495 12.7451C1.07495 19.3725 6.44753 24.7451 13.075 24.7451Z"
      stroke="white"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M13.1836 7.50757V18.2012"
      stroke="white"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M7.16846 12.8545H19.1988"
      stroke="white"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
  </svg>
);

export const QuestionMarkIcon = () => (
  <svg
    width="14"
    height="13"
    viewBox="0 0 14 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.8">
      <path
        d="M7 12.5C10.3137 12.5 13 9.81371 13 6.5C13 3.18629 10.3137 0.5 7 0.5C3.68629 0.5 1 3.18629 1 6.5C1 9.81371 3.68629 12.5 7 12.5Z"
        stroke="#ABB3F3"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M4.98877 4.68035C4.98877 2.67491 8.98877 2.67491 8.98877 4.68035C8.98877 6.11281 7.17059 5.82632 7.17059 7.54527M7.17059 9.84294L7.17786 9.83663"
        stroke="#ABB3F3"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
