import Skeleton from "@mui/material/Skeleton";
import React from "react";

import styles from "./LoadingBar.module.scss";

const LoadingBar = ({ numberOfBars = 1 }) => {
  const skeletons = [...Array(numberOfBars)].map((_, i) => (
    <Skeleton
      key={i}
      variant="rectangular"
      className={styles.loadingBarSkeleton}
    />
  ));

  return <>{skeletons}</>;
};

export default LoadingBar;
