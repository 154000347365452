export const MembersIcon = () => (
  <svg
    width="31"
    height="30"
    viewBox="0 0 31 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="30.5003" height="30" rx="6" fill="#505AA9" fillOpacity="0.1" />
    <rect
      x="0.5"
      y="0.5"
      width="29.5003"
      height="29"
      rx="5.5"
      stroke="#505AA9"
      strokeOpacity="0.3"
    />
    <path
      d="M10.2336 14.3133C9.9649 14.5821 9.5917 14.7512 9.17869 14.7512C8.35267 14.7512 7.68589 14.0845 7.68589 13.2584C7.68589 12.4324 8.35267 11.7656 9.17869 11.7656C10.0047 11.7656 10.6715 12.4324 10.6715 13.2584M6.69068 18.2344C5.44668 14.7512 7.80531 16.244 9.17869 16.244C9.70446 16.244 10.3746 16.0252 10.9318 15.9506"
      stroke="#ABB3F3"
      strokeWidth="0.908145"
      strokeLinejoin="round"
    />
    <path
      d="M17.0324 13.9241C16.6114 14.3451 16.0266 14.6102 15.3795 14.6102C14.0853 14.6102 13.0406 13.5655 13.0406 12.2713C13.0406 10.9771 14.0853 9.93237 15.3795 9.93237C16.6737 9.93237 17.7184 10.9771 17.7184 12.2713M11.4813 20.0676C9.53224 14.6101 13.2277 16.949 15.3795 16.949C17.5313 16.949 21.2268 14.6101 19.2777 20.0676"
      stroke="#ABB3F3"
      strokeWidth="0.908145"
      strokeLinejoin="round"
    />
    <path
      d="M22.3758 14.3133C22.1071 14.5821 21.7339 14.7512 21.3209 14.7512C20.4949 14.7512 19.8281 14.0845 19.8281 13.2584C19.8281 12.4324 20.4949 11.7656 21.3209 11.7656C22.147 11.7656 22.8137 12.4324 22.8137 13.2584M23.8089 18.2344C25.0529 14.7512 22.6943 16.244 21.3209 16.244C20.9072 16.244 20.404 16.1085 19.9368 16.0144"
      stroke="#ABB3F3"
      strokeWidth="0.908145"
      strokeLinejoin="round"
    />
  </svg>
);
