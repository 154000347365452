import { yupResolver } from "@hookform/resolvers/yup";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  Typography,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { ReactComponent as IconBack } from "assets/svg/arrow-left.svg";
import Alert from "Components/ErrorAlert";
import CountriesJson from "Constants/Countries.json";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { fetchCountries, setError } from "Redux/appSlice";
import { fetchUser, updateUserProfile } from "Redux/mainAppUserSlice";
import { errorResponseMessages } from "Services/Api/apiErrorHelper.tsx";
import { LocalizationContext } from "Services/Localization/LocalizationContext";
import { useIsMobile } from "Utils/Hooks";
import * as Yup from "yup";

import styles from "./KYC.module.scss";

const Step1 = ({ handleNext, handleBack, setSelectedCountry, mainError }) => {
  const { t } = useContext(LocalizationContext);
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const countries = CountriesJson.Countries;
  const [activeStep, setStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const numberInputRef = useRef(null);
  const user = useSelector((state) => state.mainAppUser.entities);
  const userCountry = countries.find((c) => c.id === user?.countryId);
  const [country, setCountry] = useState(userCountry || "");
  const isUs = country?.id === 232;

  useEffect(() => {
    dispatch(fetchUser());
    dispatch(fetchCountries());
  }, []);

  const handleChangeCountry = (country) => {
    setCountry(country);
  };

  const onBack = () => {
    if (activeStep === 0) {
      handleBack();
    } else {
      setStep(activeStep - 1);
    }
  };
  const validationSchema = Yup.object().shape({
    ssn: Yup.string()
      .trim()
      .required(t("errors.required"))
      .test("len", t("errors.ssnMinLength"), (val) => val?.length === 4),
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
    defaultValues: {
      ssn: user?.ssn || "",
    },
  });

  const updateProfileRequest = (payload) => {
    setLoading(true);

    dispatch(updateUserProfile(payload))
      .unwrap()
      .then(() => {
        handleNext();
      })
      .catch((err) => {
        if (err?.status !== 401) {
          setTimeout(() => {
            dispatch(
              setError({
                open: true,
                title: t("errors.error"),
                subtitle: errorResponseMessages(err, t),
              })
            );
          }, 0);
        }
      })
      .finally(() => setLoading(false));
  };
  const selectUs = () => {
    const country = countries.find((c) => c.id === 232);
    setCountry(country);
    handleClickContinue(country);
  };
  const handleClickContinue = (country) => {
    setSelectedCountry(country);
    if (country?.id !== 232)
      updateProfileRequest({ countryId: country?.id, ssn: "" });
  };
  const onSsnSubmit = ({ ssn }) => {
    updateProfileRequest({ countryId: country?.id, ssn });
  };

  const ssnForm = () => {
    return (
      <Box className={styles.form}>
        <Divider
          color={null}
          sx={{ marginBottom: "20px", marginTop: "40px" }}
        />
        <form onSubmit={handleSubmit(onSsnSubmit)} autoComplete="off">
          <FormControl
            className={`${styles.inputSsnContainer} ${styles.formControlInput}`}
          >
            <label className={styles.label}>{t("kyc.lastDigitsSSN")}</label>
            <Input
              className={styles.inputNumber}
              classes={{ input: styles.inputSecurity }}
              autoFocus
              {...register("ssn")}
              error={errors.ssn ? true : false}
              inputProps={{
                ref: numberInputRef,
                onWheel: () => numberInputRef.current?.blur(),
                step: 1,
                min: 0,
                type: "number",
              }}
              placeholder="XXXX"
              startAdornment={
                <InputAdornment position="start">
                  <Typography
                    variant="h1"
                    component="p"
                    className={styles.inputSecurity}
                  >
                    XXXX-XX
                  </Typography>
                </InputAdornment>
              }
            />
            <Typography variant="error">{errors.ssn?.message}</Typography>
          </FormControl>
          {mainError && <Alert>{mainError}</Alert>}

          <FormControl>
            <LoadingButton
              type="submit"
              variant="outlined"
              className={styles.btnContinue}
              loading={loading}
              disabled={!isValid}
            >
              {t("kyc.continueButton")}
            </LoadingButton>
          </FormControl>
        </form>
      </Box>
    );
  };
  const renderStep = () => {
    switch (activeStep) {
      case 0: {
        return (
          <Box className={styles.citizen}>
            <Typography variant="h3" component="h1" className={styles.title}>
              {t("kyc.areYouAUSCitizen")}
            </Typography>
            <Box className={styles.buttonContainer}>
              <Button
                variant="outlined"
                className={country?.id === 232 ? styles.active : ""}
                onClick={selectUs}
                disabled={loading}
              >
                {t("default.yes")}
              </Button>
              <Button
                variant="outlined"
                disabled={loading}
                onClick={() => {
                  setStep(1);
                  setCountry("");
                }}
              >
                {t("default.no")}
              </Button>
            </Box>
            {isUs && ssnForm()}
          </Box>
        );
      }
      case 1: {
        return (
          <Box className={styles.country}>
            <Typography variant="h3" component="h1" className={styles.title}>
              {t("kyc.country")}
            </Typography>

            <Box className={styles.countrySelect}>
              <Autocomplete
                disabled={loading}
                loading={loading}
                disableClearable
                renderInput={(params) => (
                  <TextField
                    {...params}
                    autoFocus
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {loading ? (
                            <CircularProgress color="accent" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
                id="countries-input"
                className={styles.inputWrap}
                options={countries}
                getOptionLabel={(option) => option.name}
                onChange={(_ev, option) => {
                  handleChangeCountry(option);
                }}
                componentsProps={{
                  paper: {
                    sx: {
                      backgroundColor: "#21264e",
                      border: "none",
                      padding: "0",
                      marginTop: "8px",
                      borderRadius: "8px",
                    },
                  },
                }}
                renderOption={(props, option) => (
                  <Box component="li" {...props} className={styles.inputOption}>
                    {option.name}
                  </Box>
                )}
              />
              <Button
                variant="contained"
                disabled={loading || !country}
                className={styles.continueBtn}
                onClick={() => {
                  handleClickContinue(country);
                }}
              >
                {t("default.continueBtn")}
              </Button>
            </Box>
            {isUs && ssnForm()}
          </Box>
        );
      }
      default: {
        return null;
      }
    }
  };
  return (
    <>
      {!isMobile && (
        <IconButton className={styles.back} onClick={onBack}>
          <IconBack />
        </IconButton>
      )}
      {renderStep()}
    </>
  );
};

export default Step1;
