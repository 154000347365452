import { Box, Button } from "@mui/material";
import { ReactComponent as IconPlay } from "assets/svg/play-icon.svg";
import { VIDEO_DATA_ACTIVE, VIDEO_DATA_NOT_ACTIVE } from "Constants/index";
import React, { useRef, useState } from "react";

import styles from "./FileThumb.module.scss";
import { AudioIcon, CrossIcon, FileIcon } from "./icons";

export const FileThumb = ({
  file,
  handleDeleteFile,
  className,
  thumbClassName,
  isFake,
  clickHandler,
}) => {
  const videoContainerRef = useRef(null);
  const [isLoaded, setLoader] = useState(true);

  const revokeHandler = () => {
    URL.revokeObjectURL(file.preview);
    setLoader(false);
  };

  const loadClassName =
    isLoaded && file?.type.includes("image") ? styles.previewDefault : "";

  const setActiveVideoStyle =
    ({ videoContainer }) =>
    () => {
      videoContainer.dataset.active = "";
    };

  const toggleActiveMedia = (ref, selector) => () => {
    // ToDo: need to work with html node directly, because need to use controls
    const video = ref.current.querySelector(selector);
    if (video.paused) {
      ref.current.dataset.active = VIDEO_DATA_ACTIVE;
      video.play();
      video.addEventListener(
        "ended",
        setActiveVideoStyle({ videoContainer: ref.current })
      );
    } else {
      video.pause();
      ref.current.dataset.active = VIDEO_DATA_NOT_ACTIVE;
      video.removeEventListener(
        "ended",
        setActiveVideoStyle({ videoContainer: ref.current })
      );
    }
  };

  return (
    <Box
      className={`${styles.preview} ${className} ${loadClassName}`}
      onClickCapture={clickHandler}
    >
      {handleDeleteFile ? (
        <Button
          className={styles.previewDelete}
          onClick={handleDeleteFile(file)}
        >
          <CrossIcon />
        </Button>
      ) : null}
      {file.type.includes("image") ? (
        <img
          src={file.preview}
          onLoad={revokeHandler}
          alt="thumb"
          className={thumbClassName ? thumbClassName : ""}
        />
      ) : file.type.includes("video") ? (
        <Box
          className={`${styles.previewVideo} ${
            thumbClassName ? thumbClassName : ""
          }`}
          data-active
          ref={videoContainerRef}
          onClick={toggleActiveMedia(videoContainerRef, "video")}
        >
          <IconPlay />
          <video src={file.preview} onLoad={revokeHandler} playsInline />
        </Box>
      ) : file.type.includes("audio") ? (
        <Box
          className={`${styles.previewAudio} ${
            thumbClassName ? thumbClassName : ""
          }`}
          ref={videoContainerRef}
          onClick={toggleActiveMedia(videoContainerRef, "audio")}
        >
          <AudioIcon />
          <audio src={file.preview} onLoad={revokeHandler} />
        </Box>
      ) : (
        <Box className={styles.previewFile}>
          <FileIcon />
        </Box>
      )}
    </Box>
  );
};
