import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  IconButton,
  Typography,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import useMediaQuery from "@mui/material/useMediaQuery";
import { ReactComponent as AddPerkIcon } from "assets/svg/icon-add-perk-mobile.svg";
import { ReactComponent as BurgerIcon } from "assets/svg/icon-burger-menu.svg";
import { ReactComponent as CloseIcon } from "assets/svg/icon-close.svg";
import { ReactComponent as IconWarning } from "assets/svg/icon-warning.svg";
import SetupStripeWarningModal from "Components/Modal/SetupStripeWarningModal";
import Page from "Components/Page/Page";
import SidebarTablet from "Components/SidebarTablet/SidebarTablet";
import { SwitchIOS } from "Components/SwitchIOS/SwitchIOS";
import {
  LEVEL_GROUP,
  POST_IN_GROUPS,
  SUBSCRIPTIONS_PRICE,
  TUTORIALS_PROPS,
} from "Constants/index";
import React, { memo, useContext, useEffect, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { List } from "react-movable";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { setLoader, setSnackbar } from "Redux/appSlice";
import { getStripeAccount } from "Redux/communitySlice";
import {
  getSeenTutorials,
  getUserLevel,
  getUsersMeIsPaymentEnable,
  getUsersMeStripeData,
} from "Redux/selectors/userSelector";
import {
  changeUser,
  editCommunity,
  fetchUserMe,
  updateUser,
} from "Redux/usersSlice";
import { stripeLoginLink } from "Services/Api/communityApi";
import { LocalizationContext } from "Services/Localization/LocalizationContext";
import * as yup from "yup";

import { CustomAlert } from "../../Components/CustomAlert/CustomAlert";
// import { GroupSettings } from "./components/GroupSettings/GroupSettings";
import { SectionWrapper } from "./components/SectionWrapper/SectionWrapper";
import styles from "./EditCommunity.module.scss";

const EditCommunity = memo(() => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useContext(LocalizationContext);
  const isMobile = useMediaQuery("(max-width:768px)");

  const level = useSelector(getUserLevel);
  const isPaymentEnable = useSelector(getUsersMeIsPaymentEnable);
  const stripeData = useSelector(getUsersMeStripeData);
  const seenTutorials = useSelector(getSeenTutorials);

  const {
    description,
    perks,
    subscriptionPrice,
    isDonationEnabled,
    isSubscriptionReady,
  } = useSelector(
    (state) =>
      state.users.me?.entities?.community || {
        description: "",
        perks: null,
        subscriptionPrice: null,
        isDonationEnabled: false,
        isSubscriptionReady: false,
      }
  );

  const { paymentsEnabled, subscriptionsEnabled } = useSelector(
    (state) =>
      state.users.me?.entities || {
        paymentsEnabled: false,
        subscriptionsEnabled: false,
      }
  );

  const {
    details_submitted: isStripeOnboardCompleted,
    charges_enabled: isChargesEnabled,
  } = useSelector(
    (state) =>
      state.users.me?.entities?.stripeData || {
        charges_enabled: false,
        details_submitted: false,
      }
  );

  const [subscriptionType, setSubscriptionType] = useState(
    location.state?.subscriptionType || SUBSCRIPTIONS_PRICE.FREE
  );
  const [loading, setLoading] = useState(false);
  const [stripeUrl, setStripeUrl] = useState(null);
  const [isDonationLocked, setDonationLocked] = useState(false);
  const [isDonationActive, setDonationActive] = useState(false);
  const [stripeLoginLinkUrl, setStripeLoginLinkUrl] = useState(null);
  const [postingRights, setPostingRights] = useState(null);
  const [postsPendingReview, setPostsPendingReview] = useState(false);

  const schema = yup.object({
    ...(subscriptionType === SUBSCRIPTIONS_PRICE.PAID && {
      subscriptionPrice: yup
        .number()
        .transform((value, originalValue) => {
          return originalValue === "" ? undefined : value;
        })
        .min(1, t("community.priceMinErrorMsg"))
        .max(999999.99, t("community.priceErrorMsg", { price: "$999999.99" }))
        .typeError(t("community.priceMinErrorMsg"))
        .required(t("community.priceMinErrorMsg")),
    }),
    // ...(level === LEVEL_GROUP && { postingRights: yup.string().required() }),
    ...(level === LEVEL_GROUP &&
      postingRights !== POST_IN_GROUPS.ONLY_ME && {
        postsPendingReview: yup.boolean().notRequired(),
      }),
    description: yup.string().notRequired(),
    isDonationEnabled: yup.boolean().notRequired(),
    perks: yup
      .array()
      .of(yup.string().required().notOneOf([""], t("community.perksError"))),
  });

  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { fields, append, remove, move } = useFieldArray({
    control,
    name: "perks",
  });

  const inputStyle = {
    ".MuiOutlinedInput-root": {
      minHeight: "50px",
      alignItems: "flex-start",
      color: "#fff",
      fontSize: "18px",
      borderRadius: "10px",
      padding: "12px 14px",
      fontFamily: "ClashGrotesk-Regular, sans-serif",
    },
    ".MuiInputBase-input": {
      background: "transparent",
    },
    "&:hover, &:active": {
      ".MuiOutlinedInput-notchedOutline": {
        border: "1px solid rgba(80, 90, 169, 0.7) !important",
      },
    },
    ".MuiOutlinedInput-notchedOutline": {
      border: "1px solid rgba(80, 90, 169, 0.5)",
    },
    label: {
      color: "rgba(255,255,255,0.5)",
    },
  };

  const hideAlert = () => {
    const params = {
      seenTutorials: {
        ...(seenTutorials && seenTutorials),
        [TUTORIALS_PROPS.EDIT_COMMUNITY]: true,
      },
    };
    dispatch(changeUser(params));
    dispatch(updateUser(params));
  };

  useEffect(() => {
    if (!isSubscriptionReady) {
      setValue("isDonationEnabled", false);
      setDonationLocked(true);
      setDonationActive(false);
    } else if (isSubscriptionReady) {
      setDonationActive(isDonationEnabled);
      setValue("isDonationEnabled", isDonationEnabled);
      setDonationLocked(false);
    }
  }, [isDonationEnabled, isSubscriptionReady]);

  useEffect(() => {
    setValue("subscriptionPrice", subscriptionPrice || null);
    setValue("description", description || "");
    setValue("perks", perks || []);
    setValue("isDonationEnabled", isDonationActive);
  }, [description, perks, subscriptionPrice, isDonationActive]);

  useEffect(() => {
    dispatch(setLoader(true));
    dispatch(fetchUserMe()).finally(() => dispatch(setLoader(false)));
  }, []);

  useEffect(() => {
    if (subscriptionPrice) {
      setSubscriptionType(SUBSCRIPTIONS_PRICE.PAID);
    } else {
      setSubscriptionType(SUBSCRIPTIONS_PRICE.FREE);
    }
  }, [subscriptionPrice]);

  useEffect(() => {
    if (subscriptionType === SUBSCRIPTIONS_PRICE.FREE) {
      clearErrors("subscriptionPrice");
      setValue("subscriptionPrice", null);
    }
  }, [subscriptionType]);

  const onSubmit = (params) => {
    setLoading(true);

    let payload = {
      ...params,
      ...{ isSubscriptionSet: subscriptionType === SUBSCRIPTIONS_PRICE.PAID },
      // ...(level === LEVEL_GROUP && { postingRights: postingRights }),
      ...(level === LEVEL_GROUP &&
        postingRights !== POST_IN_GROUPS.ONLY_ME && {
          postsPendingReview: postsPendingReview,
        }),
    };
    return dispatch(editCommunity(payload))
      .unwrap()
      .then(() => {
        dispatch(
          setSnackbar({
            message: t("community.editedCommunitySuccess"),
            open: true,
            right: "20px",
            left: "unset",
          })
        );
      })
      .catch((e) => {
        dispatch(
          setSnackbar({
            message: e?.message || t("errors.defaultError"),
            open: true,
            autoHideDuration: null,
            severity: "error",
            right: "20px",
            left: "unset",
          })
        );
      })
      .finally(() => setLoading(false));
  };

  const donationUnlockHandler = () => {
    getStripeAccountLinkUrl(showConfigurePaymentWarning);
  };

  const getStripeAccountLinkUrl = (callback) => {
    dispatch(setLoader(true));
    dispatch(getStripeAccount())
      .unwrap()
      .then((res) => {
        if (res?.url) callback && callback(res?.url);
      })
      .catch((e) => {
        dispatch(
          setSnackbar({
            open: true,
            message: e?.message || t("errors.defaultError"),
            severity: "error",
            autoHideDuration: 3000,
          })
        );
      })
      .finally(() => dispatch(setLoader(false)));
  };

  const getStripeLoginLinkUrl = (callback) => {
    dispatch(setLoader(true));
    stripeLoginLink()
      .then((res) => {
        if (res?.url) callback && callback(res?.url);
      })
      .catch((e) => {
        dispatch(
          setSnackbar({
            open: true,
            message: e?.message || t("errors.defaultError"),
            severity: "error",
            autoHideDuration: 3000,
          })
        );
      })
      .finally(() => dispatch(setLoader(false)));
  };

  const showConfigurePaymentWarning = (url) => {
    setStripeUrl(url);
  };

  const openStripeAccountLinkUrl = (url) => {
    window.open(url);
  };

  const switchToPaid = (e) => {
    if (isStripeOnboardCompleted) {
      setSubscriptionType(e.target?.value);
    } else {
      getStripeAccountLinkUrl(showConfigurePaymentWarning);
    }
  };
  const onCancelPaid = () => {
    setStripeUrl(null);
    setSubscriptionType(SUBSCRIPTIONS_PRICE.FREE);
  };

  useEffect(() => {
    if (isPaymentEnable && stripeData?.capabilities?.transfers === "active") {
      getStripeLoginLinkUrl((url) => {
        setStripeLoginLinkUrl(url);
      });
    }
  }, [isPaymentEnable, stripeData]);

  return (
    <Page>
      <Box className={styles.titleWrap}>
        <SidebarTablet />
        <Typography variant="h2" className={styles.header}>
          {t("community.editTitle")}
        </Typography>
      </Box>
      <CustomAlert
        isShow={!seenTutorials?.[TUTORIALS_PROPS.EDIT_COMMUNITY]}
        title={t("community.settingAlertTitle")}
        onClose={hideAlert}
        severity="info"
        classNameWrapper={styles.topAlert}
      />
      <form
        className={styles.form}
        onSubmit={handleSubmit(onSubmit)}
        style={{
          marginTop: !seenTutorials?.[TUTORIALS_PROPS.EDIT_COMMUNITY]
            ? "24px"
            : isMobile
            ? "32px"
            : "44px",
        }}
      >
        {loading ? (
          <Box className={styles.loaderBox}>
            <CircularProgress />
          </Box>
        ) : null}
        <SectionWrapper title={t("community.descriptionCommunityTitle")}>
          <FormControl>
            <Typography
              className={`${styles.label} ${styles.labelDescription}`}
              variant="body4"
              component="p"
            >
              {t("community.descriptionTitle")}
            </Typography>
            <TextField
              multiline={true}
              {...register("description")}
              InputLabelProps={{ shrink: true }}
              placeholder={t("community.descriptionPlaceholder")}
              variant="outlined"
              maxRows={7}
              sx={inputStyle}
              className={styles.description}
            />
            <Typography variant="error">
              {errors?.description?.message}
            </Typography>
          </FormControl>
          <FormControl>
            <Typography
              variant="body4"
              className={`${styles.formTitle} ${styles.formTitleWith}`}
            >
              {t("community.perksTitle")}{" "}
              <Typography
                component="span"
                variant="body18"
                className="opacity_07"
              >
                ({t("default.optional")})
              </Typography>
            </Typography>

            <Typography variant="body2" className={styles.formSubtitle}>
              {t("community.perksSubtitle")}
            </Typography>
            <List
              onChange={(meta) => {
                move(meta.oldIndex, meta.newIndex);
              }}
              values={fields}
              renderList={({ children, props }) => (
                <ul {...props}>{children}</ul>
              )}
              renderItem={({ value, props: { key, ...newProps }, index }) => {
                return (
                  <React.Fragment key={value.id}>
                    <li {...newProps} className={styles.perkContainer}>
                      <Box className={styles.perkContent}>
                        <BurgerIcon className={styles.perkBurger} />
                        <Controller
                          name={`perks.${index}`}
                          control={control}
                          render={({ field }) => (
                            <input
                              className={styles.perkInput}
                              {...field}
                              placeholder={t("community.perksPlaceholder")}
                              autoFocus={true}
                            />
                          )}
                        />
                        <IconButton
                          className={styles.perkCloseBtn}
                          onClick={() => remove(index)}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Box>
                    </li>
                    <Typography variant="error" component="p">
                      {errors?.perks ? errors?.perks[index]?.message : ""}
                    </Typography>
                  </React.Fragment>
                );
              }}
            />
            <Box>
              {isMobile ? (
                <Button
                  className={styles.perkMobileBtn}
                  variant="outlined"
                  onClick={() => {
                    append("");
                  }}
                >
                  <AddPerkIcon /> {t("community.addMobileBtn")}
                </Button>
              ) : (
                <Button
                  className={styles.perkBtn}
                  variant="contained"
                  onClick={() => {
                    append("");
                  }}
                >
                  {t("community.perkBtn")}
                </Button>
              )}
            </Box>
          </FormControl>
        </SectionWrapper>
        {paymentsEnabled ? (
          <SectionWrapper title={t("community.paymentFeatures")}>
            {paymentsEnabled && isDonationLocked && isStripeOnboardCompleted ? (
              <Typography variant="body2" className={styles.pendingTitle}>
                {t("community.stripePendingReview")}
              </Typography>
            ) : paymentsEnabled && isDonationLocked ? (
              <Button
                className={styles.paymentAccBtn}
                onClick={donationUnlockHandler}
                variant="contained"
              >
                {t("default.paymentsSectionButton")}
              </Button>
            ) : null}
            {!isDonationLocked ? (
              <Box className={styles.paymentAcc}>
                <Button
                  variant="outlined"
                  onClick={() => openStripeAccountLinkUrl(stripeLoginLinkUrl)}
                  className={styles.paymentAccBtn}
                >
                  {t("community.configurePaymentBtn")}
                </Button>
                {!isChargesEnabled && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                    }}
                  >
                    <IconWarning />
                    <Typography variant="body1">
                      {t("community.chargesDisabledStatus")}
                    </Typography>
                  </Box>
                )}
              </Box>
            ) : null}
            <Box
              className={`${styles.paymentBlock} ${
                isDonationLocked ? styles.paymentBlockNonActive : ""
              }`}
            >
              <FormControl className={styles.tipLabelWrapper}>
                <Typography
                  className={styles.label}
                  variant="body4"
                  component="p"
                >
                  {t("default.donations")}
                </Typography>
                <Typography
                  className={styles.formSubtitle}
                  variant="body2"
                  component="p"
                >
                  {t("community.donationsSubtitle")}
                </Typography>
                <FormControlLabel
                  className={styles.donationLabel}
                  control={
                    <SwitchIOS
                      checked={isDonationActive}
                      onChange={() => {
                        setDonationActive((prevState) => !prevState);
                      }}
                      name="isDonationEnabled"
                      color="primary"
                    />
                  }
                  label={
                    <Typography
                      variant="body18"
                      className={`${styles.donationLabelText} ${styles.label}`}
                    >
                      {t("community.enableDonations")}
                    </Typography>
                  }
                />
              </FormControl>
              {subscriptionsEnabled ? (
                <Box
                  className={
                    !isSubscriptionReady ? styles.paymentBlockNonActive : ""
                  }
                >
                  <Typography
                    variant="body4"
                    component="p"
                    className={styles.label}
                  >
                    {t("community.priceTitle")}
                  </Typography>
                  <Typography variant="body2" className={styles.formSubtitle}>
                    {t("community.priceSubtitle")}
                  </Typography>
                  <FormControl className={styles.price}>
                    <Box className={styles.actionBtnWrapper}>
                      <Button
                        value={SUBSCRIPTIONS_PRICE.FREE}
                        className={`${styles.priceBtn} ${styles.actionBtn} ${
                          subscriptionType === SUBSCRIPTIONS_PRICE.FREE &&
                          styles.actionBtnSelected
                        }`}
                        onClick={(e) => {
                          setSubscriptionType(e.target?.value);
                          setValue("subscriptionPrice", null);
                        }}
                      >
                        {t("community.free")}
                      </Button>
                      <Button
                        value={SUBSCRIPTIONS_PRICE.PAID}
                        className={`${styles.priceBtn} ${styles.actionBtn} ${
                          subscriptionType === SUBSCRIPTIONS_PRICE.PAID &&
                          styles.actionBtnSelected
                        }`}
                        onClick={switchToPaid}
                      >
                        {t("community.paid")}
                      </Button>
                    </Box>
                    <Box
                      display={
                        subscriptionType === SUBSCRIPTIONS_PRICE.PAID
                          ? "flex"
                          : "none"
                      }
                      className={styles.priceInput}
                    >
                      <TextField
                        {...register("subscriptionPrice")}
                        placeholder={t("community.pricePlaceholder")}
                        label={t("community.pricePlaceholder")}
                        variant="outlined"
                        sx={inputStyle}
                        inputRef={register("subscriptionPrice").ref}
                        type="text"
                        className={
                          errors?.subscriptionPrice ? styles.inputError : ""
                        }
                        InputProps={{
                          startAdornment: "$",
                        }}
                        inputProps={{
                          step: 0.01,
                          min: 1,
                          onChange: (event) => {
                            const value = event.target.value.replace(/,/g, ".");
                            setValue("subscriptionPrice", value);
                          },
                          pattern: "^[0-9]*([.,][0-9]+)?$",
                          title: t("community.priceNotANumberErrorMsg"),
                          onInvalid: (e) => {
                            e.target.setCustomValidity(
                              t("community.priceNotANumberErrorMsg")
                            );
                          },
                          onInput: (e) => {
                            e.target.setCustomValidity("");
                          },
                        }}
                      />
                      {errors?.subscriptionPrice && (
                        <Typography
                          variant="error"
                          className={styles.priceError}
                        >
                          {errors.subscriptionPrice.message}
                        </Typography>
                      )}
                    </Box>
                  </FormControl>
                </Box>
              ) : null}
            </Box>
          </SectionWrapper>
        ) : null}
        {/*{level === LEVEL_GROUP ? (*/}
        {/*  <SectionWrapper*/}
        {/*    title={t("community.groupTitle")}*/}
        {/*    subTitle={t("community.groupSubTitle")}*/}
        {/*  >*/}
        {/*    <GroupSettings*/}
        {/*      isShow={true}*/}
        {/*      setValue={setValue}*/}
        {/*      postingRights={postingRights}*/}
        {/*      setPostingRights={setPostingRights}*/}
        {/*      postsPendingReview={postsPendingReview}*/}
        {/*      setPostsPendingReview={setPostsPendingReview}*/}
        {/*      saveHandler={handleSubmit(onSubmit)}*/}
        {/*    />*/}
        {/*  </SectionWrapper>*/}
        {/*) : null}*/}
        <LoadingButton
          className={styles.saveBtn}
          // disabled={!isValid}
          variant="contained"
          fullWidth
          type="submit"
          loading={loading}
        >
          {t("community.saveBtn")}
        </LoadingButton>
      </form>

      <SetupStripeWarningModal stripeUrl={stripeUrl} onCancel={onCancelPaid} />
    </Page>
  );
});

export default EditCommunity;
