import { Box, CircularProgress, Typography } from "@mui/material";
import Page from "Components/Page/Page";
import SidebarTablet from "Components/SidebarTablet/SidebarTablet";
import { format } from "date-fns";
import React, { useContext, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch } from "react-redux";
import { setError } from "Redux/appSlice";
import { errorResponseMessages } from "Services/Api/apiErrorHelper.tsx";
import { getTransactions } from "Services/Api/donationsApi";
import { LocalizationContext } from "Services/Localization/LocalizationContext";

import { SCROLL_THRESHOLD } from "../../Constants";
import { Filter } from "./components/Filter/Filter";
import { RowsList } from "./components/RowsList/RowsList";
import styles from "./Transactions.module.scss";
import { TransactionsSkeleton } from "./TransactionsSkeleton";
import { filterTypes, getCurrentWeekRange } from "./utils";

const LIMIT = 40;

export const Transactions = () => {
  const dispatch = useDispatch();
  const { t } = useContext(LocalizationContext);

  const [loader, setLoader] = useState(true);
  const [transactionsData, setTransactionsData] = useState([]);
  const [filter, setFilter] = useState({
    type: filterTypes.all,
    startDate: getCurrentWeekRange().start,
    endDate: getCurrentWeekRange().end,
    page: 1,
  });

  const loadMore = () =>
    setFilter((prevState) => ({
      ...prevState,
      page: prevState.page + 1,
    }));

  const dataLength = transactionsData ? transactionsData.length : 0;

  const hasMore = dataLength === LIMIT * filter.page;

  useEffect(() => {
    setLoader(true);
    getTransactions({
      page: filter.page,
      limit: LIMIT,
      type: filter.type,
      startDate: format(new Date(filter.startDate), "MM/dd/yyyy"),
      ...(filter.endDate && {
        endDate: format(new Date(filter.endDate), "MM/dd/yyyy 23:59:59"),
      }),
    })
      .then((data) => {
        if (data?.transactions) {
          if (filter.page === 1) {
            setTransactionsData(data.transactions);
          } else {
            setTransactionsData((prevState) => [
              ...prevState,
              ...data.transactions,
            ]);
          }
        }
      })
      .catch((err) => {
        if (err?.status !== 401) {
          dispatch(
            setError({
              open: true,
              title: t("errors.error"),
              subtitle: errorResponseMessages(err, t),
            })
          );
        }
      })
      .finally(() => setLoader(false));
  }, [filter.endDate, filter.startDate, filter.type, filter.page]);

  return (
    <Page>
      <Box className={styles.titleWrap}>
        <SidebarTablet />
        <Typography variant="h2" className={styles.title}>
          {t("default.transactions")}
        </Typography>
      </Box>
      <Filter filter={filter} setFilter={setFilter} />
      <InfiniteScroll
        next={loadMore}
        dataLength={dataLength}
        hasMore={hasMore}
        scrollableTarget="scrollableDiv"
        scrollThreshold={SCROLL_THRESHOLD}
        loader={
          <Box style={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </Box>
        }
      >
        {loader && !transactionsData?.length ? <TransactionsSkeleton /> : null}
        <RowsList data={transactionsData} loader={loader} />
      </InfiniteScroll>
    </Page>
  );
};
