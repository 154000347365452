import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Button, Typography } from "@mui/material";
// import { ReactComponent as PlusIcon } from "assets/svg/plus-icon.svg";
import { ModalLayout } from "Components/Modal/components/ModalLayout/ModalLayout";
import { CustomTabPanel } from "Components/Tabs/CustomTabPanel/CustomTabPanel";
// import { TabsGroup } from "Components/Tabs/TabsGroup/TabsGroup";
import React, { useContext, useState } from "react";
import DatePicker from "react-datepicker";
import { useDispatch } from "react-redux";
import { setError } from "Redux/appSlice";
import { postVideoCallCalendar } from "Services/Api/userApi";
import { LocalizationContext } from "Services/Localization/LocalizationContext";

import { convertScheduleTime, DAYS_ABBR } from "../../utils";
import { ScheduledItem } from "./components/ScheduledItem/ScheduledItem";
import styles from "./ScheduledModal.module.scss";
export const ScheduledModal = ({
  isOpen,
  setOpen,
  duration,
  calendarData,
  setCalendarData,
  setCalendarLoader,
  calendarLoader,
}) => {
  const { t } = useContext(LocalizationContext);
  const dispatch = useDispatch();

  const [tabValue, setTabValue] = useState(0);
  const [isOverrideSection, setOverrideSection] = useState(false);
  const [overrideDate, setOverrideDate] = useState(null);

  const tabs = [
    { label: t("default.scheduledModalTab1Title"), index: 0 },
    { label: t("default.scheduledModalTab2Title"), index: 1 },
  ];

  const closeModalHandler = () => setOpen(false);

  const setOverrideSectionHandler = (value) => () => {
    setOverrideSection(value);
  };

  const setScheduleItemHandler = (abbr) => (value) => {
    setCalendarData((prevState) => ({
      ...prevState,
      schedule: { ...prevState.schedule, [abbr]: value },
    }));
  };

  const submit = (data) => () => {
    setCalendarLoader(true);
    const newData = JSON.parse(JSON.stringify(data));
    newData.schedule = convertScheduleTime({
      schedule: data.schedule,
      toTimezone: "Etc/UTC",
    });
    postVideoCallCalendar(newData)
      .then(() => {
        setOpen(false);
      })
      .catch((err) => {
        dispatch(
          setError({
            open: true,
            title: t("errors.error"),
            subtitle: err?.data?.message,
          })
        );
      })
      .finally(() => setCalendarLoader(false));
  };

  const isSubmitDisable = () => {
    const schedule = Object.values(calendarData.schedule);
    return schedule.some((item) =>
      item.some((slot) => !slot.start || !slot.end)
    );
  };

  if (!isOpen) {
    return null;
  }

  return (
    <ModalLayout onClose={closeModalHandler}>
      <Box className={styles.modal}>
        {!isOverrideSection ? (
          <>
            <Box className={styles.modalHeader}>
              <Typography variant="h4_24">
                {t("default.scheduledVideoInputLabel")}
              </Typography>
              <Typography variant="body2" className="opacity_07">
                {t("default.scheduledModalSubTitle")}
              </Typography>
            </Box>
            <Box className={styles.modalBody}>
              {/*<TabsGroup tabs={tabs} value={tabValue} setValue={setTabValue} />*/}
              <CustomTabPanel value={tabValue} index={0}>
                <ScheduledItem
                  duration={duration}
                  dayAbbr={t("default.sundayAbbr")}
                  setSlots={setScheduleItemHandler(DAYS_ABBR.SUNDAY)}
                  slots={calendarData.schedule[DAYS_ABBR.SUNDAY]}
                />
                <ScheduledItem
                  duration={duration}
                  dayAbbr={t("default.mondayAbbr")}
                  setSlots={setScheduleItemHandler(DAYS_ABBR.MONDAY)}
                  slots={calendarData.schedule[DAYS_ABBR.MONDAY]}
                />
                <ScheduledItem
                  duration={duration}
                  dayAbbr={t("default.tuesdayAbbr")}
                  setSlots={setScheduleItemHandler(DAYS_ABBR.TUESDAY)}
                  slots={calendarData.schedule[DAYS_ABBR.TUESDAY]}
                />
                <ScheduledItem
                  duration={duration}
                  dayAbbr={t("default.wednesdayAbbr")}
                  setSlots={setScheduleItemHandler(DAYS_ABBR.WEDNESDAY)}
                  slots={calendarData.schedule[DAYS_ABBR.WEDNESDAY]}
                />
                <ScheduledItem
                  duration={duration}
                  dayAbbr={t("default.thursdayAbbr")}
                  setSlots={setScheduleItemHandler(DAYS_ABBR.THURSDAY)}
                  slots={calendarData.schedule[DAYS_ABBR.THURSDAY]}
                />
                <ScheduledItem
                  duration={duration}
                  dayAbbr={t("default.fridayAbbr")}
                  setSlots={setScheduleItemHandler(DAYS_ABBR.FRIDAY)}
                  slots={calendarData.schedule[DAYS_ABBR.FRIDAY]}
                />
                <ScheduledItem
                  duration={duration}
                  dayAbbr={t("default.saturdayAbbr")}
                  setSlots={setScheduleItemHandler(DAYS_ABBR.SATURDAY)}
                  slots={calendarData.schedule[DAYS_ABBR.SATURDAY]}
                />
              </CustomTabPanel>
              {/*<CustomTabPanel value={tabValue} index={1}>*/}
              {/*  <Box className={styles.tabPanel}>*/}
              {/*    <Typography className={`opacity_07`} variant="body2_display">*/}
              {/*      {t("default.scheduledVideoOverrideTitle")}*/}
              {/*    </Typography>*/}
              {/*    <Box>*/}
              {/*      <Button*/}
              {/*        variant="outlined"*/}
              {/*        className={styles.overrideBtn}*/}
              {/*        onClick={setOverrideSectionHandler(true)}*/}
              {/*      >*/}
              {/*        <PlusIcon />*/}
              {/*        {t("default.scheduledVideooverrideBtn")}*/}
              {/*      </Button>*/}
              {/*    </Box>*/}
              {/*  </Box>*/}
              {/*</CustomTabPanel>*/}
            </Box>
            <LoadingButton
              variant="contained"
              className={styles.modalBtn}
              onClick={submit(calendarData)}
              loading={calendarLoader}
              disabled={calendarLoader || isSubmitDisable()}
            >
              {t("default.done")}
            </LoadingButton>
          </>
        ) : (
          <>
            <DatePicker
              selected={overrideDate}
              onChange={(date) => setOverrideDate(date)}
              calendarClassName={`scheduled-calendar ${styles.calendar}`}
              inline
            />
            {overrideDate ? (
              <Box className={styles.overrideDateSection}>
                <ScheduledItem
                  duration={duration}
                  isCheckbox={false}
                  dayAbbr={"What hours are you available?"}
                  labelWrapperClassName={styles.overrideDateSectionLabel}
                  wrapperClassName={styles.overrideDateSectionWrapper}
                  addButtonClassName={styles.overrideDateSectionAddButton}
                />
              </Box>
            ) : null}
            <Box className={styles.actions}>
              <Button
                variant="outlined"
                onClick={setOverrideSectionHandler(false)}
              >
                {t("default.cancel")}
              </Button>
              <Button
                variant="contained"
                onClick={setOverrideSectionHandler(false)}
              >
                {t("default.apply")}
              </Button>
            </Box>
          </>
        )}
      </Box>
    </ModalLayout>
  );
};
