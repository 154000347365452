export const getLECards = (state) => state.limitedEdition.cards;
export const getLECard = (id) => (state) => state.limitedEdition.cards[id];
export const getLETotal = (state) => state.limitedEdition.total;
export const getLeActiveCardId = (state) => state.limitedEdition.activeCardId;
export const getLeEditedCard = (state) => state.limitedEdition.editedCard;
export const getLeDetailsCardData = (state) =>
  state.limitedEdition.cardDetailsData;
export const getLeCurrentEditCard = (state) =>
  state.limitedEdition.currentConstructorCard;
export const getRewardsOrders = (state) => state.limitedEdition.rewardOrders;
export const getRewardsOrdersClubPass = (state) =>
  state.limitedEdition.rewardOrdersClubPass.orders;
export const getRewardsOrdersClubPassTotal = (state) =>
  state.limitedEdition.rewardOrdersClubPass.total;
export const getRewardsOrdersLoading = (state) =>
  state.limitedEdition.rewardOrdersLoading;
export const getRewardsOrdersClubPassLoading = (state) =>
  state.limitedEdition.rewardOrdersClubPassLoading;
export const getRewardsOrdersType = (state) =>
  state.limitedEdition.rewardOrdersType;
export const getRewardsOrdersTotal = (state) =>
  state.limitedEdition.rewardOrdersTotal;
export const getRewardsOrdersPage = (state) =>
  state.limitedEdition.rewardOrdersPage;
export const getRewardMessagesLoader = (state) =>
  state.limitedEdition.currentLimitedEditionRewardChat.loading;
export const getRewardMessages = (state) =>
  state.limitedEdition.currentLimitedEditionRewardChat.messages;
