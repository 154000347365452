import { Box, CircularProgress } from "@mui/material";
import {
  CHAT_CATEGORIES,
  CHATS_TYPE,
  PATHS,
  TMP_USER_CHAT_ID,
} from "Constants/index";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createSearchParams,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  deleteChatAction,
  setActiveChat,
  setDeletedChatId,
  setNewChat,
  setSelectedUser,
} from "Redux/messengerSlice";
import {
  getActiveChat,
  getChatsLoading,
  getDeletedChatId,
  getTotalChats,
} from "Redux/selectors/messengerSelectors";

import { EmptyMessengerContent } from "../EmptyMessengerContent/EmptyMessengerContent";
import { ChatContainer } from "./components/ChatContainer/ChatContainer";
import { Sidebar } from "./components/Sidebar/Sidebar";
import styles from "./Messenger.module.scss";

export const Messenger = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const chatsLoading = useSelector(getChatsLoading);
  const totalChats = useSelector(getTotalChats(CHAT_CATEGORIES.GENERAL));
  const totalChatsPremium = useSelector(getTotalChats(CHAT_CATEGORIES.PREMIUM));
  const activeChat = useSelector(getActiveChat);
  const deletedChatId = useSelector(getDeletedChatId);

  const [chatsType, setChatsType] = useState(
    searchParams.get("type") || CHATS_TYPE.MY
  );
  const [usersLoading, setUsersLoading] = useState(false);
  const [chatCategory, setChatCategory] = useState(
    searchParams.get("category") || CHAT_CATEGORIES.PREMIUM
  );

  const isChatLoader =
    (((!totalChats && chatCategory === CHAT_CATEGORIES.GENERAL) ||
      (!totalChatsPremium && chatCategory === CHAT_CATEGORIES.PREMIUM)) &&
      (chatsLoading || usersLoading)) ||
    (params.userId && !activeChat);

  const isEmptyChat =
    ((!totalChats && chatCategory === CHAT_CATEGORIES.GENERAL) ||
      (!totalChatsPremium && chatCategory === CHAT_CATEGORIES.PREMIUM)) &&
    !chatsLoading &&
    !params.userId &&
    !activeChat?.isNewMessageTo &&
    !activeChat?.id;

  useEffect(() => {
    return () => {
      dispatch(setSelectedUser(null));
      dispatch(setNewChat(null));
    };
  }, []);

  useEffect(() => {
    if (activeChat && chatsType === CHATS_TYPE.MY) {
      const state = window.history.state;
      if (
        !activeChat.isNewMessageTo &&
        activeChat.id !== TMP_USER_CHAT_ID &&
        activeChat.otherUser?.id
      ) {
        const pathname = PATHS.CHAT_DIRECT.replace(
          ":userId",
          activeChat.otherUser.id
        );
        const category =
          Object.hasOwn(activeChat, "isPremium") && activeChat.isPremium
            ? CHAT_CATEGORIES.PREMIUM
            : Object.hasOwn(activeChat, "isPremium") && !activeChat.isPremium
            ? CHAT_CATEGORIES.GENERAL
            : chatCategory || CHAT_CATEGORIES.GENERAL;

        navigate(
          {
            pathname,
            search: createSearchParams({
              category,
            }).toString(),
          },
          { replace: true, state }
        );
      } else if (
        activeChat.isNewMessageTo ||
        activeChat.id === TMP_USER_CHAT_ID
      ) {
        navigate(
          {
            pathname: PATHS.CHAT,
            search: createSearchParams({
              category:
                new URLSearchParams(window.location.search).get("category") ||
                CHAT_CATEGORIES.GENERAL,
            }).toString(),
          },
          { replace: true, state }
        );
      }
    }
  }, [activeChat, chatsType]);

  useEffect(() => {
    if (deletedChatId) {
      dispatch(deleteChatAction({ chatId: deletedChatId, type: chatCategory }));
      dispatch(setDeletedChatId(null));
      if (activeChat?.id === deletedChatId) {
        dispatch(setActiveChat(null));
        navigate(PATHS.CHAT);
      }
    }
  }, [deletedChatId]);

  return (
    <Box className={styles.container}>
      <Sidebar
        setUsersLoading={setUsersLoading}
        setChatsType={setChatsType}
        chatsType={chatsType}
        setChatCategory={setChatCategory}
        chatCategory={chatCategory}
      />
      <Box
        className={`${styles.chat} ${
          !isChatLoader && !isEmptyChat ? styles.chatContent : ""
        } ${activeChat ? styles.chatActive : ""}`}
      >
        {isChatLoader ? (
          <Box className={styles.loader}>
            <CircularProgress />
          </Box>
        ) : isEmptyChat && CHATS_TYPE.MY === chatsType ? (
          <EmptyMessengerContent />
        ) : (
          <ChatContainer
            chatsType={chatsType}
            setChatsType={setChatsType}
            chatCategory={chatCategory}
          />
        )}
      </Box>
    </Box>
  );
};
