import { Box, CircularProgress, Typography } from "@mui/material";
import { REWARD_ORDER_STATUS } from "Constants/index";
import React, { useContext } from "react";
import { LocalizationContext } from "Services/Localization/LocalizationContext";
import {
  getConvertedTimeZoneDate,
  getRewardStatus,
  getRewardType,
  rewardFormatDate,
} from "Utils/index";

import { RowTooltip } from "../RowTooltip/RowTooltip";
import styles from "./RowMobile.module.scss";

export const RowMobile = ({
  reward,
  status,
  goToProfile,
  reportHandler,
  statusHandler,
  setOpenCustomTooltip,
  isOpenCustomTooltip,
  statusColor,
  appUser,
  date,
  clickHandler,
  loading,
  isShowStatusDate,
  ...rest
}) => {
  const { t } = useContext(LocalizationContext);

  const Separator = () => (
    <svg
      width="3"
      height="4"
      viewBox="0 0 3 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect opacity="0.7" y="0.5" width="3" height="3" rx="1.5" fill="white" />
    </svg>
  );

  return (
    <Box className={styles.row} onClick={clickHandler}>
      {loading ? (
        <Box className={styles.loader}>
          <CircularProgress size={24} />
        </Box>
      ) : null}
      <Box>
        <Box className={styles.rowTop}>
          <Typography variant="body4_14" className={styles.rowTopTitle}>
            {getRewardType({ t, value: reward })}
          </Typography>
          <Separator />
          <Typography
            variant="text_12_display_bold"
            style={{ color: statusColor }}
            className={styles.rowTopStatus}
          >
            {isShowStatusDate && rest?.call?.date
              ? rewardFormatDate(rest.call.date, t)
              : getRewardStatus({ t, value: status })}
          </Typography>
        </Box>
        <Box className={styles.rowTop}>
          {appUser?.profileImageUrl ? (
            <img src={appUser.profileImageUrl} alt="avatar" />
          ) : null}
          <Typography variant="textNormal">{appUser?.username}</Typography>
          <Separator />
          <Typography variant="textNormal" className="opacity_07">
            {date ? getConvertedTimeZoneDate(date, "MMM dd, yyyy HH:mm") : null}
          </Typography>
        </Box>
      </Box>
      <RowTooltip
        reward={reward}
        status={status}
        setOpenCustomTooltip={setOpenCustomTooltip}
        isOpenCustomTooltip={isOpenCustomTooltip}
        reportHandler={reportHandler}
        goToProfile={goToProfile}
        deliveredHandler={statusHandler(REWARD_ORDER_STATUS.DELIVERED)}
      />
    </Box>
  );
};
