import { Typography } from "@mui/material";
import { useContext } from "react";
import { LocalizationContext } from "Services/Localization/LocalizationContext";
import { rewardFormatDate } from "Utils/index";

import styles from "./TimeSection.module.scss";

const isLessThan = (value, date) => {
  const newDate = new Date(date).getTime() - new Date().getTime();
  return newDate < value;
};

export const TimeSection = ({ date, callStart }) => {
  const { t } = useContext(LocalizationContext);

  if (!callStart && !isLessThan(1000 * 60 * 5, date)) {
    return null;
  }

  return (
    <>
      {!callStart && isLessThan(1000 * 60 * 5) ? (
        <Typography className={styles.time} variant="body2">
          {t("default.newTxt")}
        </Typography>
      ) : callStart ? (
        <Typography className={styles.time} variant="body2">
          {rewardFormatDate(callStart, t)}
        </Typography>
      ) : null}
    </>
  );
};
