import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getUsersMeStatus } from "Redux/selectors/userSelector";
import { getEmulateId } from "Services/Auth/auth.service";

import { PATHS, USER_BLOCKED, USER_ME_STATUS } from "../Constants";

export const RedirectTo = () => {
  const navigate = useNavigate();

  const userStatus = useSelector((state) => state.users.me.entities.status);
  const userLevel = useSelector((state) => state.users.me.entities.level);
  const emailVerified = useSelector(
    (state) => state.users.me.entities.emailVerified
  );
  const onfidoError = useSelector((state) => state.mainAppUser?.error);
  const userIsCompleted = useSelector(
    (state) => state.mainAppUser.entities?.isCompleted
  );
  const userIsCompletedLoading = useSelector(
    (state) => state.mainAppUser.loading
  );

  const userMeStatus = useSelector(getUsersMeStatus);

  useEffect(() => {
    const isNonRedirect =
      window.location.pathname === PATHS.SIGN_UP ||
      window.location.pathname === PATHS.SIGNIN;

    if (
      emailVerified !== undefined &&
      userStatus !== undefined &&
      !isNonRedirect
    ) {
      if (!getEmulateId()) {
        if (!emailVerified) {
          navigate(PATHS.EMAIL_VERIFICATION);
        } else if (
          (userStatus === USER_ME_STATUS.NEW &&
            window.location.pathname !== PATHS.PROFILE &&
            emailVerified) ||
          (window.location.pathname !== PATHS.PROFILE &&
            emailVerified &&
            !userLevel)
        ) {
          navigate(PATHS.PROFILE);
        } else if (
          onfidoError?.type === USER_BLOCKED &&
          !window.location.href.includes(PATHS.ACCOUNT_SUSPENDED)
        ) {
          navigate(PATHS.ACCOUNT_SUSPENDED);
        }
      }
    }
  }, [
    userLevel,
    emailVerified,
    userIsCompleted,
    userIsCompletedLoading,
    userMeStatus,
    userStatus,
    onfidoError,
  ]);

  return null;
};
