import { Box, Typography } from "@mui/material";
import Growth from "Components/Growth";

import styles from "./Row.module.scss";

export const Row = ({ data, classNameCol }) => {
  const { name, subs, growth } = data;
  const { value, percentage } = growth;

  return (
    <Box className={styles.tr}>
      <Box className={classNameCol}>
        <Typography variant="body18">{name}</Typography>
      </Box>
      <Box className={classNameCol}>
        <Typography variant="body18">{subs.new}</Typography>
      </Box>
      <Box className={classNameCol}>
        <Growth
          value={value}
          percentage={percentage}
          className={styles.rowGrowth}
          arrowClassName={styles.rowGrowthArrow}
        />
      </Box>
      <Box className={classNameCol}>
        <Typography variant="body18">{subs.lost}</Typography>
      </Box>
    </Box>
  );
};
