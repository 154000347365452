import { mainApi } from "./api.service";

const API_URL = process.env.REACT_APP_MAIN_API_HOST;

export const getKycStatus = async () => {
  try {
    return mainApi.get(`${API_URL}/kyc/status`);
  } catch (error) {
    console.error(error);
  }
};

export const getOnfidoToken = () => {
  return mainApi.get(`${API_URL}/kyc/onfido-token`).then((res) => res.data);
};

export const getCountries = async () => {
  try {
    const result = await mainApi.get(`${API_URL}/countries`);
    return result;
  } catch (e) {
    console.error("GET countries", e);
  }
};

export const onfidoCheck = () => {
  return mainApi.post(`${API_URL}/kyc/onfido-check`).then((res) => res.data);
};

export const getUserProfile = () => {
  return mainApi
    .get(`${API_URL}/app-users/profile`)
    .then((response) => response.data);
};

export const sendVerifyEmail = async (params) => {
  const result = await mainApi.post(`${API_URL}/app-users/me/email`, params);
  return result;
};

export const sendVerifyCode = async (params) => {
  try {
    const result = await mainApi.post(
      `${API_URL}/app-users/me/verify-email`,
      params
    );
    return result;
  } catch (error) {
    console.error("sendVerifyCode", error);
    return error;
  }
};

export const updateUser = async (params) => {
  const result = await mainApi.patch(`${API_URL}/app-users/profile`, params);
  return result;
};

export const getUserZendeskJWT = () => {
  return mainApi
    .post(`${API_URL}/app-users/me/zendesk-jwt`)
    .then((response) => response.data);
};
