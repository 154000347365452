export const AddFriendIcon = () => (
  <svg
    width="20"
    height="12"
    viewBox="0 0 20 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.73391 5.31335C4.46521 5.58205 4.09201 5.75124 3.679 5.75124C2.85298 5.75124 2.18619 5.08445 2.18619 4.25843C2.18619 3.43241 2.85298 2.76562 3.679 2.76562C4.50502 2.76562 5.1718 3.43241 5.1718 4.25843M1.19099 9.23439C-0.0530155 5.75117 2.30562 7.24398 3.679 7.24398C4.20477 7.24398 4.87493 7.0252 5.43208 6.95057"
      stroke="#ABB3F3"
      strokeWidth="0.908145"
      strokeLinejoin="round"
    />
    <path
      d="M11.5328 4.92413C11.1118 5.34513 10.5271 5.61021 9.87996 5.61021C8.58575 5.61021 7.54104 4.56549 7.54104 3.27129C7.54104 1.97709 8.58575 0.932373 9.87996 0.932373C11.1742 0.932373 12.2189 1.97709 12.2189 3.27129M5.98176 11.0676C4.03266 5.61011 7.72816 7.94903 9.87996 7.94903C12.0318 7.94903 15.7273 5.61011 13.7782 11.0676"
      stroke="#ABB3F3"
      strokeWidth="0.908145"
      strokeLinejoin="round"
    />
    <path
      d="M16.8756 5.31335C16.6069 5.58205 16.2337 5.75124 15.8207 5.75124C14.9947 5.75124 14.3279 5.08445 14.3279 4.25843C14.3279 3.43241 14.9947 2.76562 15.8207 2.76562C16.6467 2.76562 17.3135 3.43241 17.3135 4.25843M18.3087 9.23439C19.5527 5.75117 17.1941 7.24398 15.8207 7.24398C15.4069 7.24398 14.9037 7.10848 14.4365 7.01438"
      stroke="#ABB3F3"
      strokeWidth="0.908145"
      strokeLinejoin="round"
    />
  </svg>
);

export const RocketIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.5224 13.225L7.12919 14.6518C7.30959 15.07 7.88357 15.1274 8.13777 14.742L9.72854 12.3231C10.0237 11.8639 9.96634 11.2653 9.58094 10.8799"
      stroke="#ABB3F3"
      strokeWidth="0.7"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M2.6108 9.31074L1.18403 8.70395C0.765838 8.52356 0.708439 7.94957 1.09383 7.69537L3.51279 6.1046C3.97198 5.80121 4.57057 5.86681 4.95596 6.2522"
      stroke="#ABB3F3"
      strokeWidth="0.7"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M14.7109 3.93273L15.1923 0.999756L12.1603 1.46544C9.89681 1.80857 7.8107 2.83797 6.19756 4.39841C5.12495 5.43598 4.29726 6.6778 3.74829 8.04216L2.86148 10.2725L5.59791 12.9196L7.90361 12.0617C9.31405 11.5389 10.5978 10.73 11.6704 9.69247C13.292 8.13203 14.3562 6.11408 14.7109 3.93273Z"
      stroke="#ABB3F3"
      strokeWidth="0.7"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M9.64695 7.16695C9.38634 7.16695 9.11828 7.06998 8.91723 6.86857C8.51514 6.46575 8.51514 5.81676 8.91723 5.41393C9.31932 5.01111 9.96714 5.01111 10.3692 5.41393C10.7713 5.81676 10.7713 6.46575 10.3692 6.86857"
      stroke="#ABB3F3"
      strokeWidth="0.7"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M4.09647 7.98889L7.79572 12.0992"
      stroke="#ABB3F3"
      strokeWidth="0.7"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M0.807709 14.9735L2.86285 12.9183"
      stroke="#ABB3F3"
      strokeWidth="0.7"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M2.86148 14.9747L4.09456 13.7416"
      stroke="#ABB3F3"
      strokeWidth="0.7"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M0.807709 12.9188L2.04079 11.2747"
      stroke="#ABB3F3"
      strokeWidth="0.7"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
  </svg>
);

export const ArrowDownIcon = () => (
  <svg
    width="17"
    height="9"
    viewBox="0 0 17 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 1.16671L8.49213 7.83337L1 1.16671"
      stroke="white"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
  </svg>
);

export const InfoIcon = () => (
  <svg
    width="11"
    height="11"
    viewBox="0 0 11 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.7">
      <path
        d="M5.5 10.5C8.26142 10.5 10.5 8.26142 10.5 5.5C10.5 2.73858 8.26142 0.5 5.5 0.5C2.73858 0.5 0.5 2.73858 0.5 5.5C0.5 8.26142 2.73858 10.5 5.5 10.5Z"
        stroke="#F0F1F5"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M3.82385 3.9835C3.82385 2.3123 7.15719 2.3123 7.15719 3.9835C7.15719 5.17722 5.64203 4.93848 5.64203 6.37094M5.64203 8.28566L5.64809 8.28041"
        stroke="#F0F1F5"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
