import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as MAIN_APP_API from "Services/Api/mainAppApi";

export const fetchUser = createAsyncThunk(
  "users/profile",
  async (_, { rejectWithValue }) => {
    try {
      return await MAIN_APP_API.getUserProfile();
    } catch (e) {
      return rejectWithValue(e?.data || e);
    }
  }
);

export const checkKYCstatus = createAsyncThunk(
  "post/kyc",
  async (_, { rejectWithValue }) => {
    try {
      const response = await MAIN_APP_API.onfidoCheck();
      return response;
    } catch (e) {
      return rejectWithValue(e?.data || e);
    }
  }
);

export const verifyCode = createAsyncThunk("code/verify", async (params) => {
  const response = await MAIN_APP_API.sendVerifyCode(params);
  return response;
});

export const verifyEmail = createAsyncThunk(
  "email/verify",
  async (params, { rejectWithValue }) => {
    try {
      return await MAIN_APP_API.sendVerifyEmail(params);
    } catch (err) {
      if (!err.data) {
        throw err;
      }
      return rejectWithValue(err.data);
    }
  }
);

export const updateUserProfile = createAsyncThunk(
  "user/update/profile",
  async (params, { rejectWithValue }) => {
    try {
      const response = await MAIN_APP_API.updateUser(params);
      return response.data;
    } catch (err) {
      if (!err?.response.data) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const initialState = {
  loading: false,
  entities: {},
  onfido: {},
  error: {
    type: "",
    message: "",
  },
};

const mainAppUserSlice = createSlice({
  name: "mainAppUser",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUser.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.loading = false;
        state.entities = action.payload;
      })
      .addCase(fetchUser.rejected, (state, action) => {
        state.error = {
          type: action.payload?.response?.data?.error,
          message: action.payload?.response?.data?.message,
        };
        state.loading = false;
      })
      .addCase(updateUserProfile.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(updateUserProfile.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(updateUserProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.entities = {
          ...state.entities,
          ...action.payload,
        };
      })
      .addCase(checkKYCstatus.fulfilled, (state, action) => {
        state.onfido = action.payload;
      });
  },
});

// export const {} = mainAppUserSlice.actions;

export default mainAppUserSlice.reducer;
