export const LimitedEditionIcon = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.387 7.36938H3.01825C2.45017 7.36938 2 7.83053 2 8.3882V19.9812C2 20.5496 2.46089 21 3.01825 21H11.1964C11.7644 21 12.2146 20.5389 12.2146 19.9812V18.276"
      stroke="white"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M15.7125 16.3147L7.81307 14.202C7.26643 14.0518 6.94488 13.4942 7.08422 12.9472L10.0854 1.76173C10.2354 1.21479 10.7928 0.893062 11.3394 1.03248L19.2389 3.14518C19.7855 3.29532 20.107 3.85298 19.9677 4.39992L16.9773 15.5961C16.8165 16.1431 16.2591 16.4648 15.7125 16.3147Z"
      stroke="white"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <mask id="path-3-inside-1_5106_481" fill="white">
      <path d="M13.2359 8.85286L12.1426 8.32737L13.3538 8.39172L13.879 7.28711L13.8146 8.50969L14.9079 9.03518L13.6967 8.97083L13.1715 10.0647L13.2359 8.85286Z" />
    </mask>
    <path
      d="M13.2359 8.85286L12.1426 8.32737L13.3538 8.39172L13.879 7.28711L13.8146 8.50969L14.9079 9.03518L13.6967 8.97083L13.1715 10.0647L13.2359 8.85286Z"
      fill="white"
    />
    <path
      d="M13.2359 8.85286L14.2344 8.90586L14.2698 8.24031L13.6691 7.95157L13.2359 8.85286ZM12.1426 8.32737L12.1956 7.32878L11.7094 9.22866L12.1426 8.32737ZM13.3538 8.39172L13.3007 9.39031L13.9693 9.42583L14.2569 8.82112L13.3538 8.39172ZM13.879 7.28711L14.8776 7.33964L12.9758 6.85771L13.879 7.28711ZM13.8146 8.50969L12.816 8.45716L12.781 9.12239L13.3814 9.41098L13.8146 8.50969ZM14.9079 9.03518L14.8549 10.0338L15.3411 8.13389L14.9079 9.03518ZM13.6967 8.97083L13.7498 7.97224L13.0839 7.93686L12.7953 8.53801L13.6967 8.97083ZM13.1715 10.0647L12.1729 10.0117L14.073 10.4975L13.1715 10.0647ZM13.6691 7.95157L12.5758 7.42608L11.7094 9.22866L12.8026 9.75415L13.6691 7.95157ZM12.0895 9.32596L13.3007 9.39031L13.4068 7.39313L12.1956 7.32878L12.0895 9.32596ZM14.2569 8.82112L14.7821 7.71651L12.9758 6.85771L12.4506 7.96232L14.2569 8.82112ZM12.8803 7.23458L12.816 8.45716L14.8133 8.56221L14.8776 7.33964L12.8803 7.23458ZM13.3814 9.41098L14.4747 9.93647L15.3411 8.13389L14.2479 7.60839L13.3814 9.41098ZM14.961 8.03659L13.7498 7.97224L13.6437 9.96942L14.8549 10.0338L14.961 8.03659ZM12.7953 8.53801L12.2701 9.63189L14.073 10.4975L14.5982 9.40365L12.7953 8.53801ZM14.1701 10.1177L14.2344 8.90586L12.2373 8.79987L12.1729 10.0117L14.1701 10.1177Z"
      fill="white"
      mask="url(#path-3-inside-1_5106_481)"
    />
    <path
      d="M14.4034 5.20508L13.0958 7.95051L10.0518 7.78965L12.7957 9.10874L12.6349 12.1545L13.9532 9.40902L16.9973 9.55917L14.2534 8.25079L14.4034 5.20508Z"
      fill="white"
    />
  </svg>
);

export const EmptyStateIcon = () => (
  <svg
    width="62"
    height="67"
    viewBox="0 0 62 67"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.7528 21.7019H5.05029C3.20308 21.7019 1.73926 23.2006 1.73926 25.0129V62.6891C1.73926 64.5363 3.23794 66.0001 5.05029 66.0001H31.6431C33.4904 66.0001 34.9542 64.5014 34.9542 62.6891V57.1474"
      stroke="white"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M46.3185 50.7711L20.6318 43.9051C18.8543 43.4171 17.8087 41.6048 18.2618 39.8273L28.0206 3.47556C28.5086 1.69805 30.3209 0.652461 32.0985 1.10555L57.7851 7.9716C59.5626 8.45954 60.6082 10.2719 60.1551 12.0494L50.4311 48.4359C49.9083 50.2135 48.096 51.259 46.3185 50.7711Z"
      stroke="white"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M38.2679 26.5143L34.7129 24.8065L38.6513 25.0156L40.3591 21.4258L40.15 25.399L43.705 27.1068L39.7666 26.8977L38.0588 30.4527L38.2679 26.5143Z"
      fill="white"
    />
    <path
      d="M42.0673 14.6611L37.8153 23.5835L27.917 23.0607L36.8394 27.3476L36.3166 37.2459L40.6035 28.3235L50.5017 28.8115L41.5794 24.5594L42.0673 14.6611Z"
      fill="white"
    />
  </svg>
);

export const CalendarIcon = () => (
  <svg
    width="19"
    height="20"
    viewBox="0 0 19 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.3684 2.89474V1M12.3684 2.89474V4.78947M12.3684 2.89474H8.10526M1 8.57895V17.1053C1 17.6078 1.19962 18.0897 1.55496 18.445C1.91029 18.8004 2.39222 19 2.89474 19H16.1579C16.6604 19 17.1423 18.8004 17.4977 18.445C17.853 18.0897 18.0526 17.6078 18.0526 17.1053V8.57895M1 8.57895H18.0526M1 8.57895V4.78947C1 4.28696 1.19962 3.80502 1.55496 3.44969C1.91029 3.09436 2.39222 2.89474 2.89474 2.89474H4.78947M18.0526 8.57895V4.78947C18.0526 4.28696 17.853 3.80502 17.4977 3.44969C17.1423 3.09436 16.6604 2.89474 16.1579 2.89474H15.6842M4.78947 1V4.78947"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const CheckIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1C5.02944 1 1 5.02944 1 10C1 14.9706 5.02944 19 10 19Z"
      stroke="white"
      stroke-width="0.8"
      stroke-miterlimit="10"
      stroke-linejoin="round"
    />
    <path
      d="M5.21667 9.98959L8.09562 13.206L15.2419 7.69214"
      stroke="white"
      stroke-width="0.8"
      stroke-miterlimit="10"
      stroke-linejoin="round"
    />
  </svg>
);
