import { useEffect } from "react";

const Countdown = ({ children, value, onSetValue, className }) => {
  useEffect(() => {
    const timerID =
      value > 0 &&
      setTimeout(() => {
        onSetValue(value - 1);
      }, 1000);
    if (value <= 0) clearTimeout(timerID);
    return () => clearTimeout(timerID);
  }, [value, onSetValue]);
  return <div className={className}>{children}</div>;
};
export default Countdown;
