import { Box, Typography } from "@mui/material";
import React from "react";

import styles from "./CountdownDate.module.scss";

const TimeItem = ({ value, label, hasDivider }) => {
  return (
    <>
      <Box className={styles.timeItem}>
        <Typography variant="h1" component="span" className={styles.timeValue}>
          {value}
        </Typography>
        <Typography
          variant="body1"
          component="span"
          className={styles.timeLabel}
        >
          {label}
        </Typography>
      </Box>
      {hasDivider && <Box className={styles.divider} />}
    </>
  );
};

export default TimeItem;
