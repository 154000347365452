export const CardConstructorFields = {
  name: "name",
  description: "description",
  salePrice: "price",
  allocation: "allocation",
  cardFile: "cardFile",
  dropDate: "dropDate",
};

export const MIN_IMG_WIDTH = 686;
export const MIN_IMG_HEIGHT = 958;
