import {
  Box,
  CircularProgress,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { ReactComponent as ArrowDown } from "assets/svg/arrow-down.svg";
import { SearchBar } from "Components/SearchBar/SearchBar";
import { LEVEL_GROUP, POST_IN_GROUPS } from "Constants/index";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateAllFans } from "Redux/communitySlice";
import { getFansLoadingSelector } from "Redux/selectors/communitySelectors";
import {
  getUserLevel,
  getUserMePostingRights,
} from "Redux/selectors/userSelector";
import { editCommunity } from "Redux/usersSlice";
import { LocalizationContext } from "Services/Localization/LocalizationContext";

import styles from "./SearchWrapper.module.scss";

export const SearchWrapper = ({ handleSearch }) => {
  const { t } = useContext(LocalizationContext);
  const dispatch = useDispatch();

  const loading = useSelector(getFansLoadingSelector);
  const postingRights = useSelector(getUserMePostingRights);
  const level = useSelector(getUserLevel);

  const [postLoader, setPostLoader] = useState(false);
  const [postType, setPostType] = useState(null);

  const setPostAccessTypeHandle =
    ({ type }) =>
    () => {
      setPostLoader(true);
      setPostType(type);
      dispatch(editCommunity({ postingRights: type })).finally(() => {
        setPostLoader(false);
        if (
          type === POST_IN_GROUPS.EVERYONE ||
          type === POST_IN_GROUPS.ONLY_ME
        ) {
          dispatch(
            updateAllFans({ allowPosting: type === POST_IN_GROUPS.EVERYONE })
          );
        }
      });
    };

  useEffect(() => {
    setPostType(postingRights);
  }, [postingRights]);

  return (
    <Box className={styles.searchWrapper}>
      <SearchBar
        onSeacrhChanged={handleSearch}
        classNameWrapper={styles.search}
        className={styles.searchInput}
        isResultOpened={false}
        isClearDataByOutClick={false}
        placeholder={t("default.searchFans")}
        isFocus={true}
        isLoading={loading}
      />
      {level === LEVEL_GROUP ? (
        <Box className={styles.selectWrapper}>
          {postLoader ? (
            <Box className={styles.loader}>
              <CircularProgress size={24} />
            </Box>
          ) : null}
          <Select
            value={postType}
            defaultValue={postingRights}
            IconComponent={ArrowDown}
            onChange={(e) => {
              setPostAccessTypeHandle({ type: e.target?.value })();
            }}
            className={styles.select}
            MenuProps={{
              classes: { paper: styles.dropdownMenu },
            }}
          >
            <MenuItem value={POST_IN_GROUPS.ONLY_ME}>
              <Typography variant="body2">
                {t("community.selectOnlyMeItem")}
              </Typography>
            </MenuItem>
            <MenuItem value={POST_IN_GROUPS.EVERYONE}>
              <Typography variant="body2">
                {t("community.selectEveryoneItem")}
              </Typography>
            </MenuItem>
            <MenuItem value={POST_IN_GROUPS.SELECTED_MEMBERS}>
              <Typography variant="body2">
                {t("community.selectMembersItem")}
              </Typography>
            </MenuItem>
          </Select>
        </Box>
      ) : null}
    </Box>
  );
};
