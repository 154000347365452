import { Tab, Tabs } from "@mui/material";
import React from "react";

import styles from "./GraphTabs.module.scss";

const GraphTabs = ({ activeTab, handleTabChange, tabLabels }) => {
  return (
    <Tabs
      value={activeTab}
      onChange={handleTabChange}
      classes={{
        root: styles.tabs,
        indicator: styles.tabIndicator,
        flexContainer: styles.tabsflexContainer,
      }}
      variant="fullWidth"
      indicatorColor="primary"
    >
      {tabLabels.map((label, index) => (
        <Tab
          key={index}
          label={label}
          classes={{
            selected: styles.activeTab,
            root: styles.tabLabel,
            wrapper: styles.tabWrapper,
          }}
        />
      ))}
    </Tabs>
  );
};

export default GraphTabs;
