import { Box, Typography } from "@mui/material";
import { ReactComponent as InfoIcon } from "assets/svg/icon-info.svg";
import Growth from "Components/Growth";
import React, { useContext } from "react";
import { LocalizationContext } from "Services/Localization/LocalizationContext";

import { TooltipBox } from "../../../TooltipBox/TooltipBox";
import styles from "./Row.module.scss";

function formatDate(dateString, lang, isYear = false) {
  if (!dateString || isNaN(Date.parse(dateString))) {
    return "Invalid date";
  }

  const date = new Date(dateString);
  const options = isYear
    ? { month: "short", day: "numeric", year: "2-digit" }
    : { month: "short", day: "numeric" };

  return new Intl.DateTimeFormat(lang, options).format(date);
}

const isDiffYear = (dates) => {
  if (!dates || !dates.start || !dates.end) {
    return false;
  }
  const { start: startDateString, end: endDateString } = dates;
  const startDate = new Date(startDateString);
  const endDate = new Date(endDateString);
  return startDate.getFullYear() !== endDate.getFullYear();
};

export const RowMobile = ({ data: { name, subs, growth, dates } }) => {
  const { t } = useContext(LocalizationContext);
  const { value, percentage } = growth;
  const lang = localStorage.getItem("language");
  const isYear = isDiffYear(dates);

  return (
    <Box className={styles.tr}>
      <Box className={styles.topBlock}>
        <Typography
          component="span"
          variant="body3_medium_14_display"
          className={styles.descriptionTitle}
        >
          {name}
        </Typography>
        <Typography
          component="span"
          variant="textNormal"
          className={styles.descriptionTitleDate}
        >
          {t("insights.fromTo", {
            from: formatDate(dates?.start, lang, isYear),
            to: formatDate(dates?.end, lang, isYear),
          })}
        </Typography>
      </Box>
      <Box className={styles.bottomBlock}>
        <Box className={styles.col}>
          <Typography variant="body2" className={styles.colTitle}>
            {t("insights.newSubs2")}
          </Typography>
          <Typography variant="body3_medium_14_display_2">
            {subs.new}
          </Typography>
        </Box>
        <Box className={styles.col}>
          <Typography variant="body2" className={styles.colTitle}>
            {t("insights.lostSubs2")}
            <TooltipBox title={t("insights.lostSubsDesc")}>
              <InfoIcon />
            </TooltipBox>
          </Typography>
          <Typography variant="body3_medium_14_display_2">
            {subs.lost}
          </Typography>
        </Box>
        <Box className={styles.col}>
          <Typography
            variant="body2"
            className={`${styles.colTitle} ${styles.colTitleGrowth}`}
          >
            {t("insights.growth")}
          </Typography>
          <Growth
            value={value}
            percentage={percentage}
            className={styles.mobileRowGrowth}
            arrowClassName={styles.mobileRowGrowthArrow}
          />
        </Box>
      </Box>
    </Box>
  );
};
