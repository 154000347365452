import { isSameDay, setDay } from "date-fns";
import { fromZonedTime, toZonedTime } from "date-fns-tz";

export const DAYS_ABBR = {
  MONDAY: "mon",
  TUESDAY: "tue",
  WEDNESDAY: "wed",
  THURSDAY: "thu",
  FRIDAY: "fri",
  SATURDAY: "sat",
  SUNDAY: "sun",
};

export const getDayByAbbr = ({ t, value }) => {
  const days = {
    [DAYS_ABBR.MONDAY]: t("default.mondayAbbr"),
    [DAYS_ABBR.TUESDAY]: t("default.tuesdayAbbr"),
    [DAYS_ABBR.WEDNESDAY]: t("default.wednesdayAbbr"),
    [DAYS_ABBR.THURSDAY]: t("default.thursdayAbbr"),
    [DAYS_ABBR.FRIDAY]: t("default.fridayAbbr"),
    [DAYS_ABBR.SATURDAY]: t("default.saturdayAbbr"),
    [DAYS_ABBR.SUNDAY]: t("default.sundayAbbr"),
  };
  return days[value];
};

const getFormattedTime = (data, type) => {
  const newDate = new Date(data);
  const hours = newDate.getHours();
  const minutes = newDate.getMinutes();
  if (type === "end" && hours === 0 && minutes === 0) {
    return "24:00";
  }
  return `${hours < 10 ? "0" + hours : hours}:${
    minutes < 10 ? "0" + minutes : minutes
  }`;
};

export const getTimeTimestampFomString = (value) => {
  const date = new Date();
  const [hours, minutes] = value.split(":");
  date.setHours(hours, minutes, 0, 0);
  return date.getTime();
};

export const convertScheduleTime = ({ schedule, fromTimezone, toTimezone }) => {
  const daysOfWeek = [
    DAYS_ABBR.SUNDAY,
    DAYS_ABBR.MONDAY,
    DAYS_ABBR.TUESDAY,
    DAYS_ABBR.WEDNESDAY,
    DAYS_ABBR.THURSDAY,
    DAYS_ABBR.FRIDAY,
    DAYS_ABBR.SATURDAY,
  ];
  const localSchedule = {
    [DAYS_ABBR.SUNDAY]: [],
    [DAYS_ABBR.MONDAY]: [],
    [DAYS_ABBR.TUESDAY]: [],
    [DAYS_ABBR.WEDNESDAY]: [],
    [DAYS_ABBR.THURSDAY]: [],
    [DAYS_ABBR.FRIDAY]: [],
    [DAYS_ABBR.SATURDAY]: [],
  };
  const date = new Date();

  if (!schedule) {
    return localSchedule;
  }

  Object.entries(schedule).forEach(([day, times]) => {
    const dayIndex = daysOfWeek.findIndex((item) => item === day);

    times.forEach((time) => {
      const [hoursStart, minutesStart] = time.start.split(":");
      const [hoursEnd, minutesEnd] = time.end.split(":");

      let startDateTime = setDay(new Date(date), dayIndex);
      startDateTime.setHours(hoursStart, minutesStart);
      let endDateTime = setDay(new Date(date), dayIndex);
      endDateTime.setHours(hoursEnd, minutesEnd);

      if (endDateTime < startDateTime) {
        endDateTime.setDate(endDateTime.getDate() + 1); // handle overnight times
      }

      const utcDateStart = fromTimezone
        ? fromZonedTime(startDateTime, fromTimezone)
        : toZonedTime(startDateTime, toTimezone);
      const utcDateEnd = fromTimezone
        ? fromZonedTime(endDateTime, fromTimezone)
        : toZonedTime(endDateTime, toTimezone);

      const dayIndexUtc = new Date(utcDateStart).getDay();
      const dayKey = daysOfWeek[dayIndexUtc];

      if (
        getFormattedTime(utcDateStart) === "00:00" &&
        getFormattedTime(utcDateEnd, "end") === "24:00"
      ) {
        // Skip intervals that start at "00:00" and end at "24:00"
        return;
      }

      if (isSameDay(utcDateStart, utcDateEnd)) {
        localSchedule[dayKey].push({
          start: getFormattedTime(utcDateStart),
          end: getFormattedTime(utcDateEnd, "end"),
        });
      } else {
        const nextDayIndex = (dayIndexUtc + 1) % daysOfWeek.length;
        const nextDayKey = daysOfWeek[nextDayIndex];
        if (getFormattedTime(utcDateStart) !== "00:00") {
          localSchedule[dayKey].push({
            start: getFormattedTime(utcDateStart),
            end: "24:00",
          });
        }
        if (getFormattedTime(utcDateEnd, "end") !== "24:00") {
          localSchedule[nextDayKey].push({
            start: "00:00",
            end: getFormattedTime(utcDateEnd, "end"),
          });
        }
      }
    });
  });

  return localSchedule;
};
