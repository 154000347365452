import { Box, Typography } from "@mui/material";
import React from "react";

import styles from "./SumBox.module.scss";

export const SumBox = ({ icon, value = 0, title }) => {
  return (
    <Box className={styles.earn}>
      <Box className={styles.earnTop}>
        <Typography variant="body2">{title}</Typography>
        <Box className={styles.earnIconBox}>{icon}</Box>
      </Box>
      <Typography variant="h4_24">$ {value}</Typography>
    </Box>
  );
};
