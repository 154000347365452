import "theme/web/css/clash-display.css";
import "theme/web/css/clash-grotesk.css";
import "./App.scss";

import { ThemeProvider } from "@mui/material/styles";
import { StyledEngineProvider } from "@mui/material/styles";
import BackdropLoader from "Components/Loader/BackdropLoader";
import ModalContainer from "Components/Modal/Modal";
import AppSnackbar from "Components/Snackbar/Snackbar";
import React, { memo } from "react";
import { Provider } from "react-redux";
import LangService from "Services/Localization/LangService";
import ResponseInterceptor from "Services/ResponseInterceptor";
import store from "store";
import theme from "theme/theme";
import { ViewportService } from "Utils/ViewportContext";

import Pusher from "./modules/Pusher.module";
import Router from "./Router";
import AuthService from "./Services/Auth/AuthService";
import { YupCustomMethods } from "./YupCustomMethods";

const App = () => {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <YupCustomMethods />
          <AuthService />
          <ViewportService />
          <LangService />
          <ResponseInterceptor />
          <Pusher />
          <Router />
          <BackdropLoader />
          <AppSnackbar />
          <ModalContainer />
        </Provider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default memo(App);
