import React from "react";
import { Box, IconButton } from "@mui/material";
import styles from "./SidebarTablet.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { useIsMobile } from "../../Utils/Hooks";
import { toggleSidebar } from "../../Redux/appSlice";
import { ReactComponent as CloseIcon } from "assets/svg/close-mobile-menu.svg";

const SidebarTablet = () => {
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const isSidebarVisible = useSelector((state) => state.app.isSidebarVisible);

  const handleOpenSidebar = () => {
    dispatch(toggleSidebar());
  };

  return (
    <>
      {isMobile && (
        <IconButton
          onClick={handleOpenSidebar}
          className={styles.hamburgerMenu}
        >
          {isSidebarVisible ? (
            <CloseIcon className={styles.closeIcon} />
          ) : (
            <>
              <Box className={styles.line} />
              <Box className={styles.line} />
              <Box className={styles.line} />
            </>
          )}
        </IconButton>
      )}
    </>
  );
};

export default SidebarTablet;
