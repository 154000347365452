import { Box, IconButton, Typography } from "@mui/material";
import { Paper } from "@mui/material";
import { ReactComponent as IconBack } from "assets/svg/arrow-left.svg";
import { BackHeader } from "Components/BackHeader/BackHeader";
import Page from "Components/Page/Page";
import React, { useContext } from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LocalizationContext } from "Services/Localization/LocalizationContext";

import { fetchUserStatsDetailsByPeriod } from "../../Redux/usersSlice";
import styles from "./ClubMembers.module.scss";
import GraphTabs from "./components/GraphTabs/GraphTabs";
import LineGraph from "./components/LineGraph/LineGraph";
import LoadingBar from "./components/LoadingBar/LoadingBar";
import RowsList from "./components/RowsList/RowsList";

const ClubMembers = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useContext(LocalizationContext);
  const {
    loading,
    details: { week: weekD, month: monthD, year: yearD, all: allD },
  } = useSelector((state) => state.users.stats);
  const [totalSub, setTotalSub] = useState(0);
  const initialStatData = {
    name: null,
    subs: {
      new: 0,
      lost: 0,
    },
    growth: {
      value: 0,
      percentage: 0,
    },
    capitalization: {
      entries: [],
      ticks: [0, 50, 100, 150, 200],
    },
    dates: {
      start: null,
      end: null,
    },
  };
  const [week, setWeek] = useState(initialStatData);
  const [month, setMonth] = useState(initialStatData);
  const [sixMonth, setSixMonth] = useState(initialStatData);
  const [year, setYear] = useState(initialStatData);
  const [all, setAll] = useState(initialStatData);
  const [activeTab, setActiveTab] = useState(0);

  const tabData = [week, month, sixMonth, year, all];
  const rowsData = [week, month, sixMonth, year];
  const tabLabels = [
    t("insights.week"),
    t("insights.month"),
    t("insights.halfYear"),
    t("insights.year"),
    t("insights.allTime"),
  ];

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  function calculateSubscribers(data = []) {
    let newSubscribers = 0;
    let lostSubscribers = 0;
    const total = data.length ? data[data.length - 1]?.numFollowers : 0;

    for (let i = 0; i < data.length; i++) {
      const change = data[i]?.numFollowersChange || 0;

      if (change > 0) {
        newSubscribers += change;
      } else if (change < 0) {
        lostSubscribers += Math.abs(change);
      }
    }

    const diff = newSubscribers - lostSubscribers;

    let percentage;
    if (total === 0) {
      if (diff === 0) {
        percentage = 0;
      } else {
        percentage = null;
      }
    } else {
      percentage = ((Math.abs(diff) / total) * 100).toFixed(1);
    }

    return {
      subs: { new: newSubscribers, lost: lostSubscribers },
      growth: { value: diff, percentage },
    };
  }

  function calculateCapitalizationData(data = []) {
    const values = data.map((d) => d.capitalization);
    const entries = values.map((v) => ({ value: v }));
    let min = Math.max(Math.floor(Math.min(...values)), 0);
    let max = Math.ceil(Math.max(...values));

    max = max > min ? max : min + 4;
    let step = Math.max(Math.floor((max - min) / 4), 1);

    const ticks = [min, min + step, min + 2 * step, min + 3 * step, max].map(
      Math.round
    );

    return { ticks, entries };
  }

  function constructNewState({ name, data }) {
    if (!data || data.length === 0) {
      return initialStatData;
    }

    const subscribers = calculateSubscribers(data);
    const capitalization = calculateCapitalizationData(data);
    const { subs, growth } = subscribers;
    const { ticks, entries } = capitalization;

    const startDate = data[0].createdAt;
    const endDate = data[data.length - 1].createdAt;

    const newState = {
      name,
      subs,
      growth,
      capitalization: {
        entries,
        ticks,
      },
      dates: {
        start: startDate,
        end: endDate,
      },
    };

    return newState;
  }

  useEffect(() => {
    dispatch(fetchUserStatsDetailsByPeriod());
  }, []);

  useEffect(() => {
    if (weekD.length) {
      setTotalSub(weekD[weekD.length - 1].numFollowers);
    }
    weekD &&
      setWeek(constructNewState({ name: t("insights.week"), data: weekD }));
  }, [weekD]);

  useEffect(() => {
    monthD &&
      setMonth(constructNewState({ name: t("insights.month"), data: monthD }));
  }, [monthD]);

  useEffect(() => {
    if (yearD) {
      const middleIndex = Math.ceil(yearD.length / 2);
      setSixMonth(
        constructNewState({
          name: t("insights.halfYear"),
          data: yearD.slice(middleIndex),
        })
      );
    }
  }, [yearD]);

  useEffect(() => {
    yearD &&
      setYear(constructNewState({ name: t("insights.year"), data: yearD }));
  }, [yearD]);

  useEffect(() => {
    allD &&
      setAll(constructNewState({ name: t("insights.allTime"), data: allD }));
  }, [allD]);

  const content = (
    <>
      <Paper className={styles.transparentPaper}>
        <LineGraph data={tabData[activeTab]} totalSub={totalSub} />
        <GraphTabs
          activeTab={activeTab}
          handleTabChange={handleTabChange}
          tabLabels={tabLabels}
        />
        <RowsList data={rowsData} />
      </Paper>
    </>
  );

  return (
    <Page className={styles.page}>
      <Box className={styles.header}>
        <BackHeader title={t("insights.clubMembers")} isBackArrow={true} />
      </Box>
      {loading ? <LoadingBar numberOfBars={2} /> : content}
    </Page>
  );
};

export default ClubMembers;
