import { Button, Typography, Modal } from "@mui/material";
import { Box } from "@mui/system";
import { ReactComponent as IconWarning } from "assets/svg/icon-warning.svg";
import { useContext } from "react";
import { LocalizationContext } from "Services/Localization/LocalizationContext";
import styles from "./Modal.module.scss";

const SetupStripeWarningModal = ({ stripeUrl, onCancel }) => {
  const { t } = useContext(LocalizationContext);

  const handleCofigureStripe = () => {
    window.location.href = stripeUrl;
  };
  return (
    <Modal open={!!stripeUrl} onClose={onCancel}>
      <Box className={styles.modalBody}>
        <Box className={styles.modalTextBody}>
          <IconWarning />
          <Box className={styles.modalText}>
            <Typography
              className={styles.modalTitle}
              id="modal-modal-title"
              variant="h4"
            >
              {t("community.setupStripeTitle")}
            </Typography>
            <Typography className={styles.modalSubTitle} variant="h6">
              {t("community.setupStripeSubtitle")}
            </Typography>
          </Box>
        </Box>
        <Box className={`${styles.buttons} ${styles.singleBtn}`}>
          <Button
            onClick={handleCofigureStripe}
            variant="contained"
            className={styles.confBtn}
          >
            {t("community.configurePaymentBtn")}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
export default SetupStripeWarningModal;
