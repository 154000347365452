import { Box, Typography } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { LocalizationContext } from "Services/Localization/LocalizationContext";

import { PeerBox, SectionBox, Video } from "../../ui-components";
import styles from "./StreamingVideosSection.module.scss";

export const StreamingVideosSection = ({ meetingSession, user, callData }) => {
  const { t } = useContext(LocalizationContext);
  const localVideoRef = useRef(null);
  const [remoteUserJoined, setRemoteUserJoined] = useState(false);
  const [remoteCameraOn, setRemoteCameraOn] = useState(false);

  useEffect(() => {
    if (!meetingSession) {
      return;
    }
    if (!localVideoRef.current) {
      console.error("No local video element found.");
      return;
    }

    const videoElement = localVideoRef.current;
    if (videoElement) {
      const observer = {
        videoTileDidUpdate: (tileState) => {
          if (!tileState.boundAttendeeId || !tileState.localTile) {
            return;
          }

          meetingSession.audioVideo.bindVideoElement(
            tileState.tileId,
            videoElement
          );
          videoElement.id = `video-${tileState.boundAttendeeId}`;
        },
      };

      meetingSession.audioVideo.addObserver(observer);
    }
  }, [meetingSession]);

  const videoSlotsRef = useRef(
    Array(25)
      .fill()
      .map(() => ({ tileId: null, video: null }))
  );

  const [enabledTiles, setEnabledTiles] = useState([]);
  const enableTile = (tileId) =>
    setEnabledTiles((previous) => [...previous, tileId]);
  const disableTile = (tileId) =>
    setEnabledTiles((previous) => previous.filter((p) => p !== tileId));
  const isEnabledTile = (tileId) => enabledTiles.includes(tileId);

  useEffect(() => {
    if (!meetingSession) return;

    const findSlot = (tileId) =>
      videoSlotsRef.current.find((slot) => slot.tileId === tileId) ||
      videoSlotsRef.current.find((slot) => !slot.tileId);
    const mapToAssignedSlot = (assigningTileId, assigningSlot) =>
      videoSlotsRef.current.map((slot) =>
        slot.video === assigningSlot.video
          ? { ...slot, tileId: assigningTileId }
          : slot
      );
    const mapToUnassignedSlot = (unassigningTileId) =>
      videoSlotsRef.current.map((slot) =>
        slot.tileId === unassigningTileId ? { ...slot, tileId: null } : slot
      );

    const mutateVideoSlotsRef = (updatingSlots) => {
      videoSlotsRef.current = updatingSlots;
    };

    const observer = {
      videoTileDidUpdate: (tileState) => {
        if (
          !tileState.boundAttendeeId ||
          tileState.localTile ||
          tileState.isContent
        ) {
          return;
        }
        const slot = findSlot(tileState.tileId);
        if (!slot) {
          console.log("Failed to find slot for remote peer.");
          return;
        }
        setRemoteCameraOn(true);

        mutateVideoSlotsRef(mapToAssignedSlot(tileState.tileId, slot));

        if (tileState.active) {
          enableTile(tileState.tileId);
        }
        if (!slot || !slot.video) {
          console.log("Failed to find slot for remote peer.");
          return;
        }

        try {
          meetingSession.audioVideo.bindVideoElement(
            tileState.tileId,
            slot.video
          );
          slot.video.id = `video-${tileState.boundAttendeeId}`;
        } catch (e) {
          console.error(
            `error meetingSession.audioVideo.bindVideoElement for slot.tileId=${slot.tileId}`,
            e
          );
        }
      },
      videoTileWasRemoved: (tileId) => {
        mutateVideoSlotsRef(mapToUnassignedSlot(tileId));
        disableTile(tileId);
        if (enabledTiles.length <= 1) {
          setRemoteCameraOn(false);
        }
      },
    };

    const callback = (attendeeId, present) => {
      if (
        attendeeId ===
        (meetingSession?.configuration.credentials.attendeeId ?? "")
      ) {
        return;
      }
      if (present) {
        setRemoteUserJoined(true);
      } else {
        setRemoteUserJoined(false);
      }
    };
    meetingSession?.audioVideo.realtimeSubscribeToAttendeeIdPresence(callback);
    meetingSession.audioVideo.addObserver(observer);

    return () => {
      meetingSession?.audioVideo.removeObserver(observer);
    };
  }, [meetingSession]);

  return (
    <SectionBox
      aria-label="Streaming videos"
      display="flex"
      justifyContent="center"
      className={styles.streaming}
    >
      <PeerBox title="Local user" enabled className={styles.myVideo}>
        <Box className={styles.myVideoPoster}>
          {user?.userPicOriginalUrl ? (
            <img alt="avatar" src={user.userPicOriginalUrl} />
          ) : null}
        </Box>
        <Video ref={localVideoRef} />
      </PeerBox>
      {callData?.appUser?.profile && !remoteUserJoined && !remoteCameraOn ? (
        <Box className={styles.celebVideo}>
          <Box
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              background: `url(${callData.appUser.profile?.userPicOriginalUrl})`,
              filter: "blur(5px)",
            }}
          />
          <Box className={styles.celebVideoOverlay}>
            <Box className={styles.celebVideoOverlayContent}>
              <Typography variant="h4">
                {t("default.videCallPreviewTitle")}
              </Typography>
              <Typography variant="body1">
                {t("default.videCallPreviewSubTitle", {
                  name: callData.appUser.profile?.username,
                })}
              </Typography>
            </Box>
          </Box>
          <img src={callData.appUser.profile.userPicOriginalUrl} alt="avatar" />
        </Box>
      ) : null}
      {callData?.appUser && remoteUserJoined && !remoteCameraOn ? (
        <Box className={`${styles.celebVideo} ${styles.celebVideoPoster}`}>
          <img
            src={callData.appUser.profile?.userPicOriginalUrl}
            alt="avatar"
          />
        </Box>
      ) : null}
      {videoSlotsRef.current.map((slot, index) => (
        <PeerBox
          key={index}
          title={`Remote user #${index}`}
          enabled={isEnabledTile(slot.tileId)}
          className={styles.celebVideo}
        >
          <Video
            ref={(video) => (slot.video = video)}
            className="streaming-video streaming-video-remote"
          />
        </PeerBox>
      ))}
    </SectionBox>
  );
};
