import * as React from "react";
import styles from "./PhoneNumber.module.scss";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

export default React.forwardRef(({ children, className, ...rest }, ref) => {
  const classNames = `${styles.PhoneInput} ${className}`;

  return (
    <PhoneInput
      ref={ref}
      {...rest}
      className={classNames}
      defaultCountry="US"
      international
      countryCallingCodeEditable
    >
      {children}
    </PhoneInput>
  );
});
