import { Box, Typography } from "@mui/material";
import { differenceInDays, getYear, isToday } from "date-fns";
import DOMPurify from "dompurify";
import React, { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hideSidebar } from "Redux/appSlice";
import { setActiveChat } from "Redux/messengerSlice";
import { getActiveChat } from "Redux/selectors/messengerSelectors";
import { LocalizationContext } from "Services/Localization/LocalizationContext";
import { useIsMobile } from "Utils/Hooks";
import { getConvertedTimeZoneDate } from "Utils/index";

import styles from "./ChatItem.module.scss";

export const ChatItem = (data) => {
  const { id, otherUser, lastMessage, newMessages } = data;

  const { t } = useContext(LocalizationContext);
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const activeChat = useSelector(getActiveChat);

  const setActiveHandler = () => {
    dispatch(setActiveChat(data));
    if (isMobile) {
      dispatch(hideSidebar());
    }
  };

  const convertData = (value) => {
    const dateValue = new Date(value);
    const today = new Date();
    const currentYear = getYear(today);
    const dayDifference = differenceInDays(today, dateValue);

    if (isToday(dateValue) || dayDifference === 0) {
      return getConvertedTimeZoneDate(value, "HH:mm");
    } else {
      if (dayDifference <= 28) {
        return dayDifference === 1
          ? `1 ${t("default.dayAgo")}`
          : `${dayDifference} ${t("default.daysAgo")}`;
      } else {
        return getYear(dateValue) === currentYear
          ? getConvertedTimeZoneDate(value, "MMM dd")
          : getConvertedTimeZoneDate(value, "MMM dd yyyy");
      }
    }
  };

  return (
    <Box
      className={`${styles.chat} ${
        id === activeChat?.id ? styles.chatActive : ""
      }`}
      onClick={setActiveHandler}
    >
      <img
        src={otherUser.profileImageUrl}
        alt="avatar"
        className={otherUser?.isInfluencer ? styles.influencerAvatar : ""}
      />
      <Box className={styles.chatBody}>
        <Box className={`${styles.chatTop} ${styles.chatRow}`}>
          <Typography variant="body4_16" className={styles.chatName}>
            {otherUser.username}
          </Typography>
          {lastMessage?.timestamp || lastMessage?.createdAt ? (
            <Typography variant="body3" className={styles.chatTime}>
              {convertData(lastMessage.timestamp || lastMessage.createdAt)}
            </Typography>
          ) : null}
        </Box>
        <Box className={`${styles.chatRow} ${styles.chatRowText}`}>
          <Typography
            className={`${styles.opacity} ${styles.message}`}
            variant="body2"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(
                lastMessage?.messageContent ? lastMessage.messageContent : "",
                {
                  USE_PROFILES: { html: true },
                }
              ),
            }}
          />
          {newMessages && id !== activeChat?.id ? (
            <Typography
              variant="text_12_display_bold"
              className={styles.newMessages}
            >
              {newMessages}
            </Typography>
          ) : null}
        </Box>
      </Box>
    </Box>
  );
};
