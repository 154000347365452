import { Box, CircularProgress, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, { useContext } from "react";
import { LocalizationContext } from "Services/Localization/LocalizationContext";

import { usePolicyHostUrl } from "../../../../Utils/Hooks";
import { InfoIcon } from "../../icons";
import { Row } from "../Row/Row";
import { RowMobile } from "../Row/RowMobile";
import { TooltipBox } from "../TooltipBox/TooltipBox";
import styles from "./RowsList.module.scss";

export const RowsList = ({ data, loader }) => {
  const { t } = useContext(LocalizationContext);
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down(1025));
  const getDynamicZoopPolicyUrl = usePolicyHostUrl();

  const goToFAQ = (e) => {
    e.stopPropagation();
    window.open(getDynamicZoopPolicyUrl("terms-of-service"));
  };

  if (!data?.length) {
    return null;
  }

  return (
    <Box className={styles.wrapper}>
      {!isMd ? (
        <Box className={styles.thead}>
          <Box className={styles.col}>
            <Typography variant="body4">{t("default.date")}</Typography>
          </Box>
          <Box className={styles.col}>
            <Typography variant="body4">
              {t("default.descriptionKey")}
            </Typography>
          </Box>
          <Box className={styles.col}>
            <Typography variant="body4">{t("default.gross")}</Typography>
          </Box>
          <Box className={styles.col}>
            <Typography variant="body4" className={styles.colTitle}>
              {t("default.fees")}
              <TooltipBox
                cb={goToFAQ}
                title={t("default.earningsFeesTitle")}
                actionText={t("default.clickMoreDetails")}
              >
                <InfoIcon />
              </TooltipBox>
            </Typography>
          </Box>
          <Box className={styles.col}>
            <Typography variant="body4">{t("default.net")}</Typography>
          </Box>
        </Box>
      ) : null}
      {!data?.length ? (
        <Typography variant="body4" component="p" className={styles.nodata}>
          {t("default.noData")}
        </Typography>
      ) : null}
      <Box className={styles.tbodyWrapper}>
        {loader ? <CircularProgress className={styles.loader} /> : null}
        {data.map((item) =>
          isMd ? (
            <RowMobile data={item} goToFAQ={goToFAQ} />
          ) : (
            <Row data={item} classNameCol={styles.col} />
          )
        )}
      </Box>
    </Box>
  );
};
