export const CopyIcon = () => (
  <svg
    width="19"
    height="22"
    viewBox="0 0 19 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.16885 2L15.9115 2C16.5188 2 17 2.4927 17 3.08853L17 15.4749C17 16.0821 16.5073 16.5634 15.9115 16.5634L7.16885 16.5634C6.56157 16.5634 6.08032 16.0707 6.08032 15.4749L6.08032 3.08852C6.08032 2.48124 6.56157 2 7.16885 2Z"
      stroke="#ABB3F3"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M13.5625 18.9115C13.5625 19.5188 13.0698 20 12.474 20L3.73135 20C3.12407 20 2.64282 19.5073 2.64282 18.9115L2.64282 6.52514C2.64282 5.91785 3.13553 5.43661 3.73135 5.43661"
      stroke="#ABB3F3"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
  </svg>
);
