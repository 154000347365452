import React, { useEffect, useState } from "react";

import { YOUTUBE_REGEX } from "../../Constants";

export const YouTubeVideo = ({
  youtubeUrl,
  className,
  globalActiveParentIndex,
  globalActiveMediaIndex,
  parentIndex,
  mediaIndex,
  onPlayerStateChange = () => {},
}) => {
  const [player, setPlayer] = useState(null);
  const videoId = youtubeUrl?.match(YOUTUBE_REGEX)
    ? youtubeUrl?.match(YOUTUBE_REGEX)[1]
    : null;

  // const onPlayerReady = () => {};

  const onYouTubeIframeAPIReady = (videoId) => {
    if (videoId && window.YT?.Player) {
      return new window.YT.Player(`youtube-${parentIndex}`, {
        height: "315",
        width: "560",
        videoId: videoId,
        playerVars: {
          playsinline: 1,
        },
        events: {
          // onReady: onPlayerReady,
          onStateChange: onPlayerStateChange,
        },
      });
    }
  };

  useEffect(() => {
    const player = onYouTubeIframeAPIReady(videoId);
    if (player) {
      setPlayer(player);
    }
  }, [videoId]);

  useEffect(() => {
    if (
      parentIndex !== globalActiveParentIndex ||
      mediaIndex !== globalActiveMediaIndex
    ) {
      if (
        player?.pauseVideo &&
        player?.playerInfo?.playerState &&
        player?.playerInfo?.playerState !== 3
      ) {
        player.pauseVideo();
      }
    }
  }, [
    globalActiveMediaIndex,
    globalActiveParentIndex,
    mediaIndex,
    parentIndex,
    player,
  ]);

  if (!youtubeUrl) {
    return null;
  }

  return (
    <div
      id={`youtube-${parentIndex}`}
      className={`${className}`}
      referrerPolicy="no-referrer-when-downgrade"
    />
  );
};
