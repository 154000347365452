import { Box } from "@mui/material";
import React, { useContext } from "react";
import { LocalizationContext } from "Services/Localization/LocalizationContext";

import { AddFriendIcon, RocketIcon } from "../../icons";
import { SumBox } from "../SumBox/SumBox";
import styles from "./SumBoxList.module.scss";

export const SumBoxList = ({ allTimeEarnings = 0, monthlyAverage = 0 }) => {
  const { t } = useContext(LocalizationContext);

  return (
    <Box>
      <Box className={styles.row}>
        <SumBox
          title={t("default.sumBox1Title")}
          value={allTimeEarnings}
          icon={<AddFriendIcon />}
        />
        <SumBox
          title={t("default.sumBox2Title")}
          value={monthlyAverage}
          icon={<RocketIcon />}
        />
      </Box>
    </Box>
  );
};
