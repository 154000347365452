import { Box, IconButton, Modal, Typography } from "@mui/material";
import { ReactComponent as CloseReply } from "assets/svg/reply-close-icon.svg";
import { PostCreator } from "Components/PostCreator/PostCreator";
import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { updatePostScheduled } from "Redux/scheduledPostsSlice";
import { LocalizationContext } from "Services/Localization/LocalizationContext";

import styles from "./ModalPostCreator.module.scss";

export const ModalPostCreator = ({ isOpen, setOpenModal, selectedPostId }) => {
  const { t } = useContext(LocalizationContext);
  const posts = useSelector((state) => state.scheduledPosts.posts);

  const closeHandler = () => setOpenModal(false);

  if (!isOpen) {
    return <></>;
  }

  return (
    <Modal open={isOpen} onClose={() => setOpenModal(false)}>
      <Box className={styles.wrapper}>
        <IconButton onClick={closeHandler} className={styles.close}>
          <CloseReply />
        </IconButton>
        <Typography className={styles.title} variant="h4_24" component="p">
          {t("community.editPost")}
        </Typography>
        <PostCreator
          isUpdate={true}
          isSchedule={true}
          sendButtonTitle={t("default.continueKey")}
          formClassname={styles.form}
          selectedPostId={selectedPostId}
          turnOffClass={styles.turnOffClass}
          closePostCreatorModal={closeHandler}
          post={posts[selectedPostId]}
          onSubmitHandler={updatePostScheduled}
        />
      </Box>
    </Modal>
  );
};
