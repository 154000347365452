import { Box } from "@mui/material";
import React from "react";
import {
  Area,
  AreaChart,
  Line,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import { useIsTablet } from "Utils/Hooks";

import styles from "./LineGraph.module.scss";

const LineGraphComponent = ({ info, data }) => {
  const isTablet = useIsTablet();
  return (
    <Box className={styles.wrapper}>
      <ResponsiveContainer
        width={isTablet ? "103%" : "105%"}
        height={56}
        className={styles.graph}
      >
        <AreaChart data={data} className={styles.clickable}>
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor="#505AA9" stopOpacity={0.3} />
              <stop offset="100%" stopColor="#505AA9" stopOpacity={0} />
            </linearGradient>
          </defs>
          <XAxis dataKey="name" hide={true} />
          <YAxis hide={true} />
          <Line type="monotone" dataKey="value" stroke="#505AA9" dot={false} />
          <Area
            type="monotone"
            dataKey="value"
            stroke="#505AA9"
            fillOpacity={1}
            strokeWidth={1}
            fill="url(#colorUv)"
            dot={false}
          />
        </AreaChart>
      </ResponsiveContainer>
      {info ? info : null}
    </Box>
  );
};

export default LineGraphComponent;
