import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { ReactComponent as UploadIconBtn } from "assets/svg/account/upload-photo-btn.svg";
import { ReactComponent as ArrowDown } from "assets/svg/arrow-down.svg";
import { ReactComponent as UploadIcon } from "assets/svg/icon-upload.svg";
import { ReactComponent as RadioButtonCheckedIcon } from "assets/svg/radio-button-checked-icon.svg";
import { ReactComponent as RadioButtonUnCheckedIcon } from "assets/svg/radio-button-unchecked-icon.svg";
import ModalUploadPhoto from "Components/Modal/ModalUploadPhoto";
import Page from "Components/Page/Page";
import {
  getCategories,
  LEVEL_BIGGER,
  LEVEL_GROUP,
  LEVEL_SMALLER,
  MainAppDomain,
  PATHS,
  POST_IN_GROUPS,
  TUTORIALS_PROPS,
  USER_ME_STATUS,
} from "Constants";
import { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setSnackbar } from "Redux/appSlice";
import {
  changeUser,
  editCommunity,
  updateUser,
  uploadPhoto,
} from "Redux/usersSlice";
import { LocalizationContext } from "Services/Localization/LocalizationContext";
import * as Yup from "yup";

import { CustomAlert } from "../../Components/CustomAlert/CustomAlert";
import { ShareBtn } from "../../Components/ShareBtn/ShareBtn";
import SidebarTablet from "../../Components/SidebarTablet/SidebarTablet";
import { fetchUser } from "../../Redux/mainAppUserSlice";
import { getUsersMe } from "../../Redux/selectors/userSelector";
import { FormErrorMessage } from "./FormErrorMessage";
import { CopyIcon } from "./icons";
import styles from "./Profile.module.scss";
import SocialMediaLinks from "./SocialMediaLinks";

const Profile = () => {
  const { t } = useContext(LocalizationContext);

  const validationSchema = Yup.object().shape({
    name: Yup.string().trim().required(t("errors.required")),
    handle: Yup.string()
      .trim()
      .matches(/^[a-zA-Z0-9_@.]+$/, t("errors.handleError"))
      .required(t("errors.required")),
    about: Yup.string()
      .trim()
      .max(500, t("errors.aboutMaxChar"))
      .test(
        "maxLines",
        t("errors.aboutMaxLines"),
        (value) => (value.match(/^.*(\r\n|\r|\n)?$/gm) || []).length <= 11
      ),
    category: Yup.string().notRequired(),
  });

  const [openUploadPhoto, setOpenUploadPhoto] = useState(false);
  const [loading, setLoading] = useState(false);
  const [inputFields, setInputFields] = useState([]);
  const [allowPosting, setAllowPosting] = useState(POST_IN_GROUPS.ONLY_ME);

  const user = useSelector(getUsersMe);

  const {
    about = "",
    category = null,
    name = "",
    handle = "",
    level = null,
    seenTutorials = null,
  } = user;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleUploadPhotoOpen = () => setOpenUploadPhoto(true);
  const handleUploadPhotoClose = () => setOpenUploadPhoto(false);

  const handleUploadPhoto = (payload) => {
    dispatch(uploadPhoto(payload)).then(() => {
      handleUploadPhotoClose();
    });
  };

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    setError,
    watch,
    formState: {
      errors: { name: nameError, about: aboutError, handle: handleError },
    },
    control,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const headerTitle =
    user?.status === USER_ME_STATUS.NEW
      ? t("account.profilePageHeader")
      : t("account.profile");

  const watchNameValue = watch("name");
  const watchHandleValue = watch("handle");
  const watchLevelValue = watch("level");

  const isDisableSubmit =
    !user?.profileImageUrl ||
    user?.profileImageUrl.includes("default") ||
    !watchNameValue?.trim().length ||
    !watchHandleValue?.trim().length ||
    !watchLevelValue;

  const closeAlertHandler = () => {
    const params = {
      seenTutorials: {
        ...seenTutorials,
        [TUTORIALS_PROPS.PROFILE_COMPLETE]: true,
      },
    };
    dispatch(changeUser(params));
    dispatch(updateUser(params));
  };

  useEffect(() => {
    if (user?.community?.postingRights) {
      setAllowPosting(user.community.postingRights);
    }
  }, [user?.community?.postingRights]);

  useEffect(() => {
    setValue("about", about || "");
  }, [about]);

  useEffect(() => {
    setValue("category", category || "none");
  }, [category]);

  useEffect(() => {
    setValue("name", name);
  }, [name]);

  useEffect(() => {
    setValue("handle", handle);
  }, [handle]);

  useEffect(() => {
    setValue("level", level);
  }, [level, setValue]);

  useEffect(() => {
    dispatch(fetchUser()).then(() => {
      if (window.zE) {
        window.zE("messenger:set", "zIndex", 99999);
        window.zE("messenger", "show");
      }
    });
  }, []);

  const onSubmit = (data) => {
    setLoading(true);
    const newData = { ...data };
    if (newData.category === "none") {
      delete newData.category;
    }
    if (level) {
      delete newData.level;
    }
    dispatch(
      updateUser({
        ...newData,
        ...{
          socialMedia: inputFields.reduce((acc, item) => {
            item.link &&
              acc.push({
                socialPlatformId: item.socialPlatformId,
                link: item.link,
              });
            return acc;
          }, []),
        },
      })
    )
      .then((res) => {
        setLoading(false);
        if (res?.payload?.status === 400) {
          dispatch(
            setSnackbar({
              message: t("account.errorHandle"),
              open: true,
              right: "20px",
              left: "unset",
              severity: "error",
            })
          );
          setError("handle", t("account.errorHandle"));
        } else {
          dispatch(
            setSnackbar({
              message: t("account.savedSuccess"),
              open: true,
              right: "20px",
              left: "unset",
            })
          );
          if (
            allowPosting !== POST_IN_GROUPS.SELECTED_MEMBERS ||
            level === LEVEL_GROUP
          ) {
            dispatch(editCommunity({ postingRights: allowPosting }));
          }
          if (user?.status === USER_ME_STATUS.NEW) {
            navigate(PATHS.COMMUNITY);
            //ToDo: hot solution, need to reload page to connect ws
            setTimeout(() => {
              window.location.reload();
            }, 0);
          }
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <Page className={styles.container}>
      <Box className={styles.titleWrap}>
        <SidebarTablet />
        <Typography variant="h2" className={styles.title}>
          {headerTitle}
        </Typography>
      </Box>
      <Box>
        <CustomAlert
          severity="info"
          title={t("account.profileSetupAlert")}
          classNameWrapper={styles.alert}
          classNameTitle={styles.alertTitle}
          isShow={
            (user?.status === USER_ME_STATUS.NEW || !level) &&
            !seenTutorials?.[TUTORIALS_PROPS.PROFILE_COMPLETE]
          }
          onClose={closeAlertHandler}
        />
        {user && (
          <>
            <Box className={styles.mainInfoWrapper}>
              <Box className={styles.mainInfo}>
                <Box className={styles.avatarWrap}>
                  <Avatar
                    variant="square"
                    src={user?.profileImageUrl}
                    className={styles.profileImage}
                    onClick={handleUploadPhotoOpen}
                  />
                  <Box className={styles.profileImageOverlay}>
                    <UploadIcon width={24} height={24} />
                  </Box>
                </Box>
                <Box className={styles.mainInfoLeft}>
                  <Box>
                    <Button
                      variant="contained"
                      className={styles.setAvatarBtn}
                      onClick={handleUploadPhotoOpen}
                    >
                      {t("account.setAvatarBtn")}
                      <UploadIconBtn className={styles.cameraIcon} />
                    </Button>
                  </Box>
                </Box>
              </Box>
              {user?.status === USER_ME_STATUS.PROFILE_COMPLETED ? (
                <ShareBtn shareLink={`${MainAppDomain}/${handle}`} />
              ) : null}
            </Box>
          </>
        )}

        <Box>
          <Box>
            <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
              <Typography variant="subtitle" className={styles.subtitle}>
                {t("account.accountType")}
                {!level ? (
                  <Typography
                    className={styles.subtitleDescription}
                    variant="body2"
                  >
                    {t("account.accountTypeDescription")}
                  </Typography>
                ) : null}
              </Typography>
              <FormControl key={level}>
                <Controller
                  name="level"
                  control={control}
                  render={({ field }) => (
                    <RadioGroup
                      {...field}
                      aria-labelledby="radio-buttons-group-label"
                      row
                      className={`${styles.radioGroup} ${
                        user?.status !== USER_ME_STATUS.NEW
                          ? styles.radioGroupDisabled
                          : ""
                      }`}
                      defaultValue={level}
                    >
                      <FormControlLabel
                        value={
                          user?.status !== USER_ME_STATUS.NEW &&
                          level &&
                          level !== LEVEL_SMALLER
                            ? LEVEL_BIGGER
                            : LEVEL_SMALLER
                        }
                        disabled={user?.status !== USER_ME_STATUS.NEW && level}
                        className={
                          watchLevelValue === LEVEL_SMALLER ||
                          watchLevelValue === LEVEL_BIGGER
                            ? styles.radioGroupLabelActive
                            : ""
                        }
                        control={
                          <Radio
                            classes={{
                              root: styles.radioRoot,
                            }}
                            icon={<RadioButtonUnCheckedIcon />}
                            checkedIcon={<RadioButtonCheckedIcon />}
                          />
                        }
                        label={
                          <Box className={styles.radioLabelInfo}>
                            <Typography
                              variant="body18"
                              className={styles.radioLabel}
                            >
                              {t("account.accountType1")}
                            </Typography>
                            <Typography
                              variant="body2"
                              className={styles.radioDescription}
                            >
                              {t("account.accountType1Description")}
                            </Typography>
                          </Box>
                        }
                      />
                      <FormControlLabel
                        value={LEVEL_GROUP}
                        disabled={user?.status !== USER_ME_STATUS.NEW && level}
                        className={
                          watchLevelValue === LEVEL_GROUP
                            ? styles.radioGroupLabelActive
                            : ""
                        }
                        control={
                          <Radio
                            classes={{
                              root: styles.radioRoot,
                            }}
                            icon={<RadioButtonUnCheckedIcon />}
                            checkedIcon={<RadioButtonCheckedIcon />}
                          />
                        }
                        label={
                          <Box className={styles.radioLabelInfo}>
                            <Typography
                              variant="body18"
                              className={styles.radioLabel}
                            >
                              {t("account.accountType2")}
                            </Typography>
                            <Typography
                              variant="body2"
                              className={styles.radioDescription}
                            >
                              {t("account.accountType2description")}
                            </Typography>
                          </Box>
                        }
                      />
                    </RadioGroup>
                  )}
                />
              </FormControl>
              {watchLevelValue === LEVEL_GROUP ? (
                <Box className={styles.groupCheckboxSection}>
                  <Typography
                    variant="body4_16"
                    className={styles.groupCheckboxSectionTitle}
                  >
                    {t("account.groupCheckboxSectionTitle")}
                  </Typography>
                  <Typography variant="body2">
                    {t("account.groupCheckboxSectionDescription")}
                  </Typography>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox />}
                      label={t("account.groupCheckboxSectionLabel")}
                      labelPlacement="start"
                      className={styles.groupCheckboxSectionLabel}
                      onChange={(e) =>
                        setAllowPosting(
                          e.target.checked
                            ? POST_IN_GROUPS.EVERYONE
                            : POST_IN_GROUPS.ONLY_ME
                        )
                      }
                      checked={
                        allowPosting === POST_IN_GROUPS.EVERYONE ||
                        allowPosting === POST_IN_GROUPS.SELECTED_MEMBERS
                      }
                    />
                  </FormGroup>
                </Box>
              ) : null}
              <Typography variant="subtitle" className={styles.subtitle}>
                {t("account.generalTitle")}
              </Typography>
              <Box className={styles.infoBlock}>
                <FormControl className={styles.formRow}>
                  <Box className={styles.formCol}>
                    <Box className={styles.label}>
                      {t("account.displayNameLabel")}
                      <i>*</i>
                    </Box>
                    <TextField
                      label={null}
                      error={!!nameError}
                      className={styles.textarea}
                      type="text"
                      autoComplete="off"
                      {...register("name")}
                      placeholder={t("account.displayNameLabel")}
                      sx={{
                        ".MuiInputBase-input": {
                          background: "transparent",
                        },
                      }}
                    />
                    <FormErrorMessage
                      isError={!!nameError}
                      message={nameError?.message}
                    />
                  </Box>
                  <Box className={styles.formCol}>
                    <Box className={styles.label}>
                      {t("account.userNameLabel")}
                      <i>*</i>
                    </Box>
                    <TextField
                      label={null}
                      error={!!handleError}
                      className={styles.textarea}
                      type="text"
                      autoComplete="off"
                      {...register("handle")}
                      inputProps={{ maxLength: 40 }}
                      InputProps={{
                        startAdornment: (
                          <Box className={styles.startAdornment}>
                            {MainAppDomain}/
                          </Box>
                        ),
                        endAdornment: (
                          <ShareBtn
                            isText={false}
                            icon={<CopyIcon />}
                            className={styles.shareInput}
                            shareLink={`${MainAppDomain}/${handle}`}
                          />
                        ),
                      }}
                      sx={{
                        ".MuiInputBase-input": {
                          background: "transparent",
                        },
                      }}
                    />
                    <FormErrorMessage
                      isError={!!handleError}
                      message={handleError?.message}
                    />
                  </Box>
                </FormControl>

                <FormControl
                  className={`${styles.selectWrap} ${styles.formRow}`}
                >
                  <Box className={styles.formCol}>
                    <Box className={styles.label}>{t("account.category")}</Box>
                    <Controller
                      render={({ field }) => (
                        <Select
                          {...field}
                          value={getValues("category") || "none"}
                          label={"Category"}
                          IconComponent={ArrowDown}
                          className={`${styles.select} ${
                            getValues("category") === "none"
                              ? styles.selectOpacity
                              : ""
                          }`}
                          {...register("category")}
                          MenuProps={{
                            classes: { paper: styles.dropdownMenu },
                          }}
                          notched={false}
                        >
                          <MenuItem
                            key={"none"}
                            disabled
                            value={"none"}
                            className={styles.selectPlaceholder}
                          >
                            {t("account.selectCategoryPlaceholder")}
                          </MenuItem>
                          {Object.entries(getCategories({ t })).map(
                            (category) => (
                              <MenuItem key={category[0]} value={category[0]}>
                                {category[1]}
                              </MenuItem>
                            )
                          )}
                        </Select>
                      )}
                      name="category"
                      control={control}
                      defaultValue=""
                    />
                  </Box>
                </FormControl>

                <FormControl>
                  <Box className={styles.label}>{t("account.bioLabel")}</Box>
                  <TextField
                    label={null}
                    error={!!aboutError}
                    className={styles.textarea}
                    multiline
                    rows={4}
                    type="text"
                    autoComplete="off"
                    {...register("about")}
                    placeholder={t("account.textareaPlaceholder")}
                    sx={{
                      ".MuiInputBase-input": {
                        background: "transparent",
                      },
                    }}
                  />
                  <FormErrorMessage
                    isError={!!aboutError}
                    message={aboutError?.message}
                  />
                </FormControl>
              </Box>
              <SocialMediaLinks
                setInputFields={setInputFields}
                inputFields={inputFields}
              />
              <LoadingButton
                type="submit"
                variant="contained"
                loading={loading}
                disabled={loading || isDisableSubmit}
                className={styles.submitBtn}
              >
                {t("account.saveBtn")}
              </LoadingButton>
            </form>
          </Box>
        </Box>
      </Box>

      <ModalUploadPhoto
        open={openUploadPhoto}
        handleClose={handleUploadPhotoClose}
        handleClick={handleUploadPhoto}
        isCanDeletePhoto={!!user?.profileImageUrl}
        profileImageUrl={user?.profileImageUrl}
      />
    </Page>
  );
};
export default Profile;
