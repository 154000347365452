import { mainApi } from "Services/Api/api.service";
const MAIN_API_URL = process.env.REACT_APP_MAIN_API_HOST;

export const getUnreadCountMessages = async (params) => {
  try {
    const result = await mainApi.get(`${MAIN_API_URL}/chats/unread-count`, {
      params,
    });
    return result?.data;
  } catch (e) {
    console.error("GET chats", e);
  }
};

export const getChats = async (params) => {
  try {
    const result = await mainApi.get(`${MAIN_API_URL}/chats`, { params });
    return result?.data;
  } catch (e) {
    console.error("GET chats", e);
  }
};

export const getChat = async ({ chatId, ...params }) => {
  try {
    const result = await mainApi.get(`${MAIN_API_URL}/chats/${chatId}`, {
      params,
    });
    return result?.data;
  } catch (e) {
    console.error("GET chats", e);
  }
};

export const getSearchChat = async (params) => {
  try {
    const result = await mainApi.get(`${MAIN_API_URL}/chats/search-chat`, {
      params,
    });
    return result?.data;
  } catch (e) {
    console.error("GET chats", e);
  }
};

export const getSearchUser = async (params) => {
  try {
    const result = await mainApi.get(`${MAIN_API_URL}/chats/search-user`, {
      params,
    });
    return result?.data;
  } catch (e) {
    console.error("GET search user", e);
  }
};

export const postNewChat = async (params) => {
  try {
    const result = await mainApi.post(`${MAIN_API_URL}/chats`, params);
    return result?.data;
  } catch (err) {
    console.error("Create new chat", err);
    throw err;
  }
};

export const deleteChat = async ({ chatId, type, ...params }) => {
  try {
    const result = await mainApi.patch(
      `${MAIN_API_URL}/chats/${chatId}/clean`,
      params
    );
    return result?.data;
  } catch (err) {
    console.error("Create new chat", err);
    throw err;
  }
};

export const sendMessage = async ({
  chatId,
  isMy,
  chatAccepted,
  ...params
}) => {
  try {
    const result = await mainApi.post(
      `${MAIN_API_URL}/chats/${chatId}`,
      params
    );
    return result?.data;
  } catch (err) {
    console.error("GET search user", err);
    throw err;
  }
};

export const patchMessagesSeen = async ({ chatId, otherChatId, ...params }) => {
  try {
    const result = await mainApi.patch(
      `${MAIN_API_URL}/chats/${otherChatId}/seen`,
      params
    );
    return result?.data;
  } catch (e) {
    console.error("GET search user", e);
  }
};
