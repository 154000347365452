import { Box, Skeleton } from "@mui/material";

import styles from "./ListSkeleton.module.scss";

export const ListSkeleton = ({ isShow }) => {
  if (!isShow) {
    return null;
  }

  return (
    <Box className={styles.wrapper}>
      <Skeleton variant="rectangular" className={styles.skeleton} />
      <Skeleton variant="rectangular" className={styles.skeleton} />
      <Skeleton variant="rectangular" className={styles.skeleton} />
      <Skeleton variant="rectangular" className={styles.skeleton} />
      <Skeleton variant="rectangular" className={styles.skeleton} />
    </Box>
  );
};
