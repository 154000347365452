import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAccessToken, getIdToken } from "Redux/selectors/appSelector";
import PusherService from "Services/Pusher/Pusher.service";

import { EVENTS } from "../Constants";
import { addRewardMessage } from "../Redux/limitedEditionSlice";
import {
  addNewMessage,
  markAllUnseenMessagesAsSeen,
  setDeletedChatId,
  setNewChat,
  updateChatCounterUnreadMessages,
  updateGlobalCounterUnreadMessages,
} from "../Redux/messengerSlice";
import { changeUser } from "../Redux/usersSlice";

const PusherModule = () => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(getAccessToken);
  const idToken = useSelector(getIdToken);

  const subscribe = (pusher) => {
    PusherService.pusher.user.bind(EVENTS.CHATS, (data) => {
      dispatch(addNewMessage(data));
    });
    PusherService.pusher.user.bind(EVENTS.CHATS_UNREAD_COUNT, (data) => {
      if (data.chatId) {
        dispatch(updateChatCounterUnreadMessages(data));
      } else {
        dispatch(updateGlobalCounterUnreadMessages(data));
      }
    });
    PusherService.pusher.user.bind(EVENTS.CHATS_MESSAGE_SEEN, (data) => {
      if (data.chatId) {
        dispatch(markAllUnseenMessagesAsSeen(data));
      }
    });
    PusherService.pusher.user.bind(EVENTS.CHATS_DELETED, (data) => {
      if (data.chatId) {
        dispatch(setDeletedChatId(data.chatId));
      }
    });
    PusherService.pusher.user.bind(EVENTS.CHATS_DELETED_RESTORE, (res) => {
      if (res?.data) {
        dispatch(setNewChat(res.data));
      }
    });
    PusherService.pusher.user.bind(EVENTS.REWARD_MESSAGING, (res) => {
      if (res?.data?.message) {
        dispatch(
          addRewardMessage({
            messageContent: res.data.message,
            id: Date.now(),
            date: Date.now(),
            isIncoming: true,
            rewardOrderId: res.rewardOrderId,
          })
        );
      }
    });
    PusherService.pusher.user.bind(EVENTS.KYC, (res) => {
      if (res?.result === "success") {
        dispatch(changeUser({ status: "verified" }));
      }
    });
  };

  const unsubscribe = (pusher) => pusher.unbind_global();

  const pusherRestart = () => {
    if (PusherService.pusher) {
      PusherService.disconnect();
      unsubscribe(PusherService.pusher);
    }

    if (isAuthenticated) {
      PusherService.signin();
    } else {
      PusherService.init();
    }

    PusherService.pusher.connection.bind("connected", () => {
      if (isAuthenticated) {
        subscribe(PusherService.pusher);
      }
    });
  };

  const pusherReset = () => {
    unsubscribe(PusherService.pusher);
    PusherService.disconnect();
  };

  useEffect(() => {
    return () => {
      pusherReset();
    };
  }, []);

  useEffect(() => {
    pusherRestart();
  }, [isAuthenticated, idToken]);

  return <></>;
};

export default PusherModule;
