import { useTheme } from "@emotion/react";
import { Box, Typography } from "@mui/material";
import classNames from "classnames/bind";
import { EDITION_STATUSES } from "Constants/index";
import { useContext } from "react";
import { LocalizationContext } from "Services/Localization/LocalizationContext";

import { NotReleased } from "../components/NotReleased";
import { Price } from "../components/Price/Price";
import { UpcomingDrop } from "../components/UpcomingDrop";
import { CardContentItem } from "./components/CardContentItem/CardContentItem";
import styles from "./SmallCard.module.scss";

const CONTENT = {
  [EDITION_STATUSES.phase0]: {
    ContentCmpnt: UpcomingDrop,
  },
  [EDITION_STATUSES.phase2]: {
    ContentCmpnt: Price,
    showAllocation: true,
    showRevenue: true,
  },
  [EDITION_STATUSES.complete]: {
    ContentCmpnt: Price,
    showAllocation: true,
    showRevenue: true,
  },
  [EDITION_STATUSES.notReleased]: {
    ContentCmpnt: NotReleased,
  },
  defaultContent: {
    ContentCmpnt: Box,
  },
};

export const SmallCard = ({ className, data }) => {
  const cx = classNames.bind(styles);
  const {
    status,
    cardNum,
    quantityAllocated = 0,
    title,
    saleTokensLeft = 0,
    cardRevenue = 0,
  } = data;
  const { showAllocation, showRevenue, ContentCmpnt } =
    CONTENT[status] || CONTENT.defaultContent;

  const theme = useTheme();
  const { t } = useContext(LocalizationContext);

  const availableCardValue =
    Number(saleTokensLeft) === 0
      ? t("seasons.soldOut")
      : `${saleTokensLeft} / ${quantityAllocated}`;

  const CardNumItem = () =>
    cardNum ? (
      <Typography
        noWrap
        variant="body1"
        color={theme.palette.accent.main}
        component="span"
      >
        #{cardNum}{" "}
      </Typography>
    ) : null;

  const FooterTitle = () =>
    title ? (
      <Typography variant="body1" component="span" noWrap>
        {title}
      </Typography>
    ) : null;

  return (
    <Box className={cx(className, styles.card)}>
      <Box className={styles.cardContent}>
        <ContentCmpnt
          {...data}
          className={styles.cardContentBlock}
          overrideStyles={styles}
        />
        <CardContentItem
          isVisible={showAllocation}
          title={t("seasons.available")}
          color={theme.palette.lime.secondary}
          value={availableCardValue}
        />
        <CardContentItem
          isVisible={showRevenue}
          title={t("seasons.revenue")}
          color={theme.palette.lime.aqua}
          value={`$${cardRevenue}`}
        />
      </Box>
      <Box className={styles.cardFooter}>
        <CardNumItem />
        <FooterTitle />
      </Box>
    </Box>
  );
};
