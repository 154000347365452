import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import BackBtn from "Components/BackBtn/BackBtn";
import SidebarTablet from "Components/SidebarTablet/SidebarTablet";
import React from "react";
import { useIsMobile } from "Utils/Hooks";

import styles from "./BackHeader.module.scss";

export const BackHeader = ({
  title,
  pathBack,
  className,
  isSidebarHidden,
  isBackArrow = true,
}) => {
  const isLg = useIsMobile();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(769));

  return (
    <Box className={`${styles.titleWrap} ${className}`}>
      {!isMobile && !isSidebarHidden ? <SidebarTablet /> : null}
      {!isLg && !isSidebarHidden && isBackArrow ? (
        <BackBtn isPlain link={pathBack || -1} />
      ) : null}
      {(isMobile || isSidebarHidden) && isBackArrow ? (
        <BackBtn isPlain link={pathBack || -1} />
      ) : null}
      <Typography variant="h2" className={styles.title}>
        {title}
      </Typography>
    </Box>
  );
};
