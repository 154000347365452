import { Box, Typography } from "@mui/material";
import React, { useContext } from "react";
import { LocalizationContext } from "Services/Localization/LocalizationContext";

import { InfoIcon } from "../../icons";
import { TooltipBox } from "../TooltipBox/TooltipBox";
import styles from "./Row.module.scss";

export const RowMobile = ({
  goToFAQ,
  data: { date, description, gross, fee, net },
}) => {
  const { t } = useContext(LocalizationContext);

  return (
    <Box className={styles.tr}>
      <Box className={styles.topBlock}>
        <Typography
          component="span"
          variant="body3_medium_14_display"
          className={styles.descriptionTitle}
        >
          {t(`default.${description?.type}`)}
        </Typography>
        <Typography
          component="span"
          variant="textNormal"
          className={styles.descriptionTitleDate}
        >
          {date}
        </Typography>
      </Box>
      <Box className={styles.bottomBlock}>
        <Box className={styles.col}>
          <Typography variant="body2" className={styles.colTitle}>
            {t("default.gross")}
          </Typography>
          <Typography variant="body3_medium_14_display">${gross}</Typography>
        </Box>
        <Box className={styles.col}>
          <Typography variant="body2" className={styles.colTitle}>
            {t("default.fees")}
            <TooltipBox
              cb={goToFAQ}
              title={t("default.earningsFeesTitle")}
              actionText={t("default.clickMoreDetails")}
            >
              <InfoIcon />
            </TooltipBox>
          </Typography>
          <Typography variant="body3_medium_14_display">${fee}</Typography>
        </Box>
        <Box className={styles.col}>
          <Typography variant="body2" className={styles.colTitle}>
            {t("default.net")}
          </Typography>
          <Typography>${net}</Typography>
        </Box>
      </Box>
    </Box>
  );
};
