import { Box, IconButton, Popover, Typography } from "@mui/material";
import { ReactComponent as IconApprove } from "assets/svg/icon-approve.svg";
import { ReactComponent as IconDelete } from "assets/svg/icon-delete.svg";
import { ReactComponent as IconEdit } from "assets/svg/icon-edit.svg";
import { ReactComponent as IconKebab } from "assets/svg/icon-kebab.svg";
import { MODAL_TYPE, PATHS, POST_STATUS } from "Constants";
import { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setModal } from "Redux/appSlice";
import { getMainAppUserId } from "Redux/selectors/mainAppUserSelector";
import { LocalizationContext } from "Services/Localization/LocalizationContext";

import styles from "./ActionsControls.module.scss";

export const ActionsControls = ({
  postId,
  approve,
  reject,
  status,
  appUser,
  handleEditPost,
}) => {
  const mainAppUserId = useSelector(getMainAppUserId);

  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const { t } = useContext(LocalizationContext);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleDeletePost = (postId) => {
    handleClose();

    dispatch(
      setModal({
        open: true,
        type: MODAL_TYPE.DELETE_POST,
        payload: {
          postId,
          backRoute: PATHS.COMMUNITY,
        },
      })
    );
  };
  return (
    <>
      <IconButton onClick={handleClick} className={styles.iconWrapperKebab}>
        <IconKebab />
      </IconButton>
      <Popover
        id={"popover"}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 35,
          horizontal: -100,
        }}
        className={styles.popoverWrapper}
      >
        <Box className={styles.kebabPopover}>
          {status === POST_STATUS.PUBLISHED ? (
            <>
              {mainAppUserId === appUser?.id || !appUser?.id ? (
                <Typography
                  variant="body2"
                  className={styles.popoverItem}
                  onClick={handleEditPost}
                >
                  <IconEdit />
                  {t("community.editPost")}
                </Typography>
              ) : null}
              <Typography
                variant="body2"
                onClick={() => {
                  handleDeletePost(postId);
                }}
                className={`${styles.red} ${styles.popoverItem}`}
              >
                <IconDelete /> {t("community.deletePost")}
              </Typography>
            </>
          ) : (
            <>
              <Typography
                variant="body2"
                className={styles.popoverItem}
                onClick={() => {
                  handleClose();
                  approve();
                }}
              >
                <IconApprove />
                {t("community.approvePost")}
              </Typography>
              <Typography
                variant="body2"
                onClick={() => {
                  handleClose();
                  reject();
                }}
                className={`${styles.red} ${styles.popoverItem}`}
              >
                <IconDelete /> {t("community.rejectPost")}
              </Typography>
            </>
          )}
        </Box>
      </Popover>
    </>
  );
};
