import { Box, Typography } from "@mui/material";
import { ReactComponent as ArrowDownIcon } from "assets/svg/arrow-down.svg";
import { ReactComponent as SettingsIcon } from "assets/svg/icon-settings.svg";
import ActiveLink from "Components/ActiveLink";
import { PATHS } from "Constants";
import React, { useContext } from "react";
import { LocalizationContext } from "Services/Localization/LocalizationContext";

import styles from "./Settings.module.scss";

const SettingsMenu = ({ isMobile, onClickSettingsMenu }) => {
  const { t } = useContext(LocalizationContext);
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <Box className={`${styles.settingsMenu} ${isMenuOpen ? styles.open : ""}`}>
      <Box onClick={toggleMenu} className={styles.settingMenu}>
        <SettingsIcon className={`${styles.icon} ${styles.settingMenuIcon}`} />
        <Typography className={styles.menuItem}>
          {t("default.settings")}
        </Typography>
        <ArrowDownIcon
          className={`${styles.arrowDownIcon} ${styles.settingMenuArrowIcon}`}
        />
      </Box>
      <Box className={styles.dropdown}>
        <ActiveLink
          to={PATHS.EDIT_COMMUNITY}
          className={`${styles.menuItem} ${styles.settingsMenuItem}`}
          activeClass={`${styles.menuItemActive} ${styles.settingsMenuItem}`}
          onClick={isMobile ? onClickSettingsMenu : () => null}
        >
          <span>{t("default.myClub")}</span>
        </ActiveLink>
        <ActiveLink
          to={PATHS.SETTINGS_EMAIL_NOTIFICATIONS}
          className={`${styles.menuItem} ${styles.settingsMenuItem}`}
          activeClass={`${styles.menuItemActive} ${styles.settingsMenuItem}`}
          onClick={isMobile ? onClickSettingsMenu : () => null}
        >
          <span>{t("settings.emailNotifications")}</span>
        </ActiveLink>
      </Box>
    </Box>
  );
};

export default SettingsMenu;
