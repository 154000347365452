import { Box } from "@mui/material";
import React from "react";

import { SeasonItem } from "../SeasonItem/SeasonItem";

export const SeasonsList = ({ seasons }) => {
  return (
    <Box>
      {seasons.map(({ seasonCards, season }) => (
        <SeasonItem seasonCards={seasonCards} season={season} key={season.id} />
      ))}
    </Box>
  );
};
