import { Box, Typography } from "@mui/material";
import { COMMENT_TEXT_LIMIT } from "Constants/index";
import React, { useContext, useState } from "react";
import { LocalizationContext } from "Services/Localization/LocalizationContext";
import { truncateText } from "Utils/StringHelper";

import styles from "./CommentTextBlock.module.scss";

export const CommentTextBlock = ({
  message,
  isEditMode,
  isCommentEdited,
  replyToUser,
  replyToId,
}) => {
  const { t } = useContext(LocalizationContext);

  const [showMore, setShowMore] = useState(false);

  const showHandler = () => setShowMore(!showMore);

  const hideHandler = () => setShowMore((prevState) => !prevState);

  const EditedText = () =>
    isCommentEdited ? (
      <Typography
        component="span"
        variant="body2"
        className={styles.editedText}
      >
        {t("community.edited")}
      </Typography>
    ) : null;

  const ButtonMore = () =>
    !showMore && message.length > COMMENT_TEXT_LIMIT ? (
      <Typography
        valiant="body3"
        className={styles.commentItemMoreBtn}
        onClick={showHandler}
      >
        {t("community.seeMore")}
      </Typography>
    ) : null;

  const ButtonLess = () =>
    showMore && message.length > COMMENT_TEXT_LIMIT ? (
      <Typography
        valiant="body3"
        className={styles.commentItemMoreBtn}
        onClick={hideHandler}
      >
        {t("community.seeLess")}
      </Typography>
    ) : null;

  if (isEditMode) {
    return null;
  }

  return (
    <Box>
      <Typography>
        {replyToUser && replyToId ? (
          <a href={`#${replyToId}`} className={styles.replyUsername}>
            @{replyToUser}
          </a>
        ) : null}
        {showMore ? message : truncateText(message, COMMENT_TEXT_LIMIT)}
        <EditedText />
      </Typography>
      <ButtonMore />
      <ButtonLess />
    </Box>
  );
};
