export const getMatchString = (arr, sub) => {
  sub = sub.toLowerCase();
  return arr.find((str) => str.toLowerCase().startsWith(sub.slice(0, 2)));
};

export const truncateText = (text, max) => {
  if (text.length > max) {
    return text.substring(0, max) + "...";
  }
  return text;
};
