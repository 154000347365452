import { Box, Typography } from "@mui/material";
import { ReactComponent as InfoIcon } from "assets/svg/icon-info.svg";
import React, { useContext } from "react";
import { LocalizationContext } from "Services/Localization/LocalizationContext";
import { useIsMobile, useIsTablet } from "Utils/Hooks";

import { TooltipBox } from "../TooltipBox/TooltipBox";
import { Row } from "./components/Row/Row";
import { RowMobile } from "./components/Row/RowMobile";
import styles from "./RowsList.module.scss";

const RowsList = ({ data }) => {
  const { t } = useContext(LocalizationContext);
  const isMb = useIsMobile();
  const isTb = useIsTablet("1025px");
  const shouldChangeLayout = isMb && !isTb;

  if (!data?.length) {
    return null;
  }

  return (
    <Box className={styles.wrapper}>
      {!shouldChangeLayout ? (
        <Box className={styles.thead}>
          <Box className={styles.col}>
            <Typography variant="body4">{t("insights.duration")}</Typography>
          </Box>
          <Box className={styles.col}>
            <Typography variant="body4">{t("insights.newSubs")}</Typography>
          </Box>
          <Box className={styles.col}>
            <Typography variant="body4">{t("insights.growth")}</Typography>
          </Box>
          <Box className={styles.col}>
            <Typography variant="body4" className={styles.colTitle}>
              {t("insights.lostSubs")}
              <TooltipBox title={t("insights.lostSubsDesc")}>
                <InfoIcon />
              </TooltipBox>
            </Typography>
          </Box>
        </Box>
      ) : null}
      {!data?.length ? (
        <Typography variant="body4" component="p" className={styles.nodata}>
          {t("default.noData")}
        </Typography>
      ) : null}
      <Box className={styles.tbodyWrapper}>
        {data.map((item) =>
          shouldChangeLayout ? (
            <RowMobile data={item} />
          ) : (
            <Row data={item} classNameCol={styles.col} />
          )
        )}
      </Box>
    </Box>
  );
};

export default RowsList;
