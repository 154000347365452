import { useEffect } from "react";
import { api } from "Services/Api/api.service";

import { useNavigate } from "react-router-dom";

const ResponseInterceptor = () => {
  const navigate = useNavigate();

  useEffect(() => {
    api.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        if (error?.response) {
          if (error.response.status === 404) {
            navigate("/404");
          }
          if (error?.response?.data) {
            return Promise.reject(error?.response);
          }
          // else if (error.response.status === 500) {
          //   // navigate("/500");
          // }
        }
        return Promise.reject(error);
      }
    );
  }, []);
  return <></>;
};

export default ResponseInterceptor;
