/**
 *
 * @param { [getTimeRange] } timeSlots
 * @returns
 */
export const getClosestTimeSlot = ({ timeSlots }) => {
  const now = new Date().getTime();
  return timeSlots.find((item) => item.value > now);
};

/**
 *
 * @param {Object} params
 * @param {number} params.date
 * @returns
 */
export const getPostTimer = ({ date }) => {
  const currentDateTimestamp = new Date().getTime();
  const difference = date - currentDateTimestamp;

  const millisecondsInOneMinute = 60 * 1000;
  const millisecondsInOneHour = 60 * 60 * 1000;
  const millisecondsInOneDay = 24 * 60 * 60 * 1000;

  const days = Math.floor(difference / millisecondsInOneDay);
  const hours = Math.floor(
    (difference % millisecondsInOneDay) / millisecondsInOneHour
  );
  const minutes = Math.floor(
    (difference % millisecondsInOneHour) / millisecondsInOneMinute
  );

  return { days, hours, minutes };
};
