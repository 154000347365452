import { Button } from "@mui/material";
import { ReactComponent as IconYoutube } from "assets/svg/youtube-icon.svg";
import MediaResetPostModal from "Components/PostCreator/components/ResetModal/MediaResetPostModal";
import React, { useContext, useEffect, useState } from "react";
import { LocalizationContext } from "Services/Localization/LocalizationContext";

import { useIsMobile } from "../../../../Utils/Hooks";
import styles from "./AddMediaButton.module.scss";

export const YoutubeButton = ({
  isYoutubeSection,
  isAnotherMediaType,
  setYoutubeSection,
  handleMediaChange,
  setShouldHideTooltips,
}) => {
  const isMobile = useIsMobile();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useContext(LocalizationContext);

  useEffect(() => {
    setShouldHideTooltips && setShouldHideTooltips(isModalOpen);
  }, [isModalOpen]);

  const handleModalConfirmation = () => {
    handleMediaChange();
    setYoutubeSection((prevState) => !prevState);
  };

  const handleConfirmationModal = () => {
    if (isAnotherMediaType) {
      setIsModalOpen(true);
    } else {
      setYoutubeSection((prevState) => !prevState);
    }
  };

  return (
    <>
      <MediaResetPostModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        title={t("community.alertReplaceMediaTitle")}
        description={t("community.alertReplaceMediaBody")}
        handleModalConfirmation={handleModalConfirmation}
      />
      <Button
        variant="contained"
        size="small"
        className={`${styles.addPhotoBtn} ${
          isYoutubeSection ? styles.addPhotoBtnActive : ""
        }`}
        startIcon={<IconYoutube />}
        onClick={handleConfirmationModal}
      >
        {isMobile
          ? t("community.youtubeVideo")
          : t("community.addYoutubeVideo")}
      </Button>
    </>
  );
};
