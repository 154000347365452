import { api, mainApi } from "Services/Api/api.service";
const API_URL = process.env.REACT_APP_API_HOST;
const MAIN_API_URL = process.env.REACT_APP_MAIN_API_HOST;

export const getUser = () => {
  return api.get(`${API_URL}/me`).then((response) => response.data);
};

export const getAppUsersStatus = (params) => {
  return api
    .get(`${API_URL}/auth/check`, {
      params: { ...params },
    })
    .then((response) => response.data);
};

export const getUserStats = () => {
  return api.get(`${API_URL}/me/stats`).then((response) => response.data);
};

export const getUserStatsDetails = (params) => {
  const queryString = new URLSearchParams(params).toString();

  return api
    .get(`${API_URL}/me/stats-details?${queryString}`)
    .then((response) => response.data);
};

export const generateS3Url = async ({ type, ContentType, extension }) => {
  const params = {
    uploadType: "userpicOriginal",
    type,
    ContentType,
    extension,
  };
  try {
    return api.post(`${API_URL}/me/generate-upload-s3-url`, params);
  } catch (error) {
    console.error(error);
  }
};

export const updateUser = async (params) => {
  try {
    const result = await api.patch(`${API_URL}/me`, params);
    return result;
  } catch (error) {
    console.error("updateUser", error);
    return error;
  }
};

export const postCommunity = async (params) => {
  return api.post(`${API_URL}/me/community`, params).then((res) => res.data);
};

export const sendVerifyEmail = async (params) => {
  const result = await api.post(
    `${API_URL}/me/resend-verification-code`,
    params
  );
  return result;
};

export const sendVerifyCode = async (params) => {
  try {
    const result = await api.post(`${API_URL}/me/verify-email`, params);
    return result;
  } catch (error) {
    console.error("sendVerifyCode", error);
    return error;
  }
};

export const getEmailNotificationSettings = async () => {
  try {
    const response = await mainApi.get(
      `${MAIN_API_URL}/app-users/me/notifications-settings`
    );
    return response;
  } catch (error) {
    console.error("Error fetching email notification settings:", error);
    return error;
  }
};

export const updateEmailNotificationSettings = async (params) => {
  try {
    const response = await mainApi.patch(
      `${MAIN_API_URL}/app-users/me/notifications-settings`,
      params
    );
    return response;
  } catch (error) {
    console.error("Error updating email notification settings:", error);
    return error;
  }
};

export const blockUserById = async (userId) => {
  try {
    await mainApi.post(`${MAIN_API_URL}/blacklist`, { userId: userId });
  } catch (error) {
    console.error("Cannot block user", error);
  }
};

export const unblockUserById = async (userId) => {
  try {
    await mainApi.delete(`${MAIN_API_URL}/blacklist/${userId}`);
  } catch (error) {
    console.error("Cannot unblock user", error);
  }
};

export const getPublicUser = async (id) => {
  try {
    return mainApi.get(`${MAIN_API_URL}/public/users/${id}`);
  } catch (error) {
    console.error(error);
  }
};

export const getVideoCallCalendar = async (params) => {
  try {
    return api.get(`${API_URL}/me/calendar`, { params });
  } catch (error) {
    console.error(error);
  }
};

export const postVideoCallCalendar = async (params) => {
  try {
    return api.post(`${API_URL}/me/calendar`, params);
  } catch (error) {
    console.error(error);
  }
};
