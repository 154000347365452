import { api } from "Services/Api/api.service";
const API_URL = process.env.REACT_APP_API_HOST;

export const postCard = async (params) => {
  try {
    const result = await api.post(`${API_URL}/cards/custom`, params);
    return result;
  } catch (e) {
    throw new Error(e.data?.message);
  }
};

export const patchCard = async ({ cardId, ...rest }) => {
  try {
    const result = await api.patch(`${API_URL}/cards/${cardId}`, rest);
    return result;
  } catch (e) {
    throw new Error(e.data?.message);
  }
};

export const stopSaleCard = async ({ cardId, ...rest }) => {
  try {
    const result = await api.patch(
      `${API_URL}/cards/${cardId}/stop-sale`,
      rest
    );
    return result;
  } catch (e) {
    throw new Error(e.data?.message);
  }
};

export const getCard = async ({ cardId }) => {
  try {
    const result = await api.get(`${API_URL}/cards/${cardId}`);
    return result;
  } catch (e) {
    throw new Error(e.data?.message);
  }
};

export const getCardOrders = async ({ cardId, ...params }) => {
  try {
    const result = await api.get(`${API_URL}/cards/${cardId}/orders`, {
      params,
    });
    return result;
  } catch (e) {
    throw new Error(e.data?.message);
  }
};

export const getCards = async (params) => {
  try {
    const result = await api.get(`${API_URL}/me/custom-cards`, { params });
    return result.data;
  } catch (e) {
    console.log(e);
  }
};

export const deleteLeCard = async ({ cardId }) => {
  try {
    const result = await api.delete(`${API_URL}/cards/${cardId}`);
    return result;
  } catch (e) {
    throw new Error(e.data?.message);
  }
};

export const getRewardOrders = async (params) => {
  try {
    const result = await api.get(`${API_URL}/cards/reward-orders`, {
      params,
    });
    return result.data;
  } catch (e) {
    console.log(e);
  }
};

export const patchRewardOrder = async ({ cardId, orderId, ...params }) => {
  try {
    const result = await api.patch(
      `${API_URL}/cards/${cardId}/reward-orders/${orderId}`,
      params
    );
    return result.data;
  } catch (e) {
    console.log(e);
  }
};

export const getRewardMessages = async ({ cardId, orderId, ...params }) => {
  try {
    return api.get(
      `${API_URL}/cards/${cardId}/reward-orders/${orderId}/messages`,
      { params }
    );
  } catch (error) {
    console.error(error);
  }
};

export const postRewardMessage = async ({
  cardId,
  orderId,
  index,
  ...params
}) => {
  try {
    return api.post(
      `${API_URL}/cards/${cardId}/reward-orders/${orderId}/messages`,
      params
    );
  } catch (error) {
    console.error(error);
  }
};

export const patchRewardMessage = async ({
  cardId,
  orderId,
  index,
  ...params
}) => {
  try {
    return api.patch(
      `${API_URL}/cards/${cardId}/reward-orders/${orderId}`,
      params
    );
  } catch (error) {
    console.error(error);
  }
};

export const getScheduledCalls = async (params) => {
  try {
    const result = await api.get(`${API_URL}/scheduled-calls`, {
      params,
    });
    return result.data;
  } catch (error) {
    console.error("Get calendar error:", error);
  }
};
