import { Box } from "@mui/material";

import { RewardHeader } from "../RewardHeader/RewardHeader";

export const RewardCardChat = ({ title, statusTitle }) => {
  return (
    <Box>
      <RewardHeader title={title} statusTitle={statusTitle} />
    </Box>
  );
};
