import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

import styles from "./HeaderSection.module.scss";

export const HeaderSection = ({
  path,
  title,
  buttonText,
  actionBeforeNavigate,
  wrapperClass,
  customButton,
  customTitle,
}) => {
  const navigate = useNavigate();

  const clickHandler = () => {
    if (actionBeforeNavigate) {
      actionBeforeNavigate();
    }
    navigate(path);
  };

  return (
    <Box className={`${styles.wrapper} ${wrapperClass}`}>
      {customTitle ? (
        customTitle
      ) : (
        <Typography variant="subtitle" className={styles.title}>
          {title}
        </Typography>
      )}
      {customButton ? (
        customButton
      ) : path ? (
        <Button onClick={clickHandler}>{buttonText}</Button>
      ) : null}
    </Box>
  );
};
