import { Box, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { ViewAll } from "Components/ViewAll/ViewAll";
import { PATHS } from "Constants/index";
import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getUserMeCommunityComments,
  getUserMeCommunityMembers,
} from "Redux/selectors/userSelector";
import { LocalizationContext } from "Services/Localization/LocalizationContext";

import { MembersIcon } from "./icons";
import { StatsItemBlock } from "./StatsItemBlock/StatsItemBlock";
import styles from "./StatsSection.module.scss";

export const StatsSection = () => {
  const { t } = useContext(LocalizationContext);
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width:768px)");

  const numFollowers = useSelector(getUserMeCommunityMembers);
  const numComments = useSelector(getUserMeCommunityComments);

  const goTo = () => navigate(PATHS.INSIGHTS);

  return (
    <Box className={styles.content}>
      {!isMobile ? (
        <Box className={styles.contentHeader}>
          <Typography variant="body4" className={styles.contentTitle}>
            {t("default.clubStats")}
          </Typography>
          <ViewAll text={t("default.openInsights")} handler={goTo} />
        </Box>
      ) : null}
      <Box className={styles.list}>
        <StatsItemBlock
          icon={<MembersIcon />}
          title={t("community.members")}
          value={numFollowers || 0}
        />
        <StatsItemBlock
          value={numComments || 0}
          title={t("settings.emailNotificationsLabelComment")}
        />
      </Box>
    </Box>
  );
};
