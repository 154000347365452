import { format } from "date-fns";

import {
  AUDIO_FORMAT,
  AUDIO_TYPES,
  IMAGE_FORMAT,
  IMAGE_TYPES,
  MAX_ALL_FILES_SIZE,
  MAX_FILE_HEIGHT,
  MAX_FILE_SIZE,
  MEDIA_TYPES,
  VIDEO_DATA_ACTIVE,
  VIDEO_DATA_NOT_ACTIVE,
  VIDEO_FORMAT,
  VIDEO_TYPES,
} from "../../Constants";
import { setSnackbar } from "../../Redux/appSlice";

export const FILE_TYPES = [
  ".jpeg",
  ".jpg",
  ".png",
  ".mp4",
  ".mov",
  ".avi",
  ".mpeg",
  ".mp3",
];

const MEDIA_REF_NAME = "video";

export const EDITOR_TOOLBAR_ITEMS = [
  [
    { className: "ql-bold" },
    { className: "ql-italic" },
    { className: "ql-underline" },
    { className: "ql-strike" },
  ],
  [{ className: "ql-link" }],
];

const _validationTypes = ({ files, setErrorType }) => {
  // can't upload different types of files together
  let isValid = true;
  const allFiles = files.map((item) => item.file || item);
  const firstChildMediaType = allFiles[0]?.type.split("/")[0];

  if (allFiles.length > 1) {
    isValid = !allFiles.some(
      (file) => file.type.split("/")[0] !== firstChildMediaType
    );
  }
  if (!isValid) {
    setErrorType(true);
  }
  return isValid;
};

const _validationAllFilesSize = ({ files, dispatch, t }) => {
  const allFilesSize = files.reduce(
    (acc, current) => acc + current.file?.size || 0,
    0
  );
  if (allFilesSize > MAX_ALL_FILES_SIZE)
    dispatch(
      setSnackbar({
        message: t("community.maxAllFileSize"),
        open: true,
        right: "20px",
        left: "unset",
      })
    );
  return allFilesSize < MAX_ALL_FILES_SIZE;
};

const _validation = ({ files, dispatch, t, setErrorType }) => {
  const isValidTypes = _validationTypes({
    files,
    setErrorType,
  });
  const isValidAllSize = _validationAllFilesSize({
    files,
    t,
    dispatch,
  });
  return isValidAllSize && isValidTypes;
};

const _showImgErrorMessage = ({ img, file, t, dispatch }) => {
  const message =
    img.height > MAX_FILE_HEIGHT
      ? t("community.maxFileHeight")
      : file.size > MAX_FILE_SIZE
      ? t("community.maxFileSize")
      : "Something wrong";

  dispatch(
    setSnackbar({
      message,
      open: true,
      right: "20px",
      left: "unset",
    })
  );
};

const _setActiveVideoStyle =
  ({ videoContainer }) =>
  () => {
    videoContainer.dataset.active = "";
  };

const changeFiles = ({ files, dispatch, t, setFiles }) => {
  files.forEach((data) => {
    switch (data.file.type) {
      case IMAGE_TYPES.JPG:
      case IMAGE_TYPES.JPEG:
      case IMAGE_TYPES.PNG:
        changeImage({ dispatch, t, setFiles, file: data.file });
        break;
      case VIDEO_TYPES.MP4:
      case VIDEO_TYPES.MOV:
      case VIDEO_TYPES.AVI:
        changeVideo({ file: data.file, setFiles });
        break;
      case AUDIO_TYPES.MPEG:
        changeAudio({ file: data.file, setFiles });
        break;
      default:
        return null;
    }
  });
};

export const handleChangeFile =
  ({ dispatch, t, setFiles, filesInState, setErrorType }) =>
  (e) => {
    const targetFiles = Array.from(e.target.files).map((file) => ({ file }));
    const isValid = _validation({
      files: [...targetFiles, ...filesInState],
      t,
      dispatch,
      setErrorType,
    });

    if (!isValid) {
      e.target.value = "";
      return;
    }

    changeFiles({ files: targetFiles, setFiles, t, dispatch });
  };

const changeImage = ({ dispatch, t, setFiles, file }) => {
  const img = new Image();
  const imageUrl = URL.createObjectURL(file);
  img.src = imageUrl;
  img.onload = () => {
    if (img.height > MAX_FILE_HEIGHT || file.size > MAX_FILE_SIZE) {
      _showImgErrorMessage({ img, file, t, dispatch });
    } else {
      setFiles((prevState) => {
        return [...prevState, { imageUrl, height: img.height, file }];
      });
    }
  };
};

export const removeFile = ({ index, setFiles, files }) => {
  const newFiles = [...files];
  newFiles.splice(index, 1);
  setFiles(newFiles);
};

export const toggleActiveVideo = (ref) => () => {
  // ToDo: need to work with html node directly, because need to use controls
  const video = ref.current.querySelector(MEDIA_REF_NAME);
  if (video.paused) {
    ref.current.dataset.active = VIDEO_DATA_ACTIVE;
    video.play();
    video.addEventListener(
      "ended",
      _setActiveVideoStyle({ videoContainer: ref.current })
    );
  } else {
    video.pause();
    ref.current.dataset.active = VIDEO_DATA_NOT_ACTIVE;
    video.removeEventListener(
      "ended",
      _setActiveVideoStyle({ videoContainer: ref.current })
    );
  }
};

export const changeVideo = ({ file, setFiles }) => {
  const videoUrl = window.URL.createObjectURL(file);
  setFiles([{ file, videoUrl }]); // array for common logic
};

export const changeAudio = ({ file, setFiles }) => {
  const audioUrl = window.URL.createObjectURL(file);
  setFiles((prevState) => [...prevState, { file, audioUrl }]);
};

const _getFilenameAndTypeFromUrl = (url) => {
  const parts = url.split("/");
  const fileName = parts[parts.length - 1];
  const formatSplitted = fileName.split(".");
  const format = formatSplitted[formatSplitted.length - 1]?.toLowerCase();

  const fileType =
    format === VIDEO_FORMAT.MP4 ||
    format === VIDEO_FORMAT.AVI ||
    VIDEO_FORMAT.MOV
      ? `video/${format}`
      : format === IMAGE_FORMAT.JPG ||
        format === IMAGE_FORMAT.JPEG ||
        format === IMAGE_FORMAT.PNG
      ? `image/${format}`
      : format === AUDIO_FORMAT.MP3 || format === AUDIO_FORMAT.MPEG
      ? `audio/${format}`
      : "";
  return {
    fileName,
    fileType,
  };
};

export const getMediaType = (data) =>
  data.imageUrl
    ? MEDIA_TYPES.IMAGE
    : data.videoUrl
    ? MEDIA_TYPES.VIDEO
    : data.audioUrl
    ? MEDIA_TYPES.AUDIO
    : "";

export const urlToFileWithProps = async ({ url, type }) => {
  if (!url) return;
  const { fileName, fileType } = _getFilenameAndTypeFromUrl(url);
  const response = await fetch(url);
  const blob = await response.blob();
  const file = new File([blob], fileName, { type: fileType });

  return type === MEDIA_TYPES.IMAGE
    ? { file, imageUrl: url }
    : type === MEDIA_TYPES.VIDEO
    ? { file, videoUrl: url }
    : type === MEDIA_TYPES.AUDIO
    ? { file, audioUrl: url }
    : { file };
};
