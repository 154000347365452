import { Box, Typography } from "@mui/material";
import classNames from "classnames/bind";
import { PriceMovementIndicator } from "Components/PriceTitle/PriceMovementIndicator";
import { useContext } from "react";
import { LocalizationContext } from "Services/Localization/LocalizationContext";

import stylesGeneral from "./Price.module.scss";

export const Price = ({
  className,
  currencySymbol,
  priceMovement,
  currentSalePrice,
  overrideStyles,
}) => {
  const styles = overrideStyles || stylesGeneral;

  const cx = classNames.bind(styles);
  const { t } = useContext(LocalizationContext);
  return (
    <Box className={cx(className, styles.price)}>
      <Typography className={styles.priceTitle} variant="body5">
        {t("seasons.price")}
      </Typography>
      <Box className={styles.priceContent}>
        <Typography variant="body5" className={styles.priceContentValue}>
          {currencySymbol}
          {currentSalePrice}
        </Typography>
        <PriceMovementIndicator
          priceMovement={priceMovement}
          width={6}
          height={12}
          className={styles.priceContentIndicator}
        />
      </Box>
    </Box>
  );
};
