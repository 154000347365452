import * as Yup from "yup";

import { CardConstructorFields } from "./constant";

export const getValidationSchema = ({ requiredErrorMsg, t }) =>
  Yup.object().shape({
    [CardConstructorFields.name]: Yup.string()
      .trim()
      .required(requiredErrorMsg)
      .test(
        "len",
        t("errors.maxCharacters", { count: 100 }),
        (val) => val.length < 101
      ),
    [CardConstructorFields.description]: Yup.string()
      .trim()
      .required(requiredErrorMsg)
      .test(
        "len",
        t("errors.maxCharacters", { count: 1000 }),
        (val) => val.length < 1001
      ),
    [CardConstructorFields.salePrice]: Yup.number()
      .required(requiredErrorMsg)
      .typeError(t("errors.invalidValue"))
      .test("only numbers", t("errors.invalidValueLEPrice"), (value) =>
        /^(?!0*(?:\.0*)?$)([1-9]\d{1,}|\d{2,})(?:\.\d{1,2})?$/.test(
          String(value)
        )
      ),
    [CardConstructorFields.allocation]: Yup.number()
      .test(
        "only numbers",
        t("errors.invalidValueLETotal", { from: 1, to: 1000 }),
        (value) =>
          /^((?:[1-9]\d{0,2}(?:\.\d{1,2})?)|(?:1000(?:\.00?)?))$/.test(
            String(value)
          )
      )
      .typeError(t("errors.invalidValue"))
      .required(requiredErrorMsg),
  });
