import { Box, Button, Typography } from "@mui/material";
import EmptyBlock from "Components/EmptyBlock";
import Page from "Components/Page/Page";
import SidebarTablet from "Components/SidebarTablet/SidebarTablet";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getSeasons } from "Services/Api/seasonsApi";
import { LocalizationContext } from "Services/Localization/LocalizationContext";

import { ReactComponent as IconEdit } from "../../assets/svg/icon-edit.svg";
import { PATHS } from "../../Constants";
import { MainSeason } from "./components/MainSeason/MainSeason";
import { SeasonsList } from "./components/SeasonsList/SeasonsList";
import styles from "./Seasons.module.scss";
import { SeasonSkeleton } from "./SeasonSkeleton";

const isSeasonsEmpty = (data) => {
  return !data?.length || !data?.filter((item) => Boolean(item)).length; // need this check because BE can return array like this [null, null, ...]
};

export const Seasons = () => {
  const [seasons, setSeasons] = useState([]);
  const [loading, setSeasonsLoading] = useState(false);

  const navigate = useNavigate();

  const { t } = useContext(LocalizationContext);

  const getInfluencerSeason = () => {
    setSeasonsLoading(true);
    getSeasons()
      .then((data) => {
        const { seasonNum = 1 } = data?.season;
        if (seasonNum > 1) {
          Promise.all(
            [...Array(seasonNum - 1)].map((_, index) => {
              return getSeasons({ params: { seasonNum: index + 1 } });
            })
          ).then((result) => {
            const sortedResult = result.sort(
              (a, b) => b.season.seasonNum - a.season.seasonNum
            );
            setSeasons([data, ...sortedResult]);
            setSeasonsLoading(false);
          });
        } else {
          setSeasons([data]);
          setSeasonsLoading(false);
        }
      })
      .catch(() => {
        setSeasonsLoading(false);
      });
  };

  useEffect(() => {
    getInfluencerSeason();
  }, []);

  const lastSeason = seasons[0];
  const seasonsList = seasons.slice(1, seasons.length);
  const seasonNumTitle = lastSeason?.season.seasonNum
    ? lastSeason?.season.seasonNum
    : "";

  return (
    <Page className={styles.seasons}>
      <Box className={styles.titleWrap}>
        <SidebarTablet />
        <Typography variant="h2">
          {`${t("seasons.season")} ${seasonNumTitle}`}
          <Button
            className={styles.btnEdit}
            variant="text"
            size="small"
            onClick={() => navigate(PATHS.SEASONS_REWARDS_EDIT)}
            disabled={isSeasonsEmpty(seasons)}
          >
            <IconEdit />
          </Button>
        </Typography>
      </Box>
      {loading ? (
        <SeasonSkeleton />
      ) : isSeasonsEmpty(seasons) && !loading ? (
        <EmptyBlock text={t("seasons.noSeason")} />
      ) : (
        <>
          <MainSeason {...lastSeason} />
          <SeasonsList seasons={seasonsList} isSeasonsLoading={loading} />
        </>
      )}
    </Page>
  );
};
