import { Box } from "@mui/material";
import { ReactComponent as DatePickerIcon } from "assets/svg/datapiker-icon.svg";
import { CustomSelect } from "Components/CustomSelect/CustomSelect";
import { ScheduleCustomInput } from "Components/ScheduleCustomInput/ScheduleCustomInput";
import { useContext, useEffect, useState } from "react";
import { getVideoCallCalendar } from "Services/Api/userApi";
import { LocalizationContext } from "Services/Localization/LocalizationContext";

import { ScheduledModal } from "./components/ScheduledModal/ScheduledModal";
import styles from "./ScheduledReward.module.scss";
import { convertScheduleTime, DAYS_ABBR, getDayByAbbr } from "./utils";

export const ScheduledReward = ({ isShow, children, rowClassName }) => {
  const { t } = useContext(LocalizationContext);
  const options = [
    { label: `15 ${t("default.minutes")}`, value: 15 },
    { label: `30 ${t("default.minutes")}`, value: 30 },
    { label: `60 ${t("default.minutes")}`, value: 60 },
  ];

  const [duration, setDuration] = useState(15);
  const [isScheduledModal, setScheduledModal] = useState(false);
  const [calendarData, setCalendarData] = useState({
    duration,
    schedule: {
      [DAYS_ABBR.MONDAY]: [],
      [DAYS_ABBR.TUESDAY]: [],
      [DAYS_ABBR.WEDNESDAY]: [],
      [DAYS_ABBR.THURSDAY]: [],
      [DAYS_ABBR.FRIDAY]: [],
      [DAYS_ABBR.SATURDAY]: [],
      [DAYS_ABBR.SUNDAY]: [],
    },
    overrides: [],
  });
  const [calendarLoader, setCalendarLoader] = useState(true);

  const toggleScheduledModalHandler = (value) => () => setScheduledModal(value);

  const handleChange = (_, data) => {
    setDuration(data?.props?.value);
  };

  const getInputLabel = (value) => {
    const data = Object.entries(value.schedule).reduce(
      (acc, current) => {
        if (current[1].length) {
          acc.days.push(getDayByAbbr({ t, value: current[0] }));
          current[1].forEach((item) => {
            acc.time.start.push(item.start);
            acc.time.end.push(item.end);
          });
        }
        return acc;
      },
      { days: [], time: { start: [], end: [] } }
    );
    if (data.days.length) {
      if (data.time.start.length && data.time.end.length) {
        const isStartEqual = data.time.start.every(
          (val) => val === data.time.start[0]
        );
        const isEndEqual = data.time.end.every(
          (val) => val === data.time.end[0]
        );
        if (isEndEqual && isStartEqual) {
          data.days.push(`${data.time.start[0]} - ${data.time.end[0]}`);
        } else {
          data.days.push(t("default.hoursVary"));
        }
      }

      return data.days.join(", ");
    }
    return t("default.scheduledVideoInputPlaceholder");
  };

  useEffect(() => {
    getVideoCallCalendar()
      .then((res) => {
        if (res?.data) {
          const newData = JSON.parse(JSON.stringify(res.data));
          newData.schedule = convertScheduleTime({
            schedule: res.data.schedule,
            fromTimezone: "Etc/UTC",
          });
          setCalendarData(newData);
        }
      })
      .finally(() => setCalendarLoader(false));
  }, []);

  if (!isShow) {
    return null;
  }

  return (
    <Box className={`${styles.row} ${rowClassName ? rowClassName : ""}`}>
      {children ? (
        <Box onClick={toggleScheduledModalHandler(true)}>{children}</Box>
      ) : (
        <>
          <ScheduleCustomInput
            label={t("default.scheduledVideoInputLabel")}
            placeholder={getInputLabel(calendarData)}
            icon={<DatePickerIcon />}
            onClick={toggleScheduledModalHandler(true)}
            loader={calendarLoader}
          />
          <CustomSelect
            value={duration}
            onChange={handleChange}
            options={options}
            label={t("default.cardConstructorCallDurationLabel")}
            controlClassName={styles.selectWrapper}
            disabled={true}
          />
        </>
      )}

      <ScheduledModal
        isOpen={isScheduledModal}
        setOpen={setScheduledModal}
        duration={duration}
        calendarData={calendarData}
        setCalendarData={setCalendarData}
        calendarLoader={calendarLoader}
        setCalendarLoader={setCalendarLoader}
      />
    </Box>
  );
};
