import { Box, IconButton } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import stylesAuth from "Views/Login/Auth.module.scss";

import { ReactComponent as LogoIcon } from "../../assets/svg/logo.svg";
import AuthLeftSide from "../../Components/AuthLeftSide";
import LicenseDlg from "../../Components/TalentTerms/LicenseDlg";
import { useIsMobile } from "../../Utils/Hooks";
import { LicenseBlock } from "./LicenseBlock/LicenseBlock";
import styles from "./Main.module.scss";
import { SignUpForm } from "./SignUpForm/SignUpForm";
import { SuccessBlock } from "./SuccessBlock/SuccessBlock";

const MainPage = () => {
  const [data, setData] = useState(null);
  const [dialog, setDialog] = useState(false);
  const [success, setSuccess] = useState(false);

  const navigate = useNavigate();

  const isMobile = useIsMobile();

  const onSuccess = () => {
    setSuccess(true);
    window.scrollTo(0, 0);
  };

  return (
    <Box className={`${stylesAuth.authContainer}`}>
      {!isMobile && <AuthLeftSide step={0} />}
      <Box className={stylesAuth.authRightSide}>
        <Box
          className={`${stylesAuth.authRightSideContent} ${styles.signUpContent}`}
        >
          {isMobile && !dialog ? (
            <Box className={styles.authLogoWrapper}>
              <IconButton onClick={() => navigate("/")} sx={{ p: 0 }}>
                <LogoIcon className={styles.authLogo} />
              </IconButton>
            </Box>
          ) : null}
          {success ? (
            <SuccessBlock />
          ) : dialog && isMobile ? (
            <LicenseDlg
              open={dialog}
              success={onSuccess}
              data={data}
              closeModal={() => setDialog(false)}
            />
          ) : dialog && !isMobile ? (
            <LicenseBlock success={onSuccess} data={data} />
          ) : (
            <SignUpForm setData={setData} setDialog={setDialog} />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default MainPage;
