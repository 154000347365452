import { Box, IconButton } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { CognitoUser } from "amazon-cognito-identity-js";
import { ReactComponent as ArrowBack } from "assets/svg/arrow-left.svg";
import AuthLeftSide from "Components/AuthLeftSide";
import { ERROR_RESPONSE_TYPES } from "Constants/";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { errorResponseMessages } from "Services/Api/apiErrorHelper.tsx";
import { LocalizationContext } from "Services/Localization/LocalizationContext";
import UserPool from "Services/userPool";
import { useIsMobile } from "Utils/Hooks";
import styles from "Views/Login/Auth.module.scss";

import { ReactComponent as LogoIcon } from "../../assets/svg/logo.svg";
import { MainAppDomain, PATHS } from "../../Constants";
import { setLoader } from "../../Redux/appSlice";
import { isAuthenticated } from "../../Services/Auth/auth.service";
import Email from "./Email";
import Password from "./Password";
import Success from "./Success";
import Code from "./VerificationCode";

const forgotPasswordSteps = {
  email: 1,
  code: 2,
  password: 3,
  success: "success",
};
const ForgotPassword = () => {
  const dispatch = useDispatch();
  const { t } = useContext(LocalizationContext);
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [serverError, setServerError] = useState(false);
  const [step, setStep] = useState(forgotPasswordSteps.email);
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const [resendCountdown, setResendCountdown] = useState(0);

  const goBack = () => {
    setServerError(false);
    step > 1 ? setStep(step - 1) : navigate("/signin");
  };

  const sendCode = (email, isResend) => {
    setLoading(true);
    setServerError(false);

    const user = new CognitoUser({
      Username: email,
      Pool: UserPool,
    });

    user.forgotPassword({
      onSuccess: function (data) {
        setLoading(false);
        if (!isResend) {
          setStep(forgotPasswordSteps.code);
          setServerError(false);
          setEmail(email);
        } else {
          setResendCountdown(15);
        }
      },
      onFailure: function (err) {
        setServerError(errorResponseMessages(err, t));
        setLoading(false);
      },
    });
  };

  const onCodeSubmit = (code) => {
    setCode(code);
    setStep(forgotPasswordSteps.password);
    setServerError(false);
  };

  const onSubmit = (password) => {
    const user = new CognitoUser({
      Username: email,
      Pool: UserPool,
    });

    setLoading(true);
    setServerError(false);
    user.confirmPassword(code, password, {
      onSuccess() {
        setLoading(false);
        setStep(forgotPasswordSteps.success);
      },
      onFailure(err) {
        setLoading(false);
        setServerError(errorResponseMessages(err, t));
        if (
          err.code === ERROR_RESPONSE_TYPES.CodeMismatchException ||
          err.code === ERROR_RESPONSE_TYPES.CodeExpiredException
        ) {
          setStep(forgotPasswordSteps.code);
        }
      },
    });
  };

  useEffect(() => {
    dispatch(setLoader(true));
    setTimeout(() => {
      if (!isAuthenticated()) {
        window.location.href = `${MainAppDomain}/forgot-password`;
      } else {
        dispatch(setLoader(false));
        navigate(PATHS.HOME);
      }
    }, 1000);
  }, []);

  return (
    <Box>
      {/*{step === forgotPasswordSteps.success ? (*/}
      {/*  <Success />*/}
      {/*) : (*/}
      {/*  <Box className={styles.authContainer}>*/}
      {/*    {!isMobile && <AuthLeftSide step={0} />}*/}
      {/*    <Box className={styles.authRightSide}>*/}
      {/*      <Box className={styles.authRightSideContent}>*/}
      {/*        {!isMobile && (*/}
      {/*          <IconButton*/}
      {/*            onClick={goBack}*/}
      {/*            className={styles.backBtn}*/}
      {/*            sx={{ bgcolor: theme.palette.dark.surface }}*/}
      {/*          >*/}
      {/*            <ArrowBack width={40} height={40} />*/}
      {/*          </IconButton>*/}
      {/*        )}*/}
      {/*        {isMobile ? (*/}
      {/*          <Box className={styles.authLogoWrapper}>*/}
      {/*            <IconButton onClick={() => navigate("/")} sx={{ p: 0 }}>*/}
      {/*              <LogoIcon className={styles.authLogo} />*/}
      {/*            </IconButton>*/}
      {/*          </Box>*/}
      {/*        ) : null}*/}
      {/*        {step === forgotPasswordSteps.email && (*/}
      {/*          <Email*/}
      {/*            onSubmit={sendCode}*/}
      {/*            mainServerError={serverError}*/}
      {/*            isMainLoading={loading}*/}
      {/*          />*/}
      {/*        )}*/}
      {/*        {step === forgotPasswordSteps.code && (*/}
      {/*          <Code*/}
      {/*            email={email}*/}
      {/*            onResend={sendCode}*/}
      {/*            onSubmit={onCodeSubmit}*/}
      {/*            mainServerError={serverError}*/}
      {/*            isMainLoading={loading}*/}
      {/*            resendCountdown={resendCountdown}*/}
      {/*            setResendCountdown={setResendCountdown}*/}
      {/*          />*/}
      {/*        )}*/}
      {/*        {step === forgotPasswordSteps.password && (*/}
      {/*          <Password*/}
      {/*            onSubmit={onSubmit}*/}
      {/*            isMainLoading={loading}*/}
      {/*            mainServerError={serverError}*/}
      {/*          />*/}
      {/*        )}*/}
      {/*      </Box>*/}
      {/*    </Box>*/}
      {/*  </Box>*/}
      {/*)}*/}
    </Box>
  );
};

export default ForgotPassword;
