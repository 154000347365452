import { Box, Typography } from "@mui/material";
import React, { useContext } from "react";
import { LocalizationContext } from "Services/Localization/LocalizationContext";
import { goToMainAppProfile } from "Utils/mainAppUtils";

import { TooltipBox } from "../TooltipBox/TooltipBox";
import styles from "./Row.module.scss";

export const Row = ({
  data: { date, description, gross, fee, net },
  classNameCol,
}) => {
  const { t } = useContext(LocalizationContext);
  const lang = localStorage.getItem("language");

  const convertedDate = lang
    ? new Date(date).toLocaleDateString(lang, {
        year: "numeric",
        month: "short",
        day: "numeric",
      })
    : date;

  return (
    <Box className={styles.tr}>
      <Box className={classNameCol}>
        <Typography variant="h6">{convertedDate}</Typography>
      </Box>
      <Box className={classNameCol}>
        <Typography variant="h6" className={styles.description}>
          <Box className={styles.descriptionTitle}>
            {description?.type ? t(`default.${description.type}`) : null}
          </Box>
          <TooltipBox
            title={description?.message}
            actionText={
              <Box className={styles.actionTooltipTxt}>
                {t("default.from")}{" "}
                <a
                  href={goToMainAppProfile({
                    pathname: `public/user/${description?.appUser?.username}`,
                    isReturn: true,
                  })}
                  target="_blank"
                  rel="noreferrer"
                >
                  <u>{description?.appUser?.username}</u>
                </a>
              </Box>
            }
            rootClassName={styles.tooltipBox}
            tooltipClassName={styles.tooltip}
          >
            <Typography variant="body2" className={styles.descriptionMessage}>
              {description?.message}
            </Typography>
          </TooltipBox>
        </Typography>
      </Box>
      <Box className={classNameCol}>
        <Typography variant="h6">${gross}</Typography>
      </Box>
      <Box className={classNameCol}>
        <Typography variant="h6">${fee}</Typography>
      </Box>
      <Box className={classNameCol}>
        <Typography variant="h6">${net}</Typography>
      </Box>
    </Box>
  );
};
