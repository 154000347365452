import { Box } from "@mui/material";
import { BackHeader } from "Components/BackHeader/BackHeader";
import React from "react";

import styles from "./NavigationPost.module.scss";

const NavigationPost = ({ pathBack, pathBackText }) => {
  if (!pathBack) {
    return null;
  }

  return (
    <Box className={styles.header}>
      <Box className={styles.col}>
        <BackHeader
          title={pathBackText}
          pathBack={pathBack}
          isSidebarHidden={true}
        />
      </Box>
    </Box>
  );
};

export default NavigationPost;
