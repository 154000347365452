import { Box, Skeleton } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React from "react";

import seasonsStyle from "./Seasons.module.scss";

export const SeasonSkeleton = () => {
  const theme = useTheme();

  return (
    <Box>
      <Box className={seasonsStyle.seasonsTiersSkeletonWrapper}>
        <Skeleton
          className={`${seasonsStyle.skeleton} ${seasonsStyle.seasonsSkeletonTier}`}
          variant="rectangular"
          sx={{ bgcolor: theme.palette.dark.main }}
        />
        <Skeleton
          className={`${seasonsStyle.skeleton} ${seasonsStyle.seasonsSkeletonTier}`}
          variant="rectangular"
          sx={{ bgcolor: theme.palette.dark.main }}
        />
        <Skeleton
          className={`${seasonsStyle.skeleton} ${seasonsStyle.seasonsSkeletonTier}`}
          variant="rectangular"
          sx={{ bgcolor: theme.palette.dark.main }}
        />
      </Box>
      <Skeleton
        className={`${seasonsStyle.skeleton} ${seasonsStyle.seasonsDescription}`}
        variant="rectangular"
        sx={{ bgcolor: theme.palette.dark.main }}
      />
      <Skeleton
        className={`${seasonsStyle.skeleton} ${seasonsStyle.seasonsSlider}`}
        variant="rectangular"
        sx={{ bgcolor: theme.palette.dark.main }}
      />
      <Skeleton
        className={`${seasonsStyle.skeleton} ${seasonsStyle.seasonItem}`}
        variant="rectangular"
        sx={{ bgcolor: theme.palette.dark.main }}
      />
      <Skeleton
        className={`${seasonsStyle.skeleton} ${seasonsStyle.seasonItem}`}
        variant="rectangular"
        sx={{ bgcolor: theme.palette.dark.main }}
      />
    </Box>
  );
};
