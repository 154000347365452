import { Box } from "@mui/material";
import classNames from "classnames/bind";
import { useLayoutEffect, useRef } from "react";
import Slider from "react-slick";

import styles from "./ImagesSlider.module.scss";
export const ImagesSlider = ({
  images,
  index = 0,
  alt,
  isFullScreen,
  overrideStyles,
}) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: index,
    accessibility: true,
    adaptiveHeight: true,
  };
  const cx = classNames.bind(styles);
  const sliderRef = useRef();
  const setSliderFocus = () => {
    sliderRef?.current.innerSlider.list.setAttribute("tabindex", 0);
    sliderRef?.current.innerSlider.list.focus();
  };
  useLayoutEffect(() => {
    setSliderFocus();
  }, [sliderRef, isFullScreen]);
  return (
    <Box className={cx(styles.slider, isFullScreen && styles.sliderFullScreen)}>
      {images ? (
        <Slider {...settings} ref={sliderRef} afterChange={setSliderFocus}>
          {images.map((image, index) => (
            <div
              className={cx(styles.sliderItem, overrideStyles?.sliderItem)}
              key={index}
            >
              <img src={image} alt={alt} />
            </div>
          ))}
        </Slider>
      ) : null}
    </Box>
  );
};
