import { Box, Skeleton } from "@mui/material";
import React, { useState } from "react";

import { CommentBox } from "./CommentBox/CommentBox";
import styles from "./Comments.module.scss";

const Comments = ({
  items,
  setIsReply,
  loadingReplyIds,
  setSelectedReply,
  setActiveReplyId,
  loading,
  commentsCount,
}) => {
  const [activeCommentReplyIds, setActiveCommentReplyIds] = useState([]);

  const handleReply = (comment) => {
    setIsReply(true);
    setSelectedReply(comment);
  };

  const renderComments = (
    comments,
    isNested = false,
    parentId,
    parentRepliesCount
  ) =>
    comments.map((comment) => {
      return (
        <CommentBox
          renderComments={renderComments}
          isNested={isNested}
          comment={comment}
          handleReply={handleReply}
          loadingReplyIds={loadingReplyIds}
          parentId={parentId}
          activeCommentReplyIds={activeCommentReplyIds}
          setActiveCommentReplyIds={setActiveCommentReplyIds}
          setActiveReplyId={setActiveReplyId}
          parentRepliesCount={parentRepliesCount}
          key={comment?.id}
        />
      );
    });

  return (
    <Box className={styles.comments}>
      {loading && !items?.length && commentsCount ? (
        <>
          <Skeleton variant="rectangular" className={styles.commentSkeleton} />
          <Skeleton variant="rectangular" className={styles.commentSkeleton} />
        </>
      ) : null}
      <>{renderComments(items)}</>
    </Box>
  );
};

export default Comments;
