import { Box, Typography } from "@mui/material";

import styles from "./SectionWrapper.module.scss";
export const SectionWrapper = ({ children, title, subTitle }) => {
  return (
    <Box className={styles.container}>
      {title ? (
        <Typography variant="subtitle" className={styles.title}>
          {title}
        </Typography>
      ) : null}
      {subTitle ? (
        <Typography
          className={`${styles.containerSubTitle} opacity_07`}
          variant="body2"
        >
          {subTitle}
        </Typography>
      ) : null}
      <Box className={styles.containerBox}>{children}</Box>
    </Box>
  );
};
