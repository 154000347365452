import { Box, Button, Skeleton, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import BackBtn from "Components/BackBtn/BackBtn";
import Page from "Components/Page/Page";
import { ShareBtn } from "Components/ShareBtn/ShareBtn";
import SidebarTablet from "Components/SidebarTablet/SidebarTablet";
import { CARD_STATUS, EDITION_STATUSES, MainAppDomain } from "Constants/index";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { setLoader } from "Redux/appSlice";
import { setCardDetailsData } from "Redux/limitedEditionSlice";
import { getLeDetailsCardData } from "Redux/selectors/limitedEditionSelectors";
import { getCard, getCardOrders } from "Services/Api/limitedEditionApi";
import { LocalizationContext } from "Services/Localization/LocalizationContext";
import { useIsMobile } from "Utils/Hooks";
import { hederaLink } from "Utils/index";

import styles from "./CardDetails.module.scss";
import { DropIn } from "./components/DropIn/DropIn";
import OrderHistory from "./components/OrderHistory";
import { Rewards } from "./components/Rewards/Rewards";
import { Section } from "./components/Section/Section";
import { StatusBlock } from "./components/StatusBlock/StatusBlock";
import { IconRedirect } from "./icons";

const FETCH_ORDERS_LIMIT = 25;

export const CardDetails = () => {
  const { t } = useContext(LocalizationContext);
  const dispatch = useDispatch();
  const params = useParams();
  const isMobile = useIsMobile();
  const theme = useTheme();
  const isS = useMediaQuery("(max-width:767px)");

  const card = useSelector(getLeDetailsCardData);

  const [ordersData, setOrders] = useState({ orders: null, total: 0 });
  const [ordersPage, setOrdersPage] = useState(1);
  const [ordersLoader, setOrdersLoader] = useState(false);
  const [rewardsSkeletonLoader, setRewardsSkeleton] = useState(true);

  const isDroppIn =
    card?.status === EDITION_STATUSES.phase0 &&
    card?.reviewStatus !== CARD_STATUS.REJECTED;

  const handleRedirectBlockscaner = () => {
    window.open(`${hederaLink()}/${card.hederaTokenId}`, "_blank");
  };

  const loadMoreOrders = () => {
    setOrdersPage((prevState) => prevState + 1);
  };

  useEffect(() => {
    if (
      card?.status === EDITION_STATUSES.complete ||
      card?.status === EDITION_STATUSES.phase2
    ) {
      setOrdersLoader(true);
      getCardOrders({
        cardId: card.id,
        page: ordersPage,
        limit: FETCH_ORDERS_LIMIT,
      })
        .then((res) => {
          if (res?.data) {
            if (ordersPage === 1) {
              setOrders(res.data);
            } else {
              setOrders((prevState) => ({
                ...prevState,
                orders: [...prevState.orders, ...res?.data.orders],
              }));
            }
          }
        })
        .finally(() => setOrdersLoader(false));
    }
  }, [card?.status, ordersPage]);

  useEffect(() => {
    if (!card?.id && params?.id) {
      dispatch(setLoader(true));
    }
    getCard({ cardId: params.id })
      .then((res) => {
        dispatch(setCardDetailsData(res?.data));
      })
      .finally(() => {
        setRewardsSkeleton(false);
        dispatch(setLoader(false));
      });
  }, [card?.id]);

  return (
    <Page className={styles.container}>
      <Box className={styles.titleWrap}>
        <SidebarTablet />
        {!isMobile || isS ? <BackBtn isPlain /> : null}
        <Typography variant="h2" className={styles.title}>
          {t("default.cardDetails")}
        </Typography>
      </Box>
      {card ? (
        <Box className={styles.card}>
          <Box className={styles.cardSidebar}>
            <img src={card.imageUrl} alt="card" />
            <StatusBlock
              isShow={card.reviewStatus !== CARD_STATUS.APPROVED}
              card={card}
            />
          </Box>
          <Box className={styles.cardMain}>
            {card.status === EDITION_STATUSES.complete ? (
              <Box className={styles.sections}>
                <Section className={styles.sectionItem}>
                  <Box>
                    <Typography
                      variant="body2"
                      className={styles.sectionItemTitle}
                    >
                      {t("default.cardPrice")}
                    </Typography>
                    <Typography
                      variant="h5_display_medium"
                      className={styles.sectionItemValue}
                    >
                      ${card.currentSalePrice}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      variant="body2"
                      className={styles.sectionItemTitle}
                    >
                      {t("seasons.revenue")}
                    </Typography>
                    <Typography variant="h5_display_medium">
                      ${card.revenue || 0}
                    </Typography>
                  </Box>
                </Section>
                <Section className={styles.sectionItem}>
                  {card.hederaTokenId ? (
                    <Box>
                      <Typography
                        variant="body2"
                        className={styles.sectionItemTitle}
                      >
                        {t("default.hederaIdLabel")}
                      </Typography>
                      <Box
                        className={`${styles.blockData} ${styles.blockDataLink}`}
                        onClick={() =>
                          handleRedirectBlockscaner(card.hederaTokenId)
                        }
                      >
                        <Typography variant="h5_display_medium">
                          {card.hederaTokenId}
                        </Typography>
                        <IconRedirect />
                      </Box>
                    </Box>
                  ) : null}
                  <Box>
                    <Typography
                      variant="body2"
                      className={styles.sectionItemTitle}
                    >
                      {t("default.cardsTotal")}
                    </Typography>
                    <Typography variant="h5_display_medium">
                      {card.quantityAllocated}
                    </Typography>
                  </Box>
                </Section>
              </Box>
            ) : null}
            {isDroppIn ? (
              <Section>
                <DropIn
                  startDate={card.createdAt}
                  endDate={card.dropStartDate}
                  colorTime={
                    card.reviewStatus === CARD_STATUS.NEW
                      ? theme.palette.lime.main
                      : theme.palette.tertiary.main
                  }
                  colorProgress={
                    card.reviewStatus === CARD_STATUS.NEW
                      ? theme.palette.lime
                      : theme.palette.tertiary
                  }
                />
              </Section>
            ) : null}
            {card.status === EDITION_STATUSES.phase2 ? (
              <Section className={styles.priceSection}>
                <Box>
                  <Typography className={styles.cardPriceTitle} variant="body1">
                    {t("default.cardPrice")}
                  </Typography>
                  <Typography className={styles.cardPriceValue}>
                    ${card.currentSalePrice}
                    <Typography
                      variant="body18"
                      className={styles.cardRevenueValue}
                    >
                      ${card.revenue || 0} {t("seasons.revenue")}
                    </Typography>
                  </Typography>
                </Box>
                <Button variant="contained" className={styles.cardPriceButton}>
                  {card.saleTokensLeft} {t("default.left")}
                </Button>
              </Section>
            ) : null}
            <Section>
              <Box className={styles.sectionTitle}>
                <Typography variant="h5_display_medium">{card.name}</Typography>
                <ShareBtn shareLink={`${MainAppDomain}/card/${card.id}`} />
              </Box>
              <Typography variant="body2" className={styles.sectionDescription}>
                {card.description}
              </Typography>
              <Rewards
                isLoading={rewardsSkeletonLoader}
                media={card?.media || []}
                rewardType={card?.rewardType}
                awaitingOrdersCount={card?.awaitingOrdersCount}
              />
            </Section>
            <OrderHistory
              data={ordersData.orders}
              loading={ordersLoader}
              page={ordersPage}
              isNext={ordersData.total > ordersData.orders?.length}
              handler={loadMoreOrders}
            />
          </Box>
        </Box>
      ) : (
        <Box className={styles.card}>
          <Box className={styles.cardSidebar}>
            <Skeleton
              variant="rectangular"
              className={styles.cardSidebarSkeleton}
            />
          </Box>
          <Box className={styles.cardMain}>
            <Skeleton
              variant="rectangular"
              className={styles.cardMainSkeleton}
            />
          </Box>
        </Box>
      )}
    </Page>
  );
};
