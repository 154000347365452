import { Box, Button, CircularProgress } from "@mui/material";
import classNames from "classnames/bind";
import { BackHeader } from "Components/BackHeader/BackHeader";
import Page from "Components/Page/Page";
import { UndoSnackbar } from "Components/UndoSnackbar/UndoSnackbar";
import { PATHS, SCROLL_THRESHOLD } from "Constants/index";
import { orderBy } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import { setError, setLoader } from "Redux/appSlice";
import {
  deleteLimitedEditionCard,
  fetchLECards,
  fetchRewardOrders,
  setActiveLeCardId,
} from "Redux/limitedEditionSlice";
import {
  getLeActiveCardId,
  getLECards,
  getRewardsOrdersTotal,
} from "Redux/selectors/limitedEditionSelectors";
import { errorResponseMessages } from "Services/Api/apiErrorHelper.tsx";
import { deleteLeCard } from "Services/Api/limitedEditionApi";
import { LocalizationContext } from "Services/Localization/LocalizationContext";

import { ScheduledReward } from "../../Components/ScheduledReward/ScheduledReward";
import { useIsMobile } from "../../Utils/Hooks";
import { Card } from "./components/Card/Card";
import { EmptyState } from "./components/EmptyState/EmptyState";
import { ListSkeleton } from "./components/ListSkeleton/ListSkeleton";
import { RewardOrders } from "./components/RewardOrders/RewardOrders";
import { CalendarIcon, LimitedEditionIcon } from "./icons";
import styles from "./LimitedEdition.module.scss";
const CARDS_LIMIT = 30;

export const LimitedEdition = () => {
  const { t } = useContext(LocalizationContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();

  const activeCardId = useSelector(getLeActiveCardId);
  const cardsData = useSelector(getLECards);
  const totalRewards = useSelector(getRewardsOrdersTotal);

  const [page, setPage] = useState(1);
  const [loader, setLocalLoader] = useState(true);
  const [isCreatedSnackbarOpen, setCreatedSnackbarOpen] = useState(false);
  const [cardId, setCardId] = useState(null);

  const goToCreateCard = () => navigate(PATHS.CARD_CONSTRUCTOR);

  const cards = orderBy(Array.from(Object.values(cardsData)), "id", "desc");
  const isEmpty = !cards?.length;

  const deleteCardHandler = (id) => {
    setCreatedSnackbarOpen(false);
    if (id) {
      dispatch(deleteLimitedEditionCard({ cardId: id }));
      deleteLeCard({ cardId: id });
      dispatch(setActiveLeCardId(null));
    }
  };

  const loadMore = () => setPage((prevState) => prevState + 1);

  const hasMore = cards?.length === CARDS_LIMIT * page;

  const listWrapperClass = classNames(styles.listWrapper, {
    [styles.listWrapperFull]: !totalRewards,
  });

  const listClass = classNames(styles.list, {
    [styles.listFull]: !totalRewards,
  });

  useEffect(() => {
    if (!totalRewards) {
      dispatch(fetchRewardOrders({ page: 1, limit: 25 }));
    }
  }, []);

  useEffect(() => {
    if (activeCardId) {
      setCreatedSnackbarOpen(true);
      setCardId(activeCardId);
    }
    return () => dispatch(setActiveLeCardId(null));
  }, [activeCardId]);

  useEffect(() => {
    // we add the card to store in the reducer function to prevent additional fetch.
    // card.length === 1 to trigger the global loader only on the first execution.
    if (isEmpty || cards.length === 1) {
      dispatch(setLoader(true));
      dispatch(fetchLECards({ page: 1, limit: CARDS_LIMIT }))
        .catch((err) => {
          if (err?.status !== 401) {
            dispatch(
              setError({
                open: true,
                title: t("errors.error"),
                subtitle: errorResponseMessages(err, t),
              })
            );
          }
        })
        .finally(() => {
          dispatch(setLoader(false));
          setLocalLoader(false);
        });
    }
  }, []);

  useEffect(() => {
    if (page > 1) {
      dispatch(fetchLECards({ page, limit: CARDS_LIMIT }));
    }
  }, [page]);

  return (
    <Page className={styles.container}>
      <Box className={styles.header}>
        <BackHeader title={t("default.sales")} isBackArrow={false} />
        <Box className={styles.headerActions}>
          <ScheduledReward isShow={true} rowClassName={styles.scheduledReward}>
            <Button
              variant="outlined"
              size="small"
              className={`${styles.headerBtn} ${styles.headerBtnScheduled}`}
            >
              {t("default.scheduledVideoInputLabel")}
              <CalendarIcon />
            </Button>
          </ScheduledReward>
          {!isEmpty || !isMobile ? (
            <Button
              variant="outlined"
              size="small"
              className={styles.headerBtn}
              onClick={goToCreateCard}
            >
              {t("default.createCard")}
              <LimitedEditionIcon />
            </Button>
          ) : null}
        </Box>
      </Box>
      <Box className={styles.main}>
        <EmptyState action={goToCreateCard} isShow={isEmpty && !loader} />
        <Box
          className={`${styles.mainContent} ${
            isEmpty ? styles.mainContentEmpty : ""
          }`}
        >
          {!isEmpty && totalRewards ? <RewardOrders /> : null}
          <Box className={listWrapperClass}>
            <ListSkeleton isShow={loader && isEmpty} />
            <InfiniteScroll
              next={loadMore}
              dataLength={cards.length}
              hasMore={hasMore}
              scrollableTarget="scrollableDiv"
              scrollThreshold={SCROLL_THRESHOLD}
              loader={
                <Box style={{ display: "flex", justifyContent: "center" }}>
                  <CircularProgress />
                </Box>
              }
            >
              <Box className={listClass}>
                {cards.map((item, index) => (
                  <Card
                    key={item.id}
                    {...item}
                    index={index}
                    className={styles.card}
                  />
                ))}
              </Box>
            </InfiniteScroll>
          </Box>
        </Box>
      </Box>
      <UndoSnackbar
        isOpen={isCreatedSnackbarOpen}
        undoHandler={() => deleteCardHandler(cardId)}
        onClose={() => setCreatedSnackbarOpen(false)}
        message={t("limitedEdition.successCreateCardMessage")}
        autoHideDuration={3000}
      />
      <Outlet />
    </Page>
  );
};
