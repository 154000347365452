import { LANGUAGES_LIST } from "Constants";
import es from "date-fns/locale/es";
import fr from "date-fns/locale/fr";
import ja from "date-fns/locale/ja";
import ko from "date-fns/locale/ko";
import pt from "date-fns/locale/pt";
import tr from "date-fns/locale/tr";
import zh from "date-fns/locale/zh-CN";
import tw from "date-fns/locale/zh-TW";
import _ from "lodash";
import isEmpty from "lodash/isEmpty";
import React, { useReducer } from "react";
import { registerLocale, setDefaultLocale } from "react-datepicker";

import localize from "./localize_63ef80ef61639.json";

registerLocale("es", es);
registerLocale("fr", fr);
registerLocale("ja", ja);
registerLocale("ko", ko);
registerLocale("pt", pt);
registerLocale("tr", tr);
registerLocale("zh", zh);
registerLocale("tw", tw);

const defaultState = localize[LANGUAGES_LIST.english];
const initialState =
  localize[localStorage.getItem("language") || LANGUAGES_LIST.english];

const reducer = (localization, locale) => {
  if (isEmpty(localize[locale])) {
    console.warn("no locale provided for", locale);
    return localize[LANGUAGES_LIST.english];
  }
  if (locale) {
    const lang = locale.split("-")[0];
    if (lang) {
      setDefaultLocale(lang);
    }
  }
  localStorage.setItem("language", locale);
  return localize[locale];
};

const getLocalizedValue = (usedKey, localization) => {
  let str = _.get(localization, usedKey);
  if (str && str.startsWith("__")) str = _.get(initialState, usedKey);
  if (str && str.startsWith("__")) str = _.get(defaultState, usedKey);
  return str;
};

const LocalizationContext = React.createContext({});

function LocalizationProvider({ children }) {
  const [localization, setLocalization] = useReducer(reducer, initialState);
  const t = (key, placeholders) => {
    const isSingular = placeholders?.count == 1 && placeholders?.useSingular;
    const usedKey = (isSingular && `${key}Singular`) || key;
    let str = getLocalizedValue(usedKey, localization);

    if (placeholders && str) {
      _.forOwn(placeholders, (value, key) => {
        str = str.replace(`{{${key}}}`, value);
      });
    }

    return str || usedKey;
  };

  const timerLocalize = {
    seconds: t("default.secondShort"),
    minutes: t("default.minuteShort"),
    hours: t("default.hourShort"),
    days: t("default.dayShort"),
  };

  const timerLocalizeFull = {
    seconds: t("default.seconds"),
    minutes: t("default.minutes"),
    hours: t("default.hours"),
    days: t("default.days"),
  };

  return (
    <LocalizationContext.Provider
      value={{ setLocalization, t, timerLocalize, timerLocalizeFull }}
    >
      {children}
    </LocalizationContext.Provider>
  );
}

export { LocalizationContext, LocalizationProvider };
