import { api } from "Services/Api/api.service";
const API_URL = process.env.REACT_APP_API_HOST;

export const getEarnings = async (params) => {
  try {
    return api
      .get(`${API_URL}/me/earnings`, { params })
      .then((res) => res.data);
  } catch (error) {
    console.error(error);
  }
};

export const getTransactions = async (params) => {
  try {
    return api
      .get(`${API_URL}/me/transactions`, { params })
      .then((res) => res.data);
  } catch (error) {
    console.error(error);
  }
};
