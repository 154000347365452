export const PickerIcon = () => (
  <svg
    width="18"
    height="20"
    viewBox="0 0 18 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.7368 3.28947V1.5M11.7368 3.28947V5.07895M11.7368 3.28947H7.71053M1 8.65789V16.7105C1 17.1851 1.18853 17.6403 1.52412 17.9759C1.85972 18.3115 2.31488 18.5 2.78947 18.5H15.3158C15.7904 18.5 16.2455 18.3115 16.5811 17.9759C16.9167 17.6403 17.1053 17.1851 17.1053 16.7105V8.65789M1 8.65789H17.1053M1 8.65789V5.07895C1 4.60435 1.18853 4.14919 1.52412 3.8136C1.85972 3.47801 2.31488 3.28947 2.78947 3.28947H4.57895M17.1053 8.65789V5.07895C17.1053 4.60435 16.9167 4.14919 16.5811 3.8136C16.2455 3.47801 15.7904 3.28947 15.3158 3.28947H14.8684M4.57895 1.5V5.07895"
      stroke="#F0F1F5"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
