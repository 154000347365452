import { Box, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { CHATS_TYPE, TMP_USER_CHAT_ID } from "Constants/index";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setError } from "Redux/appSlice";
import {
  addNewMessage,
  createChat,
  deleteMessage,
  fetchChat,
  postMessage,
  setActiveChat,
} from "Redux/messengerSlice";
import { getMainAppUserId } from "Redux/selectors/mainAppUserSelector";
import {
  getActiveChat,
  getActiveMessagesData,
  getLoadingByCreateChat,
  getTotalChats,
  getTotalRequests,
} from "Redux/selectors/messengerSelectors";
import { LocalizationContext } from "Services/Localization/LocalizationContext";
import { isMobileDevice } from "Utils/index";

import { MailIcon } from "../../icons";
import styles from "./ChatContainer.module.scss";
import { ChatContainerDataWrapper } from "./components/ChatContainerDataWrapper/ChatContainerDataWrapper";
import { Footer } from "./components/Footer/Footer";

export const ChatContainer = ({ chatsType, setChatsType, chatCategory }) => {
  const { t } = useContext(LocalizationContext);
  const dispatch = useDispatch();
  const isPhone = isMobileDevice();
  const isMobile = useMediaQuery("(max-width:1024px)");

  const totalChats = useSelector(getTotalChats);
  const totalRequestsChats = useSelector(getTotalRequests);
  const mainAppUserId = useSelector(getMainAppUserId);
  const isCreateChatLoading = useSelector(getLoadingByCreateChat);
  const activeChat = useSelector(getActiveChat);
  const messagesData = useSelector(getActiveMessagesData(activeChat?.id));

  const [
    firstCreatedChatMessageTimestamp,
    setFirstCreatedChatMessageTimestamp,
  ] = useState(null);

  const total = chatsType === CHATS_TYPE.MY ? totalChats : totalRequestsChats;

  const submitHandler = ({ messageContent }) => {
    const timestamp = Date.now();
    if (activeChat?.id && activeChat.id !== TMP_USER_CHAT_ID) {
      dispatch(
        postMessage({
          chatId: activeChat.id,
          messageContent,
          timestamp,
          isMy: true,
          chatAccepted: chatsType !== CHATS_TYPE.REQUESTS,
        })
      ).then((res) => {
        if (res?.payload?.response?.data?.error) {
          dispatch(
            setError({
              open: true,
              title: t("errors.error"),
              subtitle: res.payload.response.data.message,
            })
          );
          const prevMessage = messagesData?.messages.at(-1);
          dispatch(
            deleteMessage({ timestamp, chatId: activeChat.id, prevMessage })
          );
        }
      });
      dispatch(
        addNewMessage({
          chatId: activeChat.id,
          message: messageContent,
          timestamp,
          senderId: mainAppUserId,
          chatAccepted: chatsType !== CHATS_TYPE.REQUESTS,
        })
      );
    } else {
      if (activeChat?.otherUser?.id && !isCreateChatLoading) {
        dispatch(
          createChat({
            recipientId: activeChat.otherUser.id,
            messageContent,
            timestamp,
          })
        ).then((data) => {
          if (data?.payload?.response?.data?.error) {
            dispatch(
              setError({
                open: true,
                title: t("errors.error"),
                subtitle: data.payload.response.data.message,
              })
            );
            const prevMessage = messagesData?.messages.at(-1);
            dispatch(
              deleteMessage({ timestamp, chatId: activeChat.id, prevMessage })
            );
          } else {
            dispatch(setActiveChat(data.payload));
            setFirstCreatedChatMessageTimestamp(timestamp);
          }
        });
      }
      dispatch(
        addNewMessage({
          chatId: TMP_USER_CHAT_ID,
          message: messageContent,
          timestamp,
          senderId: mainAppUserId,
          chatAccepted: true,
        })
      );
    }
  };

  useEffect(() => {
    function unlockPhoneHandler() {
      if (activeChat?.id && activeChat.id !== TMP_USER_CHAT_ID) {
        dispatch(fetchChat({ chatId: activeChat.id, limit: 35 }));
      }
    }
    unlockPhoneHandler();
    if (isPhone) {
      document.addEventListener("visibilitychange", unlockPhoneHandler);
    }
    return () => {
      if (isPhone) {
        document.removeEventListener("visibilitychange", unlockPhoneHandler);
      }
    };
  }, [activeChat?.id]);

  if (!activeChat) {
    return (
      <Box
        className={`${styles.emptyTitle} ${
          isMobile && total ? styles.emptyTitleNone : ""
        }`}
      >
        {chatsType === CHATS_TYPE.MY ? (
          <Typography className="opacity_07" variant="body2">
            {t("messages.chatBoxCta")}
          </Typography>
        ) : (
          <>
            <MailIcon />
            <Typography variant="h5">
              {t("settings.emailNotificationsLabelMessage")}
            </Typography>
          </>
        )}
      </Box>
    );
  }

  return (
    <Box className={styles.container}>
      <ChatContainerDataWrapper
        activeChat={activeChat}
        chatsType={chatsType}
        setChatsType={setChatsType}
        firstCreatedChatMessageTimestamp={firstCreatedChatMessageTimestamp}
        chatCategory={chatCategory}
      />
      <Footer
        submitHandler={submitHandler}
        activeChatId={activeChat?.id}
        chatsType={chatsType}
      />
    </Box>
  );
};
