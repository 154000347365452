export const InfoIcon = () => (
  <svg
    width="14"
    height="13"
    viewBox="0 0 14 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 12.5C10.3137 12.5 13 9.81371 13 6.5C13 3.18629 10.3137 0.5 7 0.5C3.68629 0.5 1 3.18629 1 6.5C1 9.81371 3.68629 12.5 7 12.5Z"
      stroke="#F0F1F5"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M4.98863 4.68023C4.98863 2.67479 8.98863 2.67479 8.98863 4.68023C8.98863 6.11269 7.17045 5.8262 7.17045 7.54515M7.17045 9.84282L7.17772 9.83651"
      stroke="#F0F1F5"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
