import { Box, Button, Tooltip, Typography, useMediaQuery } from "@mui/material";
import { ReactComponent as ArrowUpIcon } from "assets/svg/arrow-up.svg";
import React, { useContext, useState } from "react";
import { LocalizationContext } from "Services/Localization/LocalizationContext";
import { usePolicyHostUrl } from "Utils/Hooks";

import { ArrowDownIcon, InfoIcon } from "../../../../icons";
import styles from "./Row.module.scss";
import { RowChildren } from "./RowChildren";

export const Row = ({ row, index, month }) => {
  const { t } = useContext(LocalizationContext);
  const isSM = useMediaQuery((theme) => theme.breakpoints.down(769));
  const getDynamicZoopPolicyUrl = usePolicyHostUrl();

  const [open, setOpen] = useState(index === 0);

  const goToFAQ = (e) => {
    e.stopPropagation();
    window.open(getDynamicZoopPolicyUrl("terms-of-service"));
  };

  const grossSum =
    +row.donations.gross +
    +row.limitedEditionSales.gross +
    +row.seasonSales.gross +
    +row.subscriptions.gross;

  const feeSum =
    +row.donations.fee +
    +row.limitedEditionSales.fee +
    +row.seasonSales.fee +
    +row.subscriptions.fee;

  const netSum =
    +row.donations.net +
    +row.limitedEditionSales.net +
    +row.seasonSales.net +
    +row.subscriptions.net;

  return (
    <Box>
      <Box
        className={`${styles.tr} ${styles.mainTr} ${open ? styles.active : ""}`}
        onClick={() => setOpen((prevState) => !prevState)}
      >
        <Box className={styles.colTop}>
          <Box className={styles.col}>
            <Typography variant="body4" className={styles.trTitle}>
              {month}
            </Typography>
          </Box>
          {isSM ? (
            <Box className={styles.col}>
              {open ? <ArrowUpIcon /> : <ArrowDownIcon />}
            </Box>
          ) : null}
        </Box>
        <Box className={styles.colMainContent}>
          <Box className={styles.col}>
            <Typography variant="body2" className={styles.colTitle}>
              {t("default.gross")}
            </Typography>
            <Typography variant="body4_16" className={styles.colValue}>
              ${grossSum.toFixed(2)}
            </Typography>
          </Box>
          <Box className={styles.col}>
            <Typography variant="body2" className={styles.colTitle}>
              {t("default.fees")}
              <Tooltip
                placement="top"
                classes={{ popper: styles.popper, tooltip: styles.tooltip }}
                enterTouchDelay={0}
                title={
                  <Box className={styles.tooltip}>
                    <Typography variant="body3">
                      {t("default.earningsFeesTitle")}
                    </Typography>
                    <Box>
                      <Button type="text" onClick={goToFAQ}>
                        <Typography variant="body3">
                          {t("default.clickMoreDetails")}
                        </Typography>
                      </Button>
                    </Box>
                  </Box>
                }
              >
                <Box>
                  <InfoIcon />
                </Box>
              </Tooltip>
            </Typography>
            <Typography variant="body4_16" className={styles.colValue}>
              ${feeSum.toFixed(2)}
            </Typography>
          </Box>
          <Box className={styles.col}>
            <Typography variant="body2" className={styles.colTitle}>
              {t("default.net")}
            </Typography>
            <Typography variant="body4_16" className={styles.colValue}>
              ${netSum.toFixed(2)}
            </Typography>
          </Box>
        </Box>
        {!isSM ? (
          <Box className={styles.col}>
            {open ? <ArrowUpIcon /> : <ArrowDownIcon />}
          </Box>
        ) : null}
      </Box>
      {open ? (
        <Box className={styles.childTable}>
          <RowChildren data={row} />
        </Box>
      ) : null}
    </Box>
  );
};
