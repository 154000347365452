import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { ReactComponent as ArrowDown } from "assets/svg/arrow-down.svg";

import styles from "./CustomSelect.module.scss";

export const CustomSelect = ({
  activeValue,
  onChange,
  options,
  id,
  label,
  controlClassName,
  selectClassName,
  dropdownMenuClassName,
  ...props
}) => {
  return (
    <FormControl
      fullWidth
      className={`${styles.control} ${
        controlClassName ? controlClassName : ""
      }`}
    >
      <InputLabel id={id}>{label}</InputLabel>
      <Select
        labelId={id}
        id={id}
        value={activeValue}
        label={label}
        className={`${styles.select} ${selectClassName ? selectClassName : ""}`}
        MenuProps={{
          classes: {
            paper: `${styles.dropdownMenu} ${
              dropdownMenuClassName ? dropdownMenuClassName : ""
            }`,
          },
        }}
        IconComponent={ArrowDown}
        onChange={onChange}
        {...props}
      >
        {options.map(({ label, value, disabled }) => (
          <MenuItem value={value} key={value} disabled={disabled}>
            <Typography variant="body18" className={styles.menuItem}>
              {label}
            </Typography>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
