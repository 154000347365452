import { Box, Typography } from "@mui/material";
import Growth from "Components/Growth";
import React, { useContext } from "react";
import { LocalizationContext } from "Services/Localization/LocalizationContext";

import styles from "./Title.module.scss";

const Title = ({ data, totalSub }) => {
  const { t } = useContext(LocalizationContext);
  if (!data) return null;
  const {
    growth: { value, percentage },
    name,
  } = data;

  return (
    <Box className={styles.title}>
      <Typography variant="h6" className={styles.titleDesc}>
        {t("insights.totalSubs")}
      </Typography>
      <Box className={styles.titleInfo}>
        <Typography variant="h2">{totalSub}</Typography>
        <Growth
          value={value}
          percentage={percentage}
          desc={name}
          className={styles.titleGrowth}
          arrowClassName={styles.titleGrowthArrow}
        />
      </Box>
    </Box>
  );
};

export default Title;
