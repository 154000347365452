import _ from "lodash";
const { createContext, useState, useContext, useEffect } = require("react");

export const ViewportContext = createContext({});

export const ViewportProvider = ({ children }) => {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  const handleWindowResize = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener("resize", _.debounce(handleWindowResize, 250));
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);
  return (
    <ViewportContext.Provider value={{ width, height }}>
      {children}
    </ViewportContext.Provider>
  );
};

export const ViewportService = () => {
  const { height } = useContext(ViewportContext);
  useEffect(() => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }, [height]);
};
