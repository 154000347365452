import {
  Box,
  Button,
  Modal,
  Popover,
  Snackbar,
  Typography,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { ReactComponent as IconPin } from "assets/svg/feed/pin-post.svg";
import { ReactComponent as IconUnpin } from "assets/svg/feed/unpin-post.svg";
import { ReactComponent as IconApprove } from "assets/svg/icon-approve.svg";
// import { ReactComponent as IconBlock } from "assets/svg/icon-block.svg";
import { ReactComponent as IconDelete } from "assets/svg/icon-delete.svg";
import { ReactComponent as IconEdit } from "assets/svg/icon-edit.svg";
import { ReactComponent as IconWarning } from "assets/svg/icon-warning.svg";
import Gallery from "Components/Gallery/Gallery";
import { VideoBlock } from "Components/VideoBlock/VideoBlock";
import { YouTubeVideoBlock } from "Components/VideoBlock/YouTubeVideoBlock";
import { AUTO_HIDE_TIMEOUT, PATHS } from "Constants";
import React, { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setError, setSnackbar } from "Redux/appSlice";
import { patchPost, pinPost, setPost } from "Redux/communitySlice";
import { deletePost } from "Redux/communitySlice";
import { deleteScheduledPost } from "Redux/scheduledPostsSlice";
import { errorResponseMessages } from "Services/Api/apiErrorHelper.tsx";
import { unpinPost } from "Services/Api/communityApi";
import { LocalizationContext } from "Services/Localization/LocalizationContext";
import useDelayedExecution from "Utils/Hooks/useDelayedExecution";

import { PostRejectModal } from "../../../../Components/Modal/components/PostRejectModal/PostRejectModal";
import { getMainAppUserId } from "../../../../Redux/selectors/mainAppUserSelector";
import CommentsCount from "../CommentsCount/CommentsCount";
import Reactions from "../Reactions/Reactions";
import styles from "./CommunityPost.module.scss";
import CommunityPostHeader from "./components/CommunityPostHeader/CommunityPostHeader";
import { ModalPostCreator } from "./components/ModalPostCreator/ModalPostCreator";

const CommunityPost = (postData) => {
  const {
    id,
    message,
    createdAt,
    scheduledAt,
    commentsCount,
    images,
    videos,
    audios,
    isPinned,
    influencerReactions,
    postSummary = [],
    allowComments,
    data,
    postIndex,
    isScheduled,
    title,
    className,
    stylesWrapper,
    inReview,
    appUser,
    poll,
  } = postData;
  const { t } = useContext(LocalizationContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const pinnedPost = useSelector(
    (state) => state.community.posts.entities.pinnedPost
  );
  const mainAppUserId = useSelector(getMainAppUserId);

  const { stopExecute, startExecute } = useDelayedExecution(AUTO_HIDE_TIMEOUT);

  const [anchorEl, setAnchorEl] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [isSnackBarDelete, setSnackBarDelete] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [isPinnedPost, setIsPinnedPost] = useState(pinnedPost?.id === id);
  const [isOpenModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isActiveRejectModal, setActiveRejectModal] = useState(false);

  const handleClose = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleEditPost = (e) => {
    handleClose(e);
    if (isScheduled) {
      setOpenModal(true);
    } else {
      dispatch(setPost(postData));
      navigate(`/edit-post/${id}`);
    }
  };

  const handlePinPost = (e) => {
    handleClose(e);
    if (isPinnedPost) {
      setIsPinnedPost(false);
      unpinPost({ postId: id });
      return;
    }

    dispatch(pinPost({ postId: id }))
      .then((data) => {
        if (data?.error?.message) {
          dispatch(
            setSnackbar({
              message: data.error.message,
              open: true,
              right: "20px",
              left: "unset",
            })
          );
        } else {
          setIsPinnedPost(true);
        }
      })
      .catch((e) => {
        console.log(e?.response?.data?.message);
      });
  };

  const handleDeletePost = (e) => {
    setOpenDelete(false);
    setSnackBarDelete(true);
    setLoadingDelete(true);
    handleClose(e);
    const handleFunc = isScheduled ? deleteScheduledPost : deletePost;
    startExecute(() =>
      dispatch(handleFunc({ postId: id })).finally(() =>
        setLoadingDelete(false)
      )
    );
  };

  // const handleBlockUser = (e) => {
  //   handleClose(e);
  // };

  const handleApprovePost = (e) => {
    handleClose(e);
    setLoading(true);
    dispatch(patchPost({ postId: id, status: "published" }))
      .then(() => {
        dispatch(
          setSnackbar({
            message: t("community.approvePostSuccess"),
            open: true,
            right: "20px",
            left: "unset",
          })
        );
      })
      .catch((err) => {
        if (err?.status !== 401) {
          dispatch(
            setError({
              open: true,
              title: t("errors.error"),
              subtitle: errorResponseMessages(err, t),
            })
          );
        }
      })
      .finally(() => setLoading(false));
  };

  const handleRejectPost = (e) => {
    handleClose(e);
    setActiveRejectModal(true);
  };

  const undoDelete = () => {
    setSnackBarDelete(false);
    setLoadingDelete(false);
    stopExecute();
  };

  const openPostImage = (e, img) => {
    e.stopPropagation();
    const imgIdx = images.findIndex((i) => i === img);
    openPost(e, imgIdx);
  };

  const openPost = (e, imgIdx) => {
    e.stopPropagation();
    if (e.target.tagName !== "A") {
      dispatch(setPost(postData));
      navigate(PATHS.POST_ID.replace(":postId", id), {
        state: { from: location.pathname, imgIdx },
      });
    }
  };

  if (!message) {
    return null;
  }

  return (
    <>
      <>{title}</>
      <Box
        className={`${styles.item} ${
          isPinned ? styles.itemPinned : ""
        } community-post ${isPinned ? "community-post-pinned" : ""} ${
          className ? className : ""
        }`}
        style={stylesWrapper}
      >
        {isSnackBarDelete || loadingDelete || loading ? (
          <Box className={styles.loader}>
            <CircularProgress />
          </Box>
        ) : null}
        {images?.length ? (
          <Box className={styles.galleryWrapper}>
            <Gallery postId={id} images={images} onImgClick={openPostImage} />
          </Box>
        ) : null}
        {videos?.length ? (
          <Box
            className={`${styles.galleryWrapper} ${styles.galleryWrapperVideo}`}
          >
            <VideoBlock videos={videos} postIndex={postIndex} mediaIndex={0} />
          </Box>
        ) : null}
        {data?.youtubeUrl ? (
          <Box
            className={`${styles.galleryWrapper} ${styles.galleryWrapperVideo}`}
            key={data?.youtubeUrl}
          >
            <YouTubeVideoBlock
              youtubeUrl={data?.youtubeUrl}
              className={styles.youtubeVideo}
              parentIndex={postIndex}
            />
          </Box>
        ) : null}
        <CommunityPostHeader
          setAnchorEl={setAnchorEl}
          message={message}
          createdAt={scheduledAt || createdAt}
          isPinnedPost={isPinnedPost}
          audios={audios}
          postIndex={postIndex}
          inReview={inReview}
          appUser={appUser}
          openPost={openPost}
          poll={poll}
        />
        {!inReview ? (
          <Box valiant="body3" className={styles.itemFooter}>
            <Reactions
              postId={id}
              influencerReaction={influencerReactions}
              postSummary={postSummary}
            />
            <Box onClick={openPost}>
              <CommentsCount
                count={commentsCount}
                allowComments={allowComments}
                postSummary={postSummary}
              />
            </Box>
          </Box>
        ) : null}
        {/*ToDo: need to create one default component for the Post's popover with items and use it here and on the postId page*/}
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 35,
            horizontal: -100,
          }}
          sx={{
            borderRadius: "12px",
          }}
          className={styles.popoverWrapper}
        >
          <Box className={styles.kebabPopover}>
            {!scheduledAt && !inReview ? (
              <Typography
                variant="body2"
                className={styles.popoverItem}
                onClick={handlePinPost}
              >
                {isPinnedPost ? <IconUnpin /> : <IconPin />}
                {isPinnedPost
                  ? t("community.unpinPost")
                  : t("community.pinPost")}
              </Typography>
            ) : null}
            {!inReview ? (
              <>
                {mainAppUserId === appUser?.id || !appUser?.id ? (
                  <Typography
                    variant="body2"
                    className={styles.popoverItem}
                    onClick={handleEditPost}
                  >
                    <IconEdit />
                    {t("community.editPost")}
                  </Typography>
                ) : null}
                <Typography
                  variant="body2"
                  onClick={(e) => {
                    handleClose(e);
                    setOpenDelete(true);
                  }}
                  className={`${styles.red} ${styles.popoverItem}`}
                >
                  <IconDelete /> {t("community.deletePost")}
                </Typography>
              </>
            ) : (
              <>
                <Typography
                  variant="body2"
                  className={styles.popoverItem}
                  onClick={handleApprovePost}
                >
                  <IconApprove />
                  {t("community.approvePost")}
                </Typography>
                {/*<Typography*/}
                {/*  variant="body2"*/}
                {/*  className={styles.popoverItem}*/}
                {/*  onClick={handleBlockUser}*/}
                {/*>*/}
                {/*  <IconBlock />*/}
                {/*  {t("community.blockUser")}*/}
                {/*</Typography>*/}
                <Typography
                  variant="body2"
                  onClick={handleRejectPost}
                  className={`${styles.red} ${styles.popoverItem}`}
                >
                  <IconDelete /> {t("community.rejectPost")}
                </Typography>
              </>
            )}
          </Box>
        </Popover>

        <Modal
          open={openDelete}
          onClose={() => setOpenDelete(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              height: "auto",
              transform: "translate(-50%, -50%)",
              background: "#111322",
              outline: "none",
              borderRadius: "10px",
              border: "2px solid #21264E",
              padding: "24px",
            }}
            className={styles.modalBody}
          >
            <Box className={styles.modalTextBody}>
              <IconWarning />
              <Box className={styles.modalText}>
                <Typography
                  className={styles.modalTitle}
                  id="modal-modal-title"
                  variant="h4"
                >
                  {t("community.deleteTitle")}
                </Typography>
                <Typography className={styles.modalSubTitle} variant="h6">
                  {t("community.deleteSubtitle")}
                </Typography>
              </Box>
            </Box>
            <Box className={styles.buttons}>
              <Button
                variant="outlined"
                onClick={(e) => {
                  e.stopPropagation();
                  setOpenDelete(false);
                }}
              >
                {t("default.cancel")}
              </Button>
              <Button variant="red" onClick={handleDeletePost}>
                {t("community.deletePost")}
              </Button>
            </Box>
          </Box>
        </Modal>
      </Box>
      <ModalPostCreator
        isOpen={isOpenModal}
        setOpenModal={setOpenModal}
        selectedPostId={id}
      />
      <Snackbar
        open={isSnackBarDelete}
        message={t("community.deletePostSuccess")}
        autoHideDuration={AUTO_HIDE_TIMEOUT}
        severity={"success"}
        onClose={() => setSnackBarDelete(false)}
        action={
          <Button onClick={undoDelete} className={styles.snackbarButton}>
            {t("community.undo")}
            <svg
              width="28"
              height="12"
              viewBox="0 0 28 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M27 10.75V7.25C27 3.93629 24.3137 1.25 21 1.25H1.5"
                stroke="#111322"
                strokeWidth="1.5"
                strokeLinecap="round"
              />
            </svg>
          </Button>
        }
      />
      <PostRejectModal
        isOpen={isActiveRejectModal}
        onClose={() => setActiveRejectModal(false)}
        postId={id}
      />
    </>
  );
};

export default CommunityPost;
