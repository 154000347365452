import { Typography } from "@mui/material";
import React from "react";

import styles from "./Title.module.scss";

const Title = ({ title }) => {
  return (
    <Typography variant="h3" className={styles.titleWrap}>
      {title}
    </Typography>
  );
};

export default Title;
