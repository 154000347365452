import React from "react";
import { Box, Typography, Button, Container } from "@mui/material";
import { useNavigate } from "react-router-dom";
import styles from "./Nomatch.module.scss";
import { ReactComponent as Icon404 } from "assets/svg/404.svg";

const NoMatch = () => {
  const navigate = useNavigate();
  return (
    <Container className={styles.container}>
      <Box className={styles.block}>
        <Typography variant="h1" className={styles.title}>
          Oops!
        </Typography>
        <Typography variant="textNormal" className={styles.description}>
          You have ventured beyond the borders of Zoop metaverse. Don’t worry
          we’ll guide you back to safety
        </Typography>
        <Button
          variant="yellow"
          className={styles.btn}
          onClick={() => navigate("/")}
        >
          Back to home
        </Button>
      </Box>
      <Box className={styles.block}>
        <Icon404 />
      </Box>
    </Container>
  );
};

export default NoMatch;
