import { useTheme } from "@emotion/react";
import { Box, Typography } from "@mui/material";
import classNames from "classnames/bind";
import CountdownDate from "Components/CountdownDate/CountdownDate";
import CountdownProgress from "Components/CountdownProgress";
import stylesGeneral from "Components/NewCard/Card.module.scss";
import { format } from "date-fns";
import { useContext } from "react";
import { LocalizationContext } from "Services/Localization/LocalizationContext";
import { useLocale } from "Utils/Hooks";

export const UpcomingDrop = ({
  className,
  dropStartDate,
  createdAt,
  overrideStyles,
}) => {
  const styles = overrideStyles || stylesGeneral;
  const cx = classNames.bind(styles);
  const theme = useTheme();
  const { t } = useContext(LocalizationContext);
  const locale = useLocale();
  if (!dropStartDate) return null;
  return (
    <Box className={cx(className, styles.droppingIn)}>
      <Box className={styles.droppingInBlock}>
        <Typography variant="textNormal_14" className={styles.label}>
          {t("seasons.releaseDate")}
        </Typography>
        <Typography variant="textMediumBold">
          {format(new Date(dropStartDate), "dd MMM yyyy", {
            locale,
          })}
        </Typography>
      </Box>
      <Box className={styles.droppingInBlock}>
        <Typography variant="text_12_display_bold" className={styles.label}>
          {t("seasons.droppingIn")}
        </Typography>
        <Typography variant="text_12_display_bold">
          <CountdownDate
            endDate={dropStartDate}
            hoursStyling={{
              variant: "text_12_display_bold",
              color: theme.palette.tertiary,
            }}
          />
        </Typography>
      </Box>
      <CountdownProgress
        className={styles.droppingInTimer}
        startDate={createdAt}
        endDate={dropStartDate}
        color={theme.palette.tertiary}
      />
    </Box>
  );
};
