import "./global.scss";

import { yupResolver } from "@hookform/resolvers/yup";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import PhoneNumber from "Components/PhoneNumber";
import _ from "lodash";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { checkReferralCode } from "Services/Api/api.service";
import { LocalizationContext } from "Services/Localization/LocalizationContext";
import * as Yup from "yup";

import {
  getDays,
  getMonth,
  getSocMediaList,
  getYears,
  parseDateString,
} from "../utils";
import styles from "./SignUpForm.module.scss";

export const SignUpForm = ({ setData, setDialog }) => {
  const { t } = useContext(LocalizationContext);

  const params = useParams();

  const [dataIsCorrect, setDataIsCorrect] = useState(0);
  const [selectData, setSelectValue] = useState({
    mainSocialMedia: "",
    birthdateDay: "",
    birthdateMonth: "",
    birthdateYear: "",
  });
  const [code, setCode] = useState(params?.code || "");
  const [referralCodeError, setReferralCodeError] = useState(false);
  const [referredBy, setReferredBy] = useState(false);
  const [checkingCode, setCheckingCode] = useState(false);

  const setCodeError = () => {
    setReferralCodeError(true);
    setReferredBy(false);
  };

  const checkCode = async (e) => {
    try {
      const code = e?.target?.value || e;
      if (!code) {
        setReferredBy(false);
        setReferralCodeError(false);
        return;
      }

      setCheckingCode(true);
      const res = await checkReferralCode(code);
      if (_.isEmpty(res)) {
        setCodeError();
      } else {
        setReferredBy(code);
        setReferralCodeError(false);
      }
      setCheckingCode(false);
    } catch (e) {
      setCodeError();
      setCheckingCode(false);
    }
  };

  const debounceCheck = useMemo(() => _.debounce(checkCode, 1000), []);

  const refCodeErrorText = t("errors.refCodeError");

  useEffect(() => {
    if (params?.code) checkCode(params?.code);
  }, [params?.code]);

  const handleSelectChange = (propName) => (e) => {
    setSelectValue((prevState) => ({
      ...prevState,
      [propName]: e.target.value,
    }));
  };

  const onReferralCodeChange = (e) => {
    setCode(e.target.value);
    debounceCheck(e.target.value);
  };

  const socMediaList = getSocMediaList({ t });
  const requireddErrorMsg = t("errors.isRequired");

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required(requireddErrorMsg),
    lastName: Yup.string().required(requireddErrorMsg),
    birthdateDay: Yup.string()
      .test("is-valid-date", t("errors.bdNotValid"), () =>
        parseDateString({
          month: selectData.birthdateMonth,
          day: selectData.birthdateDay,
          year: selectData.birthdateYear,
        })
      )
      .required(requireddErrorMsg),
    birthdateMonth: Yup.string()
      .test("is-valid-date", t("errors.bdNotValid"), () =>
        parseDateString({
          month: selectData.birthdateMonth,
          day: selectData.birthdateDay,
          year: selectData.birthdateYear,
        })
      )
      .required(requireddErrorMsg),
    birthdateYear: Yup.string()
      .test("is-valid-date", t("errors.bdNotValid"), () =>
        parseDateString({
          month: selectData.birthdateMonth,
          day: selectData.birthdateDay,
          year: selectData.birthdateYear,
        })
      )
      .required(requireddErrorMsg),
    email: Yup.string()
      .required(requireddErrorMsg)
      .email(t("errors.invalidEmail")),
    address: Yup.string().required(requireddErrorMsg),
    phoneNumber: Yup.string().required(requireddErrorMsg),
    city: Yup.string().required(requireddErrorMsg),
    country: Yup.string().required(requireddErrorMsg),
    zipcode: Yup.string().required(requireddErrorMsg),
    socialAccountId: Yup.string().required(requireddErrorMsg),
    mainSocialMedia: Yup.string().required(requireddErrorMsg),
  });

  const onTerms = () => {
    setDataIsCorrect(dataIsCorrect ? 0 : 1);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const preparingData = (data) => {
    let params = { ...data, ...{ dataIsCorrect } };
    if (referredBy) params.referredBy = referredBy;
    params.birthdate = `${data.birthdateMonth}/${data.birthdateDay}/${data.birthdateYear}`;
    delete params.birthdateDay;
    delete params.birthdateMonth;
    delete params.birthdateYear;
    return params;
  };

  const handleFormSubmit = (data) => {
    const params = preparingData(data);
    setData(params);
    setDialog(true);
  };

  return (
    <Box className={styles.formWrapper}>
      <Typography variant="h3" className={styles.title}>
        {t("celebform.celebrityFormTitle")} {t("celebform.celebrityForm")}
      </Typography>
      <Box className={styles.form}>
        <form
          onSubmit={handleSubmit(handleFormSubmit)}
          autoComplete="off"
          className={styles.form}
        >
          <Box className={styles.content}>
            <Box className={styles.rowWrapper}>
              <Typography variant="h6" className={styles.sectionLabel}>
                {t("celebform.personalInformation")}
              </Typography>
              <Box className={styles.row}>
                <FormControl className={styles.formControlInput}>
                  <TextField
                    variant="outlined"
                    autoFocus
                    label={t("celebform.firstName")}
                    {...register("firstName")}
                    error={!!errors.firstName}
                    autoComplete="off"
                    className={styles.input}
                  />
                  <Typography variant="error">
                    {errors.firstName?.message}
                  </Typography>
                </FormControl>
                <FormControl className={styles.formControlInput}>
                  <TextField
                    variant="outlined"
                    label={t("celebform.lastName")}
                    {...register("lastName")}
                    error={!!errors.lastName}
                    autoComplete="off"
                    className={styles.input}
                  />
                  <Typography variant="error">
                    {errors.lastName?.message}
                  </Typography>
                </FormControl>
              </Box>
            </Box>
            <Box
              className={`${styles.rowWrapper} ${styles.rowWrapperBirthdate}`}
            >
              <Typography variant="body2" className={styles.sectionLabelSmall}>
                {t("celebform.birthdate")}
              </Typography>
              <Box className={styles.row}>
                <FormControl
                  variant="outlined"
                  className={styles.formControlInput}
                >
                  <InputLabel id="month-label">
                    {t("celebform.month")}
                  </InputLabel>
                  <Select
                    labelId="month-label"
                    value={selectData.birthdateMonth}
                    onChange={handleSelectChange("birthdateMonth")}
                    inputProps={{ ...register("birthdateMonth") }}
                    error={!!errors.birthdateMonth}
                    className={`${styles.input} ${styles.select}`}
                  >
                    {getMonth({ t }).map((item, index) => (
                      <MenuItem
                        value={index + 1}
                        key={item}
                        className={styles.itemList}
                      >
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                  <Typography variant="error">
                    {errors.birthdateMonth?.message}
                  </Typography>
                </FormControl>
                <FormControl
                  variant="outlined"
                  className={styles.formControlInput}
                >
                  <InputLabel id="day-label">{t("celebform.day")}</InputLabel>
                  <Select
                    labelId="day-label"
                    value={selectData.birthdateDay}
                    onChange={handleSelectChange("birthdateDay")}
                    inputProps={{ ...register("birthdateDay") }}
                    error={!!errors.birthdateDay}
                    className={`${styles.input} ${styles.select}`}
                  >
                    {getDays().map((item) => (
                      <MenuItem
                        value={item}
                        key={item}
                        className={styles.itemList}
                      >
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl
                  variant="outlined"
                  className={styles.formControlInput}
                >
                  <InputLabel id="year-label">{t("celebform.year")}</InputLabel>
                  <Select
                    labelId="year-label"
                    value={selectData.birthdateYear}
                    onChange={handleSelectChange("birthdateYear")}
                    inputProps={{ ...register("birthdateYear") }}
                    error={!!errors.birthdateYear}
                    className={`${styles.input} ${styles.select}`}
                  >
                    {getYears().map((item) => (
                      <MenuItem
                        value={item}
                        key={item}
                        className={styles.itemList}
                      >
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Box>
            <Box className={styles.rowWrapper}>
              <Box className={styles.row}>
                <FormControl className={styles.formControlInput}>
                  <TextField
                    variant="outlined"
                    label={t("celebform.email")}
                    {...register("email")}
                    error={!!errors.email}
                    autoComplete="off"
                    className={styles.input}
                  />
                  <Typography variant="error">
                    {errors.email?.message}
                  </Typography>
                </FormControl>
              </Box>
            </Box>
            <Controller
              control={control}
              name="phoneNumber"
              rules={{ required: requireddErrorMsg }}
              render={({
                field: { onChange, onBlur, value, ref },
                fieldState: { invalid, isTouched, isDirty, error },
              }) => (
                <Box className={styles.rowWrapper}>
                  <Box className={styles.row}>
                    <FormControl className={styles.formControlInput}>
                      <PhoneNumber
                        placeholder={t("celebform.phoneNumber")}
                        value={value}
                        variant="outlined"
                        smartCaret={true}
                        onChange={onChange}
                        className={`${styles.input} ${
                          invalid ? styles.error : ""
                        }`}
                      />
                      <Typography variant="error">{error?.message}</Typography>
                    </FormControl>
                  </Box>
                </Box>
              )}
            />
            <Box className={styles.rowWrapper}>
              <Typography variant="h6" className={styles.sectionLabel}>
                {t("celebform.socialMediaPlatform")}
              </Typography>
              <Box className={styles.row}>
                <FormControl
                  variant="outlined"
                  className={styles.formControlInput}
                >
                  <InputLabel id="social-media-label">
                    {t("celebform.selectSocialMedia")}
                  </InputLabel>
                  <Select
                    labelId="social-media-label"
                    id="social-media-label"
                    value={selectData.mainSocialMedia}
                    onChange={handleSelectChange("mainSocialMedia")}
                    inputProps={{ ...register("mainSocialMedia") }}
                    renderValue={(selected) =>
                      selected || (
                        <div className={styles.placeholder}>
                          {t("celebform.instagram")}
                        </div>
                      )
                    }
                    error={!!errors.mainSocialMedia}
                    className={`${styles.input} ${styles.select}`}
                  >
                    {socMediaList.map((item) => (
                      <MenuItem
                        value={item}
                        key={item}
                        className={styles.itemList}
                      >
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                  <Typography variant="error">
                    {errors.mainSocialMedia?.message}
                  </Typography>
                </FormControl>
              </Box>
            </Box>
            <Box className={styles.rowWrapper}>
              <Box className={styles.row}>
                <FormControl className={styles.formControlInput}>
                  <TextField
                    label={t("celebform.handle")}
                    variant="outlined"
                    {...register("socialAccountId")}
                    error={!!errors.socialAccountId}
                    autoComplete="off"
                    className={styles.input}
                  />
                  <Typography variant="error">
                    {errors.socialAccountId?.message}
                  </Typography>
                </FormControl>
              </Box>
            </Box>
            <Box className={styles.rowWrapper}>
              <Typography variant="h6" className={styles.sectionLabel}>
                {t("celebform.referralCodeApplicable")}
              </Typography>
              <Box className={styles.row}>
                <FormControl
                  className={`${styles.formControlInput} ${styles.referralInput}`}
                >
                  <TextField
                    variant="outlined"
                    label={t("celebform.referralCode")}
                    disabled={checkingCode}
                    error={!!referralCodeError}
                    autoComplete="off"
                    className={styles.input}
                    value={code}
                    onChange={onReferralCodeChange}
                  />
                  <Box mt="5px">
                    {referralCodeError && (
                      <Typography variant="error">
                        {refCodeErrorText}
                      </Typography>
                    )}
                  </Box>
                </FormControl>
              </Box>
            </Box>
            <Box>
              <Typography variant="h6" className={styles.sectionLabel}>
                {t("celebform.addressInformation")}
              </Typography>
              <Box className={styles.row}>
                <FormControl className={styles.formControlInput}>
                  <TextField
                    variant="outlined"
                    label={t("celebform.address")}
                    {...register("address")}
                    error={!!errors.address}
                    autoComplete="off"
                    className={styles.input}
                  />
                  <Typography variant="error">
                    {errors.address?.message}
                  </Typography>
                </FormControl>
              </Box>
            </Box>
            <Box className={styles.row}>
              <FormControl className={styles.formControlInput}>
                <TextField
                  label={t("celebform.city")}
                  {...register("city")}
                  error={!!errors.city}
                  autoComplete="off"
                  className={styles.input}
                />
                <Typography variant="error">{errors.city?.message}</Typography>
              </FormControl>
              <FormControl className={styles.formControlInput}>
                <TextField
                  label={t("celebform.state")}
                  {...register("state")}
                  error={!!errors.state}
                  autoComplete="off"
                  className={styles.input}
                />
                <Typography variant="error">{errors.state?.message}</Typography>
              </FormControl>
            </Box>
            <Box className={styles.row}>
              <FormControl className={styles.formControlInput}>
                <TextField
                  label={t("celebform.country")}
                  {...register("country")}
                  error={!!errors.country}
                  autoComplete="off"
                  className={styles.input}
                />
                <Typography variant="error">
                  {errors.country?.message}
                </Typography>
              </FormControl>
              <FormControl className={styles.formControlInput}>
                <TextField
                  variant="outlined"
                  label={t("celebform.zip")}
                  {...register("zipcode")}
                  error={!!errors.zipcode}
                  autoComplete="off"
                  className={styles.input}
                />
                <Typography variant="error">
                  {errors.zipcode?.message}
                </Typography>
              </FormControl>
            </Box>
            <FormControlLabel
              className={styles.formControlLabel}
              control={<Checkbox onChange={onTerms} />}
              label={
                <Typography variant="body3">
                  {t("celebform.infoAccurate")}
                </Typography>
              }
            />
            <LoadingButton
              disabled={!dataIsCorrect || checkingCode}
              type="submit"
              variant="outlined"
              className={styles.btn}
            >
              {t("default.nextBtn")}
            </LoadingButton>
          </Box>
        </form>
      </Box>
    </Box>
  );
};
