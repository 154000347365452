import { Box, Button, IconButton, Typography } from "@mui/material";
import { ReactComponent as AddPerkIcon } from "assets/svg/icon-add-perk-mobile.svg";
import { ReactComponent as BurgerIcon } from "assets/svg/icon-burger-menu.svg";
import { ReactComponent as CloseIcon } from "assets/svg/icon-close.svg";
import React, { useContext } from "react";
import { Controller } from "react-hook-form";
import { List } from "react-movable";
import { LocalizationContext } from "Services/Localization/LocalizationContext";
import { useIsMobile } from "Utils/Hooks";
import { v4 as uuidv4 } from "uuid";

import styles from "./DragInputList.module.scss";

export const DragInputList = ({
  fields,
  append,
  move,
  control,
  remove,
  name,
}) => {
  const { t } = useContext(LocalizationContext);
  const isMobile = useIsMobile();

  const AddBtn = () =>
    isMobile ? (
      <Button
        className={styles.mobileBtn}
        variant="outlined"
        onClick={() => {
          append("");
        }}
      >
        <AddPerkIcon />
        <Typography component="span" variant="body2">
          {t("community.addMobileBtn")}
        </Typography>
      </Button>
    ) : (
      <Button
        className={styles.btn}
        variant="contained"
        onClick={() => {
          append("");
        }}
      >
        <Typography component="span" variant="body2">
          {t("community.perkBtn")}
        </Typography>
      </Button>
    );

  return (
    <Box className={styles.wrapper}>
      <List
        onChange={(meta) => {
          move(meta.oldIndex, meta.newIndex);
        }}
        values={fields}
        renderList={({ children, props }) => <ul {...props}>{children}</ul>}
        renderItem={({ value, props: { key, ...newProps }, index }) => {
          return (
            <li key={uuidv4()} {...newProps} className={styles.container}>
              <Box className={styles.content}>
                <BurgerIcon className={styles.burger} />
                <Controller
                  name={`${name}.${index}`}
                  control={control}
                  render={({ field }) => (
                    <input className={styles.input} {...field} />
                  )}
                />
                <IconButton
                  className={styles.closeBtn}
                  onClick={() => remove(index)}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            </li>
          );
        }}
      />
      <AddBtn />
    </Box>
  );
};
