import { Box } from "@mui/material";
import React from "react";

import { AudioWrapper } from "./AudioWrapper";

export const AudioBlock = ({ audios, postIndex }) => {
  if (!audios?.length) {
    return null;
  }

  return (
    <Box>
      {audios.map((src, index) => (
        <AudioWrapper
          src={src}
          postIndex={postIndex}
          mediaIndex={index}
          key={`${postIndex}-${index}`}
        />
      ))}
    </Box>
  );
};
