import { Box, Button, Typography } from "@mui/material";
import { ReactComponent as MessageIcon } from "assets/svg/message-icon.svg";
import React, { useContext } from "react";
import { useDispatch } from "react-redux";
import { setActiveChat } from "Redux/messengerSlice";
import { LocalizationContext } from "Services/Localization/LocalizationContext";

import Page from "../../Components/Page/Page";
import { Messenger } from "./components/Messenger/Messenger";
import styles from "./Messages.module.scss";

export const Messages = () => {
  const dispatch = useDispatch();
  const { t } = useContext(LocalizationContext);

  const newMessageHandler = () => {
    dispatch(setActiveChat({ isNewMessageTo: true }));
  };

  return (
    <Page className={styles.container}>
      <Box className={styles.titleWrapper}>
        <Typography variant="h2" className={styles.title}>
          {t("messages.messages")}
        </Typography>
        <Button variant="outlined" onClick={newMessageHandler}>
          <MessageIcon />
          <Box className={styles.btnText}>{t("messages.newMessage")}</Box>
        </Button>
      </Box>
      <Box className={styles.mainContent}>
        <Messenger />
      </Box>
    </Page>
  );
};
