import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    fontFamily: `"ClashDisplay-Regular", "Noto Sans JP", "Noto Sans KR", "Noto Sans SC", sans-serif`,
    textNormal: {
      fontFamily: `"Clash Display", "Noto Sans JP", "Noto Sans KR", "Noto Sans SC", sans-serif`,
      fontSize: 12,
      fontWeight: 400,
      color: "#FFFFFF",
    },
    text_12_display_bold: {
      fontFamily: `"Clash Display", "Noto Sans JP", "Noto Sans KR", "Noto Sans SC", sans-serif`,
      fontSize: 12,
      fontWeight: 500,
      color: "#FFFFFF",
      lineHeight: 1.2,
    },
    h1: {
      fontFamily: `"Clash Display", "Noto Sans JP", "Noto Sans KR", "Noto Sans SC", sans-serif`,
      fontSize: 54,
      lineHeight: 1.22,
      fontWeight: 500,
      letterSpacing: "-0.01em",
      color: "#FFFFFF",
    },
    h2: {
      fontFamily: `"ClashDisplay-Medium", "Noto Sans JP", "Noto Sans KR", "Noto Sans SC", sans-serif`,
      fontSize: 36,
      lineHeight: 1.14,
      fontWeight: 500,
      color: "#FFFFFF",
      "@media (max-width:1280px)": {
        fontWeight: 500,
        fontSize: 24,
        lineHeight: "30px",
      },
    },
    h3: {
      fontFamily: `"ClashDisplay-Medium", "Noto Sans JP", "Noto Sans KR", "Noto Sans SC", sans-serif`,
      fontSize: 28,
      lineHeight: 1.43,
      fontWeight: 500,
      color: "#FFFFFF",
    },
    h4: {
      fontFamily: `"ClashDisplay-Medium", "Noto Sans JP", "Noto Sans KR", "Noto Sans SC", sans-serif`,
      fontSize: 24,
      lineHeight: 1,
      fontWeight: 500,
      color: "#FFFFFF",
      "@media (max-width:1280px)": {
        fontSize: 16,
        lineHeight: 1.2,
      },
    },
    h4_24: {
      fontFamily: `"ClashDisplay-Medium", "Noto Sans JP", "Noto Sans KR", "Noto Sans SC", sans-serif`,
      fontSize: 24,
      lineHeight: 1,
      fontWeight: 500,
      color: "#FFFFFF",
    },
    subtitle: {
      fontFamily: `"ClashDisplay-Medium", "Noto Sans JP", "Noto Sans KR", "Noto Sans SC", sans-serif`,
      fontSize: 22,
      fontWeight: 500,
      color: "#FFFFFF",
    },
    h5: {
      fontFamily: `"ClashDisplay-Regular", "Noto Sans JP", "Noto Sans KR", "Noto Sans SC", sans-serif`,
      fontSize: 20,
      lineHeight: 1,
      fontWeight: 400,
      letterSpacing: "0.05em",
      color: "#FFFFFF",
    },
    h5_display_medium: {
      fontFamily: `"ClashDisplay-Medium", "Noto Sans JP", "Noto Sans KR", "Noto Sans SC", sans-serif`,
      fontSize: 20,
      lineHeight: 1,
      fontWeight: 500,
      color: "#FFFFFF",
    },
    h6: {
      fontFamily: `"ClashDisplay-Regular", "Noto Sans JP", "Noto Sans KR", "Noto Sans SC", sans-serif`,
      fontSize: 18,
      lineHeight: 1,
      fontWeight: 400,
      color: "#FFFFFF",
    },
    body6_medium_dark: {
      fontFamily: `"ClashGrotesk-Medium", "Noto Sans JP", "Noto Sans KR", sans-serif`,
      fontSize: 10,
      fontWeight: 500,
      lineHeight: 1.2,
      color: "#111322",
    },
    body18: {
      fontFamily: `"ClashGrotesk-Regular", "Noto Sans JP", "Noto Sans KR", "Noto Sans SC", sans-serif`,
      fontSize: 18,
      fontWeight: 400,
      color: "#FFFFFF",
      lineHeight: "120%",
    },
    body1: {
      fontFamily: `"ClashGrotesk-Regular", "Noto Sans JP", "Noto Sans KR", "Noto Sans SC", sans-serif`,
      fontSize: 16,
      lineHeight: 1.5,
      fontWeight: 400,
      color: "#FFFFFF",
    },
    body1_medium: {
      fontFamily: `"ClashGrotesk-Medium", "Noto Sans JP", "Noto Sans KR", sans-serif`,
      fontSize: 16,
      lineHeight: 1.5,
      fontWeight: 500,
      color: "#FFFFFF",
    },
    subtitle1: {
      fontFamily: `"ClashGrotesk-Regular", "Noto Sans JP", "Noto Sans KR", "Noto Sans SC", sans-serif`,
      fontSize: 18,
      fontWeight: 400,
      lineHeight: 1.33,
      color: "#FFFFFF",
      "@media (max-width:480)": {
        fontSize: 16,
      },
    },
    body2: {
      fontFamily: `"ClashGrotesk-Regular", "Noto Sans JP", "Noto Sans KR", "Noto Sans SC", sans-serif`,
      fontSize: 14,
      lineHeight: 1.14,
      fontWeight: 400,
      color: "#FFFFFF",
    },
    body2_display: {
      fontFamily: `"ClashDisplay-Regular", "Noto Sans JP", "Noto Sans KR", "Noto Sans SC", sans-serif`,
      fontSize: 14,
      lineHeight: 1,
      fontWeight: 400,
      color: "#FFFFFF",
    },
    body3: {
      fontFamily: `"ClashGrotesk-Regular", "Noto Sans JP", "Noto Sans KR", "Noto Sans SC", sans-serif`,
      fontSize: 12,
      lineHeight: 1.33,
      fontWeight: 400,
      color: "#FFFFFF",
    },
    body3_medium: {
      fontFamily: `"ClashGrotesk-Medium", "Noto Sans JP", "Noto Sans KR", sans-serif`,
      fontSize: 12,
      lineHeight: 1.33,
      fontWeight: 500,
      color: "#FFFFFF",
    },
    body3_medium_14_display: {
      fontFamily: `"ClashGrotesk-Medium", "Noto Sans JP", "Noto Sans KR", sans-serif`,
      fontSize: 14,
      lineHeight: 1,
      fontWeight: 500,
      color: "#FFFFFF",
    },
    body3_medium_14_display_2: {
      fontFamily: `"ClashDisplay-Medium", "Noto Sans JP", "Noto Sans KR", sans-serif`,
      fontSize: 14,
      lineHeight: 1,
      fontWeight: 500,
      color: "#FFFFFF",
    },
    body3_display_medium: {
      fontFamily: `"ClashDisplay-Medium", "Noto Sans JP", "Noto Sans KR", "Noto Sans SC", sans-serif`,
      fontSize: 12,
      lineHeight: 1.1,
      fontWeight: 500,
      color: "#FFFFFF",
    },
    body4: {
      fontFamily: `"ClashDisplay-Medium", "Noto Sans JP", "Noto Sans KR", "Noto Sans SC", sans-serif`,
      fontSize: 18,
      lineHeight: 1.1,
      fontWeight: 500,
      color: "#FFFFFF",
    },
    body4_16: {
      fontFamily: `"ClashDisplay-Medium", "Noto Sans JP", "Noto Sans KR", "Noto Sans SC", sans-serif`,
      fontSize: 16,
      lineHeight: 1.1,
      fontWeight: 500,
      color: "#FFFFFF",
    },
    body4_14: {
      fontFamily: `"ClashDisplay-Medium", "Noto Sans JP", "Noto Sans KR", "Noto Sans SC", sans-serif`,
      fontSize: 14,
      lineHeight: 1.4,
      fontWeight: 500,
      color: "#FFFFFF",
    },
    body4_16_regular: {
      fontFamily: `"ClashDisplay-Regular", "Noto Sans JP", "Noto Sans KR", "Noto Sans SC", sans-serif`,
      fontSize: 16,
      lineHeight: 1.1,
      fontWeight: 400,
      color: "#FFFFFF",
    },
    body5: {
      fontFamily: `"ClashGrotesk-Medium", "Noto Sans JP", "Noto Sans KR", sans-serif`,
      fontSize: 14,
      lineHeight: 1.2,
      fontWeight: 500,
      color: "#FFFFFF",
    },
    body6: {
      fontFamily: `"ClashGrotesk-Light", "Noto Sans JP", "Noto Sans KR", sans-serif`,
      fontSize: 14,
      fontWeight: 300,
      color: "#FFFFFF",
    },
  },
  breakpoints: {
    values: {
      lg: 1281,
      xl: 1440,
    },
  },
  components: {
    MuiContainer: {
      defaultProps: {
        maxWidth: "xl",
      },
      styleOverrides: {
        maxWidthLg: {
          paddingLeft: 24,
          paddingRight: 24,
        },
        maxWidthXl: {
          paddingLeft: 48,
          paddingRight: 48,
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          color: "#fff",
          border: "none",
          ".MuiOutlinedInput-root, .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
          ".MuiInputBase-input": {
            color: "#fff",
            background: "#21264E",
            borderRadius: "10px",
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          color: "#fff",
          fontSize: "16px",
          paddingBottom: "16px",
          fontFamily: `"ClashGrotesk-Regular", "Noto Sans JP", "Noto Sans KR", "Noto Sans SC", sans-serif`,
          "&:before": {
            borderBottom: "1px solid #FFFFFF !important",
            borderColor: "#fff",
          },
          "&:after": {
            borderColor: "#70E8FC",
          },
          "&.Mui-error": {
            fontSize: "16px",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: `"ClashGrotesk-Medium", "Noto Sans JP", "Noto Sans KR", "Noto Sans SC", sans-serif`,
          fontStyle: "normal",
          fontWeight: 500,
          fontSize: "16px",
          lineHeight: "20px",
          textTransform: "none",
          height: "64px",
          borderRadius: "10px",
          padding: "12px 29px",
          color: "#111322",
          background: "#3D4BB5",
          border: "none",
          flexShrink: 0,
          "&.MuiButton-sizeSmall": {
            height: "42px",
          },
          "&.MuiButton-sizeXsmall": {
            height: "32px",
            fontSize: "18px",
            borderRadius: "6px",
            padding: "5px 8px",
            minWidth: "auto",
          },
          "&.MuiButton-text": {
            background: "none",
            padding: 0,
            height: "auto",
            color: "#00D3F3",
          },
          "&.MuiButton-contained": {
            background: "#3D4BB5",
            color: "white",
            border: "none",
            "&:hover": {
              background: "#3D4BB1",
            },
            ".MuiLoadingButton-loadingIndicator": {
              color: "#FFFFFF",
            },
          },
          "&.MuiButton-outlined": {
            background: "none",
            color: "#FFFFFF",
            border: "1px solid #FFFFFF",
            "&:hover": {
              color: "#70E8FC",
              borderColor: "#70E8FC",
            },
            ".MuiLoadingButton-loadingIndicator": {
              color: "#FFFFFF",
            },
          },
          "&.MuiButton-red": {
            background: "#EE5261",
            color: "#111322",
            border: "none",
            transition: "all .5s",
            "&:hover": {
              color: "#111322",
              opacity: 0.7,
            },
            ".MuiLoadingButton-loadingIndicator": {
              color: "#FFFFFF",
            },
          },
          "&.Mui-disabled": {
            opacity: 0.5,
          },
        },
        containedPrimary: {
          "&:hover": {},
        },
        containedSecondary: {
          color: "#000646",
          "&:hover": {
            background: "#edfbff",
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          borderRadius: "10px",
          color: "#FFFFFF",
          ".MuiOutlinedInput-notchedOutline": {
            border: "1px solid #FFFFFF",
          },
          ".MuiOutlinedInput-notchedOutline span": {
            border: "1px solid #FFFFFF",
          },
          "&:hover": {
            ".MuiOutlinedInput-notchedOutline": {
              border: "1px solid #FFFFFF",
            },
          },
          "&.Mui-focused": {
            ".MuiOutlinedInput-notchedOutline": {
              border: "1px solid #FFFFFF",
            },
          },
        },
      },
    },
  },
  palette: {
    dark: {
      main: "#111322",
      contrastText: "#FFFFFF",
      placeholder: "rgba(255, 255, 255, 0.5)",
      surface: "#21264E",
    },
    accent: {
      main: "#ABB3F3",
      mistyLilak: "#CDD1F8",
    },
    primary: {
      main: "#3D4BB5",
      surface: "#575A75",
    },
    secondary: {
      main: "#FFFFFF",
      opacity: "rgba(255, 255, 255, 0.70)",
    },
    error: {
      main: "#EE5261",
    },
    chart: {
      main: "#3D4BB5",
      second: "#70E8FC",
      third: "#EE5261",
      forth: "#F1FC70",
      fifth: "#27E7C4",
      seventh: "rgba(112, 232, 252, 0.25)",
    },
    lime: {
      main: "#f1fc70",
      progressBg: "#737696",
      secondary: "#DAE41F",
      aqua: "#00D3F3",
    },
    tertiary: { main: "#27E7C4", progressBg: "#737696" },
    borderColor: {
      main: "rgba(240, 241, 245, 0.1)",
      progress: "rgba(255, 255, 255, 0.2)",
    },
  },
});

export default theme;
