import { Box, Typography } from "@mui/material";
import React, { useState, useContext, useEffect, useMemo, useRef } from "react";
import { LocalizationContext } from "Services/Localization/LocalizationContext";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useIsMobile } from "Utils/Hooks";
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from "recharts";

import styles from "./PieChart.module.scss";
import "./pie-chart.scss";

const PieChartComponent = ({ id, data, label, title }) => {
  const { t } = useContext(LocalizationContext);
  const theme = useTheme();
  const isMobile = useIsMobile();
  const [activeIndex, setActiveIndex] = useState(0);
  const [position, setPosition] = useState(null);
  const chartRef = useRef(null);

  const mobileBP = 768;
  const mobileWidth = useMediaQuery(theme.breakpoints.down(mobileBP));

  const [titleOffset, setTitleOffset] = useState(0);

  useEffect(() => {
    if (!chartRef?.current) {
      return;
    }

    const p = 16;
    const l = isMobile ? 0 : 16;
    const rect = chartRef.current.getBoundingClientRect();
    setTitleOffset(p + l + rect.height / 2);
  }, [isMobile]);

  const colors = Object.values(theme.palette.chart);

  const isInitialValue = false;

  const onPieEnter = (_, index) => {
    setActiveIndex(index);
  };

  useEffect(() => {
    const tooltip = document.querySelector(`#${id} .recharts-tooltip-wrapper`);
    if (!tooltip) return;

    // Init tooltip values
    const tooltipHeight = tooltip.getBoundingClientRect().height;
    const tooltipWidth = tooltip.getBoundingClientRect().width;
    const spaceForLittleTriangle = 8;

    // Rewrite tooltip styles
    tooltip.style = `
      transform: translate(${position?.data.tooltipPosition.x}px, ${
      position?.data.tooltipPosition.y
    }px);
      pointer-events: none;  position: absolute;
      top: -${tooltipHeight + spaceForLittleTriangle}px;
      left: -${tooltipWidth / 2}px;
      opacity: ${position?.show ? "1" : 0};
      transition: all 375ms ease 0s;
    `;
  }, [id, position]);

  const CategoryItem = ({ category, color }) => {
    return (
      <Box className={styles.categoryItem}>
        <Box
          className={styles.categoryItemBox}
          sx={{ backgroundColor: color }}
        ></Box>
        <Typography variant="body3" className={styles.categoryItemText}>
          {category}
        </Typography>
      </Box>
    );
  };

  const CustomTooltip = ({ active, payload, color }) => {
    if (active && payload && payload.length) {
      return (
        <Box className={styles.tooltip}>
          <Box className={styles.tooltipTriangle} />
          <Box
            className={styles.categoryItemBox}
            sx={{ backgroundColor: color }}
          ></Box>
          <Box className={styles.tooltipTextBlock}>
            <Typography variant="body3" className={styles.tooltipText}>
              {payload[0].name}
            </Typography>
            <Typography
              variant="body3"
              className={styles.tooltipText}
              sx={{ opacity: 0.6 }}
            >
              {`(${payload[0].value}%)`}
            </Typography>
          </Box>
        </Box>
      );
    }

    return null;
  };

  return (
    <>
      <Box ref={chartRef} className={styles.chartWrap}>
        {!isInitialValue && chartRef?.current ? (
          <Box className={styles.textBlock} sx={{ top: titleOffset }}>
            <Typography variant="body3" className={styles.label}>
              {label}
            </Typography>
            <Typography variant="h3" className={styles.title}>
              {title}
            </Typography>
          </Box>
        ) : null}
        <ResponsiveContainer id={id}>
          {isInitialValue ? (
            <Box className={styles.initialValueBlock}>
              <Box className={styles.initialValueCircle} />
              <Typography variant="h3" className={styles.title}>
                {t("insights.na")}
              </Typography>
            </Box>
          ) : (
            <PieChart>
              <Pie
                activeIndex={activeIndex}
                data={data}
                cx="50%"
                cy="50%"
                stroke="none"
                innerRadius={mobileWidth ? 52 : 72}
                outerRadius={mobileWidth ? 65 : 90}
                dataKey="amount"
                onMouseEnter={onPieEnter}
                onMouseMove={(data) => setPosition({ data: data, show: true })}
                onMouseOut={(data) => setPosition({ data: data, show: false })}
                cursor="pointer"
              >
                {data.map((_entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={colors[index % colors.length]}
                  />
                ))}
              </Pie>
              <Tooltip
                position={{
                  // Static position
                  x: position?.data.x ?? 0,
                  y: position?.data.y ?? 0,
                }}
                wrapperStyle={{ outline: "none", border: "none" }}
                content={
                  <CustomTooltip color={colors[activeIndex % colors.length]} />
                }
                trigger={isMobile ? "click" : "hover"}
              />
            </PieChart>
          )}
        </ResponsiveContainer>
      </Box>
      <Box className={styles.categoriesBlock}>
        {data.map((entry, index) => (
          <CategoryItem
            key={`cat-${index}`}
            category={entry.name}
            color={colors[index % colors.length]}
          />
        ))}
      </Box>
    </>
  );
};

export default PieChartComponent;
