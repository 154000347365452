import { Button, Tooltip, Typography } from "@mui/material";
import { ReactComponent as ShareIcon } from "assets/svg/share-icon.svg";
import React, { useContext, useState } from "react";
import { LocalizationContext } from "Services/Localization/LocalizationContext";

import styles from "./ShareBtn.module.scss";

export const ShareBtn = ({ shareLink, isText = true, icon, className }) => {
  const [open, setOpen] = useState(false);

  const { t } = useContext(LocalizationContext);

  const onShareHandler = async () => {
    await navigator.clipboard.writeText(shareLink);
    setOpen(true);
  };

  const handleTooltipClose = () => {
    setOpen(false);
  };

  return (
    <Tooltip
      title={t("default.copied")}
      onClose={handleTooltipClose}
      open={open}
    >
      <Button
        className={`${styles.button} ${className}`}
        onClick={onShareHandler}
      >
        {isText ? (
          <Typography variant="span">{t("default.share")}</Typography>
        ) : null}
        {icon ? icon : <ShareIcon />}
      </Button>
    </Tooltip>
  );
};
