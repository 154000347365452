export const PostIcon = () => (
  <svg
    width="22"
    height="17"
    viewBox="0 0 22 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17 5.59091V2.5664C17 1.9822 16.4941 1.5 15.8593 1.5H2.14073C1.51581 1.5 1 1.97293 1 2.5664V10.6154C1 11.1996 1.50589 11.6818 2.14073 11.6818H11"
      stroke="white"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1 3.11719V3.50767L7.83447 8.10396C8.55859 8.48201 9.43149 8.48201 10.1655 8.10396L17 3.50767V3.11719"
      stroke="white"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M18.3757 8.58995L13.5926 13.4259L13 15.5L15.0741 14.9074L19.8571 10.0714M18.3757 8.58995L19.5185 7.5C20.2497 7.88709 20.5797 8.20371 21 8.98148L19.8571 10.0714M18.3757 8.58995C19.058 9.07318 19.3824 9.40273 19.8571 10.0714"
      stroke="white"
      strokeWidth="1.2"
      strokeLinejoin="round"
    />
  </svg>
);

export const FollowersEmptyIcon = () => (
  <svg
    width="60"
    height="36"
    viewBox="0 0 60 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.1988 15.877C13.368 16.7078 12.2142 17.2309 10.9373 17.2309C8.38338 17.2309 6.32182 15.1693 6.32182 12.6154C6.32182 10.0616 8.38338 8 10.9373 8C13.4911 8 15.5527 10.0616 15.5527 12.6154M3.24488 28C-0.601314 17.2307 6.69107 21.8461 10.9373 21.8461C12.5628 21.8461 14.6348 21.1697 16.3574 20.939"
      stroke="white"
      strokeWidth="1.2658"
      strokeLinejoin="round"
    />
    <path
      d="M35.6212 14.6032C34.292 15.9324 32.4458 16.7694 30.4027 16.7694C26.3165 16.7694 23.018 13.4709 23.018 9.38469C23.018 5.2985 26.3165 2 30.4027 2C34.4889 2 37.7874 5.2985 37.7874 9.38469M18.0949 34C11.941 16.7691 23.6088 24.1537 30.4027 24.1537C37.1967 24.1537 48.8645 16.7691 42.7106 34"
      stroke="white"
      strokeWidth="1.2658"
      strokeLinejoin="round"
    />
    <path
      d="M52.3223 15.877C51.4915 16.7078 50.3377 17.2309 49.0607 17.2309C46.5069 17.2309 44.4453 15.1693 44.4453 12.6154C44.4453 10.0616 46.5069 8 49.0607 8C51.6146 8 53.6762 10.0616 53.6762 12.6154M56.7531 28C60.5993 17.2307 53.307 21.8461 49.0608 21.8461C47.7815 21.8461 46.2256 21.4271 44.7813 21.1362"
      stroke="white"
      strokeWidth="1.2658"
      strokeLinejoin="round"
    />
  </svg>
);

export const EyeIcon = () => (
  <svg
    width="24"
    height="16"
    viewBox="0 0 24 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23 8.00005C23 8.00005 18.0744 14.7223 12 14.7223C5.92556 14.7223 1 8.00005 1 8.00005C1 8.00005 5.92556 1.27783 12 1.27783C18.0744 1.27783 23 8.00005 23 8.00005Z"
      stroke="white"
      strokeWidth="0.8"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M14.4556 10.463C13.8323 11.0863 12.9523 11.4774 11.999 11.4774C10.0801 11.4774 8.51562 9.92519 8.51562 7.99408C8.51562 6.07519 10.0678 4.51074 11.999 4.51074C13.9301 4.51074 15.4823 6.06296 15.4823 7.99408"
      stroke="white"
      strokeWidth="0.8"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
  </svg>
);
