import { Box } from "@mui/material";
import classNames from "classnames/bind";
import { CARD, CARD_VARIANTS } from "Constants/card";
import { EDITION_STATUSES } from "Constants/index";
import { useLocation } from "react-router-dom";

import styles from "./Card.module.scss";
import { SmallCard } from "./Small/SmallCard";

const CONTENT = {
  [CARD_VARIANTS.SMALL]: {
    Main: SmallCard,
  },
};

export const Card = ({
  variant = CARD_VARIANTS.SMALL,
  data,
  overrideStyles,
  isGrayedOut,
}) => {
  const cx = classNames.bind(styles);
  const location = useLocation();
  const { status, imageUrl } = data || {};
  const { width, imgHeight } = CARD[variant];
  const hideImage = status === EDITION_STATUSES.phase0;
  const isSmall = variant === CARD_VARIANTS.SMALL;

  const { Main } = CONTENT[variant];
  return (
    <Box
      className={cx(
        styles.card,
        overrideStyles?.root,
        isSmall && styles.cardSmall
      )}
      component={Box}
      state={{ from: location.pathname }}
      sx={{ width }}
    >
      <Box
        className={cx(
          styles.cardImg,
          isGrayedOut && styles.cardImgNotHolding,
          overrideStyles?.img
        )}
        style={{
          backgroundImage: imageUrl && !hideImage ? `url(${imageUrl})` : null,
        }}
        sx={{ height: imgHeight }}
      />
      <Main data={data} className={styles.cardInfo} />
    </Box>
  );
};
