import { Box, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

import styles from "./StatsItemBlock.module.scss";

export const StatsItemBlock = ({ title, value, icon }) => {
  const isMobile = useMediaQuery("(max-width:768px)");

  return (
    <Box className={styles.stats}>
      <Box className={styles.statsHeader}>
        <Typography
          variant="body2"
          className={`${styles.statsTitle} opacity_07`}
        >
          {title}
        </Typography>
        {!isMobile ? icon : null}
      </Box>
      <Typography variant="h4" className={styles.statsValue}>
        {value}
      </Typography>
    </Box>
  );
};
