import { Avatar, Box, Typography } from "@mui/material";
import { format } from "date-fns";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteComments,
  likeComment,
  setCommentIsLiked,
  unlikeComment,
} from "Redux/communitySlice";
import { deleteComment } from "Services/Api/communityApi";

import { STATE_REPLIES_COUNT } from "../../utils";
import Likes from "../Likes/Likes";
import Reply from "../Reply/Reply";
import ReplyLoad from "../Reply/ReplyLoad";
import styles from "./CommentItem.module.scss";
import { ActionTooltip } from "./components/ActionTooltip/ActionTooltip";
import { CommentMainSection } from "./components/CommentMainSection/CommentMainSection";

const CommentItem = ({
  comment,
  isNested,
  handleReply,
  activeCommentReplyIds,
  setActiveCommentReplyIds,
  setActiveReplyId,
  isReplyLoading,
  setDeleteParentMode,
  setSnackBarDelete,
  startExecute,
  setDeleteMode,
  setActionLoading,
  updateRepliesCountHandler,
  isActionLoading,
  isDeleteMode,
}) => {
  const {
    id,
    appUser: { username, type, profileImageUrl, id: appUserId },
    message,
    countReplies,
    isLiked,
    likes: likesCount,
    isEdited = false,
    replyToName: replyToUser,
    replyToId,
  } = comment;

  const [isEditMode, setEditMode] = useState(false);
  const [isCommentEdited, setCommentEdited] = useState(isEdited);

  const post = useSelector((state) => state.community.posts.postById);
  const user = useSelector((state) => state.users.me.entities);

  const dispatch = useDispatch();

  const { id: postId } = post;

  const isMyComment = appUserId === user.id;

  const deleteHandler = () => {
    if (isNested) {
      setDeleteMode(true);
    } else {
      setDeleteParentMode(true);
    }
    setSnackBarDelete(true);
    setActionLoading(true);
    updateRepliesCountHandler(STATE_REPLIES_COUNT.DECREASE);
    startExecute(() =>
      dispatch(deleteComments({ postId, commentId: id })).finally(() => {
        setActionLoading(false);
      })
    );
  };

  const ActionCommentTooltip = () =>
    !isEditMode ? (
      <ActionTooltip
        deleteHandler={deleteHandler}
        editHandler={() => setEditMode(true)}
        isLoading={isActionLoading}
        isMyComment={isMyComment}
      />
    ) : null;

  const onLikeChanged = (value) => {
    dispatch(setCommentIsLiked({ commentId: id, isLiked: value }));
    value ? dispatch(likeComment(id)) : dispatch(unlikeComment(id));
  };

  const activeHandler = () => {
    if (activeCommentReplyIds.includes(id)) {
      setActiveCommentReplyIds((prevState) =>
        prevState.filter((commentId) => commentId !== id)
      );
      setActiveReplyId(null);
    } else {
      setActiveCommentReplyIds((prevState) => [...prevState, id]);
      setActiveReplyId(id);
    }
  };

  return (
    <>
      {!isDeleteMode ? (
        <Box
          id={id}
          className={`${styles.commentItemContainer} ${
            isEditMode ? styles.commentItemContainerEdit : ""
          }`}
          key={comment.id}
        >
          <Box className={styles.commentWrapper}>
            <Avatar
              src={profileImageUrl}
              alt="comment avatar"
              className={`${
                type === "influencer"
                  ? styles.commentInfluencerAvatar
                  : styles.commentAvatar
              }`}
            />
            <Box className={styles.commentItemWrapper}>
              <Box className={styles.commentItemInfoContainer}>
                <Box className={styles.commentItemInfo}>
                  <Typography variant="body2">{username}</Typography>
                  <Typography variant="body2" className={styles.commentDate}>
                    {format(new Date(comment.createdAt), "HH:mm a")}
                  </Typography>
                </Box>
                <ActionCommentTooltip />
              </Box>
              <CommentMainSection
                isEditMode={isEditMode}
                setEditMode={setEditMode}
                message={message}
                postId={postId}
                commentId={id}
                setActionLoading={setActionLoading}
                isCommentEdited={isCommentEdited}
                setCommentEdited={setCommentEdited}
                replyToUser={replyToUser}
                replyToId={replyToId}
              />
            </Box>
          </Box>

          <Box className={styles.commentItemReactions}>
            <Likes
              count={likesCount}
              isLiked={isLiked}
              onChange={onLikeChanged}
            />
            <Reply
              repliesCount={countReplies}
              isNested={isNested}
              activeHandler={activeHandler}
              isReplyLoading={isReplyLoading}
              onClick={() => {
                handleReply(comment);
              }}
              isHidden={!handleReply}
            />
          </Box>
          <ReplyLoad
            repliesCount={countReplies}
            isNested={isNested}
            activeHandler={activeHandler}
            isReplyLoading={isReplyLoading}
            handleReply={handleReply}
            comment={comment}
            isHidden={!handleReply}
          />
        </Box>
      ) : null}
    </>
  );
};

export default CommentItem;
