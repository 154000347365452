export const getTimesSlot = ({ startTime, endTime, duration }) => {
  const startTimes = [];
  let currentTime = startTime;

  while (currentTime < endTime) {
    const current = new Date(currentTime);
    const formattedHour = current.getHours().toString().padStart(2, "0");
    const formattedMinute = current.getMinutes().toString().padStart(2, "0");
    startTimes.push(`${formattedHour}:${formattedMinute}`);

    currentTime += duration * 60 * 1000;
  }

  const end = new Date(currentTime);
  if (end.getHours() === 0 && end.getMinutes() === 0) {
    startTimes.push("24:00");
  } else {
    const formattedHour = end.getHours().toString().padStart(2, "0");
    const formattedMinute = end.getMinutes().toString().padStart(2, "0");
    startTimes.push(`${formattedHour}:${formattedMinute}`);
  }

  return startTimes;
};
