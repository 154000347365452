import { Box, Skeleton } from "@mui/material";

import styles from "../Community.module.scss";

export const SkeletonBlock = () => (
  <Box>
    <Skeleton
      className={styles.postSkeleton}
      sx={{
        width: "100%",
        height: "24px",
        marginBottom: "16px",
        background: "rgba(61, 75, 181, 0.1)",
      }}
    />
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Skeleton
        className={styles.postSkeleton}
        sx={{
          width: "20%",
          background: "rgba(61, 75, 181, 0.1)",
          height: "24px",
        }}
      />
      <Skeleton
        className={styles.postSkeleton}
        sx={{
          width: "20%",
          background: "rgba(61, 75, 181, 0.1)",
          height: "42px",
        }}
      />
    </Box>
    <Skeleton
      className={styles.postSkeleton}
      sx={{
        width: "100%",
        height: "165px",
        background: "rgba(61, 75, 181, 0.1)",
      }}
    />
  </Box>
);
