import React, { useContext, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import styles from "./License.module.scss";
import { ReactComponent as Icon } from "assets/icons/close.svg";
import { Checkbox, FormControlLabel, IconButton } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { signTerms } from "../../Services/Api/api.service";
import { LocalizationContext } from "Services/Localization/LocalizationContext";

const LicenseDlg = ({ open, closeModal, success, data }) => {
  const [termsRead, setTermsRead] = useState(0);
  const [termsAgree, setTermsAgree] = useState(0);
  const [policyAgree, setPolicyAgree] = useState(0);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const { t } = useContext(LocalizationContext);

  const handleClose = () => {
    closeModal();
  };
  const onTermsRead = () => {
    setTermsRead(termsRead ? 0 : 1);
  };
  const onTermsAgree = () => {
    setTermsAgree(termsAgree ? 0 : 1);
  };
  const onPolicyAgree = () => {
    setPolicyAgree(policyAgree ? 0 : 1);
  };
  const onSubmit = async () => {
    try {
      setLoading(true);
      const params = {
        ...data,
        ...{ termsRead, termsAgree, type: "INFLUENCER" },
      };
      await signTerms(params);
      setLoading(false);
      closeModal();
      success();
    } catch (e) {
      setError(e?.response?.data?.message || t("errors.defaultError"));
      setLoading(false);
    }
  };

  const TalentLicenseHTML = t("agreement.agreementText");

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className={styles.modal}>
        <IconButton className={styles.close} onClick={handleClose}>
          <Icon />
        </IconButton>
        <Typography variant="h3" component="h3" className={styles.modalTitle}>
          {t("agreement.title")}
        </Typography>
        <Box className={styles.modalBody}>
          <Typography variant="body-2">
            <div dangerouslySetInnerHTML={{ __html: TalentLicenseHTML }}></div>
          </Typography>
          <Box className={styles.checkLabel}>
            <FormControlLabel
              control={
                <Checkbox onChange={onTermsRead} checked={!!termsRead} />
              }
              label={
                <Typography variant="body3">{t("agreement.isRead")}</Typography>
              }
            />
          </Box>
          <Box className={styles.checkLabel}>
            <FormControlLabel
              control={
                <Checkbox onChange={onPolicyAgree} checked={!!policyAgree} />
              }
              label={
                <Typography variant="body3">
                  {t("agreement.agreeTo")}
                  <a
                    className={styles.link}
                    href="https://policy.zoopcards.com/en/privacy-policy"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t("agreement.privacyPolicy")}
                  </a>{" "}
                  {t("agreement.and")}{" "}
                  <a
                    className={styles.link}
                    href="https://policy.zoopcards.com/en/terms-of-service"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t("agreement.terms")}
                  </a>
                </Typography>
              }
            />
          </Box>
          <Box className={[styles.checkLabel, styles.last]}>
            <FormControlLabel
              control={
                <Checkbox onChange={onTermsAgree} checked={!!termsAgree} />
              }
              label={
                <Typography variant="body3">
                  {t("agreement.isAgree")}
                </Typography>
              }
            />
          </Box>
          <Box mt="20px">
            <Typography variant="error">{error}</Typography>
          </Box>
          <LoadingButton
            loading={loading}
            className={styles.btn}
            onClick={onSubmit}
            disabled={!termsAgree || !termsRead || !policyAgree}
          >
            {t("agreement.send")}
          </LoadingButton>
        </Box>
      </Box>
    </Modal>
  );
};

export default LicenseDlg;
