import { Box, CircularProgress, Typography } from "@mui/material";
import React from "react";

import { getConvertedTimeZoneDate } from "../../Utils";
import styles from "./ScheduleCustomInput.module.scss";

export const ScheduleCustomInput = ({
  onClick,
  value,
  label,
  icon,
  placeholder,
  classNameWrapper,
  loader,
}) => {
  return (
    <Box
      className={`${styles.inputBox} ${
        classNameWrapper ? classNameWrapper : ""
      }`}
      onClick={onClick}
    >
      {loader ? (
        <Box className={styles.inputBoxLoader}>
          <CircularProgress size={24} />
        </Box>
      ) : null}
      <Box className={styles.icon}>{icon}</Box>
      <Typography
        variant="body2"
        component="p"
        className={styles.inputBoxLabel}
      >
        {label}
      </Typography>
      <Typography variant="body1_medium">
        {value ? getConvertedTimeZoneDate(new Date(value)) : placeholder}
      </Typography>
    </Box>
  );
};
