import { LoadingButton } from "@mui/lab";
import { Box, Button, Typography } from "@mui/material";
import { ReactComponent as ReplyArrow } from "assets/svg/reply.svg";
import React, { useContext } from "react";

import { LocalizationContext } from "../../../../Services/Localization/LocalizationContext";
import styles from "./Reply.module.scss";

const Reply = ({ isHidden = false, onClick }) => {
  const { t } = useContext(LocalizationContext);

  return (
    <Box className={`${styles.reply} ${isHidden ? styles.replyHidden : ""}`}>
      <Button
        onClick={onClick}
        sx={{ visibility: isHidden ? "hidden" : "visible" }}
      >
        <Typography variant="body2">{t("community.replyLabel")}</Typography>
      </Button>
    </Box>
  );
};

export default Reply;
