import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { Box, IconButton, Typography } from "@mui/material";
import { ReactComponent as ArrowBack } from "assets/svg/arrow-back.svg";
import { DragInputList } from "Components/DragInputList/DragInputList";
import Page from "Components/Page/Page";
import SidebarTablet from "Components/SidebarTablet/SidebarTablet";
import { PATHS } from "Constants";
import React, { useContext, useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setSnackbar } from "Redux/appSlice";
import { getSeasons, postSeasonPerks } from "Services/Api/seasonsApi";
import { LocalizationContext } from "Services/Localization/LocalizationContext";
import { useIsMobile } from "Utils/Hooks";
import * as yup from "yup";

import styles from "./EditSeasonRewards.module.scss";

export const EditSeasonRewards = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [season, setSeason] = useState({
    seasonNum: 1,
    perks: [{ tier1Perks: null, tier2Perks: null, tier3Perks: null }],
  });

  const isMobile = useIsMobile();
  const navigate = useNavigate();

  const { t } = useContext(LocalizationContext);

  const schema = yup.object({
    tier1Perks: yup
      .array()
      .of(yup.string().required().notOneOf([""], t("community.perksError"))),
    tier2Perks: yup
      .array()
      .of(yup.string().required().notOneOf([""], t("community.perksError"))),
    tier3Perks: yup
      .array()
      .of(yup.string().required().notOneOf([""], t("community.perksError"))),
  });

  const {
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const isFormValid = Object.keys(errors).length === 0;

  const { fields, append, remove, move } = useFieldArray({
    control,
    name: "tier1Perks",
  });

  const {
    fields: fieldsTier2,
    append: appendTier2,
    remove: removeTier2,
    move: moveTier2,
  } = useFieldArray({
    control,
    name: "tier2Perks",
  });

  const {
    fields: fieldsTier3,
    append: appendTier3,
    remove: removeTier3,
    move: moveTier3,
  } = useFieldArray({
    control,
    name: "tier3Perks",
  });

  useEffect(() => {
    setValue("tier1Perks", season?.perks[0]?.tier1Perks || []);
  }, [season?.perks[0]?.tier1Perks]);

  useEffect(() => {
    setValue("tier2Perks", season?.perks[0]?.tier2Perks || []);
  }, [season?.perks[0]?.tier2Perks]);

  useEffect(() => {
    setValue("tier3Perks", season?.perks[0]?.tier3Perks || []);
  }, [season?.perks[0]?.tier3Perks]);

  const onSubmit = (params) => {
    setLoading(true);
    postSeasonPerks({ seasonId: season.id, params })
      .then(() => {
        dispatch(
          setSnackbar({
            open: true,
            message: t("seasons.newRewardsAdded"),
            right: "20px",
            left: "unset",
          })
        );
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setLoading(true);
    getSeasons()
      .then((data) => {
        if (data.season) {
          setSeason(data.season);
        }
      })
      .finally(() => setLoading(false));
  }, []);

  return (
    <Page>
      <Box className={styles.titleWrap}>
        {!isMobile ? (
          <IconButton onClick={() => navigate(PATHS.SEASONS)}>
            <ArrowBack />
          </IconButton>
        ) : null}
        <SidebarTablet />
        <Typography variant="h2">
          {t("seasons.editSeasonRewards", { count: season.seasonNum })}
        </Typography>
      </Box>
      <Typography variant="body1" component="p" className={styles.description}>
        {t("seasons.editSeasonRewardsDescription")}
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box className={styles.wrappersList}>
          <Box className={styles.dragList}>
            <Typography variant="body1" className={styles.title}>
              {t("seasons.tier", { count: 1 })}
            </Typography>
            <DragInputList
              fields={fields}
              append={append}
              control={control}
              move={move}
              remove={remove}
              name="tier1Perks"
            />
          </Box>
          <Box className={styles.dragList}>
            <Typography variant="body1" className={styles.title}>
              {t("seasons.tier", { count: 2 })}
            </Typography>
            <DragInputList
              fields={fieldsTier2}
              append={appendTier2}
              control={control}
              move={moveTier2}
              remove={removeTier2}
              name="tier2Perks"
            />
          </Box>
          <Box className={styles.dragList}>
            <Typography variant="body1" className={styles.title}>
              {t("seasons.tier", { count: 3 })}
            </Typography>
            <DragInputList
              fields={fieldsTier3}
              append={appendTier3}
              control={control}
              move={moveTier3}
              remove={removeTier3}
              name="tier3Perks"
            />
          </Box>
        </Box>
        <LoadingButton
          className={styles.saveBtn}
          disabled={!isFormValid || loading}
          variant="contained"
          fullWidth
          type="submit"
          loading={loading}
        >
          {t("community.saveBtn")}
        </LoadingButton>
      </form>
    </Page>
  );
};
