import { Typography } from "@mui/material";
import { isSameDay, isThisYear, isToday } from "date-fns";
import { useContext } from "react";
import { LocalizationContext } from "Services/Localization/LocalizationContext";
import { getConvertedTimeZoneDate } from "Utils/index";

import styles from "./Messages.module.scss";

export const DateTitle = ({ dateItem, datePrevItem, index }) => {
  const { t } = useContext(LocalizationContext);

  const getValue = () => {
    if (
      index === 0 ||
      (datePrevItem && !isSameDay(new Date(dateItem), new Date(datePrevItem)))
    ) {
      const date = new Date(dateItem);
      return isToday(date)
        ? t("default.today")
        : isThisYear(date)
        ? getConvertedTimeZoneDate(date, "iiii, MMMM do")
        : getConvertedTimeZoneDate(date);
    }
    return null;
  };

  const value = getValue();

  return value ? (
    <Typography
      variant="body3"
      className={`opacity_07 ${styles.dateTitle} ${
        index === 0 ? styles.dateTitleFirst : ""
      }`}
    >
      {value}
    </Typography>
  ) : null;
};
